import {  TangiTypography, TangiButton, TangiIconButton } from '_components/TangiLibrary';
import { TangiActionToolbar } from '_components/TangiLibrary/TangiActionToolbar/TangiActionToolbar';
import { t } from 'i18next';
import { BUTTON_VARIANTS } from 'utils/theme';
import { ActionToolBarDivider } from './styles';

interface AssetsActionToolbarProps {
  totalSelected: number;
  deselectSelectedAssets: () => void;
  handleDistributeAsset: () => void;
}

const AssetsActionToolbar = ({ totalSelected, deselectSelectedAssets, handleDistributeAsset }: AssetsActionToolbarProps) => {
  return (
    <TangiActionToolbar isVisible={!!totalSelected}>
      <TangiIconButton icon="close" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={deselectSelectedAssets} />
      <TangiTypography>{t('PEOPLE.TITLE.SELECTED', { totalSelected })}</TangiTypography>
      <ActionToolBarDivider />
      <TangiButton svgIcon="send" text={t('GENERAL.BUTTONS_TEXT.NOTIFY')} onClick={handleDistributeAsset} data-testid="notify-assets" />
    </TangiActionToolbar>
  );
};

export default AssetsActionToolbar;
