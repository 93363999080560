import { IdAndName } from '../types';

export interface Course {
  id: string;
  title: string;
  description?: string;
  author?: string;
  client?: IdAndName;
  difficulty: CourseDifficulty;
  featuredVideoUrl: string;
  createdBy: string;
  updatedBy: string;
  createdFrom: string;
  updatedFrom: string;
  date?: string;
  createdAt: string;
  updatedAt: string;
  alternativeVideoUrls?: string[];
}
export interface ICourseProgress {
  _id: string;
  title: string;
  description: string | null;
  author: string;
  client: string;
  difficulty: string;
  featuredVideoUrl: string;
  createdBy: string;
  updatedBy: string;
  createdFrom: string;
  updatedFrom: string;
  date: string;
  createdAt: string;
  updatedAt: string;
  progression: IProgression | null;
}

export interface IProgression {
  user: string;
  course: string;
  status: CourseProgressionStatus;
  createdAt: string;
  updatedAt: string;
  score: string;
  id: string;
}

export enum CourseProgressionStatus {
  COMPLETED = 'Completed',
  IN_PROGRESS = 'In Progress',
  REQUIRED = 'Required For you',
  RETAKE_QUIZ = 'Retake Quiz',
}

export enum CourseDifficulty {
  BEGINNER = 'Beginner',
  INTERMEDIATE = 'Intermediate',
  ADVANCED = 'Advanced',
}
