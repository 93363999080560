import { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, batch } from 'react-redux';

import { getAssetMetaData } from 'redux-toolkit/thunks/assetMetaDataThunks';
import { getAgreementVersions } from 'redux-toolkit/thunks/clientThunks';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { clientSettingsActions } from '../../_actions';
import { getAssetById, getFilePathUrl, deleteAssetFilePreview, deleteAsset as deleteAssetFromSystem } from 'redux-toolkit/thunks/assetsThunks';
import { RESULT_STATUS } from '../../utils/enums';

const useAsset = () => {
  const PreviewURL = process.env.REACT_APP_previewURL;

  const dispatch = useDispatch();
  const { clientId, assetId } = useParams();

  const { permissions, user, loggedIn } = useSelector((state) => state.authentication);
  const {
    asset,
    assetLoading,
    filePath,
    deleteAssetSuccess,
    deleteAssetError,
    deleteAssetStatus,
    loading: assetDeleteLoading,
    deleteRecipientData,
    assetCreationToastProps,
  } = useSelector((state) => state.asset);
  const { authors: contributors, businessValues, tags, assetTypes, projects, loading: metaDataLoading, departments } = useSelector((state) => state.assetMetaData);

  const [fileUrl, setFileUrl] = useState('');
  const [showDistributeModal, setShowDistributeModal] = useState(false);

  const isMetaDataFetched = useMemo(
    () => contributors?.length > 0 && businessValues?.length > 0 && assetTypes?.length > 0 && !metaDataLoading,
    [contributors, businessValues, assetTypes, tags, projects, departments, metaDataLoading],
  );

  // Data fetching
  useEffect(() => {
    batch(() => {
      dispatch(getAssetById(assetId));
      if (!isMetaDataFetched && !metaDataLoading) {
        dispatch(getAssetMetaData(clientId));
      }
      dispatch(clientSettingsActions.getClientSettings(clientId));
      dispatch(getAgreementVersions({ client: clientId }));
    });
  }, []);

  useEffect(() => {
    if (filePath?.file) {
      setFileUrl(`${PreviewURL + filePath?.file}`);
    }
  }, [filePath]);

  useEffect(() => {
    if (deleteRecipientData && deleteRecipientData[0] && deleteRecipientData[0].message === 'Recipient deleted Successfully') {
      setShowDistributeModal(false);
      dispatch(getAssetById(assetId));
    }
  }, [deleteRecipientData]);

  const handleDeleteAssetFilePreview = (data) => {
    setFileUrl('');
    dispatch(deleteAssetFilePreview({ data, token: '', isAuthenticated: loggedIn }));
  };

  const clearFileUrl = () => {
    dispatch(assetsActions.setClearFilePathUrl());
  };

  const getFilePath = (payload) => {
    dispatch(getFilePathUrl({ data: payload, token: '', isPreview: false }));
  };

  const handleDeleteAsset = (id) => {
    dispatch(deleteAssetFromSystem(id));
  };

  const clearDeleteStatus = () => {
    dispatch(assetsActions.setClearDeleteStatus());
  };

  const clearPeopleToast = () => {
    batch(() => {
      dispatch(usersActions.setPeopleToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
      dispatch(usersActions.setClearUser());
    });
  };

  return {
    clientId,
    assetId,
    permissions,
    user,
    asset,
    assetLoading,
    fileUrl,
    deleteAssetSuccess,
    deleteAssetError,
    deleteAssetStatus,
    showDistributeModal,
    assetDeleteLoading,
    assetCreationToastProps,
    clientId,
    setShowDistributeModal,
    handleDeleteAssetFilePreview,
    clearFileUrl,
    getFilePath,
    handleDeleteAsset,
    clearDeleteStatus,
    clearPeopleToast,
  };
};

export default useAsset;
