import styled from 'styled-components';
import { ACCOUNT_STATUS } from 'utils/enums';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { PeopleStatusItem as PeopleStatusItemProps } from '../Widgets.types';
import { TangiTypography } from '_components/TangiLibrary';

const color = {
  [ACCOUNT_STATUS.ACTIVE]: { background: '#F7FFFE', border: '#BCF2E8' },
  [ACCOUNT_STATUS.PENDING]: { background: '#FFFDF0', border: '#F1EBBE' },
  [ACCOUNT_STATUS.DISABLED]: { background: '#FFF7F6', border: '#F4CDCB' },
  [ACCOUNT_STATUS.TERMINATED]: { background: '#FFF7F6', border: '#e11207' },
};

const ItemContainer = styled.div<{ backgroundColor: string; borderColor: string }>`
  display: flex;
  height: 58px;
  padding: ${SPACING[3]};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${SPACING[2]};
  border: 1px solid ${NEUTRAL_SHADES[400]};
  flex: 1;
  background-color: ${(props) => props.backgroundColor};
  border-color: ${(props) => props.borderColor};
`;

const PeopleStatusItem = ({ status, amount }: PeopleStatusItemProps) => {
  const isEmpty = !amount;
  const backgroundColor = isEmpty ? NEUTRAL_SHADES[100] : color[status].background;
  const borderColor = isEmpty ? NEUTRAL_SHADES[400] : color[status].border;

  return (
    <ItemContainer backgroundColor={backgroundColor} borderColor={borderColor} data-testid="people-status-item">
      <TangiTypography weight="semibold" type="heading-lg" color={isEmpty ? NEUTRAL_SHADES[700] : NEUTRAL_SHADES.BLACK}>
        {amount}
      </TangiTypography>
      <TangiTypography type="sub-body" color={isEmpty ? NEUTRAL_SHADES[800] : NEUTRAL_SHADES.BLACK}>
        {status}
      </TangiTypography>
    </ItemContainer>
  );
};

export default PeopleStatusItem;
