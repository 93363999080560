import { useTranslation } from 'react-i18next';

import { NOTIFICATION_VARIANTS, TangiNotification } from '_components/TangiLibrary';

interface Props {
  variant: NOTIFICATION_VARIANTS;
  translationKey: string;
  dataTestId: string;
}

const DropZoneNotification = ({ variant, translationKey, dataTestId }: Props) => {
  const { t } = useTranslation();

  return (
    <TangiNotification dataTestId={dataTestId} variant={variant}>
      {t(translationKey)}
    </TangiNotification>
  );
};

export default DropZoneNotification;
