/* eslint-disable no-useless-return */
import React, { useState, useEffect, useCallback } from 'react';
import { Form, Row, Button } from 'react-bootstrap';
import { Controller, useForm, useFormState, useWatch } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import _ from 'lodash';

import { createLawFirm } from 'redux-toolkit/thunks/lawFirmThunks';
import './AdminClientForm.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../Loader';
import { useIsMount } from '../../../utils/customHooks';
import { lawFirmActions } from 'redux-toolkit/slices/lawfirmSlice';
import { adminClientActions } from '../../../AdminRedux/_actions';
import { AsyncSelect, optionTypes } from '../../AsyncSelect';
import { getFileExtensionCard, icons } from '../../../utils/fileUtils';
import { scrollToTop } from '../../../utils/windowFuncs';
import { TangiToggle } from '_components/TangiLibrary';

function AdminClientForm({ type, action, getFirm, firmid, onSuccess }) {
  const acceptedFilesList = 'image/png, image/jpeg';
  const formTypes = {
    CLIENT: 'Add new item to Clients',
    LAWFIRM: 'Add new firm',
    EDIT_LAWFIRM: 'Edit firm',
    VIEW_LAWFIRM: 'View Firm',
  };

  const isEditLawfirm = (formType) => formType === formTypes.EDIT_LAWFIRM;
  const isViewLawfirm = (formType) => formType === formTypes.VIEW_LAWFIRM;
  const isEditViewLawfirm = (formType) => isEditLawfirm(formType) || isViewLawfirm(formType);

  const clientStatusMsg = {
    CLIENT_SUCCESS: 'Client Created Successfully',
  };
  const [isloading, setIsloading] = useState(false);
  const [existingLogo, setExistingLogo] = useState({});
  const [clientStatus, setClientStatus] = useState(null);
  const [isView, setIsView] = useState(false);
  const { editFirm } = useSelector((state) => state.clientAdmin && state.clientAdmin);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      lawfirm: [],
      permissions: {
        aliasId: { hasPermission: true },
        sharePoint: { hasPermission: false },
        ipAudit: { hasPermission: false },
      },
    },
  });
  const { dirtyFields } = useFormState({
    control,
  });

  const lawfirm = useWatch({
    control,
    name: 'lawfirm',
    defaultValue: '',
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    // watch: watch2,
    setValue: setValue2,
    formState: { errors: errors2 },
  } = useForm({
    mode: 'all',
  });

  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [errors1, setErrors1] = useState('');

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          setErrors1(`Please upload only accepted formats! We are trying to update other formats type in future`);
          setTimeout(() => {
            setErrors1('');
          }, 3000);
        }

        if (err.code === 'file-invalid-type') {
          setErrors1(`Please upload only accepted formats! We are trying to update other formats type in future`);
          setTimeout(() => {
            setErrors1('');
          }, 3000);
        }
      });
    });
    setFiles(acceptedFiles);
  }, []);
  const { error: lawFirmError, status } = useSelector((state) => state.lawfirm);
  const { errors: clientError, createClient } = useSelector((state) => state.clientAdmin && state.clientAdmin);

  useEffect(() => {
    if (!_.isEmpty(createClient)) {
      setIsloading(false);
      setClientStatus(clientStatusMsg.CLIENT_SUCCESS);
      setTimeout(() => {
        scrollToTop();
      }, 500);
    } else if (!_.isEmpty(clientError)) {
      setIsloading(false);
    }
  }, [clientError, createClient]);

  const isMount = useIsMount();

  useEffect(() => {
    // setIsloading(true);
    if (!isMount) {
      setValue('logo', files, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    }
  }, [files]);

  useEffect(() => {
    if (formTypes.CLIENT === type) {
      dispatch(adminClientActions.getAdminLawFirm(1, ''));
    }
  }, []);

  useEffect(() => {
    if (action === 'edit' || action === 'view') {
      reset2(transformAPItoFormData(getFirm));
    }
    if (action === 'view') {
      setIsView(true);
    }
    if (getFirm && getFirm.id) {
      setIsloading(false);
    }
  }, [getFirm]);

  useEffect(() => {
    if (editFirm && editFirm.id) {
      setIsloading(false);
      onSuccess();
    }
  }, [editFirm]);

  function transformAPItoFormData(data) {
    if (data.logo && data.logoName && _.isEmpty(data.profile)) {
      setExistingLogo({
        name: data.logoName,
        link: data.logo,
      });
    }

    const form = {};
    for (const [key] of Object.entries(data)) {
      switch (key) {
        case 'description':
          form[key] = data.description;
          break;
        case 'name':
          form[key] = data.name;
          break;
        case 'parent':
          form[key] = data.parent;
          break;
        case 'slug':
          form[key] = data.slug;
          break;
        default:
          form[key] = data[key];
          break;
      }
    }
    return form;
  }

  const onSubmitClient = (data) => {
    delete data.lawfirm;
    if (!data.lawyers) {
      data.lawyers = [];
    }
    setIsloading(true);
    dispatch(adminClientActions.createAdminClient(data));
  };

  const onSubmitLawFirm = (data) => {
    if (_.isEmpty(data.logo)) {
      data.logo = ' ';
    }

    setIsloading(true);

    if (action === 'edit') {
      dispatch(adminClientActions.getAdminLawFirmEdit(firmid, data));
    } else {
      setIsloading(false);
      // eslint-disable-next-line no-unused-expressions
      data.logo && _.isEmpty(data.logo) && delete data.logo;
      dispatch(createLawFirm(data));
    }
  };

  useEffect(() => {
    if (status === 'success') {
      reset2('', {
        keepValues: false,
      });
      setTimeout(() => {
        dispatch(lawFirmActions.clearLawFirmStatus());
      }, 2000);
    }

    if (clientStatus === 'Client Created Successfully') {
      reset('', {
        keepValues: false,
      });
      setFiles([]);
      setTimeout(() => {
        dispatch(adminClientActions.clearAdminClient());
        setClientStatus(null);
      }, 2000);
    }
  }, [status, clientStatus]);

  function handleFiles(profilePicture, id) {
    if (isEditLawfirm(type)) {
      setExistingLogo({});
      setValue2('logo', null);
      setValue2('logoName', null);
    }

    setValue2(id, profilePicture);
  }

  return (
    <Row className="m-0">
      <Loader isLoading={isloading} />
      <div className="col-md-12 col-lg-12 mx-auto mt-4">
        <div className="border-0 rounded">
          {/* <pre>{JSON.stringify(watch2(), null, 2)}</pre>
          <pre>{JSON.stringify(existingLogo, null, 2)}</pre> */}
          <h3 className="font-weight-normal">{type}</h3>
          {type === 'Add new item to Clients' ? (
            <div className="mb-2 card py-4 p-3 px-5">
              {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}

              <Form onSubmit={handleSubmit(onSubmitClient)}>
                {/* Client Name */}
                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formClientName">
                    <Form.Label className="text-dark fw-600">
                      Add Title <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control data-testid="title" type="text" placeholder="Client Name" {...register('name', { required: { value: true, message: 'Title is required' } })} />
                    <div className="d-block invalid-feedback">{errors.name && errors.name.message}</div>
                  </Form.Group>
                </div>

                {/* Client Logo */}
                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formClientLogo">
                    <Form.Label className="text-dark fw-600">Logo</Form.Label>
                    <Controller
                      data-testid="logo"
                      render={({ field: { ref, ...rest } }) => (
                        <Dropzone maxFiles={1} {...rest} onDrop={onDrop} accept={acceptedFilesList}>
                          {({ getRootProps, getInputProps, open }) => (
                            <div
                              {...getRootProps()}
                              ref={ref}
                              onKeyPress={open}
                              className="bg-secondary text-center rounded text-muted mb-3 dropZone"
                              style={{ cursor: 'pointer' }}
                              role="presentation"
                            >
                              <input {...getInputProps()} />
                              <svg className="my-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fill="#828282"
                                  d="M9.375 7.44595H11.107V15.3749C11.107 15.478 11.1914 15.5624 11.2945 15.5624H12.7008C12.8039 15.5624 12.8883 15.478 12.8883 15.3749V7.44595H14.625C14.782 7.44595 14.8687 7.26548 14.7727 7.14361L12.1477 3.82017C12.1301 3.79776 12.1077 3.77964 12.0821 3.76717C12.0565 3.75471 12.0285 3.74823 12 3.74823C11.9715 3.74823 11.9435 3.75471 11.9179 3.76717C11.8923 3.77964 11.8699 3.79776 11.8523 3.82017L9.22734 7.14127C9.13125 7.26548 9.21797 7.44595 9.375 7.44595ZM20.5781 14.6717H19.1719C19.0688 14.6717 18.9844 14.7561 18.9844 14.8592V18.4686H5.01562V14.8592C5.01562 14.7561 4.93125 14.6717 4.82812 14.6717H3.42188C3.31875 14.6717 3.23438 14.7561 3.23438 14.8592V19.4999C3.23438 19.9147 3.56953 20.2499 3.98438 20.2499H20.0156C20.4305 20.2499 20.7656 19.9147 20.7656 19.4999V14.8592C20.7656 14.7561 20.6812 14.6717 20.5781 14.6717Z"
                                />
                              </svg>
                              <span className="text-muted fs-6">
                                Drag &apos;n&apos; drop some files here, or <span className="card-link text-primary text-decoration-underline">Choose file</span>
                              </span>
                            </div>
                          )}
                        </Dropzone>
                      )}
                      name="logo"
                      control={control}
                    />
                    <ul className="p-0">
                      {files &&
                        files.length > 0 &&
                        files.map((acceptedFile) => (
                          <li key={acceptedFile.name} style={{ listStyle: 'none' }} className="border border-black rounded-3">
                            <div className="px-4 py-1">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <i aria-hidden="true" className={`${icons[getFileExtensionCard(acceptedFile.name)]} me-2 icon-big`} />
                                  <div className="m-2">
                                    <span>{acceptedFile.name}</span>
                                  </div>
                                </div>
                                <button type="button" onClick={() => setFiles([])} className="btn del-btnt btn-sm btn btn-secondary remove-btn">
                                  <i className="far fa-trash-alt" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </Form.Group>
                </div>
                {errors1 && <div className="alert alert-primary fade show text-center w-100">{errors1}</div>}
                {/* Client Description */}
                <div className="mb-3 col-12 border-0 row">
                  <Form.Group controlId="formDescription">
                    <Form.Label className="text-dark fw-600">Description</Form.Label>
                    <Form.Control data-testid="description" as="textarea" style={{ height: '100px' }} {...register('description')} />
                  </Form.Group>
                </div>

                {/* Client Law Firm  */}
                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formClientLawFirm">
                    <Form.Label className="text-dark fw-600">Law Firm</Form.Label>
                    <Controller
                      name="lawfirm"
                      data-testid="lawfirm"
                      render={({ field: { ref, ...rest } }) => (
                        <AsyncSelect
                          inputRef={ref}
                          selectStyles="ff-din-DemiBold"
                          type={optionTypes.LAWFIRMS}
                          {...rest}
                          reloadOptions
                          reloadVar={clientStatus === clientStatusMsg.CLIENT_SUCCESS && clientStatusMsg.CLIENT_SUCCESS}
                        />
                      )}
                      control={control}
                      // rules={{ required: { value: true, message: 'Lawfirm Field is required' } }}
                    />
                  </Form.Group>
                </div>

                {/* Client Law Firm - Lawyers */}
                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formClientLawyers">
                    <Form.Label className="text-dark fw-600">
                      Lawyers <span className={dirtyFields.lawfirm ? 'text-danger' : 'text-white'}>*</span>
                    </Form.Label>
                    <Controller
                      name="lawyers"
                      data-testid="lawyers"
                      render={({ field: { ref, ...rest } }) => (
                        <AsyncSelect
                          reloadOptions
                          inputRef={ref}
                          selectStyles="ff-din-DemiBold"
                          type={optionTypes.LAWYERS}
                          {...rest}
                          isMulti
                          isDisabled={!dirtyFields.lawfirm}
                          reloadVar={dirtyFields.lawfirm && lawfirm}
                          lawFirmId={dirtyFields.lawfirm && lawfirm}
                        />
                      )}
                      control={control}
                      rules={{ required: { value: dirtyFields.lawfirm, message: 'Lawyers Field is required' } }}
                    />
                  </Form.Group>
                </div>

                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formClientAssetsPrefix">
                    <Form.Label className="text-dark fw-600">
                      Add Assets Prefix <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      data-testid="prefix"
                      type="text"
                      maxLength="6"
                      placeholder="Client Assets Prefix"
                      {...register('assetsPrefix', {
                        required: { value: true, message: 'Asset Prefix is required' },
                        maxLength: { value: 6, message: 'Asset Prefix must be exactly 6 characters' },
                        minLength: { value: 6, message: 'Asset Prefix must be exactly 6 characters' },
                        pattern: {
                          value: /^[0-9]*$/,
                          message: 'Only digits [0-9] are allowed!',
                        },
                      })}
                    />
                    <div className="d-block invalid-feedback">{errors.assetsPrefix && errors.assetsPrefix.message}</div>
                  </Form.Group>
                </div>

                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formRefCountStarter">
                    <Form.Label className="text-dark fw-600">
                      Add Reference Counter Starter <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength="6"
                      data-testid="counter"
                      placeholder="Assets Counter Starter"
                      {...register('refCountStarter', {
                        required: { value: true, message: 'Asset Reference Counter Starter is required' },
                        maxLength: { value: 6, message: 'Reference Counter Starter must be exactly 6 characters' },
                        minLength: { value: 6, message: 'Reference Counter Starter must be exactly 6 characters' },
                        pattern: {
                          value: /^[0-9]*$/,
                          message: 'Only digits [0-9] are allowed!',
                        },
                      })}
                    />
                    <div className="d-block invalid-feedback">{errors.refCountStarter && errors.refCountStarter.message}</div>
                  </Form.Group>
                </div>

                <Form.Group className="mb-3" controlId="formXrayFeaturesPatent">
                  <TangiToggle
                    label="Enable Asset Creation from Alias ID"
                    checked={watch('permissions.aliasId.hasPermission')}
                    setChecked={() => setValue('permissions.aliasId.hasPermission', !watch('permissions.aliasId.hasPermission'))}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formQuickStart">
                  <TangiToggle
                    label="Enable Quick Start"
                    checked={watch('permissions.ipAudit.hasPermission')}
                    setChecked={() => setValue('permissions.ipAudit.hasPermission', !watch('permissions.ipAudit.hasPermission'))}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formSharePointPermission">
                  <TangiToggle
                    label="Enable SharePoint integration"
                    checked={watch('permissions.sharePoint.hasPermission')}
                    setChecked={() => setValue('permissions.sharePoint.hasPermission', !watch('permissions.sharePoint.hasPermission'))}
                  />
                </Form.Group>
                {watch('permissions.sharePoint.hasPermission') && (
                  <div className="mt-3 border-0 row">
                    <Form.Control
                      type="email"
                      placeholder="Enter user email"
                      {...register('permissions.sharePoint.integratorEmail', {
                        required: {
                          value: true,
                          message: 'Email is required for SharePoint',
                        },
                      })}
                    />
                  </div>
                )}

                {/* Sumbit Button */}
                <div className="d-flex justify-content-end">
                  <Button className="my-4 btn btn-lg px-5 form-btn btn btn-primary text-white" type="submit">
                    Add
                  </Button>
                </div>

                <div className="mt-3 row">
                  <div style={{ backgroundColor: '#F75676' }} className={`alert alert-primary fade ${clientError && clientError.message ? 'show' : ''} rounded py-2 text-white`}>
                    <span style={{ fontSize: '13px' }} className="mx-3">
                      {clientError && clientError.message}
                    </span>
                  </div>
                  <div
                    style={{ backgroundColor: '#4ABB79', borderColor: '#4ABB79' }}
                    className={`alert alert-primary fade ${clientStatus === clientStatusMsg.CLIENT_SUCCESS ? 'show' : ''} rounded py-2 text-white`}
                  >
                    <span style={{ fontSize: '13px' }} className="mx-3">
                      {clientStatus && clientStatus}
                    </span>
                  </div>
                </div>
              </Form>
            </div>
          ) : (
            <Form onSubmit={handleSubmit2(onSubmitLawFirm)} className="ff-din-regular">
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label className="text-dark fs-6">
                  Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control data-testid="name" disabled={isView} type="text" placeholder="Name" {...register2('name', { required: { value: true, message: 'Name is required' } })} />
                <small className="form-text text-muted">The name is how it appears on your site.</small>
                <div className="d-block invalid-feedback">{errors2.name && errors2.name.message}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formSlug">
                <Form.Label className="text-dark fs-6">Slug</Form.Label>
                <Form.Control data-testid="slug" disabled={isView} type="text" placeholder="Slug" {...register2('slug')} />
                <small className="form-text text-muted">The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens.</small>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formDescription">
                <Form.Label className="text-dark fs-6">Description</Form.Label>
                <Form.Control data-testid="description" disabled={isView} as="textarea" rows={3} placeholder="Description" {...register2('description')} />
                <small className="form-text text-muted">The description is not prominent by default; however, some themes may show it.</small>
              </Form.Group>
              {isEditViewLawfirm(type) && !_.isEmpty(existingLogo) && (
                <ul className="p-0">
                  <li style={{ listStyle: 'none' }} className="border border-black rounded-3 cursor-pointer">
                    <div className="px-4 py-1">
                      <div className="d-flex justify-content-between align-items-center">
                        <img src={existingLogo.link} className="existing-logo img-responsive" alt={existingLogo.name} />

                        {!isViewLawfirm(type) && (
                          <button
                            type="button"
                            onClick={() => {
                              setExistingLogo({});
                              setValue2('logo', null);
                              setValue2('logoName', null);
                            }}
                            className="btn del-btnt btn-sm btn btn-secondary remove-btn"
                          >
                            <i className="far fa-trash-alt" aria-hidden="true" />
                          </button>
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
              )}

              {isEditLawfirm(type) && (
                <Dropzone onDrop={(file) => handleFiles(file, 'profile')} accept={acceptedFilesList}>
                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <>
                      <div {...getRootProps()} className="u-focus-outline dropzone-box text-center p-2 text-muted">
                        <input {...getInputProps()} />
                        <svg className="my-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fill="#828282"
                            d="M9.375 7.44595H11.107V15.3749C11.107 15.478 11.1914 15.5624 11.2945 15.5624H12.7008C12.8039 15.5624 12.8883 15.478 12.8883 15.3749V7.44595H14.625C14.782 7.44595 14.8687 7.26548 14.7727 7.14361L12.1477 3.82017C12.1301 3.79776 12.1077 3.77964 12.0821 3.76717C12.0565 3.75471 12.0285 3.74823 12 3.74823C11.9715 3.74823 11.9435 3.75471 11.9179 3.76717C11.8923 3.77964 11.8699 3.79776 11.8523 3.82017L9.22734 7.14127C9.13125 7.26548 9.21797 7.44595 9.375 7.44595ZM20.5781 14.6717H19.1719C19.0688 14.6717 18.9844 14.7561 18.9844 14.8592V18.4686H5.01562V14.8592C5.01562 14.7561 4.93125 14.6717 4.82812 14.6717H3.42188C3.31875 14.6717 3.23438 14.7561 3.23438 14.8592V19.4999C3.23438 19.9147 3.56953 20.2499 3.98438 20.2499H20.0156C20.4305 20.2499 20.7656 19.9147 20.7656 19.4999V14.8592C20.7656 14.7561 20.6812 14.6717 20.5781 14.6717Z"
                          />
                        </svg>
                        <p className=" text-black fs-7">
                          Drag and drop photo, or <span className="card-link text-primary text-decoration-underline color-hover">browse</span>
                        </p>
                      </div>

                      <aside>
                        <small className="text-muted fs-7 txt-size">Upload only accepted formats (.jpeg, .png, .jpg).</small>
                        <ul className="fs-7">{acceptedFiles && acceptedFiles[0] && acceptedFiles[0].name}</ul>
                      </aside>
                    </>
                  )}
                </Dropzone>
              )}
              {type === 'Add new firm' ? (
                <Form.Group className="mb-3" controlId="formLogo">
                  <Form.Label>Law firm logo</Form.Label> <br />
                  <Form.Control type="file" size="lg" {...register2('logo')} />
                </Form.Group>
              ) : (
                ''
              )}
              <div className="d-flex justify-content-center">
                <Button disabled={isView} className="my-4 btn btn-lg px-5 form-btn btn btn-primary text-white" type="submit">
                  {type}
                </Button>
              </div>

              <div className={`alert alert-primary fade ${status === 'failed' ? 'show' : ''} text-center w-100`}>{lawFirmError}</div>

              <div style={{ backgroundColor: '#4ABB79', borderColor: '#4ABB79' }} className={`alert alert-primary fade ${status === 'success' ? 'show' : ''} text-center w-100`}>
                LawFirm Created Successfully
              </div>
            </Form>
          )}
        </div>
      </div>
    </Row>
  );
}

export { AdminClientForm };
