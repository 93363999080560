import { TangiTypography } from '_components/TangiLibrary/TangiTypography';
import { AVATAR_SIZES, NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { GroupType } from '../types';
import { usePartnership } from '_hooks/usePartnership';
import PartnershipOrClientLogo from '_components/ThirdPartyTag/components/PartnershipOrClientLogo';
import { RecipientCategoryType } from 'utils/types/assets/assets';
import { FlexContainer } from 'utils/globalStyles';
import styled from 'styled-components';

interface Props {
  groupId: string;
  groupName: string;
  groupType: GroupType;
  recipientsNum: number;
}

const Container = styled(FlexContainer)`
  gap: ${SPACING[1]};
`;

export const GroupDisplay = ({ groupId, recipientsNum, groupType, groupName }: Props) => {
  const partnership = usePartnership(groupId);

  return (
    <Container>
      {groupType === RecipientCategoryType.PARTNER && partnership && (
        <PartnershipOrClientLogo isPartnership={true} name={partnership.name} logo={partnership.logo} size={partnership?.logo && AVATAR_SIZES.XS} />
      )}
      <TangiTypography color={NEUTRAL_SHADES.BLACK} weight="semibold">{`${groupName} (${recipientsNum})`}</TangiTypography>
    </Container>
  );
};
