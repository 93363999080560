import React, { useEffect, useState } from 'react';
import { Form, Row, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Select from 'react-select';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';

import './AdminCoursesForm.scss';
import { TANGI_BUTTON_SIZES, TangiButton, TangiIconButton } from '_components/TangiLibrary';
import { Star } from '../../Star';
import { Loader } from '../../Loader';
import { adminCourseActions } from '../../../AdminRedux/_actions';
import { NestedChoices } from '../../NestedChoices';
import { EditAsyncSelect, optionTypes } from '../../EditAsyncSelect';
import { BUTTON_VARIANTS } from 'utils/theme';

function AdminCoursesForm({ type }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isloading, setIsloading] = useState(false);
  const [defaults, setDefaults] = useState({});

  const isEditMode = type === 'Edit course';
  const isCreateMode = type === 'Add new item to Courses';

  const { createCourse, updateCourse, statusUpdateCourse, errors: courseErrors, getCourseByID, statusCourse } = useSelector((state) => state.adminCourseReducer);

  const difficult = [
    { value: '1', label: 'Beginner' },
    { value: '2', label: 'Intermediate' },
    { value: '3', label: 'Advanced' },
  ];

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'questions',
  });

  const {
    fields: alternativeVideoFields,
    append: appendAlternativeLink,
    remove: removeAlternativeLink,
  } = useFieldArray({
    control,
    name: 'alternativeVideoUrls',
  });

  useEffect(() => {
    dispatch(adminCourseActions.getAdminCategory(1000));
  }, []);

  useEffect(() => {
    if (isEditMode && id) {
      dispatch(adminCourseActions.getAdminCourseById(id));
    }
  }, []);

  useEffect(() => {
    if (statusCourse === 'success' || statusUpdateCourse === 'success') {
      setIsloading(true);
      setTimeout(() => {
        history.push('/admin/course');
      }, 3000);
    }
  }, [statusCourse, statusUpdateCourse]);

  useEffect(() => {
    if (!_.isEmpty(getCourseByID)) {
      const { client, difficulty, featuredVideoUrl, title, questions, description, alternativeVideoUrls } = getCourseByID;

      setDefaults({
        CLIENT: {
          value: client?.id || '',
          label: client?.name || '',
        },
      });

      reset({
        description,
        difficulty: {
          value: difficulty,
          label: difficult?.find((item) => item.label === difficulty)?.label,
        },
        featuredVideoUrl,
        title,
        questions,
        alternativeVideoUrls: alternativeVideoUrls?.map((item) => ({ url: item })),
      });
    }
  }, [getCourseByID]);

  useEffect(() => {
    if (createCourse && createCourse.id) {
      setTimeout(() => {
        setIsloading(false);
      }, 3000);
    } else {
      setTimeout(() => {
        setIsloading(false);
      }, 7000);
    }
  }, [createCourse]);

  useEffect(() => {
    if (updateCourse && updateCourse.id) {
      setTimeout(() => {
        setIsloading(false);
      }, 3000);
    } else {
      setTimeout(() => {
        setIsloading(false);
      }, 7000);
    }
  }, [updateCourse]);

  function onSubmit(formData) {
    setIsloading(true);
    const alternativeVideoUrls = formData?.alternativeVideoUrls?.map((item) => item.url).filter((url) => url.trim()) || [];

    const { client, ...rest } = formData;
    const cleanedData = client ? formData : rest;

    const payload = {
      ...cleanedData,
      difficulty: cleanedData?.difficulty.label,
      alternativeVideoUrls,
    };

    if (isCreateMode) {
      dispatch(adminCourseActions.adminCreateCourse(payload));
    } else if (isEditMode) {
      dispatch(adminCourseActions.updateAdminCourse(id, payload));
    } else {
      setIsloading(false);
      return;
    }
  }

  const handleAppendQuestion = () => {
    append({ text: '' });
  };

  const handleAppendAlternativeLink = () => {
    appendAlternativeLink({ url: '' });
  };

  return (
    <>
      <Loader isLoading={isloading} />
      <Row className="m-0">
        <div className="col-md-12 col-lg-12 mx-auto mt-4">
          <div className="border-0 rounded">
            <h3 className="font-weight-normal">{type}</h3>
            {isCreateMode && 'Add new item to Courses' && (
              <div className="mb-2 card">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="px-3 mt-3 border-0 row">
                    <Form.Group className="mb-3" controlId="title">
                      <Form.Label className="text-dark fw-600">Title</Form.Label>
                      <Star />
                      <Form.Control data-testid="courseTitle" type="text" placeholder="Add Title" {...register('title', { required: { value: true, message: 'Title is required' } })} />
                      <div className="d-block invalid-feedback">{errors?.title?.message || ''}</div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="description">
                      <Form.Label className="text-dark fw-600">Description</Form.Label>
                      <Form.Control data-testid="description" as="textarea" rows={6} placeholder="description" {...register('description')} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="featuredVideoUrl">
                      <div className="featured-video-label-container">
                        <div>
                          <Form.Label className="text-dark fs-6">Featured Video</Form.Label>
                          <Star />
                        </div>
                        <TangiButton
                          size={TANGI_BUTTON_SIZES.SMALL}
                          smallbtn
                          text="Add Alternative Video"
                          variant={BUTTON_VARIANTS.TERTIARY}
                          onClick={handleAppendAlternativeLink}
                          svgIcon="add"
                          font={14}
                          data-testid="add-alternative-link"
                        />
                      </div>
                      <Form.Control data-testid="featuredVideoUrl" type="text" placeholder="Enter URL" {...register('featuredVideoUrl', { required: { value: true, message: 'Url is required' } })} />
                      <div className="d-block invalid-feedback">{errors?.featuredVideoUrl?.message || ''}</div>
                    </Form.Group>

                    {alternativeVideoFields.map((field, index) => (
                      <Form.Group key={field.id} className="mb-3" controlId={`alternativeVideoUrls[${index}].url`}>
                        <Form.Label className="text-dark fw-600">{`Alternative Video ${index + 1}`}</Form.Label>
                        <div className="alternative-link-input-container">
                          <Form.Control type="text" data-testid={`alternative-video-url-${index}`} placeholder="Enter URL" {...register(`alternativeVideoUrls.${index}.url`)} />
                          <TangiIconButton dataTestId={`remove-alternative-video-${index}`} variant={BUTTON_VARIANTS.TERTIARY_GREY} icon="close" onClick={() => removeAlternativeLink(index)} />
                        </div>
                        <small className="ff-din-regular form-text text-muted txt-size">The alternative video will be shown when the featured video is not working</small>
                      </Form.Group>
                    ))}

                    <Form.Group className="mb-3" controlId="client">
                      <Form.Label className="text-dark fw-600 txt-size">Client</Form.Label>
                      <Controller
                        data-testid="client"
                        name="client"
                        render={({ field: { ref, ...rest } }) => <EditAsyncSelect data-testid="client-selector" inputRef={ref} selectStyles="ff-din-DemiBold" type={optionTypes.CLIENTS} {...rest} />}
                        control={control}
                      />
                      <small className="ff-din-regular form-text text-muted txt-size">When not choosing a specific client this training will be displayed to all clients</small>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="difficulty">
                      <Form.Label className="text-dark fs-6">Difficulty</Form.Label>
                      <Star />
                      <Controller
                        control={control}
                        name="difficulty"
                        render={({ field: { ref, ...rest } }) => <Select options={difficult} inputRef={ref} classNamePrefix="addl-class" {...rest} />}
                        rules={{
                          required: { value: true, message: 'Difficulty is required' },
                        }}
                      />
                      <div className="d-block invalid-feedback">{errors?.difficulty?.message || ''}</div>
                    </Form.Group>

                    <h2 className="font-weight-normal">Quiz</h2>
                    {fields.map((field, index) => (
                      <div key={field.id}>
                        <div className="mb-2">
                          <Form.Group className="mb-3" controlId={`questions[${index}].text`}>
                            <Form.Label className="text-dark fw-600">Question {index + 1}</Form.Label>
                            <Form.Control type="text" data-testid="question" placeholder="Question" {...register(`questions[${index}].text`)} />
                          </Form.Group>
                          <h3 className="font-weight-normal mt-3">Choices</h3>
                          <div>
                            <NestedChoices nestIndex={index} {...{ control, register }} />
                          </div>
                          <Form.Group className="mb-3" controlId={`questions[${index}].answer`}>
                            <Form.Label className="text-dark fw-600">Answer - Choice</Form.Label>
                            <Form.Control data-testid="answer" type="text" placeholder="Enter Choice No" {...register(`questions[${index}].answer`)} />
                          </Form.Group>
                        </div>
                        <button type="button" onClick={() => remove(index)} className="btn btn-danger text-white pull-right">
                          REMOVE QUESTION
                        </button>
                      </div>
                    ))}
                    <div className="d-flex justify-content-between">
                      <button type="button" onClick={handleAppendQuestion} className="btn btn-primary text-white">
                        ADD NEW QUESTION
                      </button>
                    </div>
                  </div>

                  <div className="d-flex pull-right p-3">
                    <Button type="submit" className="btn btn-primary text-white">
                      SUBMIT
                    </Button>
                  </div>
                </Form>
                <div style={{ backgroundColor: '#4ABB79', borderColor: '#4ABB79' }} className={`alert alert-primary fade ${statusCourse === 'success' ? 'show' : ''} text-center w-100`}>
                  Created Successfully
                </div>
              </div>
            )}

            {isEditMode && (
              <div className="mb-2 card">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="px-3 mt-3 border-0 row">
                    <Form.Group className="mb-3" controlId="title">
                      <Form.Label className="text-dark fw-600">Title</Form.Label>
                      <Star />
                      <Form.Control data-testid="editTitle" type="text" placeholder="Add Title" {...register('title', { required: { value: true, message: 'Title is required' } })} />
                      <div className="d-block invalid-feedback">{errors?.title?.message || ''}</div>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="description">
                      <Form.Label className="text-dark fw-600">Description</Form.Label>
                      <Form.Control data-testid="description" as="textarea" rows={6} placeholder="description" {...register('description')} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="featuredVideoUrl">
                      <div className="featured-video-label-container">
                        <div>
                          <Form.Label className="text-dark fs-6">Featured Video</Form.Label>
                          <Star />
                        </div>
                        <TangiButton
                          size={TANGI_BUTTON_SIZES.SMALL}
                          smallbtn
                          text="Add Alternative Video"
                          variant={BUTTON_VARIANTS.TERTIARY}
                          onClick={handleAppendAlternativeLink}
                          svgIcon="add"
                          font={14}
                          data-testid="add-alternative-link"
                        />
                      </div>
                      <Form.Control type="text" placeholder="Enter URL" {...register('featuredVideoUrl', { required: { value: true, message: 'Url is required' } })} />
                      <div className="d-block invalid-feedback">{errors?.featuredVideoUrl?.message || ''}</div>
                    </Form.Group>

                    {alternativeVideoFields.map((field, index) => (
                      <Form.Group className="mb-3" key={field.id} controlId={`alternativeVideoUrls[${index}].url`}>
                        <Form.Label className="text-dark fw-600">{`Alternative Video ${index + 1}`}</Form.Label>
                        <div className="alternative-link-input-container">
                          <Form.Control type="text" data-testid={`alternative-video-url-${index}`} placeholder="Enter URL" {...register(`alternativeVideoUrls.${index}.url`)} />
                          <TangiIconButton dataTestId={`remove-alternative-video-${index}`} variant={BUTTON_VARIANTS.TERTIARY_GREY} icon="close" onClick={() => removeAlternativeLink(index)} />
                        </div>
                        <small className="ff-din-regular form-text text-muted txt-size">The alternative video will be shown when the featured video is not working</small>
                      </Form.Group>
                    ))}

                    <Form.Group className="mb-3" controlId="client">
                      <Form.Label className="text-dark fw-600 txt-size">Client</Form.Label>
                      <Controller
                        data-testid="client"
                        name="client"
                        render={({ field: { ref, ...rest } }) => (
                          <EditAsyncSelect data-testid="client-selector" inputRef={ref} selectStyles="ff-din-DemiBold" type={optionTypes.CLIENTS} defaultValue={defaults.CLIENT} {...rest} />
                        )}
                        control={control}
                      />
                      <small className="ff-din-regular form-text text-muted txt-size">When not choosing a specific client this training will be displayed to all clients</small>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="difficulty">
                      <Form.Label className="text-dark fs-6">Difficulty</Form.Label>
                      <Star />
                      <Controller
                        control={control}
                        data-testid="difficulty"
                        name="difficulty"
                        render={({ field: { ref, ...rest } }) => <Select options={difficult} inputRef={ref} classNamePrefix="addl-class" {...rest} />}
                        rules={{
                          required: { value: true, message: 'Difficulty is required' },
                        }}
                      />
                      <div className="d-block invalid-feedback">{errors?.difficulty?.message || ''}</div>
                    </Form.Group>

                    <h2 className="font-weight-normal">Quiz</h2>
                    {fields?.map((field, index) => (
                      <div key={field.id}>
                        <div className="mb-2">
                          <Form.Group className="mb-3" controlId={`questions.${index}.text`}>
                            <Form.Label className="text-dark fw-600">Question {index + 1}</Form.Label>
                            <Form.Control type="text" data-testid="question" placeholder="Question" {...register(`questions.${index}.text`)} />
                          </Form.Group>
                          <h3 className="font-weight-normal mt-5">Choices</h3>
                          <div>
                            <NestedChoices nestIndex={index} {...{ control, register }} />
                          </div>
                          <Form.Group className="mb-3" controlId={`questions[${index}].answer`}>
                            <Form.Label className="text-dark fw-600">Answer - Choice</Form.Label>
                            <Form.Control type="text" data-testid="answer" placeholder="Enter Choice No" {...register(`questions[${index}].answer`)} />
                          </Form.Group>
                        </div>
                        <button type="button" onClick={() => remove(index)} className="btn btn-danger text-white pull-right">
                          REMOVE QUESTION
                        </button>
                      </div>
                    ))}
                    <div className="d-flex justify-content-between">
                      <button type="button" onClick={handleAppendQuestion} className="btn btn-primary text-white">
                        ADD NEW QUESTION
                      </button>
                    </div>
                  </div>

                  <div className="d-flex pull-right p-3">
                    <Button type="submit" className="btn btn-primary text-white">
                      UPDATE
                    </Button>
                  </div>
                </Form>
                <div className={`alert alert-primary fade ${statusUpdateCourse === 'failed' ? 'show' : ''} text-center w-100`}>{courseErrors?.message || ''}</div>
                <div style={{ backgroundColor: '#4ABB79', borderColor: '#4ABB79' }} className={`alert alert-primary fade ${statusUpdateCourse === 'success' ? 'show' : ''} text-center w-100`}>
                  Updated Successfully
                </div>
              </div>
            )}
          </div>
        </div>
      </Row>
    </>
  );
}

export { AdminCoursesForm };
