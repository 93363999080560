import { TangiIconButton } from '_components/TangiLibrary/TangiIconButton';
import { StyledModalHeader, TitleContainer } from './styles';
import { BUTTON_VARIANTS } from 'utils/theme';
import { useTranslation } from 'react-i18next';

interface Props {
  handleClose: () => void;
  assetsAmount: number;
  assetName: string;
}

export const Header = ({ handleClose, assetName, assetsAmount }: Props) => {
  const { t } = useTranslation();

  let title;
  if (assetsAmount > 1) {
    title = t('ASSET_PAGE.TITLE.SEND_MULTIPLE_ASSETS_NOTIFICATION', { NUMBER: assetsAmount });
  } else if (assetName) {
    title = t('ASSET_PAGE.TITLE.SEND_ASSET_NOTIFICATION', { ASSET_NAME: assetName });
  } else {
    title = t('ASSET_PAGE.TITLE.SEND_ASSET_NOTIFICATION_DEFAULT');
  }

  return (
    <StyledModalHeader>
      <TitleContainer data-testid="distribute-asset-modal-title">
        <div>{title}</div>
        <TangiIconButton icon="close" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={handleClose} />
      </TitleContainer>
    </StyledModalHeader>
  );
};
