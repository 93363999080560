import { RootState } from '_helpers';
import { useSelector } from 'react-redux';
import { buildSelectOptions } from './utils';
import { useMemo } from 'react';

export const useHandleRecipientEntitySearch = () => {
  const { authors, departments, businessUnits, loading: metadataLoading } = useSelector((state: RootState) => state.assetMetaData);
  const { partnerships, loadingPartnerships } = useSelector((state: RootState) => state.partnership);

  const selectOptions = useMemo(
    () => (!loadingPartnerships && !metadataLoading ? buildSelectOptions({ recipients: authors, departments, businessUnits, partners: [...partnerships.active, ...partnerships.disabled] }) : []),
    [loadingPartnerships || metadataLoading],
  );

  return { isLoading: loadingPartnerships || metadataLoading, selectOptions };
};
