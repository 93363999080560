import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'DIN2014-Regular';
  font-weight: 200;
  padding: 16px;
  gap: 8px;
  .title {
    font-size: 24px;
  }
`;
export const QuestionText = styled.div`
  font-family: 'DIN2014-BOLD';
`;
