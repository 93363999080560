import { SelectOption } from '_components/DistributeAssetModal/useHandleRecipientEntitySearch/types';
import { Container } from './styles';
import { RecipientCategoryType } from 'utils/types/assets/assets';
import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES } from 'utils/theme';

interface Props {
  data: SelectOption;
  removeProps: { onClick: () => void };
}

export const CustomSelectValue = ({ data, removeProps }: Props) => {
  return (
    <Container>
      <TangiTypography>{data.type == RecipientCategoryType.INDIVIDUAL ? data.label : data.name}</TangiTypography>
      <div style={{ cursor: 'pointer' }} onClick={removeProps.onClick}>
        <TangiSvgIcon component="remove" color={NEUTRAL_SHADES[1100]} />
      </div>
    </Container>
  );
};
