import styled from 'styled-components';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${NEUTRAL_SHADES[300]};
  border-radius: ${SPACING[1]};
  gap: ${SPACING[2]};
  height: ${SPACING[5]};
  padding: ${SPACING[1]} ${SPACING[1.5]};
  width: fit-content;
`;
