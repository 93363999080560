import styled from 'styled-components';
import { BORDER_RADIUS, NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const ToastContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: ${NEUTRAL_SHADES.WHITE};
  border-radius: ${BORDER_RADIUS[4]};
  box-shadow: ${SPACING[0]} ${SPACING[1]} ${SPACING[2]} rgba(0, 0, 0, 0.1);
  max-width: 400px;
  z-index: 1000;
  padding: ${SPACING[3]};
`;

export const ToastHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${NEUTRAL_SHADES[400]};
  padding-bottom: ${SPACING[2]};
  margin-bottom: ${SPACING[2]};
`;

export const ToastBody = styled.div``;

export const ToastFooter = styled.div`
  border-top: 1px solid ${NEUTRAL_SHADES[400]};
  padding-top: ${SPACING[2]};
  margin-top: ${SPACING[2.5]};
`;
