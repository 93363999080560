import { useState, useEffect, useMemo, useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useLocalStorage from '_hooks/useLocalStorage';

import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { getAgreementVersions } from 'redux-toolkit/thunks/clientThunks';
import { getAssetMetaData } from 'redux-toolkit/thunks/assetMetaDataThunks';
import { getRoleNameContractor, getRoleNameEmployee, IRoles, isUserIsRole, lawyers } from 'utils/roles';
import { clientSettingsActions } from '../../../_actions/clientSettingsActions';
import { getColumns, mapFieldNameToServer } from '../../../utils/peoplePartnerUtils';
import { dispatchGetUsersByType } from '../../../utils/customHooks';
import { excelExporters } from '../../../utils/excelExporters';
import { CREATED_FROM, RESULT_STATUS } from '../../../utils/enums';
import { userConstants } from '_constants';
import { getRoles, getUniqueCountries, updateSelectedUsers } from 'redux-toolkit/thunks/usersThunks';
import { ACCOUNT_TYPE, ACCOUNT_ACTIONS, ACCOUNT_STATUS } from '../../../utils/enums';
import { getConditionalRowStyles } from '../../../utils/peoplePartnerUtils';
import { FormerTableData, TableData } from '../Components/TableData/TableData';
import { getFormerEmpColumns } from 'utils/formerEmplyeesUtils';

export const useFormerEmployees = () => {
  const { clientId } = useParams();
  const { tablesLoaders } = useSelector((state) => state.client);
  const {
    tablesData: { employee, contractor },
  } = useSelector((state) => state.client);
  const { permissions, user, Role, activeAccount } = useSelector((state) => state.authentication);
  const { peopleToastProps, status: userUpdateStatus } = useSelector((state) => state.users);
  const metaDataState = useSelector((state) => state.assetMetaData);
  const agreementVersions = useSelector((state) => state.client.agreementVersions);
  const clientLoading = useSelector((state) => state.client.loading);
  const activeClient = useSelector((state) => state.lawfirm.activeClient);
  const { settings, loading: clientSettingsLoading } = useSelector((state) => state.clientSettings);
  const filter = useSelector((state) => state.users.peoplePage.filters.selectedOptions);
  const searchValue = useSelector((state) => state.users.peoplePage.filters.searchValue);
  const selectedEmployees = useSelector((state) => state.users.peoplePage.selected.selectedEmployees);
  const selectedContractors = useSelector((state) => state.users.peoplePage.selected.selectedContractors);
  const failedToUpdate = useSelector((state) => state.users.peoplePage.failedToUpdate);
  const isUpdateManyLoading = useSelector((state) => state.users.peoplePage.loadingUpdateMany);
  const isFormerEmployeesTable = useSelector((state) => state.users.peoplePage.isFormerEmployeesTable);

  const initialPagination = { page: 1, limit: 10 };
  const initialFilter = {
    status: [],
    compliantProgress: [],
    trainingProgress: [],
    dateOfJoining: [],
    roleId: [],
    country: [],
    businessUnit: [],
    department: [],
  };

  const [employeeData, setEmployeeData] = useState([]);
  const [contractorData, setContractorData] = useState([]);
  const [sortState, setSortState] = useLocalStorage('tableSorts', {});
  const [activeSelectorEmployee, setActiveSelectorEmployee] = useState(() => ({
    selector: sortState.employee?.field || '',
    direction: sortState.employee?.direction || '',
  }));

  const [activeSelectorContractor, setActiveSelectorContractor] = useState(() => ({
    selector: sortState.contractor?.field || '',
    direction: sortState.contractor?.direction || '',
  }));

  const [empPagination, setEmpPagination] = useState(initialPagination);
  const [cntrPagination, setCntrPagination] = useState(initialPagination);
  const [view, setView] = useState({ show: false, data: {}, type: '', mode: 'add' });
  const [, setBatchOptions] = useState(false);
  const [showBatchValidation, setShowBatchValidation] = useState(false);
  const [showInviteAccountForm, setShowInviteAccountForm] = useState(false);

  const [isAllEmployeesSelected, setIsAllEmployeesSelected] = useState(false);
  const [isAllContractorsSelected, setIsAllContractorsSelected] = useState(false);

  const isLawyer = useMemo(() => lawyers.includes(Role), [Role]);
  const isUserIsLearnType = useMemo(() => isUserIsRole(activeAccount, 'Learn'), [activeAccount]);
  const roleNameContractor = useMemo(() => getRoleNameContractor(isUserIsLearnType), [isUserIsLearnType]);
  const roleNameEmployee = useMemo(() => getRoleNameEmployee(isUserIsLearnType), [isUserIsLearnType]);

  const isFilterApplied = useMemo(() => {
    const anyFilterApplied = Object.values(filter).some((valuesArray) => valuesArray.length > 0);
    return anyFilterApplied || searchValue !== '';
  }, [filter, searchValue]);

  const tableSettings = useMemo(() => {
    return isLawyer ? activeClient?.peopleTableSettings || null : activeAccount?.client?.peopleTableSettings || null;
  }, [isLawyer, activeClient, activeAccount]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isAllVisibleEmployeesSelected = useMemo(() => {
    const allEmployeeIDs = employee?.data?.filter((employee) => employee.user !== user._id).map((employee) => employee.id) || [];
    return allEmployeeIDs.length > 0 && allEmployeeIDs.every((id) => selectedEmployees.some((user) => user.id === id));
  }, [employee?.data, selectedEmployees]);

  const isAllVisibleContractorsSelected = useMemo(() => {
    const allContractorIDs = contractor?.data?.map((contractor) => contractor.id) || [];
    return allContractorIDs.length > 0 && allContractorIDs.every((id) => selectedContractors.some((user) => user.id === id));
  }, [contractor?.data, selectedContractors]);

  const getEmployeeCount = () => {
    const totalEmployeesCount = employee?.metadata[0]?.total;
    if (Role === IRoles.LAWYER) {
      return totalEmployeesCount;
    } else {
      return totalEmployeesCount - 1; // -1 is for removing myself
    }
  };

  const getTotalSelected = useMemo(() => {
    return () => {
      if (isAllEmployeesSelected && isAllContractorsSelected) {
        return getEmployeeCount() + contractor?.metadata[0]?.total;
      } else if (isAllEmployeesSelected) {
        return getEmployeeCount() + selectedContractors.length;
      } else if (isAllContractorsSelected) {
        return selectedEmployees.length + contractor?.metadata[0]?.total;
      } else {
        return selectedEmployees.length + selectedContractors.length;
      }
    };
  }, [isAllEmployeesSelected, isAllContractorsSelected, selectedContractors, selectedEmployees]);

  const selectAllVisible = (type) => {
    const isContractor = type === ACCOUNT_TYPE.CONTRACTOR;
    const data = isContractor ? contractor.data : employee.data;
    const selectedList = isContractor ? selectedContractors : selectedEmployees;
    const setSelected = isContractor ? usersActions.setSelectedContractors : usersActions.setSelectedEmployees;
    const isAllSelected = isContractor ? isAllVisibleContractorsSelected : isAllVisibleEmployeesSelected;

    if (isAllEmployeesSelected && !isContractor) {
      dispatch(usersActions.setSelectedEmployees([]));
      setIsAllEmployeesSelected(false);
    }
    if (isAllContractorsSelected && isContractor) {
      dispatch(usersActions.setSelectedContractors([]));
      setIsAllContractorsSelected(false);
    }

    const visibleItems = data?.filter((item) => item.user !== user._id).map((item) => ({ id: item.id, status: item.status, email: item.email }));

    if (isAllSelected) {
      const remainingSelected = selectedList.filter((selectedItem) => !visibleItems.some((item) => item.id === selectedItem.id));
      dispatch(setSelected(remainingSelected));
    } else {
      const updatedSelection = [...selectedList, ...visibleItems.filter((item) => !selectedList.some((selectedItem) => selectedItem.id === item.id))];
      dispatch(setSelected(updatedSelection));
    }
  };

  const filteredAccounts = (selectedContractors, selectedEmployees) => {
    if (isAllEmployeesSelected && isAllContractorsSelected) {
      return [];
    } else if (isAllEmployeesSelected) {
      return selectedContractors;
    } else if (isAllContractorsSelected) {
      return selectedEmployees;
    } else {
      return [...selectedContractors, ...selectedEmployees];
    }
  };

  const deselectUsers = () => {
    setIsAllEmployeesSelected(false);
    setIsAllContractorsSelected(false);
    dispatch(usersActions.deselectSelectedUsers());
  };

  const columns2 = getColumns(activeSelectorEmployee, false, true, Role, () => selectAllVisible(ACCOUNT_TYPE.EMPLOYEE), isAllVisibleEmployeesSelected, isAllEmployeesSelected, ACCOUNT_TYPE.EMPLOYEE);
  const columns3 = getColumns(
    activeSelectorContractor,
    false,
    true,
    Role,
    () => selectAllVisible(ACCOUNT_TYPE.CONTRACTOR),
    isAllVisibleContractorsSelected,
    isAllContractorsSelected,
    ACCOUNT_TYPE.CONTRACTOR,
  );
  const columns4 = getFormerEmpColumns(activeSelectorContractor);

  const isCreatedFromSharePoint = (value) => value.createdFrom === CREATED_FROM.SHARE_POINT;

  const getFiltersOptions = () => {
    batch(() => {
      dispatch(getRoles());
      dispatch(getUniqueCountries());
    });
  };

  useEffect(() => {
    dispatch(assetsActions.setGetAssetsClear());
    dispatchGetUsersByType(dispatch, roleNameContractor, clientId, 'Contractor', { ...cntrPagination, search: searchValue }, filter, sortState.contractor, false, tableSettings);
  }, [cntrPagination, searchValue, filter, sortState.contractor, dispatch]);

  useEffect(() => {
    dispatchGetUsersByType(dispatch, roleNameEmployee, clientId, 'Employee', { ...empPagination, search: searchValue }, filter, sortState.employee, isFormerEmployeesTable, tableSettings);
  }, [empPagination, searchValue, filter, sortState.employee, dispatch, isFormerEmployeesTable]);

  useEffect(() => {
    if (userUpdateStatus === userConstants.USER_UPDATE_SUCCESSFULLY) {
      getFiltersOptions();
    }
  }, [userUpdateStatus, dispatch]);

  const [hoveredContractorId, setHoveredContractorId] = useState(null);
  const [hoveredEmployeeId, setHoveredEmployeeId] = useState(null);

  const handleCheckboxToggleForContractors = (id, status, email) => {
    const exists = selectedContractors.some((user) => user.id === id);
    if (exists) {
      const updatedSelection = selectedContractors.filter((user) => user.id !== id);
      dispatch(usersActions.setSelectedContractors(updatedSelection));
    } else {
      const updatedSelection = [...selectedContractors, { id, status, email }];
      dispatch(usersActions.setSelectedContractors(updatedSelection));
    }
  };

  const handleCheckboxToggleForEmployees = (id, status, email) => {
    const exists = selectedEmployees.some((user) => user.id === id);
    if (exists) {
      const updatedSelection = selectedEmployees.filter((user) => user.id !== id);
      dispatch(usersActions.setSelectedEmployees(updatedSelection));
    } else {
      const updatedSelection = [...selectedEmployees, { id, status, email }];
      dispatch(usersActions.setSelectedEmployees(updatedSelection));
    }
  };

  const dispatchUserAction = (actionType, isRetry) => {
    const selectedAccounts = isRetry ? failedToUpdate : filteredAccounts(selectedContractors, selectedEmployees);
    const allEmployeesSelected = isRetry ? false : isAllEmployeesSelected;
    const allContractorsSelected = isRetry ? false : isAllContractorsSelected;
    setIsAllContractorsSelected(false);
    setIsAllContractorsSelected(false);
    if (actionType === ACCOUNT_ACTIONS.ACTIVATE) {
      dispatch(
        updateSelectedUsers({
          accounts: selectedAccounts,
          allEmployees: allEmployeesSelected,
          allContractors: allContractorsSelected,
          filter: filter ? filter : {},
          searchValue: searchValue,
          action: ACCOUNT_ACTIONS.ACTIVATE,
        }),
      );
    } else if (actionType === ACCOUNT_ACTIONS.DEACTIVATE) {
      dispatch(
        updateSelectedUsers({
          accounts: filteredAccounts(selectedContractors, selectedEmployees),
          allEmployees: allEmployeesSelected,
          allContractors: allContractorsSelected,
          filter: filter ? filter : {},
          searchValue: searchValue,
          action: ACCOUNT_ACTIONS.DEACTIVATE,
        }),
      );
    }
  };

  const selectAll = (type) => () => {
    if (type === ACCOUNT_TYPE.EMPLOYEE) {
      if (isAllEmployeesSelected) {
        setIsAllEmployeesSelected(false);
        dispatch(usersActions.setSelectedEmployees([]));
      } else {
        setIsAllEmployeesSelected(true);
      }
    } else if (type === ACCOUNT_TYPE.CONTRACTOR) {
      if (isAllContractorsSelected) {
        setIsAllContractorsSelected(false);
        dispatch(usersActions.setSelectedContractors([]));
      } else {
        setIsAllContractorsSelected(true);
      }
    }
  };

  useEffect(() => {
    const processedContractors = TableData({
      data: contractor?.data,
      user,
      Role,
      handleViewData,
      hoveredId: hoveredContractorId,
      setHoveredId: setHoveredContractorId,
      selectedUsers: selectedContractors,
      handleCheckboxToggle: handleCheckboxToggleForContractors,
      isCreatedFromSharePoint,
      isAllSelected: isAllContractorsSelected,
      isUserIsLearnType,
      clientId,
      type: ACCOUNT_TYPE.CONTRACTOR,
      t,
    });
    setContractorData(processedContractors);
  }, [contractor, t, filter, hoveredContractorId, selectedContractors, isAllContractorsSelected, isUpdateManyLoading]);

  useEffect(() => {
    const processedEmployees = isFormerEmployeesTable
      ? FormerTableData({
          data: employee?.data,
          handleViewData,
          setHoveredId: setHoveredEmployeeId,
          isCreatedFromSharePoint,
          type: ACCOUNT_TYPE.EMPLOYEE,
          t,
        })
      : TableData({
          data: employee?.data,
          user,
          Role,
          handleViewData,
          hoveredId: hoveredEmployeeId,
          setHoveredId: setHoveredEmployeeId,
          selectedUsers: selectedEmployees,
          handleCheckboxToggle: handleCheckboxToggleForEmployees,
          isCreatedFromSharePoint,
          isAllSelected: isAllEmployeesSelected,
          isUserIsLearnType,
          clientId,
          type: ACCOUNT_TYPE.EMPLOYEE,
          t,
        });
    setEmployeeData(processedEmployees);
  }, [employee, t, filter, hoveredEmployeeId, selectedEmployees, isAllEmployeesSelected, isUpdateManyLoading, isFormerEmployeesTable]);

  const handleSortEmployee = (column, sortDirection) => {
    setActiveSelectorEmployee({
      selector: column.fieldName,
      direction: sortDirection,
    });
    const field = mapFieldNameToServer(column.fieldName);

    const sort = [field, ':', sortDirection].join('');
    setEmpPagination({ ...empPagination, sortBy: sort });
    setSortState({
      ...sortState,
      employee: { field, direction: sortDirection },
    });
  };
  const handleSortContractor = (column, sortDirection) => {
    setActiveSelectorContractor({
      selector: column.fieldName,
      direction: sortDirection,
    });
    const field = mapFieldNameToServer(column.fieldName);

    const sort = [field, ':', sortDirection].join('');
    setCntrPagination({ ...cntrPagination, sortBy: sort });
    setSortState({
      ...sortState,
      contractor: { field, direction: sortDirection },
    });
  };

  const handleViewData = (data, type, mode) => {
    const newdata = { ...data };
    setView({ show: true, data: newdata, type, mode });
  };

  const exportExcelEmployees = () => {
    excelExporters.exportEmployeeTemplate(metaDataState, settings.agreements, isUserIsLearnType, agreementVersions);
    setBatchOptions(false);
  };
  const handleOpenBatchValidation = () => {
    setShowBatchValidation(true);
    setBatchOptions(false);
    dispatch(usersActions.setResetBatchEmployees());
  };

  const initPeople = () => {
    batch(() => {
      dispatch(getAssetMetaData(clientId));
      dispatch(clientSettingsActions.getClientSettings(clientId));
      dispatch(getAgreementVersions({ client: clientId }));
    });
  };

  const handleCloseBatchValidation = () => {
    dispatchGetUsersByType(dispatch, roleNameEmployee, clientId, 'Employee', empPagination, filter, sortState.employee, isFormerEmployeesTable);

    setShowBatchValidation(false);
    getFiltersOptions();
  };

  useEffect(() => {
    initPeople();
    getFiltersOptions();
    deselectUsers();
  }, []);

  const fetchAccounts = () => {
    dispatchGetUsersByType(dispatch, roleNameEmployee, clientId, 'Employee', empPagination, filter, sortState.employee, isFormerEmployeesTable);
    dispatchGetUsersByType(dispatch, roleNameContractor, clientId, 'Contractor', cntrPagination, filter, sortState.contractor, isFormerEmployeesTable);
    batch(() => {
      dispatch(usersActions.setClearUser());
      dispatch(usersActions.setPeopleToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
    });
  };

  const handleClearSearch = useCallback(() => {
    dispatch(usersActions.clearSearchQuery());
  }, [dispatch]);

  const allSelectedUsers = useMemo(() => [...selectedEmployees, ...selectedContractors], [selectedEmployees, selectedContractors]);

  const isDeactivatedUsersSelected = useMemo(() => {
    if (getTotalSelected() === 0) return false;
    return allSelectedUsers.some((user) => user.status === ACCOUNT_STATUS.DISABLED);
  }, [allSelectedUsers, getTotalSelected]);

  const isActiveUsersSelected = useMemo(() => {
    if (getTotalSelected() === 0) return false;
    return allSelectedUsers.some((user) => user.status === ACCOUNT_STATUS.ACTIVE);
  }, [allSelectedUsers, getTotalSelected]);

  const isPendingUsersSelected = useMemo(() => {
    if (getTotalSelected() === 0) return false;
    return allSelectedUsers.some((user) => user.status == ACCOUNT_STATUS.PENDING);
  }, [allSelectedUsers, getTotalSelected]);

  const conditionalRowStyles = useMemo(() => getConditionalRowStyles(selectedEmployees, selectedContractors), [selectedEmployees, selectedContractors]);

  return {
    handleSortContractor,
    setEmpPagination,
    setCntrPagination,
    handleSortEmployee,
    exportExcelEmployees,
    handleOpenBatchValidation,
    handleCloseBatchValidation,
    setShowInviteAccountForm,
    fetchAccounts,
    handleClearSearch,
    deselectUsers,
    selectAll,
    dispatchUserAction,
    getTotalSelected,
    isDeactivatedUsersSelected,
    isAllEmployeesSelected,
    isActiveUsersSelected,
    isPendingUsersSelected,
    isAllContractorsSelected,
    isAllVisibleContractorsSelected,
    isAllVisibleEmployeesSelected,
    conditionalRowStyles,
    searchValue,
    initialFilter,
    filter,
    isLearn: isUserIsLearnType,
    view,
    permissions,
    contractor,
    columns2,
    columns3,
    columns4,
    empPagination,
    cntrPagination,
    employeeData,
    contractorData,
    tablesLoaders,
    Role,
    showBatchValidation,
    showInviteAccountForm,
    activeAccount,
    activeClient,
    isLawyer,
    peopleToastProps,
    isFilterApplied,
    clientSettingsLoading,
    clientLoading,
    metaDataLoading: metaDataState.loading,
    clientName: user?.accounts[0]?.client?.name,
    isUpdateManyLoading,
    conditionalRowStyles,
    failedToUpdate,
  };
};
