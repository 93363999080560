import { Avatar } from '_components/Avatar';
import { emptyAvatar } from 'assets/icons';
import { AVATAR_SIZES } from 'utils/componentUtils';
import { TableHeaderText } from 'utils/globalStyles';

interface Props {
  name: string;
  email?: string;
  isEmpty?: boolean;
}

export const AvatarWithName = ({ isEmpty, name, email }: Props) => {
  return (
    <>
      <Avatar size={AVATAR_SIZES.XS} name={name} email={email} image={isEmpty ? emptyAvatar : ''} />
      <TableHeaderText className="ms-2">{name || email}</TableHeaderText>
    </>
  );
};
