import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';

import agreementAdapter from 'redux-toolkit/adapters/agreementAdapter';
import { DownloadAgreementParams, FilePayloadWithIndex } from 'utils/types/agreement/agreement';
import { agreementNewActions } from 'redux-toolkit/slices/agreementSlice';
import { RESULT_STATUS } from 'utils/enums';
import { downloadFile } from 'utils/downloadFile';

// TODO: ------ THIS IS NEW ------
// agreementActions NEED TO BE REFACTORED AND MOVED HERE

export const downloadAgreement = createAsyncThunk<FilePayloadWithIndex, DownloadAgreementParams>('/agreement/downloadAgreement', async (downloadAgreementParams, { dispatch }) => {
  const res = await agreementAdapter.downloadAgreement(downloadAgreementParams);
  const { base64, fileName, mimeType } = res || {};

  if (base64 && fileName && mimeType) {
    await downloadFile(mimeType, base64, fileName);
  } else {
    const text = i18next.t('GENERAL.ERROR.SOMETHING_WENT_WRONG');
    dispatch(agreementNewActions.setAgreementToastProps({ show: true, text, type: RESULT_STATUS.ERROR }));
  }
  return { agreementOrderNumber: downloadAgreementParams.agreementOrderNumber };
});
