import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { TangiItemToast } from '_components/TangiLibrary/TangiItemToast/TangiItemToast';
import { t } from 'i18next';
import * as Styled from './styles';
import { ACCOUNT_ACTIONS } from 'utils/enums';
import { ERROR_SHADES, NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { SelectedAccount } from 'utils/types/account/account';
import { getToastTextKeys } from './utils';

interface MultiUpdateToastProps {
  action: ACCOUNT_ACTIONS;
  showUpdateMultipleToast: boolean;
  setShowUpdateMultipleToast: (value: boolean) => void;
  activateSelectedUsers: () => void;
  deactivateSelectedUsers: () => void;
  getTotalSelected: () => number;
  isUpdateManyLoading: boolean;
  failedToUpdate: SelectedAccount[] | [];
  clientName: string;
  fetchAccounts: () => void;
  deselectUsers: () => void;
  updatedAccountsLength: number;
}

export const MultiUpdateToast = ({
  action,
  showUpdateMultipleToast,
  setShowUpdateMultipleToast,
  activateSelectedUsers,
  deactivateSelectedUsers,
  getTotalSelected,
  isUpdateManyLoading,
  failedToUpdate,
  clientName,
  fetchAccounts,
  deselectUsers,
  updatedAccountsLength,
}: MultiUpdateToastProps) => {
  const [totalSelected] = useState<number>(getTotalSelected());
  const [isRetrying, setIsRetrying] = useState<boolean>(false);
  useEffect(() => {
    if (!isUpdateManyLoading) {
      fetchAccounts();
    }
  }, [isUpdateManyLoading]);

  useEffect(() => {
    deselectUsers();
    setIsRetrying(false);
  }, []);

  const handleRetryClick = () => {
      setIsRetrying(true);
    if (action === ACCOUNT_ACTIONS.ACTIVATE) {
      activateSelectedUsers();
    } else {
      deactivateSelectedUsers();
    }
  };

  const {
    loadingTextKey,
    successTextKey,
    emailTextKey,
    failureTextKey,
    headerTextKey
  } = getToastTextKeys(action);

  const loadingText = t(loadingTextKey, { totalSelected: isRetrying ? failedToUpdate?.length : totalSelected });

  const renderLoadingState = () => (
    <Styled.ResultContainer>
      <Styled.StyledSpinner animation="border" role="status" />
      <TangiTypography type="body" weight="semibold">
        {loadingText}
      </TangiTypography>
    </Styled.ResultContainer>
  );

  const renderSuccessState = () =>
    updatedAccountsLength > 0 && (
      <Styled.ResultContainer>
        <TangiSvgIcon component="matchIndication" color={NEUTRAL_SHADES.WHITE} />
        <div>
          <TangiTypography dataTestId={`${action}-${updatedAccountsLength}-users`} type="body" weight="semibold">
            {t(successTextKey, { activated: updatedAccountsLength, deactivated: updatedAccountsLength })}
          </TangiTypography>
          <TangiTypography type="body">
            <Trans t={t} i18nKey={t(emailTextKey, { clientName })} />
          </TangiTypography>
        </div>
      </Styled.ResultContainer>
    );

  const renderFailureState = () =>
    failedToUpdate?.length > 0 && (
      <Styled.ResultContainer>
        <TangiSvgIcon component="errorIcon" color={ERROR_SHADES[500]} />
        <div>
          <TangiTypography dataTestId={`failed-${action}-${failedToUpdate?.length}-users`} type="body" weight="semibold">
            {t(failureTextKey, { failed: failedToUpdate?.length })}
          </TangiTypography>
          <Styled.RetryContainer data-testid={'retry-button'} onClick={handleRetryClick}>
            <TangiTypography type="body" color={PRIMARY_SHADES[800]}>
              {t('PEOPLE.MULTISELECT.ACTION.TRY_AGAIN')}
            </TangiTypography>
          </Styled.RetryContainer>
        </div>
      </Styled.ResultContainer>
    );

  return (
    <TangiItemToast
      closeIcon={!isUpdateManyLoading}
      header={
        <TangiTypography type="body" weight="semibold">
          {t(headerTextKey)}
        </TangiTypography>
      }
      body={
        <Styled.ToastBodyContainer>
          {isUpdateManyLoading ? (
            renderLoadingState()
          ) : (
            <>
              {renderSuccessState()}
              {renderFailureState()}
            </>
          )}
        </Styled.ToastBodyContainer>
      }
      show={showUpdateMultipleToast}
      onHide={() => setShowUpdateMultipleToast(false)}
    />
  );
};
