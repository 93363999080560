import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { getClientById, updateRequiredCourses } from 'redux-toolkit/thunks/clientThunks';
import { Row } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Learn.scss';
import { TangiButton, TangiTypography } from '../../_components/TangiLibrary';
import { Dashboard } from '../Dashboard';
import { courseActions, courseProgressionsActions } from '../../_actions';
import { RootState } from '_helpers';
import { Course, IProgression } from 'utils/types/courses/course';
import useRole from '_hooks/useRole';
import CourseCardSkeleton from './components/CourseCardSkeleton';
import LearnSingleCourse from './components/LearnSingleCourse';
import LearnCourseCard from './components/LearnCourseCard/LearnCourseCard';

const Learn = () => {
  const courses: Course[] = useSelector((state: RootState) => state.courseReducer.courses);
  const user = useSelector((state: RootState) => state.authentication.user);
  const Role = useSelector((state: RootState) => state.authentication.Role);
  const { courseProgressions, loading: progressionsLoading } = useSelector((state: RootState) => state.courseProgressionsReducer);
  const { clientDetails, clientLoading } = useSelector((state: RootState) => state.client);

  const [newRequiredCourses, setNewRequiredCourses] = useState([]);
  // Single course data
  const [courseById, setCourseById] = useState<Course | null>(null);
  const [courseByIdProgression, setCourseByIdProgression] = useState<IProgression | null>(null);

  const { t } = useTranslation();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { clientId } = useParams<{ clientId: string }>();

  const { isLawyer } = useRole();

  const courseid = new URLSearchParams(search).get('courseid');

  // Fetching courses data
  useEffect(() => {
    batch(() => {
      dispatch(courseActions.getCourses());
      dispatch(getClientById(clientId));
      if (!isLawyer) {
        dispatch(courseProgressionsActions.getByUser(user._id));
      }
    });
  }, []);

  // Edit required Courses initial state
  useEffect(() => {
    setNewRequiredCourses(clientDetails?.requiredCourses || []);
  }, [clientDetails]);

  // If url has Id -> find the correct course in courses arr
  useEffect(() => {
    if (courseid && !!courses?.length) {
      setCourseById(courses?.find((course: Course) => course.id === courseid) || null);
    }
  }, [courseid, courses]);

  // If url has Id -> find the correct Progression in progressions arr
  useEffect(() => {
    if (courseById) {
      const currentCourseProg = courseProgressions.find((progression: IProgression) => progression.course === courseById.id);
      setCourseByIdProgression(currentCourseProg ?? null);
    }
  }, [courseById, courseProgressions]);

  const saveChanges = () => {
    dispatch(updateRequiredCourses({ clientId: clientDetails.id, updatedCourses: { requiredCourses: newRequiredCourses } }));
  };

  return (
    <Dashboard title="">
      {courseById && !progressionsLoading ? (
        // Single course view
        <LearnSingleCourse course={courseById} courseProgression={courseByIdProgression} />
      ) : (
        // Cards view
        <Row data-testid="courses" className="px-39 m-3 mx-3">
          {!progressionsLoading && !clientLoading && (
            <nav className="p-0 mb-4 navbar text-top">
              <TangiTypography weight="bold" type="heading-lg">
                {t('LEARN_PAGE.MY_COURSES')}
              </TangiTypography>

              {/* Required courses can be changed by lawyers and admins, including Learn Admin */}
              {(Role?.includes('Admin') || Role?.includes('Lawyer')) && (
                <div>
                  <TangiButton
                    text={t('LEARN_PAGE.SAVE_CHANGES')}
                    disabled={newRequiredCourses === clientDetails?.requiredCourses || (newRequiredCourses.length === 0 && !clientDetails?.requiredCourses) || clientLoading}
                    onClick={saveChanges}
                  />
                </div>
              )}
            </nav>
          )}

          {!!courses?.length && !progressionsLoading && !clientLoading ? (
            courses?.map((course: Course) => {
              const relevantProgression = courseProgressions?.find((progression: IProgression) => {
                return course.id === progression.course;
              });
              return <LearnCourseCard course={course} key={course.id} status={relevantProgression?.status} setNewRequiredCourses={setNewRequiredCourses} newRequiredCourses={newRequiredCourses} />;
            })
          ) : (
            <CourseCardSkeleton />
          )}
        </Row>
      )}
    </Dashboard>
  );
};

export default Learn;
