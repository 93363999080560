import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  flex-direction: column;
  padding: ${SPACING[3.5]} ${SPACING[3]};
  gap: ${SPACING[1]};
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: ${SPACING[4]};
  font-family: 'DIN2014-Regular';
  gap: ${SPACING[4]};
`;
