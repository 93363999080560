import { config } from 'config/config';
import mixpanel from 'mixpanel-browser';
import { AnyAction, Middleware, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';

import { Environment, TRACK_MIXPANEL_EVENT } from './const';
import { MixpanelEventNames, CommonEventProps, ExtractTradeSecretProps, InviteAccountProps } from './types';
import { getCommonProperties, shouldTrackEvent } from './utils';
import { AppDispatch, RootState } from '_helpers';
import { IRoles, lawyers } from 'utils/roles';
import { XRAY_SOURCE_TYPE } from 'utils/types/xRay/xRay';
import { Account } from 'utils/types/account/account';
import { Client } from 'utils/types/client/client';
import { LawFirm } from 'utils/types/lawFirm/lawFirm';
import { getMimeTypeKey } from 'utils/getMimeTypeKey';
import { SystemSecuritySelection } from '_components/IpAudit/components/Steps/SystemSecurity/types';
import { HrProcessFormValues } from '_components/IpAudit/components/Steps/HrProcess/types';

interface MixpanelEventAction {
  type: typeof TRACK_MIXPANEL_EVENT;
  payload: {
    eventName: MixpanelEventNames;
    properties: Record<string, unknown>;
  };
}

export const mixpanelConfig = () => {
  if (!config.mixpanelId) {
    console.error('Mixpanel ID is not defined in the config');
    return;
  }

  mixpanel.init(config.mixpanelId, {
    debug: process.env.REACT_APP_ENVIRONMENT === Environment.DEVELOPMENT,
    track_pageview: false,
    persistence: 'localStorage',
  });
};

export const trackMixpanelEvent = (eventName: MixpanelEventNames, additionalProperties: Record<string, unknown>): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState: () => RootState) => {
    const state = getState();
    const commonProperties = getCommonProperties(state);

    dispatch({
      type: TRACK_MIXPANEL_EVENT,
      payload: {
        eventName,
        properties: {
          ...commonProperties,
          ...additionalProperties,
        },
      },
    });
  };
};

export const trackingMiddleware: Middleware = () => (next) => (action: MixpanelEventAction) => {
  if (action.type === TRACK_MIXPANEL_EVENT) {
    const { eventName, properties } = action.payload;

    // Extract the clientName from properties
    const clientName = properties.clientName as string;

    // Check if the event should be tracked
    if (shouldTrackEvent(clientName)) {
      mixpanel.track(eventName, properties);
    }
  }
  return next(action);
};

export const mixpanelEvents = {
  // ------------------------------ NEW EVENTS ------------------------------ //
  quizCompleted: () => (dispatch: AppDispatch) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.QuizCompleted, {}));
  },
  quizStarted: () => (dispatch: AppDispatch) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.QuizStarted, {}));
  },
  logoutActive:
    ({ message }: { message: string }) =>
    (dispatch: AppDispatch) => {
      dispatch(trackMixpanelEvent(MixpanelEventNames.LogoutActive, { message }));
    },
  logoutAuto:
    ({ message }: { message: string }) =>
    (dispatch: AppDispatch) => {
      dispatch(trackMixpanelEvent(MixpanelEventNames.LogoutAuto, { message }));
    },
  assetFilePreview:
    ({ fromWhere }: { fromWhere: string }) =>
    (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
      dispatch(trackMixpanelEvent(MixpanelEventNames.AssetFilePreview, { fromWhere }));
    },
  downloadPolicy: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.DownloadPolicy, {}));
  },
  downloadPolicyTemplate: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.DownloadPolicyTemplate, {}));
  },
  deleteAsset: (assetId: string) => (dispatch: AppDispatch) => {
    dispatch(
      trackMixpanelEvent(MixpanelEventNames.DeleteAsset, {
        assetId,
      }),
    );
  },
  acknowledgementAsset:
    ({ accountId, selectedAssets, source }: { accountId: string; selectedAssets: string[] | string; source: string }) =>
    (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
      dispatch(
        trackMixpanelEvent(MixpanelEventNames.AcknowledgementAsset, {
          accountId,
          selectedAssets,
          source,
        }),
      );
    },
  inviteAccount:
    ({ accountData }: InviteAccountProps) =>
    (dispatch: AppDispatch) => {
      dispatch(
        trackMixpanelEvent(MixpanelEventNames.InviteAccount, {
          createdAccountEmail: accountData.email,
          createdAccountRole: accountData.role,
        }),
      );
    },
  contractXray: (fileType: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
      dispatch(
        trackMixpanelEvent(MixpanelEventNames.ContractXray, {
          fileType,
        }),
      );
    };
  },
  inventionSearch: (inventionDisclosureTextLength: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
      dispatch(
        trackMixpanelEvent(MixpanelEventNames.InventionSearch, {
          inventionDisclosureTextLength,
        }),
      );
    };
  },
  xraySearch:
    ({ typeOfAction, fileType, patentId }: { typeOfAction: string; fileType?: string; patentId?: string }) =>
    (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
      const additionalProperties: Record<string, unknown> = {
        typeOfAction,
      };

      if (fileType) {
        additionalProperties.fileType = getMimeTypeKey(fileType);
      }

      if (patentId) {
        additionalProperties.patentId = patentId;
      }

      dispatch(trackMixpanelEvent(MixpanelEventNames.XraySearch, additionalProperties));
    },
  // LOGIN - cannot be like the new events - due to sync
  login: (role: IRoles, userEmail: string, activeAccount: Account, activeClient: Client) => {
    const isLawyer = lawyers.includes(role);
    const clientName = isLawyer ? activeClient?.name || '' : (activeAccount?.client as Client)?.name || '';
    const clientId = isLawyer ? activeClient?.id || '' : (activeAccount?.client as Client)?._id || '';
    const isSuperAdmin = role === IRoles.SUPER_ADMIN;

    if (shouldTrackEvent(clientName) && !isSuperAdmin) {
      const baseProperties = {
        Role: role,
        userEmail: userEmail || '',
        clientName,
        clientId,
      };

      const lawyerProperties = { lawFirmId: (activeAccount?.lawFirm as LawFirm)?._id || '', lawFirmName: (activeAccount?.lawFirm as LawFirm)?.name || '' };

      const commonProperties = {
        ...baseProperties,
        ...(isLawyer ? lawyerProperties : {}),
      };

      mixpanel.track(MixpanelEventNames.Login, {
        message: 'User logged in',
        ...commonProperties,
      });
    }
  },
  continuationSearch: (typeOfSearch: XRAY_SOURCE_TYPE, additionalInfo: { fileType?: string; patentNumber?: string }): ThunkAction<void, RootState, unknown, AnyAction> => {
    return (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
      dispatch(
        trackMixpanelEvent(MixpanelEventNames.ContinuationSearch, {
          typeOfSearch,
          ...additionalInfo,
        }),
      );
    };
  },
  requestAccess:
    ({ source }: { source: string }) =>
    (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
      dispatch(trackMixpanelEvent(MixpanelEventNames.RequestAccess, { source }));
    },
  analyzeContractFile: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.AnalyzeContractFile, {}));
  },
  cancelAnalyzeContractFile: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.CancelAnalyzeContractFile, {}));
  },
  enterPredictedPage: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.EnterPredictedPage, {}));
  },
  enterCompetitivePage: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.EnterCompetitivePage, {}));
  },
  downloadTradeSecretPolicyTemplate: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.DownloadTradeSecretPolicyTemplate, {}));
  },
  downloadLLMPolicyTemplate: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.DownloadLLMPolicyTemplate, {}));
  },
  uploadTradeSecretPolicy: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.UploadTradeSecretPolicy, {}));
  },
  uploadLLMPolicy: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.UploadLLMPolicy, {}));
  },
  downloadTradeSecretPolicy: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.DownloadTradeSecretPolicy, {}));
  },
  downloadLLMPolicy: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.DownloadLLMPolicy, {}));
  },
  deleteTradeSecretPolicy: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.DeleteTradeSecretPolicy, {}));
  },
  deleteLLMPolicy: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.DeleteLLMPolicy, {}));
  },
  // ************ IP Audit ***********
  selectSystemSecurity: (selectedItems: SystemSecuritySelection[]) => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.SelectSystemSecurity, { selectedItems }));
  },
  selectHRProcess: (selectedItems: HrProcessFormValues) => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.SelectHRProcess, { selectedItems }));
  },
  completeIPAudit: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.CompleteIPAudit, {}));
  },
  openIPAuditModal: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.OpenIPAuditModal, {}));
  },
  completeIPAuditStep: (step: string) => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.CompleteIPAuditStep, { step }));
  },
  // ******** End of IP Audit ********

  notifyAssets: () => (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(trackMixpanelEvent(MixpanelEventNames.ShareAssets, {}));
  },

  // --------------------------- END OF NEW EVENTS --------------------------- //

  // ------------------------------ OLD EVENTS ------------------------------ //
  createAsset:
    ({ clientId, createdFrom, role, clientName, createdFromTool, userEmail }: CommonEventProps) =>
    (dispatch: AppDispatch) => {
      dispatch(
        trackMixpanelEvent(MixpanelEventNames.CreateAsset, {
          clientId,
          createdFrom,
          role,
          clientName,
          createdFromTool,
          userEmail,
        }),
      );
    },
  extractTradeSecret: ({ clientId, patentModel, clientName, role, typeOfAction, extraData }: ExtractTradeSecretProps) => {
    if (shouldTrackEvent(clientName)) {
      mixpanel.track(MixpanelEventNames.ExtractTradeSecret, {
        clientId,
        patentModel,
        clientName,
        role,
        typeOfAction,
        extraData,
      });
    }
  },

  editAsset: (assetId: string, clientId: string, clientName: string, role: string, userEmail: string) => {
    if (shouldTrackEvent(clientName)) {
      mixpanel.track(MixpanelEventNames.EditAsset, {
        assetId,
        clientId,
        clientName,
        role,
        userEmail,
      });
    }
  },
  closeAsset: (clientName: string, role: string, userEmail: string) => {
    if (shouldTrackEvent(clientName)) {
      mixpanel.track(MixpanelEventNames.CloseAsset, {
        clientName,
        role,
        userEmail,
      });
    }
  },
  privilegeLogDownload: (accountId: string, clientName: string, role: string, userEmail: string) => {
    if (shouldTrackEvent(clientName)) {
      mixpanel.track(MixpanelEventNames.PrivilegeLogDownload, {
        accountId,
        clientName,
        role,
        userEmail,
      });
    }
  },

  firstTimeLogin: (accountId: string, clientName: string, role: string, userEmail: string) => {
    if (shouldTrackEvent(clientName)) {
      mixpanel.track(MixpanelEventNames.FirstTimeLogin, {
        accountId,
        clientName,
        role,
        userEmail,
      });
    }
  },
  // ------------------------- END OF OLD EVENTS ----------------------------- //
};
