import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { TangiTypography } from '_components/TangiLibrary';
import ExistingUsers from './components/ExistingUsers';
import NewMembers from './components/NewMembers';
import { RootState } from '_helpers';
import { AccountSharePoint } from 'utils/types/account/account';
import { SharePointMember } from 'utils/types/sharepoint/sharepoint';
import { createableRoles, IRoles } from 'utils/roles';
import { FormSchema } from '_components/SharePointWizard/types';
import { TopContainer } from './styles';

const AddUsersStep = () => {
  const existingUsers: AccountSharePoint[] = useSelector((state: RootState) => state.sharePoint.members.existingUsers);
  const newMembers: SharePointMember[] = useSelector((state: RootState) => state.sharePoint.members.newMembers);
  const user = useSelector((state: RootState) => state.authentication.user);
  const sharePointMembers = existingUsers.length + newMembers.length;

  const { control } = useFormContext<FormSchema>();

  const { fields, replace } = useFieldArray({
    control,
    name: 'users',
  });

  useEffect(() => {
    if (newMembers.length) {
      const formattedUsers = newMembers.map((member) => ({
        email: member.email,
        statusActive: false,
        displayName: member.displayName,
        role: createableRoles.find((role) => role.value === IRoles.EMPLOYEE),
        externalUser: user?.externalUser ?? false,
      }));
      replace(formattedUsers);
    }
  }, [newMembers, replace, user]);

  return (
    <>
      <TopContainer>
        <TangiTypography type="subheading">
          <Trans i18nKey="SHARE_POINT_WIZARD.ADD_USERS.HEADER" values={{ COUNT: sharePointMembers }} />
        </TangiTypography>
      </TopContainer>

      {!!existingUsers?.length && <ExistingUsers existingUsers={existingUsers} />}

      {!!fields?.length && <NewMembers newMembersFields={fields} />}
    </>
  );
};

export default AddUsersStep;
