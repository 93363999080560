import { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getUsers } from 'redux-toolkit/thunks/clientThunks';
import { getRedirectRoute, IRoles } from './roles';
import { AppRoutes } from '../routing/consts';

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

// Redirects logged in users depending on the role, else redirects to the main page
export const useLoggedInRedirect = (isPushHistory = false, condition = true) => {
  const { user, loggedIn, activeAccount } = useSelector((state) => state.authentication);
  const history = useHistory();
  const [redirect, setRedirect] = useState(AppRoutes.MAIN);

  useEffect(() => {
    if (condition && loggedIn && user && activeAccount) {
      const currRole = activeAccount.role.name;
      if (currRole !== IRoles.SUPER_ADMIN && isPushHistory) {
        localStorage.setItem(`seenLoginDisclaimer[${activeAccount._id}]`, false);
      }
      setRedirect(getRedirectRoute(activeAccount));
    }
  }, [loggedIn, user, activeAccount, activeAccount, condition]);

  useEffect(() => {
    if (condition && redirect !== AppRoutes.MAIN && isPushHistory) {
      history.push(redirect);
    }
  }, [redirect, condition]);

  return { loggedIn, redirect };
};

export const dispatchGetUsersByType = (dispatch, roleName, clientId, entityType, pagination = { page: 1, limit: 10 }, filter = {}, sortConfig, isFormerTable = false, tableSettings = {}) => {
  const options = {
    userRoleName: roleName,
    client: clientId,
    type: entityType,
    pagination: {
      ...pagination,
      ...(sortConfig && { sortBy: `${sortConfig.field}:${sortConfig.direction}` }),
    },
    filter,
    isFormerTable,
    tableSettings,
  };
  dispatch(getUsers(options));
};

/**
 * Custom hook for managing the visibility of a component based on clicks outside the component.
 * @param {boolean} initialIsVisible - The initial visibility state of the component.
 * @returns {object} An object containing references and state for managing component visibility.
 */
export const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
};
