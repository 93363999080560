import { IpAuditActionStepSubmission, IpAuditStepKey } from '_components/IpAudit/types';
import { StepTitle } from '../components/StepTitle/StepTitle';
import { StepContainer, StepInnerContainer } from '../styles';
import { TangiTypography } from '_components/TangiLibrary/TangiTypography/TangiTypography';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { useTranslation } from 'react-i18next';
import { ContractAnalyzerCard } from './ContractAnalyzerCard';
import TangiModal from '_components/TangiLibrary/TangiModal/TangiModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '_helpers/store';
import { useEffect, useState } from 'react';
import { ContractToolBodyContainer, ContractToolBodyStyle, ContractToolContainer, OverflowContainer } from './styles';
import { TangiContractContent } from '_components/TangiLibrary/TangiContractTool/TangiContractContent';
import { TangiContractMatchScore } from '_components/TangiLibrary/TangiContractTool/TangiContractMatchScore';
import { ContractChip } from '_components/TangiLibrary/TangiContractTool/components/ContractChip/ContractChip';
import { getScoreColor, getTextByScore } from '_components/TangiLibrary/TangiContractTool/components/ContractHeader/utils';
import { ChipType } from '_components/TangiLibrary/TangiContractTool/components/ContractChip/types';
import { FormRadiosGroup } from '../components/FormRadiosGroup/FormRadiosGroup';
import { formBooleanOptions } from '../components/FormRadiosGroup/consts';
import { useForm } from 'react-hook-form';
import { ContractsFormValues } from './types';
import { parseValueToFormValue } from './utils';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { useIpAuditPopup } from '../../context/IpAuditPopup.provider';
import { QuestionsContainer } from '../components/FormRadiosGroup/styles';
import { useMediaQuery } from '_hooks/useMediaQuery';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { useIpAuditContext } from '_components/IpAudit/IpAudit.provider';
import { NOTIFICATION_VARIANTS, TangiNotification } from '_components/TangiLibrary';
import { useHandleSteps } from '_components/TangiLibrary/TangiStepper/useHandleSteps';

const createContractModalHeader = (title: string) => {
  return (
    <TangiTypography weight="semibold" type="heading-lg">
      {title}
    </TangiTypography>
  );
};

const createContractModalBody = (score: number, scoreStatus: ChipType, textByScore: string) => {
  return (
    <ContractToolContainer>
      <ContractChip variant={scoreStatus} text={textByScore} />
      <ContractToolBodyContainer>
        <OverflowContainer>
          <TangiContractContent />
        </OverflowContainer>

        <TangiContractMatchScore score={score} />
      </ContractToolBodyContainer>
    </ContractToolContainer>
  );
};

export const Contracts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contractsAction: IpAuditActionStepSubmission = useSelector((state: RootState) => state.client.ipAudit[IpAuditStepKey.CONTRACTS]);
  const { getStepCurrentData, submitContracts, isStepHasWarning } = useIpAuditContext();
  const { setHasStepChanges } = useIpAuditPopup();
  const value = getStepCurrentData(IpAuditStepKey.CONTRACTS);
  const { handleStepChange } = useHandleSteps();
  const [isShowNotification, setIsShowNotification] = useState(!!isStepHasWarning(IpAuditStepKey.CONTRACTS));
  const {
    handleSubmit,
    register,
    formState: { isValid },
    watch,
    setValue,
  } = useForm<ContractsFormValues>({
    defaultValues: parseValueToFormValue(value),
    mode: 'onChange',
  });
  const watchProvisions = watch('provisions');

  const [isOpenContractsModal, setIsOpenContractsModal] = useState(false);
  const contractData = useSelector((state: RootState) => state.contract).contractData;

  useEffect(() => {
    if (contractsAction.hasData) {
      dispatch(clientActions.clearIpAuditAction(IpAuditStepKey.CONTRACTS));
      handleStepChange(IpAuditStepKey.KEY_ASSETS_SYSTEMS);
    }
  }, [contractsAction.hasData]);

  useEffect(() => {
    const provisions = JSON.stringify(value?.hasConfidentialityProvisions);
    if (watchProvisions !== provisions) setHasStepChanges(true);
    setIsShowNotification(watchProvisions === 'false');

    return () => setHasStepChanges(false);
  }, [watchProvisions]);

  const onSubmit = (data: ContractsFormValues) => {
    const { provisions } = data;
    const hasConfidentialityProvisions = provisions === 'true';
    submitContracts({ hasConfidentialityProvisions });
    dispatch(mixpanelEvents.analyzeContractFile());
    setHasStepChanges(false);
  };

  const { filename, score } = contractData;

  const scoreStatus = getScoreColor(score);
  const textByScore = getTextByScore(score);

  const isSmallScreen = useMediaQuery('(max-width: 1440px)');

  return (
    <>
      <StepContainer>
        <StepInnerContainer>
          <StepTitle stepKey={IpAuditStepKey.CONTRACTS} />
          <form>
            <QuestionsContainer>
              <FormRadiosGroup
                groupKey="provisions"
                options={formBooleanOptions}
                title={'IP_AUDIT.IP_AUDIT_STEPS.CONTRACTS.PROVISIONS'}
                register={register}
                onClear={() => setValue('provisions', '')}
                isDisableOnClear={!watchProvisions}
              />
            </QuestionsContainer>
          </form>
          <ContractAnalyzerCard onClickContractStatus={() => setIsOpenContractsModal(true)} />
          {isShowNotification && <TangiNotification variant={NOTIFICATION_VARIANTS.WARNING}>{t('IP_AUDIT.IP_AUDIT_STEPS.CONTRACTS.FALSE_COMPLETION')}</TangiNotification>}
        </StepInnerContainer>
        <StepFooter onSubmit={handleSubmit(onSubmit)} isContinueBtnDisabled={!isValid} isContinueBtnLoading={contractsAction.isLoading} />
      </StepContainer>
      <TangiModal
        show={isOpenContractsModal}
        header={createContractModalHeader(filename ?? '')}
        onHide={() => setIsOpenContractsModal(false)}
        body={createContractModalBody(score, scoreStatus, t(textByScore))}
        slotsStyles={{ bodyContainer: { style: ContractToolBodyStyle } }}
        containerStyle={{ height: isSmallScreen ? '92vh' : '85vh' }}
      />
    </>
  );
};
