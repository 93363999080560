import { TangiCard } from '_components/TangiLibrary/TangiCard/TangiCard';
import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const Container = styled(TangiCard)`
  background: linear-gradient(266.5deg, #dccff0 5.09%, #f6f3fb 64.09%);
  min-height: 112px;
  padding: ${SPACING[3]};
  display: flex;
  gap: ${SPACING[3.5]};
  align-items: center;
`;

export const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[0.5]};
  width: 100%;
`;

export const RightSideHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[2]};
`;

export const RemoveBannerBtn = styled.span`
  margin-left: auto;
  cursor: pointer;
`;

export const DescriptionContainer = styled.div`
  max-width: 668px;
`;
