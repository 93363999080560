import TangiCollapseTable from '_components/TangiLibrary/TangiCollapseTable/TangiCollapseTable';
import { buildMultipleAcknowledgementTableRows } from './MultipleAcknowledgementTable.utils';
import { DeleteRecipientData, MultipleAcknowledgementTableData, MultipleAcknowledgementTableRowData, RecipientEntityRowData } from './types';
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types';
import { acknowledgementExpandableRowStyle, multipleAcknowledgementTableStyle } from './styles';
import TableSkeleton from '_components/TableSkeleton/TableSkeleton';

interface Props {
  acknowledgementTableColumns: TableColumn<MultipleAcknowledgementTableRowData>[];
  groupsTableColumns: TableColumn<RecipientEntityRowData>[];
  recipientsTableColumns: TableColumn<RecipientEntityRowData>[];
  tableData: MultipleAcknowledgementTableData[];
  handleDeleteRecipient: (data: DeleteRecipientData) => void;
  isLoading?: boolean;
}

export const MultipleAcknowledgementTable = ({ tableData, acknowledgementTableColumns, groupsTableColumns, recipientsTableColumns, handleDeleteRecipient, isLoading = false }: Props) => (
  <>
    {isLoading ? (
      <TableSkeleton count={5} />
    ) : (
      <TangiCollapseTable
        tableColumns={acknowledgementTableColumns}
        tableData={buildMultipleAcknowledgementTableRows(tableData, groupsTableColumns, recipientsTableColumns, handleDeleteRecipient)}
        expandableRowStyle={acknowledgementExpandableRowStyle}
        customStyles={multipleAcknowledgementTableStyle}
      />
    )}
  </>
);
