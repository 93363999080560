import { ReactNode } from 'react';
import DataTable, { TableColumn, TableStyles } from 'react-data-table-component';
import { defaultStyles, TableContainer } from './styles';
import { CSSObject } from 'styled-components';
import { useMediaQuery } from '_hooks/useMediaQuery';

interface Props<T extends { collapseContent?: ReactNode; _id: string }> {
  tableColumns: TableColumn<T>[];
  tableData: T[];
  tableHeader?: string;
  customStyles?: TableStyles;
  isLoading?: boolean;
  progressComponent?: ReactNode;
  noDataComponent?: ReactNode;
  expandableRowClasses?: string[];
  nonExpandableRowClasses?: string[];
  expandableRowStyle?: CSSObject;
  nonExpandableRowStyle?: CSSObject;
  isNoHeader?: boolean;
}

const TangiCollapseTable = <T extends { collapseContent?: ReactNode; _id: string }>({
  tableColumns,
  tableData,
  customStyles,
  tableHeader = '',
  progressComponent,
  noDataComponent,
  isLoading,
  expandableRowClasses,
  expandableRowStyle,
  nonExpandableRowClasses,
  nonExpandableRowStyle,
  isNoHeader = false,
}: Props<T>) => {
  const ExpandableComponent = <T extends { collapseContent?: ReactNode }>({ data }: { data: T }) => <div>{data.collapseContent}</div>;
  const conditionalRowStyles = [
    {
      when: (row: T) => !!row.collapseContent,
      classNames: expandableRowClasses,
      ...(expandableRowStyle && { style: expandableRowStyle }),
    },
    {
      when: (row: T) => !row.collapseContent,
      classNames: nonExpandableRowClasses,
      ...(nonExpandableRowStyle && { style: nonExpandableRowStyle }),
    },
  ];

  const isSmallScreen = useMediaQuery('(max-width: 1440px)');

  return (
    <TableContainer $maxTableBodyHeight={isSmallScreen ? '285px' : '458px'}>
      <DataTable
        title={tableHeader}
        columns={tableColumns}
        data={tableData}
        expandableRows
        expandableRowsComponent={ExpandableComponent}
        customStyles={{
          ...defaultStyles,
          ...customStyles,
        }}
        conditionalRowStyles={conditionalRowStyles}
        expandableRowDisabled={(row) => !row.collapseContent}
        progressComponent={progressComponent}
        noDataComponent={noDataComponent}
        progressPending={isLoading}
        noTableHead={isNoHeader}
      />
    </TableContainer>
  );
};

export default TangiCollapseTable;
