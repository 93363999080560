import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ComputedCell, HeatMap, HeatMapSerie, TooltipProps } from '@nivo/heatmap';

import { ChartContainer, ColorIcon, SubjectContainer, ToolTipRow, TooltipContainer } from '../styles';
import { TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, tradeSecretsHeatMapPalette } from 'utils/theme';
import { TRANSLATION_KEY } from 'pages/TradeSecretsAnalysis/types';
import { Datum, ExtendedDatum, ExtraProps } from './types';
import { GradientContainer, HeatMapChartAndLabelContainer, HeatMapChartScrollContainer, LeftScrollGradient, RightScrollGradient, RotateLabelStyle, horizontalLabel, verticalLabel } from './styles';
import { SCROLL_GRADIENT_SIZE, getFormat, getTextColor, maxSubjectLength } from './utils';
import { HexColor } from '../types';

interface Props {
  isCompetitiveChart?: boolean;
  data: HeatMapSerie<ExtendedDatum, ExtraProps>[];
  height?: number;
  width?: number;
  handleClick: (node: ComputedCell<ExtendedDatum>) => void;
}

const HEAT_MAP_TOP_HEIGHT = 130;

const HeatMapChart = ({ data, height = 800, width = 800, isCompetitiveChart = false, handleClick }: Props) => {
  const { t } = useTranslation();

  const axisTopRotation = isCompetitiveChart ? horizontalLabel : verticalLabel;
  const axisXLabel = TRANSLATION_KEY.CHARTS_PATENTS;
  const borderWidth = isCompetitiveChart ? 1 : 0;
  const axisYLabel = TRANSLATION_KEY.CHARTS_SUBJECTS;

  const leftMargin = useMemo(() => maxSubjectLength(data) + SCROLL_GRADIENT_SIZE, [data]);

  const textColor = useCallback((p: Omit<ComputedCell<Datum>, 'labelTextColor'>) => {
    return getTextColor(p.color as HexColor);
  }, []);

  const renderTooltip = useCallback(
    (p: TooltipProps<ExtendedDatum>) => {
      return (
        <TooltipContainer data-testid="heat-map-tooltip">
          <ToolTipRow>
            <TangiTypography color={NEUTRAL_SHADES[800]}>{`${t(TRANSLATION_KEY.CHARTS_SUBJECT)}:`}</TangiTypography>
            <SubjectContainer>
              <ColorIcon color={p.cell.color} />
              <TangiTypography color={NEUTRAL_SHADES.BLACK}>{p.cell.serieId}</TangiTypography>
            </SubjectContainer>
          </ToolTipRow>
          <ToolTipRow>
            <TangiTypography color={NEUTRAL_SHADES[800]}>{`${t(TRANSLATION_KEY.CHARTS_POTENTIAL_TRADE_SECRETS)}:`}</TangiTypography>
            {isCompetitiveChart ? (
              <TangiTypography color={NEUTRAL_SHADES.BLACK}>{p.cell.data.ts_count}</TangiTypography>
            ) : (
              <TangiTypography color={NEUTRAL_SHADES.BLACK}>{p.cell.data.y}</TangiTypography>
            )}
          </ToolTipRow>
          <ToolTipRow>
            {isCompetitiveChart ? (
              <TangiTypography color={NEUTRAL_SHADES[800]}>{`${t(TRANSLATION_KEY.COMPANY)}:`}</TangiTypography>
            ) : (
              <TangiTypography color={NEUTRAL_SHADES[800]}>{`${t(TRANSLATION_KEY.CHARTS_PATENT_NUMBER)}:`}</TangiTypography>
            )}
            <TangiTypography color={NEUTRAL_SHADES.BLACK}>{p.cell.data.x}</TangiTypography>
          </ToolTipRow>
        </TooltipContainer>
      );
    },
    [isCompetitiveChart, t],
  );

  return (
    <ChartContainer className="ChartContainer">
      <TangiTypography customStyles={RotateLabelStyle} weight="bold">
        {t(axisYLabel)}
      </TangiTypography>
      <HeatMapChartAndLabelContainer data-testid="heat-map-chart">
        {!isCompetitiveChart && <TangiTypography weight="bold">{t(axisXLabel)}</TangiTypography>}
        <GradientContainer>
          <LeftScrollGradient height={height} />
          <HeatMapChartScrollContainer isCompetitiveChart={isCompetitiveChart} className="HeatMapChartScrollContainer">
            <HeatMap<ExtendedDatum, ExtraProps>
              onClick={(node) => handleClick(node)}
              height={height + HEAT_MAP_TOP_HEIGHT}
              width={width}
              data={data}
              valueFormat={(p: number) => getFormat(p, isCompetitiveChart)}
              margin={{ top: 120, left: leftMargin, right: 100 }}
              forceSquare={false}
              borderWidth={borderWidth}
              axisTop={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: axisTopRotation,
                truncateTickAt: 0,
              }}
              axisLeft={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                truncateTickAt: 0,
              }}
              colors={{ type: 'quantize', colors: tradeSecretsHeatMapPalette }}
              emptyColor={NEUTRAL_SHADES[100]}
              inactiveOpacity={0.2}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 0]],
              }}
              labelTextColor={(p: Omit<ComputedCell<Datum>, 'labelTextColor'>) => {
                return textColor(p);
              }}
              tooltip={(p: TooltipProps<ExtendedDatum>) => {
                return renderTooltip(p);
              }}
              animate={false}
              hoverTarget="cell"
            />
          </HeatMapChartScrollContainer>
          <RightScrollGradient height={height} />
        </GradientContainer>
      </HeatMapChartAndLabelContainer>
    </ChartContainer>
  );
};

export default HeatMapChart;
