import { useState } from 'react';
import { Container, QuestionText } from './styles';

const QuizQuestion = ({ questionData, setAnswersState }) => {
  const [radioValue, setRadioValue] = useState(null);

  const onChangeHandler = (answer) => {
    // Setting local state for radio boxes
    setRadioValue(answer);
    // Setting The general answer with boolean state about the users answer.
    setAnswersState((prevState) => {
      const newState = { ...prevState, [questionData._id]: { questionData, usersAnswer: answer, wasUserRight: answer === questionData.answer.toString() } };
      return newState;
    });
  };

  return (
    <Container>
      <QuestionText className="question">{questionData.text}</QuestionText>
      <div className="options">
        {questionData.choices.map((radio, index) => (
          <>
            <input
              style={{ color: 'black', margin: '0px 8px', border: 'none' }}
              key={index}
              type="radio"
              name={radio._id}
              value={radio.serialNumber}
              checked={radioValue === radio.serialNumber.toString()}
              onChange={(e) => {
                onChangeHandler(e.target.value);
              }}
            />
            {radio.option}
            <br />
          </>
        ))}
      </div>
    </Container>
  );
};

export default QuizQuestion;
