/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { createAsyncThunk } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import assetsAdapter from 'redux-toolkit/adapters/assetsAdapter';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { acknowledgementActions } from '_actions/acknowledgementActions';
import {
  AcknowledgePostAssetParams,
  AcknowledgePreviewAssetPOSTParams,
  Asset,
  DeleteRecipientParams,
  GetAssets,
  GetPrivilegeLogData,
  GetAssetAuditLog,
  DeleteDecryptedFileFromFsPayload,
  AllAssetsFilesResponse,
  ShareMultipleAssetsData,
  GetFilePathPayload,
  ShareMultipleAssetsResponse,
  CheckShareStatusResponse,
  CheckShareStatusData,
  GetRecipientsResponse,
  RecipientGroup,
  GetAssetsGroupedRecipientsResponse,
} from 'utils/types/assets/assets';
import { AppDispatch, AppThunk, RootState } from '_helpers';
import { clientConstants, responseStatus } from '_constants';
import { excelExporters } from 'utils/excelExporters';
import { INITIAL_PAGE } from 'utils/types/activityLog/asset/types';
import { EventSource } from '_services/utils/MixPanel/types';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { KeyAssetsFromSystemData } from '_components/IpAudit/types';
import { AssetForAcknowledgement, PreviewToken } from 'utils/types/assets/acknowledgementAsset';
import { removeStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { JOB_STATUS } from 'utils/enums';

export const getAssetById = createAsyncThunk('/assets/assetId', async (assetId: string) => {
  const res = await assetsAdapter.getAssetsById(assetId);
  return res.data ?? null;
});

export const getAssets = createAsyncThunk('/assets', async ({ sortValue, searchValue, page, limit, filter }: GetAssets) => {
  const res: any = await assetsAdapter.getAssets({ sortValue, searchValue, page, limit, filter });
  return res ?? null;
});

export const getIpAuditAssets = createAsyncThunk('/assets/ip-audit', async () => {
  const res: any = await assetsAdapter.getIpAuditAssets();
  return res ?? null;
});

export const getPrivilegeLogData = createAsyncThunk('/getPrivilegeLogData', async (requestData: GetPrivilegeLogData) => {
  const res: any = await assetsAdapter.getPrivilegeLogData(requestData);
  if (res) {
    const { isFilterApplied } = requestData;
    excelExporters.exportPrivilegeLog(res, isFilterApplied);
  }
  return res ?? null;
});

export const createAsset: AppThunk = (assetData: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(assetsActions.setCreateUpdateAssetLoading(true));
    const asset: Asset = await assetsAdapter.createAsset(assetData);
    const createdAssetId: string = asset?.id;
    const messageSuccussOfSharingContributors = await assetsAdapter.shareContributors({ assetId: asset.id, contributors: asset.contributor });
    dispatch(assetsActions.setShareContributorsStatus(messageSuccussOfSharingContributors));
    // if files were added upload them to the server
    if (asset?.client && createdAssetId && assetData?.uploadFiles?.length > 0) {
      dispatch(assetsActions.setAssetStatus(responseStatus.uploadStarted));
      await assetsAdapter.uploadFiles(assetData, createdAssetId);
      batch(() => {
        dispatch(assetsActions.setAssetStatus(responseStatus.uploadSuccess));
        dispatch(assetsActions.setCreatedAssetId(createdAssetId));
        dispatch(assetsActions.setCreateUpdateAssetLoading(false));
      });
    } else {
      batch(() => {
        dispatch(assetsActions.setAssetStatus(responseStatus.createSuccess));
        dispatch(assetsActions.setCreatedAssetId(createdAssetId));
        dispatch(assetsActions.setCreateUpdateAssetLoading(false));
      });
    }
  } catch (error) {
    dispatch(assetsActions.setUploadFileError(error));
  }
};

export const validateAssets = createAsyncThunk('/assets/validate', async ({ assetsRows, clientId }: any) => {
  const res: any = await assetsAdapter.validateAssets({ data: assetsRows, clientId });
  return res ?? null;
});

export const createAssets = createAsyncThunk('/assets/create_multiple', async ({ batchAssets, clientId }: any) => {
  const res: any = await assetsAdapter.createAssets({ data: batchAssets, clientId });
  return res ?? null;
});

export const createAssetsIpAudit = createAsyncThunk('/assets/create_multiple_ip_audit', async ({ data, clientId }: KeyAssetsFromSystemData) => {
  const res: any = await assetsAdapter.createAssetsIpAudit({ data, clientId });
  return res ?? null;
});

export const updateAsset: AppThunk = (assetData: any) => async (dispatch: AppDispatch) => {
  try {
    const { files } = assetData;
    const originalFiles = files.filter((file: any) => file._id);
    const newFiles = files.filter((file: any) => !file._id);
    assetData.files = originalFiles;
    assetData.newfiles = newFiles;
    dispatch(assetsActions.setCreateUpdateAssetLoading(true));
    const asset = await assetsAdapter.updateAsset(assetData);
    if (assetData.client && assetData.id && assetData.newfiles.length > 0) {
      dispatch(assetsActions.setAssetStatus(responseStatus.uploadStarted));
      await assetsAdapter.uploadUpdateFiles(assetData);
      batch(() => {
        dispatch(assetsActions.setAssetStatus(responseStatus.uploadSuccess));
        dispatch(assetsActions.setCreatedAssetId(asset.id));
        dispatch(assetsActions.setCreateUpdateAssetLoading(false));
      });
    } else {
      batch(() => {
        dispatch(assetsActions.setAssetStatus(responseStatus.editSuccess));
        dispatch(assetsActions.setCreatedAssetId(asset.id));
        dispatch(assetsActions.setCreateUpdateAssetLoading(false));
      });
    }
  } catch (error) {
    dispatch(assetsActions.setError(error));
  }
};

export const getFilePathUrl = createAsyncThunk('/files/getFilePathUrl', async ({ data, token, isPreview }: GetFilePathPayload, { dispatch }) => {
  const fromWhere: EventSource = isPreview ? EventSource.FromEmail : EventSource.FromSystem;
  dispatch(mixpanelEvents.assetFilePreview({ fromWhere }));

  const res: any = await assetsAdapter.getFileUrl(data, token, isPreview);
  return res ?? null;
});

export const deleteAssetFilePreview = createAsyncThunk('/files/deleteAssetFilePreview', async ({ data, token, isAuthenticated = false }: DeleteDecryptedFileFromFsPayload) => {
  await assetsAdapter.deleteAssetFilePreview(data, token, isAuthenticated);
  return;
});

export const shareMultipleAssets = createAsyncThunk<ShareMultipleAssetsResponse | null, ShareMultipleAssetsData, { dispatch: AppDispatch }>(
  '/asset/shareMultipleAsset',
  async ({ clientId, recipients, departments, businessUnits, partnerships, assetsIds, isUpdateAsset }, { dispatch }) => {
    const res = await assetsAdapter.shareMultipleAssets({ recipients, assetsIds, departments, businessUnits, partnerships });

    if (res?.jobId) {
      const localStorageKey = `${clientConstants.SHARE_ASSETS_JOB_ID}[${clientId}]`;
      saveStateToLocalStorage(localStorageKey, res.jobId);
      dispatch(checkShareStatus({ jobId: res.jobId, clientId, isUpdateAsset }));
    }
    return res ?? null;
  },
);

export const getAssetsGroupedRecipients = createAsyncThunk<GetAssetsGroupedRecipientsResponse | null, string[]>('/asset/getAssetsGroupedRecipients', async (assetsIds) => {
  const res = await assetsAdapter.getAssetsGroupedRecipients({ assetsIds });
  return res ?? null;
});

export const checkShareStatus = createAsyncThunk<CheckShareStatusResponse | null, CheckShareStatusData, { dispatch: AppDispatch }>(
  'assets/recipients-check-status',
  async ({ jobId, clientId, isUpdateAsset }, { dispatch }) => {
    const res = await assetsAdapter.checkShareStatus(jobId);
    const { status } = res ?? {};

    if (status === JOB_STATUS.STARTED) {
      setTimeout(() => {
        dispatch(checkShareStatus({ jobId, clientId, isUpdateAsset }));
      }, clientConstants.POLLING_INTERVAL);
    }

    if ([JOB_STATUS.SUCCEEDED, JOB_STATUS.FAILED].includes(status)) {
      removeStateFromLocalStorage(`${clientConstants.SHARE_ASSETS_JOB_ID}[${clientId}]`);
    }

    return res ? { ...res, isUpdateAsset } : null;
  },
);

export const getRefNumber = createAsyncThunk('/asset/getRefNumber', async (clientId: string) => {
  const res: any = await assetsAdapter.getRefNumber(clientId);
  return res ?? null;
});

export const deleteAsset = createAsyncThunk('/asset/deleteAsset', async (assetId: string) => {
  const res: any = await assetsAdapter.deleteAsset(assetId);
  return res ?? null;
});

export const getAssetsClient = createAsyncThunk('/asset/getAssetsClient', async (params: GetAssets) => {
  const res: any = await assetsAdapter.getAssets(params);
  return res ?? null;
});

export const deleteRecipient = createAsyncThunk('/asset/deleteRecipient', async ({ assetId, recipientId }: DeleteRecipientParams) => {
  const res: any = await assetsAdapter.deleteRecipient(assetId, recipientId);
  if (!res) return null;
  return { res, assetId, recipientId };
});

export const getAssetRecipients = createAsyncThunk<RecipientGroup[], string[]>('assets/getRecipients', async (assetsIds: string[]) => {
  const res: GetRecipientsResponse = await assetsAdapter.getAssetRecipients(assetsIds);
  return res.recipients ?? [];
});

export const getAssetsForAcknowledgement = createAsyncThunk<AssetForAcknowledgement[], string>('/asset/getAssetsForAcknowledgement', async (token: string) => {
  const res: AssetForAcknowledgement[] = await assetsAdapter.getAssetsForAcknowledgement(token);
  return res ?? [];
});

export const acknowledgeAndGetPreviewToken = createAsyncThunk<PreviewToken | null, { code: string; token: string }>(
  '/asset/acknowledgeAndGetPreviewToken',
  async ({ code, token }: AcknowledgePostAssetParams) => {
    const res = await assetsAdapter.acknowledgeAndGetPreviewToken(code, token);
    return res ?? null;
  },
);

export const requestPreviewOTP = createAsyncThunk<{ result: string } | null, string>('/asset/requestPreviewOTP', async (token: string) => {
  const res: { result: string } = await assetsAdapter.requestPreviewOTP(token);
  return res ?? null;
});

export const previewAssetWithOtp = createAsyncThunk('/asset/previewAssetWithOtp', async ({ token, otp, params }: AcknowledgePreviewAssetPOSTParams, { dispatch, getState }) => {
  const state: RootState = getState();
  const { pagination } = state.asset;
  const pa = params || { page: pagination.page, limit: pagination.limit };

  const res: any = await assetsAdapter.previewAssetWithOtp(token, otp, pa);
  const accountId = '';
  const assetId = res.id;
  const source = EventSource.FromEmail;

  if (res) {
    dispatch(mixpanelEvents.acknowledgementAsset({ accountId, selectedAssets: assetId, source }));
  }

  return { res, otp };
});

export const pendingAcknowledgement = createAsyncThunk('/asset/pendingAcknowledgement', async (clientId: string, { dispatch }) => {
  const assetsWaitingForAcknowledgement: any = await assetsAdapter.getAssetsPendingAcknowledgement(clientId);
  dispatch(acknowledgementActions.setAssetsNeedToBeAcknowledged(assetsWaitingForAcknowledgement));
  if (!assetsWaitingForAcknowledgement.length) {
    dispatch(acknowledgementActions.changeModalVisibility(false));
  }
  return assetsWaitingForAcknowledgement ?? null;
});

export const getAssetAuditLog = createAsyncThunk('/asset/getAssetAuditLog', async ({ assetId, page, limit }: GetAssetAuditLog, { getState, dispatch }) => {
  let newPage: number = INITIAL_PAGE;
  if (!page) {
    dispatch(assetsActions.setAssetAuditLogLoader()); //handling InfiniteScroll loaders
    const currentState: RootState = getState();
    newPage = currentState.asset.assetAuditLogMetaData.page + 1;
    dispatch(assetsActions.setAuditLogPage(newPage));
  }

  const res: any = await assetsAdapter.getAssetAuditLog({ assetId, page: page ?? newPage ?? INITIAL_PAGE, limit });
  return res ?? null;
});

export const downloadAllAssetsFiles = createAsyncThunk<AllAssetsFilesResponse, string, { rejectValue: string }>('asset/downloadAllAssetsFiles', async (clientId, { rejectWithValue }) => {
  try {
    const response = await assetsAdapter.downloadAllAssetsFiles(clientId);
    return response;
  } catch (error) {
    console.error('Error in downloadAllAssetsFiles thunk');
    return rejectWithValue('Failed to download assets files');
  }
});

export const checkDownloadAssetsFilesStatus = createAsyncThunk<Blob | AllAssetsFilesResponse, string, { rejectValue: string }>(
  'asset/checkDownloadAssetsFilesStatus',
  async (jobId, { rejectWithValue }) => {
    try {
      const response = await assetsAdapter.checkDownloadAssetsFilesStatus(jobId);
      return response;
    } catch (error) {
      console.error('Error in checkDownloadAssetsFilesStatus thunk');
      return rejectWithValue('Failed to get status of download assets files');
    }
  },
);
