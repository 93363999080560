import { useTranslation } from 'react-i18next';

import { CourseProgressionStatus, ICourseProgress } from '../../utils/types/courses/course';
import { getThumbnailUrl } from '../../utils/getThumbnailUrl';
import { formatDate } from '../../utils/dateUtils';
import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, SUCCESS_SHADES } from 'utils/theme';
import { TooltipContainer, TooltipHeader, TrainingContainer, RowContainer, DuoCenteredContainer, ViewItemContainer, TitleOfItemContainer } from './style';

interface CoursesProgressTooltipProps {
  completedCoursesNumber: number;
  coursesArray: ICourseProgress[];
}

const CoursesProgressTooltip = ({ completedCoursesNumber, coursesArray }: CoursesProgressTooltipProps) => {
  const { t } = useTranslation();
  return (
    <TooltipContainer>
      <TooltipHeader>
        <TangiTypography color={NEUTRAL_SHADES[800]}>
          {completedCoursesNumber}/{coursesArray.length} {t('PEOPLE.TRAINING_TOOLTIP.TRAINING_COMPLETED')}
        </TangiTypography>
      </TooltipHeader>
      <TrainingContainer>
        {coursesArray.map((course) => (
          <RowContainer key={course._id}>
            <TangiTypography className="course-name" weight="semibold">
              {course?.featuredVideoUrl && (
                <ViewItemContainer>
                  <img height="42" src={getThumbnailUrl(course.featuredVideoUrl)} alt="course-img" />
                  <TitleOfItemContainer>{course.title}</TitleOfItemContainer>
                </ViewItemContainer>
              )}
            </TangiTypography>
            {course?.progression?.status === CourseProgressionStatus.COMPLETED ? (
              <DuoCenteredContainer>
                <TangiTypography color={SUCCESS_SHADES[500]}>{course?.progression?.updatedAt ? formatDate(course.progression.updatedAt) : ''}</TangiTypography>
                <TangiSvgIcon component="check" color={SUCCESS_SHADES[500]} />
              </DuoCenteredContainer>
            ) : (
              <TangiTypography color={NEUTRAL_SHADES[600]}> {t('PEOPLE.TRAINING_TOOLTIP.NOT_COMPLETED')}</TangiTypography>
            )}
          </RowContainer>
        ))}
      </TrainingContainer>
    </TooltipContainer>
  );
};

export default CoursesProgressTooltip;
