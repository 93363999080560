import Skeleton from 'react-loading-skeleton';

import { RecipientsContainer } from '../style';

const RecipientsSkeleton = () => {
  return (
    <>
      <Skeleton height={35} width={250} className="my-3" />
      <RecipientsContainer>
        <Skeleton height={200} count={3} className="mt-4" />
      </RecipientsContainer>
    </>
  );
};

export default RecipientsSkeleton;
