import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import 'react-loading-skeleton/dist/skeleton.css';

export const CardsContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
`;

const CourseCardSkeleton = () => {
  return (
    <CardsContainer>
      {[1, 2, 3, 4, 5].map((card) => (
        <Skeleton key={card} height={300} width="72vw" style={{ marginRight: '0px', marginBottom: '30px' }} />
      ))}
    </CardsContainer>
  );
};

export default CourseCardSkeleton;
