import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { SPACING } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';

export const StyledFromGroup = styled(Form.Group)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledList = styled.ul`
  margin-bottom: 10px;
  padding-left: ${SPACING[1]};
`;

export const NotificationContainer = styled(FlexContainer)`
  flex-direction: column;
  gap: ${SPACING[2]};
`;
