import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import HomeContent from './HomeContent';
import { LoginHeader } from '../../_components/LoginHeader';
import { IconTypes } from '_components/TangiLibrary/TangiSvgIcon/types';
import { useLoggedInRedirect } from 'utils/customHooks';
import { AppRoutes } from 'routing/consts';
import { HomeContentProps, LoggedInRedirectProps } from './types';
import { Container, MainWrapper } from './style';

export const Home = () => {
  const { loggedIn, redirect } = useLoggedInRedirect() as LoggedInRedirectProps;
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const isOkta = !!queryParams.get('isOkta');
  const isOktaLogin = isOkta && !loggedIn;

  const handleButtonClick = () => {
    if (loggedIn) {
      history.replace(redirect);
    } else {
      history.replace(AppRoutes.LOGIN);
    }
  };

  const baseContentProps = {
    onButtonClick: handleButtonClick,
    isOktaLogin,
    oktaText: t('HOME_PAGE.OKTA_TEXT'),
  };

  const contentProps: HomeContentProps = loggedIn
    ? {
        ...baseContentProps,
        svgIcon: 'goToHomePage' as IconTypes,
        title: t('HOME_PAGE.RECONNECT'),
        buttonText: t('HOME_PAGE.GO_TO_HOMEPAGE'),
      }
    : {
        ...baseContentProps,
        svgIcon: 'loginPage' as IconTypes,
        title: t('LOBBY_PAGE.TITLE'),
        subtitle: t('HOME_PAGE.DISCOVER_AND_PROTECT'),
        buttonText: t('HOME_PAGE.LOGIN'),
      };

  return (
    <MainWrapper>
      <LoginHeader />
      <Container>
        <HomeContent {...contentProps} />
      </Container>
    </MainWrapper>
  );
};
