import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetTradeSecretsFromPatent,
  GetInventorsAccounts,
  GetPatentLastResult,
  UploadPatentApplication,
  ContinuationAnalysisRequest,
  ContinuationAnalysisResponse,
  ContinuationAnalysisData,
} from 'utils/types/patent/patent';
import patentAdapter from 'redux-toolkit/adapters/patentAdapter';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { patentConstants } from '_constants/patentConstants';
import { saveStateToLocalStorage } from 'utils/localStorage';
import { addIsAssetCreatedToPotentialTS } from 'utils/xrayUtils';
import { createContinuationExcelFromJson, triggerContinuationExcelDownload } from 'utils/exportExcelContiniation';
import { XRAY_JOB_STATUS, XRAY_SOURCE_TYPE } from 'utils/types/xRay/xRay';

import { RootState } from '_helpers';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { ACTION_TYPE } from '_components/PatentXRaySearch/PatentXraySearch';
import { getMimeTypeKey } from 'utils/getMimeTypeKey';
import { createWordDocument } from 'pages/ContinuationAnalysis/utils/createWordDocument';

export const getTradeSecretsFromPatent = createAsyncThunk('/patents/getPatentById', async ({ patentId, clientId, patentModel }: GetTradeSecretsFromPatent, { dispatch }) => {
  dispatch(patentActions.setPatentDataInitState());
  const res = await patentAdapter.getPatentById({ patentId, clientId, patentModel });
  dispatch(
    mixpanelEvents.xraySearch({
      typeOfAction: ACTION_TYPE.PATENT_ID,
      patentId: patentId,
    }),
  );
  if (res) {
    dispatch(getPatentResults({ patentResultId: res?.patentResultId, clientId }));
    saveStateToLocalStorage(patentConstants.PATENT_REQUEST_INFO, { ...res, clientId });
  }
  return res ? { ...res, clientId } : null;
});

export const getInventorsAccounts = createAsyncThunk('/patents/getInventorsAccounts', async ({ inventors, clientId }: GetInventorsAccounts) => {
  const accounts = await patentAdapter.getInventorsAccounts({ inventors: inventors, clientId });
  return accounts ?? null;
});

export const getPatentResults = createAsyncThunk('/patents/getPatentResults', async ({ patentResultId, clientId }: GetPatentLastResult) => {
  const res = await patentAdapter.getPatentResults({ patentResultId, clientId });
  if (res && res?.choice_0?.length) {
    const tradeSecretsWithIsAssetCreated = addIsAssetCreatedToPotentialTS(res.choice_0);
    res.choice_0 = tradeSecretsWithIsAssetCreated;
  }
  return res ?? null;
});

export const uploadPatentApplication = createAsyncThunk('/patents/uploadPatentApplication', async ({ files, clientId, patentModel }: UploadPatentApplication, { dispatch }) => {
  dispatch(patentActions.setPatentDataInitState());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res: any = await patentAdapter.uploadPatentApplication({ files, clientId, patentModel });

  //Mix Panel Event: patent search with file upload
  dispatch(
    mixpanelEvents.xraySearch({
      typeOfAction: ACTION_TYPE.FILE,
      fileType: files[0].type,
    }),
  );

  // TODO: fix any type
  dispatch(patentActions.setCurrentPatentFile(files));
  if (res) {
    dispatch(getPatentResults({ patentResultId: res?.patentResultId, clientId }));
    saveStateToLocalStorage(patentConstants.PATENT_REQUEST_INFO, { ...res, clientId });
  }
  return res ? { ...res, clientId } : null;
});

export const getContinuationAnalysisById = createAsyncThunk('/patents/getContinuationAnalysisById', async ({ patentId = '', isAdmin, clientId }: ContinuationAnalysisRequest, { dispatch }) => {
  dispatch(patentActions.initializeContinuationData());

  if (patentId) {
    dispatch(patentActions.setContinuationPatentId(patentId));
  }
  const res = await patentAdapter.getContinuationAnalysisById({ patentId, isAdmin, clientId });
  // Mix Panel Event: continuation search
  dispatch(mixpanelEvents.continuationSearch(XRAY_SOURCE_TYPE.GOOGLE, { patentNumber: patentId }));

  if (res) {
    dispatch(getContinuationAnalysisResults({ continuationResultId: res.continuationResultId }));
  }
  return res ?? null;
});

export const getContinuationAnalysisByFile = createAsyncThunk('/patents/getContinuationAnalysisByFile', async ({ isAdmin, files, clientId }: ContinuationAnalysisRequest, { dispatch }) => {
  dispatch(patentActions.initializeContinuationData());
  if (files) {
    dispatch(patentActions.setContinuationFile(files?.[0].name));
  }

  const res = await patentAdapter.getContinuationAnalysisByFile({ isAdmin, files, clientId });

  // Mix Panel Event: continuation search
  const fileTypeKey = getMimeTypeKey(files?.[0].type);
  dispatch(mixpanelEvents.continuationSearch(XRAY_SOURCE_TYPE.FILE, { fileType: fileTypeKey }));

  if (res) {
    dispatch(getContinuationAnalysisResults({ continuationResultId: res.continuationResultId }));
  }
  return res ?? null;
});

export const getContinuationAnalysisResults = createAsyncThunk('/patents/getContinuationAnalysisResults', async ({ continuationResultId }: ContinuationAnalysisResponse, { dispatch, getState }) => {
  const res = await patentAdapter.getContinuationAnalysisResults({ continuationResultId });
  const currentState: RootState = getState();
  const data: ContinuationAnalysisData = currentState.patent.continuationData;
  const patentIdentifier = data.patentId || data.fileName || '';

  const { activeAccount } = currentState.authentication;

  const isAccessToExcel = activeAccount.specialAdminPermissions?.ContinuationExcel;

  if (res.status === XRAY_JOB_STATUS.SUCCEEDED) {
    dispatch(patentActions.setShowContinuationExportToast(true));
    createWordDocument(res, patentIdentifier, dispatch);
    if (isAccessToExcel) {
      try {
        dispatch(patentActions.setContinuationExportFileStatus({ fileType: 'excel', status: XRAY_JOB_STATUS.STARTED }));
        const buffer = await createContinuationExcelFromJson(res);
        triggerContinuationExcelDownload(buffer, `ContinuationAnalysisExcel ${patentIdentifier}.xlsx`);
        dispatch(patentActions.setContinuationExportFileStatus({ fileType: 'excel', status: XRAY_JOB_STATUS.SUCCEEDED }));
      } catch (error) {
        console.error('Error generating Excel file:', error);
        dispatch(patentActions.setContinuationExportFileStatus({ fileType: 'excel', status: XRAY_JOB_STATUS.FAILED }));
      }
    }
  }

  return res ?? null;
});
