import styled from 'styled-components';

import { NEUTRAL_SHADES } from '../../../utils/theme';

export const Container = styled.div<{ $disabled: boolean }>`
  flex-shrink: 0;
  opacity: ${(props) => (props.$disabled ? 0.5 : undefined)};
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};

  .form-check {
    padding: 0;
    display: flex;

    input {
      margin: 0;

      &:hover {
        cursor: pointer;
      }

      &:hover:not(:focus):not(:checked) {
        border: 1px solid ${NEUTRAL_SHADES[600]};
      }
    }

    label {
      margin-left: 16px;
    }
  }
`;
