import { TangiSvgIcon, TangiTypography, TangiButton } from '_components/TangiLibrary';
import { TangiActionToolbar } from '_components/TangiLibrary/TangiActionToolbar/TangiActionToolbar';
import { t } from 'i18next';
import { NEUTRAL_SHADES } from 'utils/theme';
import Divider, { SelectedContainer } from './styles';
import Tippy from '@tippyjs/react';

interface PeopleActionToolbarProps {
  activateSelectedUsers: () => void;
  deactivateSelectedUsers: () => void;
  deselectUsers: () => void;
  getTotalSelected: () => number;
  isUpdateManyLoading: boolean;
  isDeactivatedUsersSelected: boolean;
  isActiveUsersSelected: boolean;
  isPendingUsersSelected: boolean;
}

export const PeopleActionToolbar = ({
  activateSelectedUsers,
  deactivateSelectedUsers,
  deselectUsers,
  getTotalSelected,
  isUpdateManyLoading,
  isDeactivatedUsersSelected,
  isActiveUsersSelected,
  isPendingUsersSelected,
}: PeopleActionToolbarProps) => {
  const totalSelected = getTotalSelected();

  return (
    <TangiActionToolbar isVisible={!!totalSelected}>
      <SelectedContainer data-testid={`total-selected-${totalSelected}`} onClick={() => deselectUsers()}>
        <TangiSvgIcon component="remove" color={NEUTRAL_SHADES[1100]} />
        <TangiTypography>{t('PEOPLE.TITLE.SELECTED', { totalSelected })}</TangiTypography>
      </SelectedContainer>
      <Divider />
      {(isDeactivatedUsersSelected || isPendingUsersSelected) &&
        (isUpdateManyLoading ? (
          <Tippy content={t('PEOPLE.MULTISELECT.ACTION.DISABLE_ACTION')} placement="top">
            <div>
              <TangiButton
                data-testid="activate-many-button"
                disabled={isUpdateManyLoading}
                variant="tertiary"
                svgIcon="activate"
                text={t('GENERAL.BUTTONS_TEXT.ACTIVATE')}
                onClick={() => activateSelectedUsers()}
              />
            </div>
          </Tippy>
        ) : (
          <TangiButton
            data-testid="activate-many-button"
            disabled={isUpdateManyLoading}
            variant="tertiary"
            svgIcon="activate"
            text={t('GENERAL.BUTTONS_TEXT.ACTIVATE')}
            onClick={() => activateSelectedUsers()}
          />
        ))}
      {(isActiveUsersSelected || isPendingUsersSelected) &&
        (isUpdateManyLoading ? (
          <Tippy content={t('PEOPLE.MULTISELECT.ACTION.DISABLE_ACTION')} placement="top">
            <div>
              <TangiButton
                data-testid="deactivate-many-button"
                disabled={isUpdateManyLoading}
                variant="tertiary"
                svgIcon="deactivate"
                text={t('GENERAL.BUTTONS_TEXT.DEACTIVATE')}
                onClick={() => deactivateSelectedUsers()}
              />
            </div>
          </Tippy>
        ) : (
          <TangiButton
            data-testid="deactivate-many-button"
            disabled={isUpdateManyLoading}
            variant="tertiary"
            svgIcon="deactivate"
            text={t('GENERAL.BUTTONS_TEXT.DEACTIVATE')}
            onClick={() => deactivateSelectedUsers()}
          />
        ))}
    </TangiActionToolbar>
  );
};
