import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';
import { SPACING } from 'utils/theme';

import { NEUTRAL_SHADES } from '../../utils/theme';

export const ModalContentContainer = styled.div`
  max-width: 856px;
  min-width: 576px;
`;

export const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 4px;
`;

export const StyledModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  padding: 0px 16px 24px;
  max-height: 464px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #c5c5c5;
    border-radius: 6px;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-family: 'DIN2014-Regular';
  gap: 20px;
  font-weight: 600;
`;

export const StyledModalFooter = styled(Modal.Footer)`
  padding: 4px 20px 20px;
  span:first-of-type {
    margin-right: 24px;
  }
`;
