import { TangiChip, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { Container, DescriptionContainer, RemoveBannerBtn, RightSideContainer, RightSideHeader } from './styles';
import { useState } from 'react';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { NEUTRAL_SHADES } from 'utils/theme';
import { useTranslation } from 'react-i18next';

export const NotificationBanner = () => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(() => {
    const isVisible = loadStateFromLocalStorage('showGroupNotificationBanner');
    return isVisible != null ? isVisible : true;
  });

  const onClick = () => {
    saveStateToLocalStorage('showGroupNotificationBanner', false);
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <Container>
      <TangiSvgIcon component="groupNotification" color="" />

      <RightSideContainer>
        <RightSideHeader>
          <TangiChip text={t('ASSET_PAGE.MODAL.GROUP_NOTIFICATION_BANNER.NEW')} variant="secondary" />
          <TangiTypography type="heading-md" weight="bold" color={NEUTRAL_SHADES.BLACK}>
            {t('ASSET_PAGE.MODAL.GROUP_NOTIFICATION_BANNER.TITLE')}
          </TangiTypography>

          <RemoveBannerBtn onClick={onClick}>
            <TangiSvgIcon component="remove" color={NEUTRAL_SHADES[1100]} />
          </RemoveBannerBtn>
        </RightSideHeader>

        <DescriptionContainer>
          <TangiTypography type="subheading" color={NEUTRAL_SHADES[900]}>
            {t('ASSET_PAGE.MODAL.GROUP_NOTIFICATION_BANNER.DESCRIPTION')}
          </TangiTypography>
        </DescriptionContainer>
      </RightSideContainer>
    </Container>
  );
};
