import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AuthContext } from '_hocs/Cognito/Auth';
import { RootState } from '_helpers';
import { LOCAL_STORAGE_KEYS } from 'utils/token/localStorage';
import { loadStateFromLocalStorage } from 'utils/localStorage';
import cognitoUtil from '_services/utils/cognitoUtil';

const useTokenRefresh = () => {
  const { refreshCognitoToken, refreshOktaToken } = useContext(AuthContext);
  const loggedIn = useSelector((state: RootState) => state.authentication.loggedIn);
  const externalUser = useSelector((state: RootState) => state?.authentication?.user?.externalUser);
  const intervalId = useRef<NodeJS.Timer | null>(null);

  const refreshIntervalMinutes = JSON.parse(process.env.REFRESH_INTERVAL_MINUTES ?? '1');

  useEffect(() => {
    if (typeof Cypress === 'object') return;
    if (!loggedIn) return;

    // When the externalUser variable is set to 'true', this indicates an Okta user.
    if (externalUser) {
      const oktaRefreshToken = _getOktaRefreshToken();
      _initiateTokenRefresh(() => refreshOktaToken(oktaRefreshToken));
    } else {
      const cognitoRefreshToken = _getCognitoRefreshToken();
      _initiateTokenRefresh(() => refreshCognitoToken(cognitoRefreshToken));
    }

    return () => {
      _cleanupFunction();
    };
  }, [loggedIn]);

  const _initiateTokenRefresh = (refreshTokenFunc: () => void) => {
    const isNeedsToRefresh = cognitoUtil.checkTokenExpiration(refreshIntervalMinutes);

    if (isNeedsToRefresh) {
      refreshTokenFunc();
    }

    intervalId.current = setInterval(() => {
      refreshTokenFunc();
    }, 1000 * 60 * refreshIntervalMinutes);
  };

  const _getOktaRefreshToken = () => {
    const oktaUser = loadStateFromLocalStorage(LOCAL_STORAGE_KEYS.OKTA_USER_TOKENS);
    return oktaUser.refresh_token;
  };

  const _getCognitoRefreshToken = () => {
    const refreshToken = loadStateFromLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    return refreshToken;
  };

  const _cleanupFunction = () => {
    if (intervalId?.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  };
};

export default useTokenRefresh;
