import { TangiSvgIcon } from '../TangiSvgIcon/TangiSvgIcon';
import { PRIMARY_SHADES, SECONDARY_SHADES, WARNING_SHADES } from '../../../utils/theme';
import { StyledNotification, NotificationBody, NotificationTitle, RightAnchor } from './style';

export enum NOTIFICATION_VARIANTS {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  MARKETING = 'marketing',
}

export interface Props {
  children: React.ReactNode;
  variant: NOTIFICATION_VARIANTS;
  title?: string;
  centered?: boolean;
  startIcon?: boolean;
  rightAnchor?: React.ReactNode;
  dataTestId?: string;
}

export const TangiNotification = ({ children, variant = NOTIFICATION_VARIANTS.INFO, title = '', centered = false, startIcon = true, rightAnchor, dataTestId = 'tangi-notification' }: Props) => {
  return (
    <StyledNotification variant={variant} centered={centered} data-testid={dataTestId}>
      {startIcon && (
        <div>
          {variant === NOTIFICATION_VARIANTS.INFO && <TangiSvgIcon component="info" color={PRIMARY_SHADES[700]} />}
          {variant === NOTIFICATION_VARIANTS.WARNING && <TangiSvgIcon component="warning" color={WARNING_SHADES[400]} />}
          {variant === NOTIFICATION_VARIANTS.SUCCESS && <TangiSvgIcon component="matchIndication" color="" />}
          {variant === NOTIFICATION_VARIANTS.ERROR && <TangiSvgIcon component="errorIcon" color="" />}
          {variant === NOTIFICATION_VARIANTS.MARKETING && <TangiSvgIcon component="diamond" color={SECONDARY_SHADES[800]} />}
        </div>
      )}

      <NotificationBody>
        {title && <NotificationTitle>{title}</NotificationTitle>}
        {children}
      </NotificationBody>
      {!!rightAnchor && <RightAnchor>{rightAnchor}</RightAnchor>}
    </StyledNotification>
  );
};
