import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { FlexContainer } from 'utils/globalStyles';
import { SPACING, PRIMARY_SHADES } from 'utils/theme';

export const ToastBodyContainer = styled(FlexContainer)`
  width: 390px;
  flex-direction: column;
  gap: ${SPACING[3]};
`;

export const ResultContainer = styled(FlexContainer)`
  align-items: flex-start;
  gap: ${SPACING[2]};
`;

export const StyledSpinner = styled(Spinner)`
  color: ${PRIMARY_SHADES[600]};
  height: 24px;
  width: 24px;
`;

export const RetryContainer = styled.div`
  cursor: pointer;
`;
