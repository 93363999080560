import { parseEnvBoolean } from 'utils/parseEnvBoolean';

export const config = {
  apiUrl: process.env.REACT_APP_apiUrl,
  apiVersion: process.env.REACT_APP_apiVersion,
  name: process.env.REACT_APP_name,
  passwordHashkey: process.env.REACT_APP_LOGIN_PASSWORD_AES_HASH,
  cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  cognitoRedirectUri: process.env.REACT_APP_COGNITO_REDIRECT_URI,
  cognitoUserPoolDomain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
  finderUrl: process.env.REACT_APP_FINDER_URL,
  patentUrl: process.env.REACT_APP_PATENT_URL,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  isShowLanguage: process.env.REACT_APP_IS_SHOW_LANGUAGE,
  mixpanelId: process.env.REACT_APP_MIXPANEL_ID,
  sentryEnv: process.env.REACT_APP_SENTRY_ENV,
  region: process.env.REACT_APP_AWS_REGION,
  isUnderMaintenance: parseEnvBoolean(process.env.REACT_APP_MAINTENANCE),
  sharepointApplicationId: process.env.REACT_APP_SHAREPOINT_APPLICATION_ID,
  sharepointDirectoryId: process.env.REACT_APP_SHAREPOINT_DIRECTORY_ID,
  sharepointClientSecret: process.env.REACT_APP_SHAREPOINT_CLIENT_SECRET,
};
