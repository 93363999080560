import i18next from 'i18next';
import { TableColumn } from 'react-data-table-component';
import { TableHeaderText } from 'utils/globalStyles';
import { AvatarWithName } from './components/AvatarWithName';
import { SUCCESS_SHADES } from 'utils/theme';
import { DateDisplay } from './components/DateDisplay/DateDisplay';
import { DeleteRecipient } from './components/DeleteRecipient';
import { MultipleAcknowledgementTableRowData, RecipientEntity, RecipientEntityRowData, TablePermissions } from './types';
import { useMemo } from 'react';
import { AssetDisplay } from './components/AssetDisplay/AssetDisplay';
import { GroupDisplay } from './components/GroupDisplay';
import { AcknowledgmentDisplay } from './components/AcknowledgmentDisplay/AcknowledgmentDisplay';

export const useColumns = (isSingleAsset: boolean, permissions: TablePermissions) => {
  const { removeRecipient } = permissions;

  const acknowledgementTableColumns: TableColumn<MultipleAcknowledgementTableRowData>[] = useMemo(
    () => [
      {
        name: <TableHeaderText>{i18next.t('ASSET_PAGE.RECIPIENT_TABLE.RECIPIENTS')}</TableHeaderText>,
        cell: ({ name, type, itemsNum }) => <AssetDisplay name={name} type={type} itemsNum={itemsNum} />,
        style: { paddingLeft: '4px', minWidth: '34%', maxWidth: '36%' },
      },
      { name: <TableHeaderText>{i18next.t('ASSET_PAGE.RECIPIENT_TABLE.SENT_DATE')}</TableHeaderText>, width: !removeRecipient ? '30%' : '20%' },
      { name: <TableHeaderText>{i18next.t('ASSET_PAGE.RECIPIENT_TABLE.ACKNOWLEDGEMENT')}</TableHeaderText>, width: !removeRecipient ? '30%' : '22%', center: true },
      { omit: !removeRecipient, name: <TableHeaderText>{i18next.t('ASSET_PAGE.RECIPIENT_TABLE.ACTIONS')}</TableHeaderText>, width: '18%', right: true },
    ],
    [removeRecipient, isSingleAsset],
  );

  const groupsTableColumns: TableColumn<RecipientEntityRowData>[] = useMemo(
    () => [
      {
        ...(isSingleAsset && { name: <TableHeaderText>{i18next.t('ASSET_PAGE.RECIPIENT_TABLE.RECIPIENTS')}</TableHeaderText> }),
        cell: (row) =>
          row.type === 'group' ? (
            <GroupDisplay groupName={row.name} recipientsNum={row.recipientsNum} groupType={row.groupType} groupId={row._id} />
          ) : (
            <AvatarWithName name={row.fullName} email={row.email} isEmpty={row.isEmptyAvatar} />
          ),
        conditionalCellStyles: [
          {
            when: (row) => row.type === 'group',
            style: {
              paddingLeft: '4px',
              minWidth: '34%',
              maxWidth: '36%',
            },
          },
          {
            when: (row) => row.type === 'recipient',
            style: {},
          },
        ],
      },
      {
        ...(isSingleAsset && { name: <TableHeaderText>{i18next.t('ASSET_PAGE.RECIPIENT_TABLE.SENT_DATE')}</TableHeaderText> }),
        cell: ({ sentDate }) => <DateDisplay date={sentDate} />,
        width: !removeRecipient ? '30%' : '20%',
      },
      {
        ...(isSingleAsset && { name: <TableHeaderText>{i18next.t('ASSET_PAGE.RECIPIENT_TABLE.ACKNOWLEDGEMENT')}</TableHeaderText> }),
        cell: (row) => <AcknowledgmentDisplay row={row} />,
        width: !removeRecipient ? '30%' : '22%',
        center: true,
      },
      {
        ...(isSingleAsset && { name: <TableHeaderText>{i18next.t('ASSET_PAGE.RECIPIENT_TABLE.ACTIONS')}</TableHeaderText> }),
        cell: (row) => row.type === RecipientEntity.RECIPIENT && <DeleteRecipient isContributor={row.isContributor} onClickDelete={row.onClickDelete} isAcknowledged={row.acknowledged} />,
        width: '18%',
        right: true,
      },
    ],
    [removeRecipient, isSingleAsset],
  );

  const recipientsTableColumns: TableColumn<RecipientEntityRowData>[] = useMemo(
    () => [
      {
        cell: (row) => row.type === RecipientEntity.RECIPIENT && <AvatarWithName name={row.fullName} email={row.email} isEmpty={row.isEmptyAvatar} />,
        width: '40%',
        style:{paddingLeft:'40px'}
      },
      {
        cell: ({ sentDate }) => <DateDisplay date={sentDate} />,
        width: !removeRecipient ? '30%' : '20%',
      },
      {
        cell: (row) => row.type === RecipientEntity.RECIPIENT && row.acknowledgedOn && <DateDisplay date={row.acknowledgedOn} showChecked={true} color={SUCCESS_SHADES[500]} />,
        width: !removeRecipient ? '30%' : '22%',
        center: true,
      },
      {
        omit: !removeRecipient,
        cell: (row) => row.type === RecipientEntity.RECIPIENT && <DeleteRecipient isContributor={row.isContributor} onClickDelete={row.onClickDelete} isAcknowledged={row.acknowledged} />,
        width: '18%',
        right: true,
      },
    ],
    [isSingleAsset, removeRecipient],
  );

  return { acknowledgementTableColumns, recipientsTableColumns, groupsTableColumns };
};
