import i18next from 'i18next';
import { RESULT_STATUS } from 'utils/enums';
import { ToastProps } from 'utils/types/types';

export const generateOnNotifyToastData = (isSuccess: boolean, numOfUpdatedAssets: number): ToastProps => {
  const text = isSuccess ? i18next.t('ASSET_PAGE.TOOLTIP.EMAILS_SENT', { NUMBER: numOfUpdatedAssets }) : i18next.t('ASSET_PAGE.TOOLTIP.SOMETHING_WENT_WRONG');
  return {
    show: true,
    text,
    type: isSuccess ? RESULT_STATUS.SUCCESS : RESULT_STATUS.ERROR,
  };
};
