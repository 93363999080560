/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import _ from 'lodash';

import { RecipientCategory, SharedRecipient } from 'utils/types/assets/assets';
import { RenderCategoryRecipients } from './types';

export interface PartnershipGroup {
  partnershipId: string;
  partnershipName: string;
  recipients: SharedRecipient[];
}

export const groupPartnersByPartnership = (recipientsPartners: SharedRecipient[]): PartnershipGroup[] => {
  const grouped = _.groupBy(recipientsPartners, (recipient) => recipient?.account?.partnership?._id || '');
  return Object.keys(grouped).map((partnershipId) => {
    const recipients = grouped[partnershipId];
    const partnershipName = recipients[0]?.account?.partnership?.name || '';

    return {
      partnershipId,
      partnershipName,
      recipients,
    };
  });
};

export const filterRecipientsByName = (array: any[], value: string): any[] => {
  return array.filter((elem) => elem?.fullName?.includes(value) || elem.email.includes(value));
};

export const hasNoPartnerships = (recipientsPartners: any[]): boolean => {
  return recipientsPartners.some((recipient) => !recipient?.account?.partnership?._id || !recipient?.account?.partnership?.name);
};

export const filterCategoriesByName = (categories: RecipientCategory[], value: string) => {
  return categories
    .map((category) => ({
      ...category,
      items: filterRecipientsByName(category.items, value),
    }))
    .filter((category) => category.items.length > 0);
};

export const groupAndCombineCategories = (categories: RecipientCategory[], renderCategoryRecipients: RenderCategoryRecipients, headerTranslation: string): React.ReactNode[] => {
  const groupedCategories = _.groupBy(categories, 'categoryId');

  return Object.entries(groupedCategories).map(([categoryId, categoriesGroup]) => {
    const combinedItems = categoriesGroup.flatMap((category) => category.items);
    const categoryName = categoriesGroup[0].categoryName;

    const newCategory: RecipientCategory = {
      categoryId,
      categoryName,
      categoryType: categoriesGroup[0].categoryType,
      items: combinedItems,
    };

    return renderCategoryRecipients(newCategory, headerTranslation);
  });
};
