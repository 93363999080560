import DataTable, { TableColumn } from 'react-data-table-component';
import {
  BuildAllGroupTableRowsInput,
  BuildGroupRowInput,
  BuildRecipientRowInput,
  DeleteRecipientData,
  GroupType,
  MultipleAcknowledgementTableData,
  RecipientEntity,
  RecipientEntityRowData,
} from './types';
import { GroupedRecipientsCategoryData, RecipientCategoryType, SharedRecipient } from 'utils/types/assets/assets';
import { getNoDataDisplay } from 'utils/NoDataTable';
import i18next from 'i18next';
import TangiCollapseTable from '_components/TangiLibrary/TangiCollapseTable/TangiCollapseTable';
import { groupsTableStyle, innerTableBorderBottom } from './styles';
import { getTotalRecipientsCountPerAsset } from '../utils';

export const buildMultipleAcknowledgementTableRows = (
  data: MultipleAcknowledgementTableData[],
  groupsTableColumns: TableColumn<RecipientEntityRowData>[],
  recipientsTableColumns: TableColumn<RecipientEntityRowData>[],
  handleDeleteRecipient: (data: DeleteRecipientData) => void,
) => {
  return data.map(({ id, assetName, assetType, recipients, contributorsIds, partners, departments, businessUnits }) => {
    return {
      _id: id,
      name: assetName,
      type: assetType,
      itemsNum: getTotalRecipientsCountPerAsset({ recipients, partners, departments, businessUnits }),
      collapseContent: (
        <TangiCollapseTable
          customStyles={groupsTableStyle}
          isNoHeader={true}
          tableColumns={groupsTableColumns}
          nonExpandableRowClasses={['hide-expender']}
          tableData={buildGroupTableRows({ id, departments, partners, businessUnits, recipients, recipientsTableColumns, contributorsIds, handleDeleteRecipient })}
        />
      ),
    };
  });
};

export const buildGroupTableRows = ({
  id,
  departments,
  partners,
  businessUnits,
  recipients,
  recipientsTableColumns,
  contributorsIds,
  handleDeleteRecipient,
}: BuildAllGroupTableRowsInput): RecipientEntityRowData[] => [
  ...buildGroupRows(id, departments, recipientsTableColumns, contributorsIds, handleDeleteRecipient, RecipientCategoryType.DEPARTMENT),
  ...buildGroupRows(id, partners, recipientsTableColumns, contributorsIds, handleDeleteRecipient, RecipientCategoryType.PARTNER),
  ...buildGroupRows(id, businessUnits, recipientsTableColumns, contributorsIds, handleDeleteRecipient, RecipientCategoryType.BUSINESS_UNIT),
  ...buildRecipientsTableRows(id, recipients, contributorsIds, handleDeleteRecipient),
];

const buildGroupRows = (
  assetId: string,
  groups: GroupedRecipientsCategoryData[],
  recipientsTableColumns: TableColumn<RecipientEntityRowData>[],
  contributorsIds: string[],
  handleDeleteRecipient: (data: DeleteRecipientData) => void,
  groupType: GroupType,
): RecipientEntityRowData[] =>
  groups.map(({ categoryId: _id, categoryName: name, items: recipients }) =>
    buildGroupRow({ _id, assetId, name, recipients, contributorsIds, handleDeleteRecipient, recipientsTableColumns, groupType }),
  );

const buildGroupRow = ({ _id, assetId, name, recipients, contributorsIds, handleDeleteRecipient, recipientsTableColumns, groupType }: BuildGroupRowInput): RecipientEntityRowData => ({
  _id,
  name,
  type: RecipientEntity.GROUP,
  groupType,
  acknowledgedNum: recipients.filter(({ acknowledged }) => acknowledged).length,
  recipientsNum: recipients.length,
  sentDate: recipients[0].sharedOn,
  collapseContent: (
    <DataTable
      columns={recipientsTableColumns}
      data={buildRecipientsTableRows(assetId, recipients, contributorsIds, handleDeleteRecipient)}
      noDataComponent={getNoDataDisplay(i18next.t('ASSET_PAGE.CONTENT.NO_RECIPIENTS_YET'))}
      noTableHead
      customStyles={innerTableBorderBottom}
    />
  ),
});

export const buildRecipientsTableRows = (
  assetId: string,
  recipients: SharedRecipient[],
  contributorsIds: string[],
  handleDeleteRecipient: (data: DeleteRecipientData) => void,
): RecipientEntityRowData[] =>
  recipients.map(({ _id, fullName, email, account, sharedOn: sentDate, acknowledgedOn, acknowledged }) =>
    buildRecipientRow({ _id, assetId, fullName, email, sentDate, acknowledgedOn, contributorsIds, account, acknowledged, handleDeleteRecipient }),
  );

const buildRecipientRow = ({
  _id,
  assetId,
  fullName,
  email,
  sentDate,
  acknowledgedOn,
  contributorsIds,
  account,
  acknowledged,
  handleDeleteRecipient,
}: BuildRecipientRowInput): RecipientEntityRowData => ({
  type: RecipientEntity.RECIPIENT,
  _id,
  fullName,
  email,
  sentDate,
  acknowledgedOn: acknowledgedOn ?? '',
  isContributor: contributorsIds.some((id) => id === account?._id),
  isEmptyAvatar: !account,
  acknowledged,
  onClickDelete: () => handleDeleteRecipient({ recipientId: _id, assetId }),
});
