import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { createTableColumn, makeTableRow } from './utils';
import TableSkeleton from '../TableSkeleton/TableSkeleton';
import { AssetTableContainer, customTableStyles, conditionalRowStyles } from './style';

export const PreviewAssetsTable = ({ assets, handleDistributeAsset, loading, assetOptions, handleUnlockAsset, isAdmin, pagination, updateSelectedAssets, onRowClicked }) => {
  const Role = useSelector((state) => state.authentication.Role);
  const permissions = useSelector((state) => state.authentication.permissions);
  const selectedAssets = useSelector((state) => state.asset.selectedAssets);

  const dispatch = useDispatch();

  const tableData = assets?.data?.map((value) =>
    makeTableRow({
      value,
      handleDistributeAsset,
      handleUnlockAsset,
      role: Role,
      options: assetOptions,
      permissions,
      isAdmin,
      handleRowClick: onRowClicked,
      selectedAssets,
      updateSelectedAssets,
      dispatch,
    }),
  );

  const clientTableViewColumn = createTableColumn();

  const handleChangePage = (newPage) => {
    if (newPage !== pagination.page) {
      dispatch(assetsActions.setPagination({ ...pagination, page: newPage }));
    }
  };

  return (
    <AssetTableContainer className="overflow-fix">
      <DataTable
        columns={clientTableViewColumn}
        customStyles={customTableStyles}
        sortIcon={<i className="" />}
        data={tableData}
        progressPending={loading}
        progressComponent={<TableSkeleton />}
        highlightOnHover
        onRowClicked={onRowClicked}
        conditionalRowStyles={conditionalRowStyles}
        fixedHeader={true}
        pagination
        paginationServer
        paginationTotalRows={assets?.metadata[0]?.total || 0}
        paginationPerPage={15}
        paginationDefaultPage={pagination.page || assets?.metadata[0]?.page || 1}
        paginationComponentOptions={{ noRowsPerPage: true }}
        onChangePage={handleChangePage}
      />
    </AssetTableContainer>
  );
};
