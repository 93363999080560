import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

import { TangiTypography } from '_components/TangiLibrary';
import { FlexContainerGap1 } from '_components/PartnersSideMenuDrawer/style';

const fieldMapping = {
  startdate: 'dateOfJoining',
  name: 'displayName',
  accountStatus: 'status',
};

export const mapFieldNameToServer = (fieldName) => fieldMapping[fieldName] || fieldName;

const mapFieldNameToClient = (fieldName) => {
  const invertedMapping = Object.fromEntries(Object.entries(fieldMapping).map(([key, value]) => [value, key]));
  return invertedMapping[fieldName] || fieldName;
};

function getSortIcon(activeSelectorRole, field) {
  const selector = mapFieldNameToClient(field);
  return selector === field && activeSelectorRole.direction === 'asc' ? faSortUp : faSortDown;
}

export const getFormerEmpColumns = (activeSelectorRole = false) => {
  return [
    {
      name: (
        <>
          <FlexContainerGap1>
            <TangiTypography weight="bold" color="inherit">
              {i18next.t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.NAME')}
            </TangiTypography>
            <FontAwesomeIcon className="fs-6" icon={getSortIcon(activeSelectorRole, 'name')} data-testid="name-col" />
          </FlexContainerGap1>
        </>
      ),
      selector: ({ name }) => name,
      sortable: true,
      fieldName: 'name',
    },
    {
      name: (
        <FlexContainerGap1>
          <TangiTypography weight="bold" color="inherit">
            {i18next.t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.STATUS')}
          </TangiTypography>
          <FontAwesomeIcon className="fs-6" icon={getSortIcon(activeSelectorRole, 'accountStatus')} data-testid="status-col" />
        </FlexContainerGap1>
      ),
      selector: ({ accountStatus }) => accountStatus,
      fieldName: 'accountStatus',
      sortable: true,
    },
    {
      name: (
        <FlexContainerGap1>
          <FlexContainerGap1 style={{ flexDirection: 'column', gap: 0 }}>
            <TangiTypography weight="bold" color="inherit">
              {i18next.t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.EXIT_DATE')}
            </TangiTypography>
          </FlexContainerGap1>
          <FontAwesomeIcon className="fs-6" icon={getSortIcon(activeSelectorRole, 'exitdate')} data-testid="date-col"/>
        </FlexContainerGap1>
      ),
      selector: ({ exitdate }) => exitdate,
      sortable: true,
      fieldName: 'exitdate',
    },
  ];
};
