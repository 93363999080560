import React from 'react';
import { Link } from 'react-router-dom';
import { groupPartnersByPartnership } from '../utils';
import { FlexRow, PartnershipContainer, RecipientsDivider } from '../style';
import { IRoles } from 'utils/roles';
import { TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES } from 'utils/theme';
import { SharedRecipient } from 'utils/types/assets/assets';

interface Props {
  recipients: SharedRecipient[];
  activeAccount: {
    client?: {
      _id?: string;
    };
  };
  Role: string;
  renderRecipientRow: (recipient: SharedRecipient) => React.ReactNode;
  partnershipLinkStyles?: string;
}

export const PartnersList = ({ recipients, activeAccount, Role, renderRecipientRow, partnershipLinkStyles }: Props): JSX.Element => {
  const groupedPartners = groupPartnersByPartnership(recipients);

  return (
    <>
      {groupedPartners.map((partnership, partnershipIndex) => (
        <React.Fragment key={partnership.partnershipId}>
          <PartnershipContainer data-testid="partnership-container">
            {activeAccount?.client?._id && !(Role === IRoles.LAWYER || Role === IRoles.LAWYER_ADMIN) ? (
              <Link
                to={{
                  pathname: `/client/${activeAccount.client._id}/partners/${partnership.partnershipId}`,
                }}
                data-testid="partnership-link"
              >
                <FlexRow>
                  <TangiTypography type="heading-md" weight="semibold" customStyles={partnershipLinkStyles}>
                    {partnership?.partnershipName}
                  </TangiTypography>
                  <TangiTypography type="heading-md" weight="semibold" color={NEUTRAL_SHADES[800]}>
                    • {partnership.recipients.length}
                  </TangiTypography>
                </FlexRow>
              </Link>
            ) : (
              <FlexRow>
                <TangiTypography type="heading-md" weight="semibold">
                  {partnership?.partnershipName}
                </TangiTypography>
                <TangiTypography type="heading-md" weight="semibold" color={NEUTRAL_SHADES[800]}>
                  • {partnership.recipients.length}
                </TangiTypography>
              </FlexRow>
            )}
            {partnership.recipients.map((recipient) => renderRecipientRow(recipient))}
          </PartnershipContainer>
          {partnershipIndex < groupedPartners.length - 1 && <RecipientsDivider />}
        </React.Fragment>
      ))}
    </>
  );
};
