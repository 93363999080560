import { useEffect } from 'react';
import { TangiIconButton, TangiTypography } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '_helpers';
import { competitiveAnalysisActions } from 'redux-toolkit/slices/competitiveAnalysisSlice';
import { TYPOGRAPHY_TYPES } from '_components/TangiLibrary/TangiTypography/style';
import { ExportButton } from 'pages/TradeSecretsAnalysis/styles';
import { RowContainer } from 'pages/TradeSecretsAnalysis/components/Widget/style';
import PortfolioFilters from 'pages/TradeSecretsAnalysis/components/PortfolioFilters/PortfolioFilters';
import { CompetitiveDataTable } from 'pages/TradeSecretsAnalysis/components/CompetitiveDataTable/CompetitiveDataTable';
import { FilterConfigType } from 'pages/TradeSecretsAnalysis/types';
import CompetitiveInfo from 'pages/TradeSecretsAnalysis/components/CompetitiveInfo/CompetitiveInfo';
import { CompetitiveModalData, PortfolioModalFiltersConfig } from '../../PredictedPortfolio/PredictedProfolioModal/types';
import { useCompetitiveAnalysisModal } from './useCompetitiveAnalysisModal';
import { Filters } from 'utils/types/predictedPortfolio/predictedPortfolio';
import { Pagination } from 'utils/types/types';
import { BUTTON_VARIANTS, NEUTRAL_SHADES } from 'utils/theme';
import { StyledModal } from 'utils/globalStyles';
import { Container, ModalContent, StyledDivider, StyledHeader, TotalResults } from './style';

interface Props {
  isShow: boolean;
  onClose: () => void;
  data: CompetitiveModalData | undefined;
  filterConfig?: PortfolioModalFiltersConfig;
  filtersOptions: Filters;
  initialFilter: Record<string, [{ id: string; value: string }]> | undefined;
}

const CompetitiveModal = ({
  isShow,
  data,
  onClose,
  filtersOptions,
  filterConfig = {
    enabledFilters: [FilterConfigType.Subjects, FilterConfigType.Patents, FilterConfigType.Years, FilterConfigType.Companies],
  },
  initialFilter,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { searchValue, filter, isFilterApplied, setFilter, setSearchValue, handleClearSearch, handleSearch, handleExportTable, resetModalState, setFilterByNodeSubject } = useCompetitiveAnalysisModal({
    isModalOpen: isShow,
    initialFilter: data,
  });

  const modalTableData = useSelector((state: RootState) => state.competitiveAnalysis.modalTable);
  const isModalTableExportLoading = useSelector((state: RootState) => state.competitiveAnalysis.loading.exportModalTable);
  const isModalTableLoading = useSelector((state: RootState) => state.competitiveAnalysis.loading.modalTable);
  const modalPagination = useSelector((state: RootState) => state.competitiveAnalysis.modalPagination);
  const error = useSelector((state: RootState) => state.predictedPortfolio.error);
  const totalItems = modalTableData?.metadata[0]?.total;
  const hasNoModalTableData = isModalTableLoading || !modalTableData?.data?.length || error;

  useEffect(() => {
    if (isShow) {
      setFilterByNodeSubject(initialFilter);
    }
  }, [isShow]);

  const renderModalHeader = () => {
    return (
      <div>
        <StyledHeader>
          <TangiTypography type="heading-lg">{t('PREDICTED_PORTFOLIO.PORTFOLIO_TABLE.TITLE')}</TangiTypography>
          <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={onHide} icon="close" />
        </StyledHeader>
      </div>
    );
  };

  const renderModalTable = () => {
    if (data) {
      return (
        <ModalContent>
          <CompetitiveDataTable
            tradeSecrets={modalTableData}
            pagination={modalPagination}
            loading={{ table: isModalTableLoading }}
            setPagination={(newPagination: Pagination) => dispatch(competitiveAnalysisActions.setModalPagination(newPagination))}
          />
        </ModalContent>
      );
    }
  };

  const renderModalSubjectInfo = () => {
    return <CompetitiveInfo subject={data?.subject || ''} potentialTradeSecretsCount={data?.potentialTradeSecretsCount || 0} squareColor={data?.squareColor || ''} company={data?.companies || ''} />;
  };

  const renderModalTotalItems = () => {
    return (
      !isModalTableLoading && (
        <TotalResults>
          <TangiTypography color={NEUTRAL_SHADES[800]} weight="regular" type={TYPOGRAPHY_TYPES.SUBHEADING}>
            {t('PREDICTED_PORTFOLIO.PORTFOLIO_TABLE.TOTAL_ITEMS', {
              NUMBER: totalItems,
            })}
          </TangiTypography>
        </TotalResults>
      )
    );
  };

  const renderModalBody = () => {
    return (
      <Container>
        {renderModalSubjectInfo()}
        {renderModalFilters()}
        {totalItems > 0 && renderModalTotalItems()}
        {renderModalTable()}
      </Container>
    );
  };

  const renderModalFilters = () => {
    return (
      <RowContainer>
        <PortfolioFilters
          filtersOptions={filtersOptions}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filter={filter}
          setFilter={setFilter}
          initialFilter={initialFilter}
          isFilterApplied={isFilterApplied}
          onClearAll={handleClearSearch}
          handleSearch={handleSearch}
          isTableLoading={isModalTableLoading}
          filterConfig={filterConfig}
        />
        <ExportButton
          variant="secondary"
          text={t('PREDICTED_PORTFOLIO.PORTFOLIO_TABLE.EXPORT_DATA')}
          onClick={handleExportTable}
          svgIcon="download"
          disabled={isModalTableExportLoading || hasNoModalTableData}
          loading={isModalTableExportLoading}
          smallbtn
        />
      </RowContainer>
    );
  };

  const onHide = () => {
    resetModalState();
    onClose();
  };

  return (
    <StyledModal transition="Fade" show={isShow} onHide={onHide} data-testid="predicted-modal">
      {renderModalHeader()}
      <StyledDivider />
      {renderModalBody()}
    </StyledModal>
  );
};

export default CompetitiveModal;
