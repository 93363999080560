/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { Filter } from '_services/utils/getFilterObj';
import { Metadata } from '../types';
import { XRAY_JOB_STATUS } from '../xRay/xRay';
import { Account } from '../account/account';
import { IdAndName } from '../types';
import { User } from '../users/users';
import { JOB_STATUS } from 'utils/enums';

export interface IshareContributorsStatus {
  status: string;
  message: string;
}

export interface Assets {
  metadata: Metadata[];
  data: Asset[];
}

export interface File {
  _id: string;
  name: string;
  fileName?: string;
  fileId: string;
  fileType: string;
  uploadedOn: string;
  trackerId?: string;
  uniqueFileKey: string;
}

export interface URL {
  _id: string;
  label: string;
  url: string;
  uploadedOn: string;
}

export interface SharedRecipientAccount extends Pick<Account, '_id' | 'status' | 'createdAt'> {
  displayName: string;
  role: IdAndName;
  partnership?: IdAndName;
  isActive: boolean;
  createdFrom: string;
}

export interface SharedRecipient extends Pick<Recipient, '_id' | 'username' | 'email' | 'fullName' | 'acknowledgedOn' | 'acknowledged' | 'sharedOn'> {
  account: SharedRecipientAccount | null;
}

export interface RecipientCategory {
  categoryId: string;
  categoryName: string;
  categoryType: string;
  items: SharedRecipient[];
}

export interface RecipientAssetInfo {
  _id: string;
  name: string;
}

export interface RecipientGroup {
  categories: RecipientCategory[];
  asset: RecipientAssetInfo;
  recipientsAdmins: SharedRecipient[];
  recipientsContractors: SharedRecipient[];
  recipientsContractorswithoutlearn: SharedRecipient[];
  recipientsEmployees: SharedRecipient[];
  recipientsEmployeeswithoutlearn: SharedRecipient[];
  recipientsLawyers: SharedRecipient[];
  recipientsLearncontractors: SharedRecipient[];
  recipientsLearnemployees: SharedRecipient[];
  recipientsNoAccounts: SharedRecipient[];
  recipientsPartners: SharedRecipient[];
  recipientsSpecialadmins: SharedRecipient[];
}

export interface GetRecipientsResponse {
  recipients: RecipientGroup[];
}

export interface Asset {
  id: string;
  name: string;
  description: string;
  tags: any[];
  projects?: any[];
  departments?: any[];
  businessValues: any[];
  contributor: {
    _id: string;
    displayName: string;
    user: User;
    //and other fields
  }[];
  referenceId: any;
  createdFrom: string;
  updatedFrom: string;
  isActive: boolean;
  refNumber: string;
  others1: string;
  others2: string;
  assetType: any;
  urls: URL[];
  client: any;
  createdBy: any;
  updatedBy: any;
  files: File[];
  createdAt: string;
  updatedAt: string;
  recipients?: SharedRecipient[];
  acknowledgedAccountsLists?: SharedRecipient[];
  notAcknowledgedAccountsLists?: SharedRecipient[];
  needsToBeAcknowledgedByMe?: boolean;
  recipientsContractors?: SharedRecipient[];
  recipientsEmployees?: SharedRecipient[];
  recipientsLawyers?: SharedRecipient[];
  recipientsPartners?: SharedRecipient[];
  recipientsNoAccounts?: SharedRecipient[];
  numAccountsCreatedFromFinder?: number;
  numAccountsCreatedFromTracker?: number;
  assetMetaData: AssetMetaDataValue[];
  isPublished?: boolean;
  importance?: Importance;
  isThirdParty?: boolean;
  hasAccessToThirdPartyAsset?: boolean;
  sharePoint?: SharePoint;
}

export enum SharePointStatusEnums {
  MOVED = 'moved',
  ACTIVE = 'active',
  DELETED = 'deleted',
  NOT_TRACKED = 'not_tracked',
}

export type SharePointStatus = `${SharePointStatusEnums}`;

export interface SharePoint {
  id?: string;
  siteName: string;
  path: string;
  fileLink?: string;
  status: SharePointStatus;
}

export interface Recipient {
  _id: string;
  asset: string;
  account: string | null;
  username: string;
  email: string;
  fullName: string;
  sharedBy: string;
  acknowledgedOn: string | null;
  acknowledged: boolean;
  registered: boolean;
  createdFrom: string;
  updatedFrom: string;
  isActive: boolean;
  isPartner: boolean;
  deletedBy: string | null;
  sharedOn: string;
  createdAt: string;
  updatedAt: string;
}

export interface DeleteRecipientParams {
  assetId: string;
  recipientId: string;
}

export interface AcknowledgePostAssetParams {
  code: string;
  token: string;
}

export interface AcknowledgePreviewAssetPOSTParams {
  token: string;
  otp: string;
  params?: GetAssets;
}

export interface GetAllAssetsClientParams {
  limit?: number;
  clientId: string;
  accountId: string;
}

export interface GetAssets {
  sortValue?: string;
  searchValue?: string;
  filter?: Filter;
  page: number;
  limit: number;
}

export interface GetPrivilegeLogData extends Omit<GetAssets, 'page' | 'limit'> {
  isFilterApplied: boolean;
}

export interface PrivilegeLogRecipient {
  _id: string;
  account?: {
    _id: string;
    displayName: string;
  };
  email: string;
  acknowledgedOn: string;
}

export interface PrivilegeLogAsset {
  _id: string;
  name: string;
  refNumber: string;
  assetType: {
    _id: string;
    name: string;
  };
  recipients: PrivilegeLogRecipient[];
}

export interface AssetMetaDataValue {
  type: ASSET_METADATA_VALUE;
  value: string;
  clientID: string;
  createdAt: string;
  updatedAt: string;
  _id: string;
  newRecords?: string;
}

export interface ImportanceFieldValue {
  value: string;
  score: string;
}

export interface GetAssetAuditLog {
  assetId: string;
  page?: number;
  limit: number;
}

export type Importance = {
  value: string;
  score: string;
};

export enum ASSET_METADATA_VALUE {
  BUSINESS_RELATED = 'businessRelated',
  COMMERCIAL_PRODUCT = 'commercialProduct',
}

export type RecipientFilters = {
  id: string;
  name: string | null;
};

export type RecipientsFiltersArray = RecipientFilters[];

export interface AllAssetsFilesResponse {
  jobStatus: XRAY_JOB_STATUS;
  jobId?: string;
  downloadUrl?: string;
}

export interface ShareMultipleAssetsRequestBody {
  assetsIds: string[];
  recipients: ShareAssetRecipientData[];
  departments: string[]; // ids
  businessUnits: string[]; // ids
  partnerships: string[]; //ids
}

export interface ShareMultipleAssetsData extends ShareMultipleAssetsRequestBody {
  isUpdateAsset?: boolean;
  clientId: string;
}

export interface ShareAssetRecipientData {
  id: string;
  email: string;
}

export interface GetAssetsGroupedRecipientsBody {
  assetsIds: string[];
}

export interface ShareMultipleAssetsRes {
  assets: Asset[];
  isUpdateAsset?: boolean;
}

export interface GetAssetsGroupedRecipientsResponse {
  recipients: {
    categories: GroupedRecipientsCategoryData[];
    asset: GroupedRecipientsAssetData;
  }[];
}

export interface GroupedRecipientsCategoryData {
  categoryId: string;
  categoryName: string;
  categoryType: RecipientCategoryType;
  items: SharedRecipient[];
}

interface GroupedRecipientsAssetData {
  _id: string;
  name: string;
}

export enum RecipientCategoryType {
  PARTNER = 'partner',
  DEPARTMENT = 'department',
  BUSINESS_UNIT = 'businessUnit',
  INDIVIDUAL = 'individual',
}

export type AssetGroupedRecipientsMap = Record<string, Record<RecipientCategoryType, GroupedRecipientsCategoryData[]>>;

// ------------------- Preview action types (get/delete from fs) -------------------
export interface FilePreviewRequest extends Pick<File, 'uniqueFileKey'> {
  assetId: string;
}

export interface GetFilePathPayload {
  data: FilePreviewRequest;
  token: string;
  isPreview: boolean;
}

export interface DeleteDecryptedFileFromRequest {
  fileKeyWithExtension: string;
  assetId: string;
}

export interface DeleteDecryptedFileFromFsPayload {
  data: DeleteDecryptedFileFromRequest;
  token: string;
  isAuthenticated?: boolean;
}

export interface ShareMultipleAssetsResponse {
  status: JOB_STATUS;
  jobId: string;
}

export interface CheckShareStatusResponse extends ShareMultipleAssetsRes {
  status: JOB_STATUS;
}

export interface CheckShareStatusData {
  jobId: string;
  clientId: string;
  isUpdateAsset?: boolean;
}
