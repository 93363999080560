import styled from 'styled-components';

import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from '../../utils/theme';
import { FlexContainer } from 'utils/globalStyles';

export const PreviewAssetsContainer = styled(FlexContainer)`
  padding: ${SPACING[6]};
  display: flex;
  justify-content: center;
`;

export const PageHeaderContainer = styled.div`
  width: 93%;
  margin: 0 auto;
`;

export const SideMenu = styled.div`
  width: 300px;
  padding-inline: 16px;
  height: fit-content;
  margin-left: 20px;
  margin-top: 140px;
`;
