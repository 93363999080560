import { ACCOUNT_ACTIONS } from 'utils/enums';

export const getActionTextKeys = (action: ACCOUNT_ACTIONS) => {
  let headerTextKey, noteTextKey, bodyTextKey, reversalNoteTextKey, reversalTextKey, multipleStatusKey, actionButtonTextKey;

  if (action === ACCOUNT_ACTIONS.ACTIVATE) {
    headerTextKey = 'PEOPLE.MULTISELECT.TITLE.ACTIVATE_USERS';
    noteTextKey = 'PEOPLE.MULTISELECT.MODAL_ACTIVATE_BODY.ACTIVATE_NOTE';
    bodyTextKey = 'PEOPLE.MULTISELECT.MODAL_ACTIVATE_BODY.ACTIVATE_TEXT';
    reversalNoteTextKey = 'PEOPLE.MULTISELECT.MODAL_ACTIVATE_BODY.REVERSAL_NOTE';
    reversalTextKey = 'PEOPLE.MULTISELECT.MODAL_ACTIVATE_BODY.REVERSAL_TEXT';
    multipleStatusKey = 'PEOPLE.MULTISELECT.MODAL_ACTIVATE_BODY.MULTIPLE_STATUS';
    actionButtonTextKey = 'GENERAL.BUTTONS_TEXT.ACTIVATE';
  } else {
    headerTextKey = 'PEOPLE.MULTISELECT.TITLE.DEACTIVATE_USERS';
    noteTextKey = 'PEOPLE.MULTISELECT.MODAL_DEACTIVATE_BODY.DEACTIVATE_NOTE';
    bodyTextKey = 'PEOPLE.MULTISELECT.MODAL_DEACTIVATE_BODY.DEACTIVATE_TEXT';
    reversalNoteTextKey = 'PEOPLE.MULTISELECT.MODAL_DEACTIVATE_BODY.REVERSAL_NOTE';
    reversalTextKey = 'PEOPLE.MULTISELECT.MODAL_DEACTIVATE_BODY.REVERSAL_TEXT';
    multipleStatusKey = 'PEOPLE.MULTISELECT.MODAL_DEACTIVATE_BODY.MULTIPLE_STATUS';
    actionButtonTextKey = 'GENERAL.BUTTONS_TEXT.DEACTIVATE';
  }

  return {
    headerTextKey,
    noteTextKey,
    bodyTextKey,
    reversalNoteTextKey,
    reversalTextKey,
    multipleStatusKey,
    actionButtonTextKey,
  };
};
