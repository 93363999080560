import styled, { keyframes } from 'styled-components';

import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

const fadeInAndRise = keyframes`
  from {
    transform: translateY(20px) translateX(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0) translateX(-50%);
    opacity: 1;
  }
`;
const moveLeft = keyframes`
  from {
    transform: translateY(0) translateX(-50%);
  }
  to {
    transform: translateY(0) translateX(-50%);
  }
`;

export const ToolbarContainer = styled.div`
  position: fixed;
  bottom: ${SPACING[3]};
  left: 50%;
  transform: translateX(-50%);
  background-color: ${NEUTRAL_SHADES.WHITE};
  box-shadow: 0px ${SPACING[1]} 10px rgba(0, 0, 0, 0.1);
  border-radius: ${SPACING[1]};
  padding: ${SPACING[2]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  animation: ${fadeInAndRise} 0.3s ease-in-out, ${moveLeft} 0.3s ease-in-out 0.3s;

  min-width: fit-content;
  max-width: 90%;
  white-space: nowrap;

  @media (max-width: 768px) {
    padding: ${SPACING[2]} ${SPACING[3]};
  }
`;
