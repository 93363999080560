import Tippy from '@tippyjs/react';
import { TangiIconButton } from '_components/TangiLibrary/TangiIconButton';
import i18next from 'i18next';
import { BUTTON_VARIANTS } from 'utils/theme';

interface Props {
  isAcknowledged: boolean;
  isContributor: boolean;
  onClickDelete: () => void;
}
export const DeleteRecipient = ({ isContributor, isAcknowledged, onClickDelete }: Props) => {
  return (
    <>
      {isContributor ? (
        <Tippy
          content={
            <div>
              <div>{i18next.t('ASSET_PAGE.TOOLTIP.CONTRIBUTORS_CAN_BE_DELETED')}</div>
              <div>{i18next.t('ASSET_PAGE.TOOLTIP.ONLY_IN_EDIT_ASSET_POPUP')}</div>
            </div>
          }
          placement="top-end"
        >
          <span style={{ cursor: 'not-allowed' }}>
            <TangiIconButton icon="delete" variant={BUTTON_VARIANTS.TERTIARY_GREY} disabled={true} />
          </span>
        </Tippy>
      ) : (
        !isAcknowledged && <TangiIconButton icon="delete" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={onClickDelete} />
      )}
    </>
  );
};
