import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { requestPreviewOTP, deleteAssetFilePreview, getFilePathUrl } from 'redux-toolkit/thunks/assetsThunks';
import { TangiIconButton, TangiButton, TangiTypography } from '../TangiLibrary';
import { Loader } from '../Loader';
import AssetOverview from '../AssetOverview/AssetOverview';
import AssetTypeColorBlock from '../../_components/AssetTypeColorBlock/AssetTypeColorBlock';
import { InvalidError } from '../InvalidError/InvalidError';
import { OtpScreenPreviewForm } from '../OtpScreenForm';
import { IRoles, isRoleMatch, regularRoles } from '../../utils/roles';
import { assetTypeNameFormatter } from '../../utils/assetTypes';
import { getMimeTypeKey } from '../../utils/getMimeTypeKey';
import { MAX_PREVIEW_IN_EMBED_FILE_SIZE } from '_constants/fileConstants';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { BOOK_A_DEMO_URL } from '_constants/consts';
import { Container, AssetContainer, SideMenu, TypeAndColor, WatermarkContainer, PageHeader } from './style';
import { BackToContainer } from '../../utils/globalStyles';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';

function PreviewAsset({ clientId }) {
  const history = useHistory();
  const { t } = useTranslation();
  const PreviewURL = process.env.REACT_APP_previewURL;
  const urlString = window.location.href;
  const url = new URL(urlString);
  const encodeToken = url.searchParams.get('token');
  const from = url.searchParams.get('from');
  const dispatch = useDispatch();

  const [isSpinner, setSpinner] = useState(false);
  const [OTPUI, setOTPUI] = useState(false);
  const [previeweData, setPreviewData] = useState(null);
  const { filePath, previewPostAsset, error } = useSelector((state) => state.asset);
  const { loggedIn, user, activeAccount } = useSelector((state) => state.authentication);
  const [loading, setLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [showFile, setFileShow] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (filePath && filePath.file) {
      setFileUrl(`${PreviewURL + filePath.file}`);
      reload();
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [filePath]);

  useEffect(() => {
    if (previewPostAsset.assets.data.length === 0) {
      setOTPUI(true);
      dispatch(assetsActions.clearPreviewToken());
      dispatch(assetsActions.clearAssetsForAcknowledgement());
      dispatch(requestPreviewOTP(encodeToken));
    }
  }, [dispatch]);

  useEffect(() => {
    if (previewPostAsset?.assets?.data?.length > 0) {
      setOTPUI(false);
      setPreviewData(previewPostAsset.assets.data[0]);
      setSpinner(false);
    } else if (previewPostAsset?.message) {
      setSpinner(false);
    } else {
      setTimeout(() => {
        setSpinner(false);
      }, 7000);
    }
  }, [previewPostAsset]);

  const handleOnClick = (assetFile) => {
    dispatch(assetsActions.setClearFilePathUrl());

    if (assetFile?.uniqueFileKey) {
      const data = {
        uniqueFileKey: assetFile.uniqueFileKey,
        assetId: previewPostAsset.assets.data[0].id,
      };

      dispatch(getFilePathUrl({ data, token: encodeToken, isPreview: true }));
      setFileName(name);
      handleFileShow();
    }
  };

  const handleFileClose = () => {
    if (filePath?.uniqueFileKey) {
      const fileKeyWithExtension = `${filePath.uniqueFileKey}.${getMimeTypeKey(filePath.mimeType)}`;

      const data = {
        fileKeyWithExtension,
        assetId: previewPostAsset.assets.data[0].id,
      };

      dispatch(deleteAssetFilePreview({ data, token: encodeToken }));
    }
    setFileShow(false);
    setLoading(true);
  };

  const handleFileShow = () => setFileShow(true);
  function reload() {
    setIndex((i) => i + 1);
  }

  function renderPreviewModalBody() {
    const { fileSize, mimeType, base64 } = filePath;
    const binaryData = base64 ? atob(base64) : null;

    const clientName = previewPostAsset?.assets?.data[0]?.createdBy?.partnership?.name || previewPostAsset?.assets?.data[0]?.client?.name || '';

    if (binaryData && (mimeType.includes('pdf') || mimeType.includes('image')) && fileSize <= MAX_PREVIEW_IN_EMBED_FILE_SIZE) {
      return (
        <WatermarkContainer clientName={clientName}>
          <embed title={fileName} src={`data:${mimeType};base64,${base64}#toolbar=0&navpanes=0&scrollbar=0`} width="100%" height="500" className="text-center" />
        </WatermarkContainer>
      );
    } else {
      return (
        <WatermarkContainer clientName={clientName}>
          <iframe key={index} title={fileName} src={`https://docs.google.com/viewer?url=${fileUrl}&embedded=true`} width="100%" height="500" className="text-center" />
        </WatermarkContainer>
      );
    }
  }

  const isRegularAdmin = (activeAccount) => {
    return (
      [IRoles.CONTRACTOR_ADMIN, IRoles.EMPLOYEE_ADMIN, IRoles.EMPLOYEE_MANAGER].includes(activeAccount?.role?.name) ||
      (isRoleMatch(activeAccount?.role?.name, IRoles.CLIENT_ADMIN) && activeAccount?.client)
    );
  };

  const isNormalUser = (activeAccount) => {
    return regularRoles.includes(activeAccount?.role?.name) && activeAccount?.client?._id;
  };

  const userRedirection = () => {
    if (isRegularAdmin(activeAccount) || isNormalUser(activeAccount)) {
      history.push(generateRoute(AppRoutes.HOME_LOBBY, { clientId: activeAccount?.client?._id }));
      window.location.reload();
    } else {
      history.push(AppRoutes.MAIN);
      window.location.reload();
    }
  };

  const goBackToAssets = () => {
    const fullAssetsPayload = history.location.state?.fullAssetsPayload;

    if (fullAssetsPayload) {
      dispatch(assetsActions.setPreviewPostAsset(fullAssetsPayload));
    }

    history.push(generateRoute(AppRoutes.ASSETS_PREVIEW, { clientId }, { token: encodeToken }));
  };

  return (
    <div className="container-fluid">
      <Loader isLoading={isSpinner} />
      {error ? (
        <InvalidError type="acknowledge" />
      ) : (
        <div>
          {OTPUI ? (
            <OtpScreenPreviewForm encodeToken={encodeToken} />
          ) : (
            <>
              {from === 'previewAssets' && (
                <PageHeader>
                  <BackToContainer>
                    <TangiIconButton icon="arrowLeft" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={goBackToAssets} />
                    <span>{t('ASSET_PAGE.BUTTONS_TEXT.BACK_TO_ALL_ASSETS')}</span>
                  </BackToContainer>
                </PageHeader>
              )}
              <TangiTypography type="heading-xl">{previeweData?.name}</TangiTypography>
              {previeweData?.assetType?.name && (
                <TypeAndColor>
                  <AssetTypeColorBlock assetTypeName={previeweData?.assetType?.name || ''} />
                  <TangiTypography type="subheading">{assetTypeNameFormatter(previeweData.assetType.name)}</TangiTypography>
                </TypeAndColor>
              )}
              <Container>
                {previeweData && (
                  <AssetContainer>
                    <AssetOverview asset={previeweData} handleFileClick={handleOnClick} isPreview={true} />
                  </AssetContainer>
                )}
                <SideMenu>
                  <div className="d-flex justify-content-center">
                    {loggedIn ? (
                      <div className="d-block mt-15">
                        <h4 className="ff-din-regular-2 text-muted text-center">Hi, You are logged in as</h4>
                        <div className="fw-bolder fs-5 text-center">{user?.username}</div>
                        <div>
                          <TangiButton text="Go to Dashboard" onClick={() => userRedirection()} />
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex flex-column mt-15">
                        <h3 className="ff-din-regular-2 text-center">Are you ready to start managing your trade secrets?</h3>
                        <br />
                        <h4 className="ff-din-regular-2 mx-4 text-muted text-center">Implement best practice and ensure your company’s most valuable assets are safe</h4>
                        <br />
                        <h4 className="ff-din-regular-2 text-muted text-center">Try Tangibly free for 30 days</h4>
                        <a className="btn btn-lg px-5 btn btn-primary text-white text-center align-self-center" href={BOOK_A_DEMO_URL}>
                          Book A Demo
                        </a>
                      </div>
                    )}
                  </div>
                </SideMenu>
              </Container>
            </>
          )}
        </div>
      )}
      <Modal className="fileViewer" show={showFile} onHide={handleFileClose}>
        {loading ? (
          <div className="content-center m-4">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div>
            <Modal.Header className="text-muted" closeButton>
              <Modal.Title>
                <span className="h3 font-weight-normal ff-din-regular text-dark mb-4">{fileName}</span>
                <span className="px-2">
                  <Button className="btn-secondary btnreload btn-sm px-2 py-1 shadow-none" onClick={() => reload()} variant="primary">
                    <i className="fas fa-sync" />
                  </Button>
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderPreviewModalBody()}</Modal.Body>
          </div>
        )}
      </Modal>
    </div>
  );
}

export { PreviewAsset };
