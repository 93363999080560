import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { courseProgressionsConstants } from '../_constants/courseProgressionsConstants';
import { courseProgressionsService } from '../_services/courseProgressionsService';

const createCourseProgression = (courseId, userId) => {
  function request() {
    return {
      type: courseProgressionsConstants.CREATE_PROGRESSION_REQUEST,
    };
  }

  function success(payload) {
    return {
      type: courseProgressionsConstants.CREATE_PROGRESSION_SUCCESS,
      payload,
    };
  }

  function failure() {
    return {
      type: courseProgressionsConstants.CREATE_PROGRESSION_FAILURE,
    };
  }
  return (dispatch) => {
    dispatch(request());
    courseProgressionsService
      .createCourseProgression(courseId, userId)
      .then((res) => {
        dispatch(success(res));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
};

const editCourseProgression = (courseId, userId, body) => {
  function request() {
    return {
      type: courseProgressionsConstants.EDIT_PROGRESSION_REQUEST,
    };
  }

  function success(payload) {
    return {
      type: courseProgressionsConstants.EDIT_PROGRESSION_SUCCESS,
      payload,
    };
  }

  function failure(error) {
    return {
      type: courseProgressionsConstants.EDIT_PROGRESSION_FAILURE,
      error,
    };
  }

  return async (dispatch) => {
    dispatch(request());
    try {
      const res = await courseProgressionsService.editCourseProgression(courseId, userId, body);
      dispatch(success(res));
      dispatch(mixpanelEvents.quizCompleted());
      return res;
    } catch (error) {
      dispatch(failure(error));
      throw error;
    }
  };
};

const getByUser = (userId) => {
  function request() {
    return {
      type: courseProgressionsConstants.GETBYUSER_PROGRESSION_REQUEST,
    };
  }

  function success(payload) {
    return {
      type: courseProgressionsConstants.GETBYUSER_PROGRESSION_SUCCESS,
      payload,
    };
  }

  function failure() {
    return {
      type: courseProgressionsConstants.GETBYUSER_PROGRESSION_FAILURE,
    };
  }
  return (dispatch) => {
    dispatch(request());
    courseProgressionsService
      .getByUser(userId)
      .then((res) => {
        dispatch(success(res));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
};

export const courseProgressionsActions = {
  editCourseProgression,
  createCourseProgression,
  getByUser,
};
