import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';

import { TangiTypography, TangiSvgIcon } from '_components/TangiLibrary';
import { CourseProgressionStatus } from 'utils/types/courses/course';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { THEME_COLORS, NEUTRAL_SHADES } from 'utils/theme';
import { AlternativeVideoLinkContainer, customLinkStyles } from '../style';
import { CourseContainer, CourseStatus, CourseTitle } from './style';

const MAX_DESCRIPTION_LENGTH = 550;

const LearnCourseCard = ({ course, status, newRequiredCourses, setNewRequiredCourses }) => {
  const clientDetails = useSelector((state) => state.client.clientDetails);
  const Role = useSelector((state) => state.authentication.Role);
  const [isRequired, setIsRequired] = useState(clientDetails.requiredCourses?.includes(course.id));

  const isAlternativeLinks = !!course?.alternativeVideoUrls?.length;

  const { t } = useTranslation();
  const { clientId } = useParams();

  // Checking if the course is required
  useEffect(() => {
    setIsRequired(clientDetails.requiredCourses?.includes(course.id));
  }, [clientDetails]);

  // Change required course handler - > If it exists removes it if not, adds it.
  const changeRequired = () => {
    let updatedCourses = [];
    const index = newRequiredCourses.indexOf(course.id);
    if (index === -1) {
      updatedCourses = [...newRequiredCourses, course.id];
    } else {
      updatedCourses = newRequiredCourses.slice();
      updatedCourses.splice(index, 1);
    }
    setNewRequiredCourses(updatedCourses);
  };

  return (
    <Row className="p-0 item-course bg-white border text-left rounded row mb-4 my-3">
      <Col className="px-0 col-sm-12 col-lg-4 col-xl-4">
        <div className="media-left media-top position-relative h-100 media">
          <a data-testid="player" href={`?courseid=${course.id}`} target="_self" className="text-blk hover-color">
            <ReactPlayer
              width="100%"
              height="300px"
              url={course.featuredVideoUrl}
              controls
              light
              config={{
                youtube: {
                  playerVars: { modestbranding: 1 },
                },
              }}
            />
          </a>
        </div>
      </Col>
      <Col className="px-0 col-sm-12 col-lg-8 col-xl-8 d-flex flex-column">
        <div className="px-4 py-2 media-body">
          <CourseContainer className="navbar-text">
            <div className="h2 font-weight-bold media-header m-0 d-flex">
              <a href={generateRoute(AppRoutes.TRAINING, { clientId }, { courseid: course.id })} target="_self">
                <CourseTitle type="heading-md" weight="bold">
                  {course.title}
                </CourseTitle>
              </a>

              {/* Required courses can be changed by lawyers and admins, including Learn Admin */}
              {!Role.includes('Admin') && !Role.includes('Lawyer') && isRequired && <p id="required-course">{t('LEARN_PAGE.REQUIRED')}</p>}
            </div>
            {status && (
              <CourseStatus color={status}>
                {status === CourseProgressionStatus.COMPLETED && <TangiSvgIcon component="matchIndication" color={NEUTRAL_SHADES.WHITE} />}
                <div>{status}</div>
              </CourseStatus>
            )}
          </CourseContainer>
        </div>
        <TangiTypography className="p-4 pt-0 flex-grow-1">
          {course.description.length < MAX_DESCRIPTION_LENGTH ? course.description : course.description.slice(0, MAX_DESCRIPTION_LENGTH).concat('...')}
        </TangiTypography>

        {/* We are currently show only one alternative link */}
        {isAlternativeLinks && (
          <AlternativeVideoLinkContainer>
            <TangiTypography type="subheading">{t('LEARN_PAGE.DO_NOT_SEE_VIDEO')}</TangiTypography>
            <a href={course?.alternativeVideoUrls[0]} target="_blank" rel="noopener noreferrer">
              <TangiTypography type="subheading" color={THEME_COLORS.PRIMARY_DEFAULT} customStyles={customLinkStyles} dataTestId="watch-from-alternative-link">
                {t('LEARN_PAGE.CLICK_HERE')}
              </TangiTypography>
            </a>
          </AlternativeVideoLinkContainer>
        )}

        {/* Required courses can be changed by lawyers and admins, including Learn Admin */}
        {(Role.includes('Admin') || Role.includes('Lawyer')) && (
          <div className="pt-0 px-4 pb-4 mt-auto">
            <input data-testid="requiredCourse" checked={newRequiredCourses?.includes(course.id)} type="checkbox" onChange={changeRequired} /> {t('LEARN_PAGE.REQUIRED')}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default LearnCourseCard;
