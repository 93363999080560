import { TableStyles } from 'react-data-table-component';
import { CSSObject } from 'styled-components';
import { NEUTRAL_SHADES } from 'utils/theme';

const expenderBtnStyle = {
  backgroundColor: 'transparent',
  '&:hover:enabled': {
    backgroundColor: 'transparent',
  },
  '&:hover:not(:disabled)': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
};

const expenderCellStyle = {
  marginLeft: '5px',
  maxWidth: '4%',
  minWidth: '4%',
  flex: '0 0 4%',
};

const hideExpendHeadCellStyle = {
  backgroundColor: NEUTRAL_SHADES[200],
  '& > :first-child': {
    display: 'none',
  },
};

const baseCollapseTableStyle: TableStyles = {
  headRow: {
    style: hideExpendHeadCellStyle,
  },
  expanderCell: {
    style: expenderCellStyle,
  },
  expanderButton: {
    style: expenderBtnStyle,
  },
};

export const acknowledgementExpandableRowStyle: CSSObject = {
  backgroundColor: NEUTRAL_SHADES[50],
};

export const multipleAcknowledgementTableStyle: TableStyles = {
  ...baseCollapseTableStyle,
};

export const singleAssetTableStyle: TableStyles = {
  ...baseCollapseTableStyle,
};

export const groupsTableStyle: TableStyles = {
  responsiveWrapper: {
    style: {
      borderRadius: 'initial',
    },
  },
  expanderCell: {
    style: expenderCellStyle,
  },
  expanderButton: {
    style: expenderBtnStyle,
  },
};

export const innerTableBorderBottom: TableStyles = {
  table: {
    style: {
      borderBottom: `3px solid ${NEUTRAL_SHADES[300]}`,
    },
  },
};
