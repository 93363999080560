import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { TangiTypography } from '_components/TangiLibrary';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { THEME_COLORS } from 'utils/theme';
import { AlternativeVideoLinkContainer, customLinkStyles } from './style';

export const Checkbox = styled.div`
  display: flex;
  gap: 3px;
`;

const GoToQuiz = ({ courseId, alternativeLink }) => {
  const [disableButton, setDisableButton] = useState(true);

  const history = useHistory();
  const { clientId } = useParams();
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: useMemo(() => []),
  });

  const handleSubmit = () => {
    history.push(generateRoute(AppRoutes.QUIZ, { clientId, courseId }));
  };

  return (
    <Form className="p-3 mb-2  bg-light text-dark " onSubmit={handleSubmit}>
      <Form.Label className="mb-2 p-3 h2 text-dark">{t('LEARN_PAGE.TAKE_TO_QUIZ')}</Form.Label>
      {alternativeLink && (
        <AlternativeVideoLinkContainer>
          <TangiTypography type="subheading">{t('LEARN_PAGE.DO_NOT_SEE_VIDEO')}</TangiTypography>
          <a href={alternativeLink} target="_blank" rel="noopener noreferrer">
            <TangiTypography type="subheading" color={THEME_COLORS.PRIMARY_DEFAULT} customStyles={customLinkStyles} dataTestId="watch-from-alternative-link">
              {t('LEARN_PAGE.CLICK_HERE')}
            </TangiTypography>
          </a>
        </AlternativeVideoLinkContainer>
      )}
      <Checkbox className="p-3">
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            name="checkBox"
            data-testid="confirmation"
            className="fw-lighter text-muted "
            onClick={() => setDisableButton((prevState) => !prevState)}
            {...register('checkBox', { required: { value: true, message: 'Field is required' } })}
          />
          <div className="d-block invalid-feedback">{errors.name && errors.name.message}</div>
        </Form.Group>
        <Form.Text className="text-muted col-9"> {t('LEARN_PAGE.I_CONFIRM')}</Form.Text>
      </Checkbox>
      <div className="d-grid gap-2 p-3">
        <Button data-testid="continue" variant="primary" type="submit" className={`p-3 mb-2 mt-3 bg-primary text-white  btn-block ${disableButton && 'disabled'}`}>
          {t('LEARN_PAGE.CONTINUE_TO_QUIZ')}
        </Button>
      </div>
    </Form>
  );
};

export default GoToQuiz;
