import { MultipleAcknowledgementTable } from './MultipleAcknowledgementTable';
import { DeleteRecipientData, MultipleAcknowledgementTableData, TablePermissions } from './types';
import { useColumns } from './useColumns';
import { buildGroupTableRows } from './MultipleAcknowledgementTable.utils';
import TangiCollapseTable from '_components/TangiLibrary/TangiCollapseTable/TangiCollapseTable';
import { singleAssetTableStyle } from './styles';
import TableSkeleton from '_components/TableSkeleton/TableSkeleton';

interface Props {
  tableData: MultipleAcknowledgementTableData[];
  handleDeleteRecipient: (data: DeleteRecipientData) => void;
  permissions?: TablePermissions;
  isLoading?: boolean;
}

export const TableManager = ({ tableData, handleDeleteRecipient, permissions, isLoading = false }: Props) => {
  const isSingleAsset = tableData.length === 1;

  const { acknowledgementTableColumns, recipientsTableColumns, groupsTableColumns } = useColumns(isSingleAsset, permissions ?? {});

  if (isSingleAsset) {
    const { recipients, contributorsIds, id, departments, partners, businessUnits } = tableData?.[0] ?? {};
    return (
      <>
        {isLoading ? (
          <TableSkeleton count={5} />
        ) : (
          <TangiCollapseTable
            customStyles={singleAssetTableStyle}
            tableColumns={groupsTableColumns}
            nonExpandableRowClasses={['hide-expender']}
            tableData={buildGroupTableRows({ id, departments, partners, businessUnits, recipients, recipientsTableColumns, contributorsIds, handleDeleteRecipient })}
          />
        )}
      </>
    );
  }

  return (
    <MultipleAcknowledgementTable
      tableData={tableData}
      acknowledgementTableColumns={acknowledgementTableColumns}
      groupsTableColumns={groupsTableColumns}
      recipientsTableColumns={recipientsTableColumns}
      handleDeleteRecipient={handleDeleteRecipient}
      isLoading={isLoading}
    />
  );
};
