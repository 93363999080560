import { ActiveCardFooter, RecipientNumber, ShareAssetContainer } from './style';
import Tippy from '@tippyjs/react';
import AcknowledgementTooltip from '../AcknowledgementTooltip/AcknowledgementTooltip';
import { TangiIconButton } from '_components/TangiLibrary';
import { checkAssetSharePermission } from 'utils/roles';
import { useTranslation } from 'react-i18next';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { useSelector } from 'react-redux';
import { createTippyContent } from './utils';
import { useDispatch } from 'react-redux';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';

export const AssetActiveCardFooter = ({ asset, permissions, handleDistributeAsset }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const Role = useSelector((state) => state.authentication.Role);

  const isSharePointType = !!asset?.sharePoint;

  const allAcknowledged = asset?.recipients?.every((recipient) => recipient.acknowledged === true);

  const tippyContent = createTippyContent(asset?.recipients);

  return (
    <ActiveCardFooter>
      {checkAssetSharePermission(Role, permissions, asset?.isThirdParty) && (
        <>
          {!isSharePointType && (
            <Tippy
              content={<AcknowledgementTooltip acknowledgedRecipientsList={asset.acknowledgedAccountsList} notAcknowledgedRecipientsList={asset.notAcknowledgedAccountsList} />}
              placement="top-end"
              disabled={!asset?.recipients?.length}
            >
              {!!asset?.recipients?.length && (
                <RecipientNumber allAcknowledged={allAcknowledged} data-testid="recipient-number">
                  <span>
                    {allAcknowledged
                      ? asset.recipients.length
                      : asset?.acknowledgedAccountsList?.length
                      ? asset?.acknowledgedAccountsList?.length + '/' + asset.recipients.length
                      : '0/' + asset.recipients.length}
                  </span>
                  <span>{t('ASSET_PAGE.TOOLTIP.RECIPIENTS')}</span>
                </RecipientNumber>
              )}
            </Tippy>
          )}

          {!isSharePointType && (
            <Tippy content={t('ASSET_PAGE.TOOLTIP.NOTIFY')} placement="top-end">
              <ShareAssetContainer data-testid="share-asset-container">
                <TangiIconButton
                  icon="send"
                  variant={BUTTON_VARIANTS.TERTIARY_GREY}
                  onClick={() => {
                    dispatch(assetsActions.initialSelectedAssets());
                    handleDistributeAsset(asset);
                  }}
                />
              </ShareAssetContainer>
            </Tippy>
          )}

          {isSharePointType && !!asset?.recipients?.length && (
            <Tippy content={tippyContent} placement="top-end" disabled={!asset?.recipients?.length}>
              <RecipientNumber allAcknowledged={true} data-testid="recipient-number">
                <span>{asset.recipients.length}</span>
                <span>{t('ASSET_PAGE.TOOLTIP.RECIPIENTS')}</span>
              </RecipientNumber>
            </Tippy>
          )}
        </>
      )}
    </ActiveCardFooter>
  );
};
