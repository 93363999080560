/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Fix any and Object types
import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import {
  getAssetsForAcknowledgement,
  acknowledgeAndGetPreviewToken,
  requestPreviewOTP,
  previewAssetWithOtp,
  createAssets,
  deleteAsset,
  deleteRecipient,
  getAssetById,
  getAssets,
  getAssetsClient,
  getFilePathUrl,
  getRefNumber,
  validateAssets,
  getPrivilegeLogData,
  pendingAcknowledgement,
  getAssetAuditLog,
  downloadAllAssetsFiles,
  checkDownloadAssetsFilesStatus,
  createAssetsIpAudit,
  getIpAuditAssets,
  shareMultipleAssets,
  checkShareStatus,
  getAssetRecipients,
  getAssetsGroupedRecipients,
} from 'redux-toolkit/thunks/assetsThunks';
import {
  AllAssetsFilesResponse,
  Asset,
  Assets,
  GetAssetsGroupedRecipientsResponse,
  CheckShareStatusResponse,
  RecipientGroup,
  IshareContributorsStatus,
  ShareMultipleAssetsResponse,
  AssetGroupedRecipientsMap,
  RecipientCategoryType,
} from 'utils/types/assets/assets';
import { responseStatus } from '_constants';
import { mergeActivityLogsByMonth, normalizeActivityLogData } from '_components/ActivityLog/utils';
import { ActivityLogs, ActivityMetaData, INITIAL_LIMIT } from 'utils/types/activityLog/asset/types';
import { FilePayload, Pagination, ToastProps } from 'utils/types/types';
import { JOB_STATUS, RESULT_STATUS } from 'utils/enums';
import { XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';
import { AssetForAcknowledgement, PreviewToken } from 'utils/types/assets/acknowledgementAsset';

interface assetsState {
  assets: Assets;
  batchAssets: any[];
  batchErrors: any[];
  uploadedBatchAssets: any[];
  ipAuditAssets: Asset[];
  isLoadingIpAuditAssets: boolean;
  isCreatingIpAuditAssets: boolean;
  validatedBatch: boolean;
  assetsClientDashboard: any[];
  filePath: FilePayload;
  deleteAssetSuccess: boolean;
  deleteAssetError: boolean;
  deleteAssetStatus: any;
  asset: Asset | Object;
  error: Object;
  acknowledgementLoading: boolean;
  assetsForAcknowledgement: AssetForAcknowledgement[];
  previewToken: PreviewToken;
  previewOtpGenerationResult: { result: string };
  assetStatus: any;
  previewPostAsset: { message?: string; assets: Assets };
  otp: number;
  deleteRecipientData: any;
  fullReferenceId: Object;
  assetCreationToastProps: ToastProps;
  createdAssetId: string;
  shareContributorsStatus: IshareContributorsStatus;
  isTableView: boolean;
  pagination: Pagination;
  loading: boolean;
  privilegeLogLoading: boolean;
  batchLoading: boolean;
  createRefLoading: boolean;
  assetLoading: boolean;
  createUpdateAssetLoading: boolean;
  pendingAcknowledgementLoading: boolean;
  assetAuditLog: ActivityLogs;
  assetAuditLogMetaData: ActivityMetaData;
  assetAuditLogInfiniteLoader: boolean;
  isDownloadAssetFilesLoading: boolean;
  downloadAssetFilesUrl: null | string;
  downloadAssetsFilesJobStatus: null | AllAssetsFilesResponse;
  selectedAssets: Asset[];
  notificationUpdatedAssets: Asset[];
  isLoadingNotificationUpdatedAssets: boolean;
  isLoadingUpdatedAssetRecipients: boolean;
  shareAssetStatus: JOB_STATUS;
  isNotificationUpdateComplete: boolean;
  isLoadingAssetsGroupedRecipients: boolean;
  assetsGroupedRecipientsMap: AssetGroupedRecipientsMap | null;
  recipients: RecipientGroup[] | null;
}

const initialState: assetsState = {
  assets: { metadata: [], data: [] },
  batchAssets: [],
  batchErrors: [],
  uploadedBatchAssets: [],
  ipAuditAssets: [],
  isLoadingIpAuditAssets: false,
  isCreatingIpAuditAssets: false,
  validatedBatch: false,
  assetsClientDashboard: [],
  filePath: { base64: '', mimeType: '', file: '', fileSize: 0, uniqueFileKey: '' },
  deleteAssetSuccess: false,
  deleteAssetError: false,
  deleteAssetStatus: null,
  asset: {},
  error: {},
  assetsForAcknowledgement: [],
  acknowledgementLoading: false,
  previewToken: { token: '' },
  previewOtpGenerationResult: { result: '' },
  assetStatus: null,
  previewPostAsset: { message: '', assets: { metadata: [], data: [] } },
  otp: 0,
  deleteRecipientData: null,
  fullReferenceId: {},
  assetCreationToastProps: { show: false, text: '', type: RESULT_STATUS.BLANK },
  createdAssetId: '',
  shareContributorsStatus: { status: '', message: '' },
  isTableView: false,
  pagination: { limit: 15, page: 1 },
  // loaders
  loading: false,
  privilegeLogLoading: false,
  batchLoading: false,
  createRefLoading: false,
  assetLoading: false,
  createUpdateAssetLoading: false,
  pendingAcknowledgementLoading: false,
  assetAuditLog: {},
  assetAuditLogMetaData: {
    limit: INITIAL_LIMIT,
    page: 0,
    hasMore: false,
    total: -1,
  },
  assetAuditLogInfiniteLoader: false,
  isDownloadAssetFilesLoading: false,
  downloadAssetFilesUrl: null,
  downloadAssetsFilesJobStatus: null,
  selectedAssets: [],
  notificationUpdatedAssets: [],
  isLoadingNotificationUpdatedAssets: false,
  isLoadingUpdatedAssetRecipients: false,
  shareAssetStatus: JOB_STATUS.BLANK,
  isNotificationUpdateComplete: false,
  isLoadingAssetsGroupedRecipients: false,
  assetsGroupedRecipientsMap: null,
  recipients: null,
};

const assetsSlice = createSlice({
  name: 'assetsData',
  initialState,
  reducers: {
    setAssets(state, action) {
      state.assets = action.payload;
    },
    setAssetsLoading(state, action) {
      state.assetLoading = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.loading = false;
      state.assetStatus = responseStatus.assetFailed;
      state.error = action.payload;
    },
    setAsset(state, action) {
      state.asset = action.payload;
    },
    setCreateUpdateAssetLoading(state, action) {
      state.createUpdateAssetLoading = action.payload;
    },
    setShareContributorsStatus(state, action) {
      state.shareContributorsStatus.message = action.payload;
      state.shareContributorsStatus.status = 'success';
    },
    setAssetStatus(state, action) {
      state.assetStatus = action.payload;
    },
    setCreatedAssetId(state, action) {
      state.createdAssetId = action.payload;
    },
    setUploadFileError(state, action) {
      state.error = action.payload;
      state.createUpdateAssetLoading = false;
    },
    setResetBatchAssets(state) {
      state.validatedBatch = false;
      state.batchAssets = [];
      state.batchErrors = [];
      state.uploadedBatchAssets = [];
    },
    setClearFilePathUrl(state) {
      state.filePath = { base64: '', mimeType: '', file: '', fileSize: 0, uniqueFileKey: '' };
    },
    setClearAssetNotificationSuccess(state) {
      state.notificationUpdatedAssets = [];
    },
    setClearIsNotificationUpdateComplete(state) {
      state.isNotificationUpdateComplete = false;
    },
    clearPreviewToken(state) {
      state.previewToken = { token: '' };
    },
    clearAssetsForAcknowledgement(state) {
      state.assetsForAcknowledgement = [];
    },
    setClearAssetStatus(state) {
      state.loading = false;
      state.assetStatus = null;
      state.shareContributorsStatus = { status: '', message: '' };
    },
    setClearAsset(state) {
      state.asset = {};
      state.createdAssetId = '';
    },
    setGetAssetsClear(state) {
      state.assets = { metadata: [], data: [] };
      state.asset = {};

      state.assetsClientDashboard = [];
    },
    setClearRecipient(state) {
      state.deleteRecipientData = null;
    },
    setClearGroupedRecipientsMap(state) {
      state.assetsGroupedRecipientsMap = null;
    },
    setClearDeleteStatus(state) {
      state.deleteAssetStatus = null;
      state.loading = false;
      state.deleteAssetSuccess = false;
      state.deleteAssetError = false;
    },
    setAssetCreationToastProps(state, action: PayloadAction<ToastProps>) {
      state.assetCreationToastProps = action.payload;
    },
    setPreviewPostAsset(state, action: PayloadAction<any>) {
      state.previewPostAsset = action.payload;
    },
    setIsTableView(state, action: PayloadAction<boolean>) {
      state.isTableView = action.payload;
    },
    setPagination(state, action: PayloadAction<Pagination>) {
      state.pagination = action.payload;
    },
    setInitialPagination(state) {
      state.pagination = { limit: 15, page: 1 };
    },
    setAuditLogPage(state, action: PayloadAction<number>) {
      state.assetAuditLogMetaData.page = action.payload;
    },
    setInitialAuditLog(state) {
      state.assetAuditLog = {};
    },
    setAssetAuditLogLoader(state) {
      state.loading = false;
      state.assetAuditLogInfiniteLoader = true;
    },
    resetAssetAuditLogLoader(state) {
      state.assetAuditLogInfiniteLoader = false;
    },
    setSelectedAssets(state, action: PayloadAction<Asset[]>) {
      state.selectedAssets = action.payload;
    },
    initialSelectedAssets(state) {
      state.selectedAssets = [];
    },
  },
  extraReducers: (builder) => {
    // getAssetById
    builder.addCase(getAssetById.pending, (state) => {
      state.assetLoading = true;
    });
    builder.addCase(getAssetById.fulfilled, (state, action: any) => {
      state.asset = action?.payload;
      state.assetLoading = false;
    });
    builder.addCase(getAssetById.rejected, (state) => {
      state.assetLoading = false;
    });

    // getAssets
    builder.addCase(getAssets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssets.fulfilled, (state, action: PayloadAction<Assets>) => {
      state.assets = action.payload;
      state.loading = false;
    });
    builder.addCase(getAssets.rejected, (state, action: any) => {
      state.loading = false;
      state.assetStatus = responseStatus.assetFailed;
      state.error = action.payload;
    });

    // getPrivilegeLogData
    builder.addCase(getPrivilegeLogData.pending, (state) => {
      state.privilegeLogLoading = true;
    });
    builder.addCase(getPrivilegeLogData.fulfilled, (state) => {
      state.privilegeLogLoading = false;
    });
    builder.addCase(getPrivilegeLogData.rejected, (state, action: any) => {
      state.privilegeLogLoading = false;
      state.error = action.error.message;
    });

    // validateAssets
    builder.addCase(validateAssets.pending, (state) => {
      state.batchAssets = [];
      state.batchErrors = [];
      state.validatedBatch = false;
      state.batchLoading = true;
    });
    builder.addCase(validateAssets.fulfilled, (state, action: any) => {
      state.batchAssets = action.payload.assets;

      state.batchErrors = action.payload.errors;
      state.validatedBatch = true;
      state.batchLoading = false;
    });
    builder.addCase(validateAssets.rejected, (state, action: any) => {
      state.batchAssets = [];
      state.batchErrors = [];
      state.validatedBatch = false;
      state.error = action.error.message;
      state.batchLoading = false;
    });

    // Create Assets from Batch
    builder.addCase(createAssets.pending, (state) => {
      state.batchLoading = true;
      state.uploadedBatchAssets = [];
    });
    builder.addCase(createAssets.fulfilled, (state, action: any) => {
      state.batchLoading = false;
      state.uploadedBatchAssets = action.payload.data;
    });
    builder.addCase(createAssets.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.batchLoading = false;
      state.uploadedBatchAssets = [];
    });

    // Create Ip Audit Assets from Ip audit modal
    builder.addCase(createAssetsIpAudit.pending, (state) => {
      state.isCreatingIpAuditAssets = true;
    });
    builder.addCase(createAssetsIpAudit.fulfilled, (state, action: PayloadAction<Assets>) => {
      state.isCreatingIpAuditAssets = false;
      state.ipAuditAssets = [...state.ipAuditAssets, ...action.payload.data];
    });
    builder.addCase(createAssetsIpAudit.rejected, (state) => {
      state.isCreatingIpAuditAssets = false;
    });

    // get Ip Audit Assets
    builder.addCase(getIpAuditAssets.pending, (state) => {
      state.isLoadingIpAuditAssets = true;
      state.ipAuditAssets = [];
    });
    builder.addCase(getIpAuditAssets.fulfilled, (state, action: PayloadAction<Asset[]>) => {
      state.isLoadingIpAuditAssets = false;
      state.ipAuditAssets = action.payload;
    });
    builder.addCase(getIpAuditAssets.rejected, (state) => {
      state.isLoadingIpAuditAssets = false;
      state.ipAuditAssets = [];
    });

    // getFilePathUrl
    builder.addCase(getFilePathUrl.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFilePathUrl.fulfilled, (state, action: any) => {
      state.filePath = action.payload;
      state.loading = false;
    });
    builder.addCase(getFilePathUrl.rejected, (state, action: any) => {
      state.error = action?.error || {};
      state.filePath = { base64: '', mimeType: '', file: '', fileSize: 0, uniqueFileKey: '' };
      state.loading = false;
    });

    // shareMultipleAssets
    builder.addCase(shareMultipleAssets.pending, (state) => {
      state.notificationUpdatedAssets = [];
      state.isLoadingNotificationUpdatedAssets = true;
    });

    builder.addCase(shareMultipleAssets.fulfilled, (state, action: PayloadAction<ShareMultipleAssetsResponse | null>) => {
      if (!action?.payload) return;

      const { status } = action?.payload;

      state.shareAssetStatus = status;

      if ([JOB_STATUS.SUCCEEDED, JOB_STATUS.FAILED].includes(status)) {
        state.isLoadingNotificationUpdatedAssets = false;
      }
    });

    builder.addCase(shareMultipleAssets.rejected, (state) => {
      state.isLoadingNotificationUpdatedAssets = false;
      state.notificationUpdatedAssets = [];
    });

    // checkShareStatus
    builder.addCase(checkShareStatus.pending, (state) => {
      state.notificationUpdatedAssets = [];
      state.isLoadingNotificationUpdatedAssets = true;
    });

    builder.addCase(checkShareStatus.fulfilled, (state, action: PayloadAction<CheckShareStatusResponse | null>) => {
      if (!action?.payload) return;

      const { status, assets, isUpdateAsset } = action?.payload;

      state.shareAssetStatus = status;

      if ([JOB_STATUS.SUCCEEDED, JOB_STATUS.FAILED].includes(status)) {
        state.isLoadingNotificationUpdatedAssets = false;
        state.isNotificationUpdateComplete = true;
      }
      if (status === JOB_STATUS.SUCCEEDED && !!assets?.length) {
        state.notificationUpdatedAssets = assets;

        const notificationUpdatedAssetsMap = Object.fromEntries(assets.map((asset) => [asset.id, asset]));

        state.assets.data = state.assets.data.map((asset) => (notificationUpdatedAssetsMap[asset.id] ? notificationUpdatedAssetsMap[asset.id] : asset));
        if (state.selectedAssets) {
          state.selectedAssets = state.selectedAssets.map((asset) => (notificationUpdatedAssetsMap[asset.id] ? notificationUpdatedAssetsMap[asset.id] : asset));
        }
        if (isUpdateAsset && state.asset) {
          const currentAsset = state.asset as Asset;
          state.asset = notificationUpdatedAssetsMap[currentAsset.id];
        }
        state.isLoadingNotificationUpdatedAssets = false;
      } else {
        state.notificationUpdatedAssets = [];
      }
    });

    builder.addCase(checkShareStatus.rejected, (state) => {
      state.isLoadingNotificationUpdatedAssets = false;
      state.notificationUpdatedAssets = [];
      state.isNotificationUpdateComplete = false;
    });

    // getAssetsGroupedRecipients
    builder.addCase(getAssetsGroupedRecipients.pending, (state) => {
      state.isLoadingAssetsGroupedRecipients = true;
    });
    builder.addCase(getAssetsGroupedRecipients.fulfilled, (state, action: PayloadAction<GetAssetsGroupedRecipientsResponse | null>) => {
      state.isLoadingAssetsGroupedRecipients = false;
      const { payload } = action;
      state.assetsGroupedRecipientsMap = !payload
        ? null
        : payload.recipients.reduce((acc, { asset, categories }) => {
            const individuals = categories.filter(({ categoryType }) => categoryType === RecipientCategoryType.INDIVIDUAL);

            const partners = categories.filter(({ categoryType }) => categoryType === RecipientCategoryType.PARTNER);

            const businessUnits = categories.filter(({ categoryType }) => categoryType === RecipientCategoryType.BUSINESS_UNIT);

            const departments = categories.filter(({ categoryType }) => categoryType === RecipientCategoryType.DEPARTMENT);

            acc[asset._id] = {
              [RecipientCategoryType.INDIVIDUAL]: individuals,
              [RecipientCategoryType.PARTNER]: partners,
              [RecipientCategoryType.BUSINESS_UNIT]: businessUnits,
              [RecipientCategoryType.DEPARTMENT]: departments,
            };

            return acc;
          }, {} as AssetGroupedRecipientsMap);
    });
    builder.addCase(getAssetsGroupedRecipients.rejected, (state) => {
      state.isLoadingAssetsGroupedRecipients = false;
    });

    // getRefNumber
    builder.addCase(getRefNumber.pending, (state) => {
      state.createRefLoading = true;
      state.fullReferenceId = {};
    });
    builder.addCase(getRefNumber.fulfilled, (state, action: any) => {
      state.createRefLoading = false;
      state.fullReferenceId = action.payload;
    });
    builder.addCase(getRefNumber.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.createRefLoading = false;
      state.fullReferenceId = {};
    });

    // deleteAsset
    builder.addCase(deleteAsset.pending, (state) => {
      state.deleteAssetStatus = null;
      state.loading = true;
      state.deleteAssetSuccess = false;
      state.deleteAssetError = false;
    });
    builder.addCase(deleteAsset.fulfilled, (state, action: any) => {
      state.deleteAssetSuccess = true;
      state.deleteAssetStatus = action.payload;
      state.loading = false;
    });
    builder.addCase(deleteAsset.rejected, (state, action: any) => {
      state.deleteAssetError = true;
      state.deleteAssetStatus = action.error.message;
      state.loading = false;
    });

    // getAssetsClient
    builder.addCase(getAssetsClient.pending, (state) => {
      state.assetsClientDashboard = [];
      state.loading = true;
    });
    builder.addCase(getAssetsClient.fulfilled, (state, action: any) => {
      state.assetsClientDashboard = action.payload;
      state.loading = false;
    });
    builder.addCase(getAssetsClient.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.loading = false;
    });

    // deleteRecipient
    builder.addCase(deleteRecipient.pending, (state) => {
      state.deleteRecipientData = null;
      state.isLoadingUpdatedAssetRecipients = true;
    });

    builder.addCase(deleteRecipient.fulfilled, (state, action: any) => {
      state.isLoadingUpdatedAssetRecipients = false;
      const updatedAsset = action.payload.res[0].data;
      state.asset = updatedAsset;
      state.assets.data = state.assets.data.map((asset) => (asset.id === updatedAsset.id ? updatedAsset : asset));
      state.selectedAssets = state.selectedAssets.map((asset) => (asset.id === updatedAsset.id ? updatedAsset : asset));
      state.deleteRecipientData = action.payload.res;
    });

    builder.addCase(deleteRecipient.rejected, (state, action: any) => {
      state.isLoadingUpdatedAssetRecipients = false;
      state.error = action.error.message;
      state.deleteRecipientData = null;
    });

    // getAssetsForAcknowledgement
    builder.addCase(getAssetsForAcknowledgement.pending, (state) => {
      state.acknowledgementLoading = true;
      state.assetsForAcknowledgement = [];
      state.error = '';
    });
    builder.addCase(getAssetsForAcknowledgement.fulfilled, (state, action: PayloadAction<AssetForAcknowledgement[]>) => {
      state.assetsForAcknowledgement = action.payload;
      state.acknowledgementLoading = false;
    });
    builder.addCase(getAssetsForAcknowledgement.rejected, (state, action: PayloadAction<unknown, string, never, SerializedError>) => {
      state.error = action.error?.message ?? 'Something went wrong';
      state.acknowledgementLoading = false;
    });

    // acknowledgeAndGetPreviewToken
    builder.addCase(acknowledgeAndGetPreviewToken.pending, (state) => {
      state.acknowledgementLoading = true;
      state.error = '';
      state.previewToken = { token: '' };
    });
    builder.addCase(acknowledgeAndGetPreviewToken.fulfilled, (state, action: PayloadAction<PreviewToken | null>) => {
      if (action.payload?.token) {
        state.previewToken = action.payload;
      }
      state.acknowledgementLoading = false;
    });
    builder.addCase(acknowledgeAndGetPreviewToken.rejected, (state, action: PayloadAction<unknown, string, never, SerializedError>) => {
      state.error = action.error?.message ?? 'Something went wrong';
      state.acknowledgementLoading = false;
    });

    // requestPreviewOTP
    builder.addCase(requestPreviewOTP.pending, (state) => {
      state.acknowledgementLoading = true;
      state.error = '';
      state.previewOtpGenerationResult = { result: '' };
    });
    builder.addCase(requestPreviewOTP.fulfilled, (state, action: PayloadAction<{ result: string } | null>) => {
      if (action.payload?.result) {
        state.previewOtpGenerationResult = action.payload;
      }
      state.acknowledgementLoading = false;
    });
    builder.addCase(requestPreviewOTP.rejected, (state, action: PayloadAction<unknown, string, never, SerializedError>) => {
      state.error = action.error.message || 'Something went wrong';
      state.acknowledgementLoading = false;
    });

    // acknowledgePreviewAssetPOST
    builder.addCase(previewAssetWithOtp.pending, (state) => {
      state.error = '';
      state.loading = true;
      state.previewPostAsset = { message: '', assets: { metadata: [], data: [] } };
    });
    builder.addCase(previewAssetWithOtp.fulfilled, (state, action: any) => {
      state.previewPostAsset = action.payload.res;
      state.otp = action.payload.otp;
      state.loading = false;
    });
    builder.addCase(previewAssetWithOtp.rejected, (state, action: any) => {
      state.error = action.error.message || 'Something went wrong';
      state.loading = false;
    });

    // pendingAcknowledgement
    builder.addCase(pendingAcknowledgement.pending, (state) => {
      state.pendingAcknowledgementLoading = true;
    });
    builder.addCase(pendingAcknowledgement.fulfilled, (state) => {
      state.pendingAcknowledgementLoading = false;
    });
    builder.addCase(pendingAcknowledgement.rejected, (state, action: any) => {
      state.pendingAcknowledgementLoading = false;
      state.error = action?.payload?.message ?? '';
    });

    // getAuditLog
    builder.addCase(getAssetAuditLog.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getAssetAuditLog.fulfilled, (state, action) => {
      state.error = false;
      state.loading = false;

      const combinedData = mergeActivityLogsByMonth(state.assetAuditLog, normalizeActivityLogData(action.payload.data));

      state.assetAuditLog = combinedData;
      state.assetAuditLogMetaData = action.payload.metadata;
    });
    builder.addCase(getAssetAuditLog.rejected, (state, action: any) => {
      state.error = action?.error?.message ?? '';
      state.loading = false;
    });

    // downloadAllAssetsFiles
    builder.addCase(downloadAllAssetsFiles.pending, (state) => {
      state.isDownloadAssetFilesLoading = true;
    });
    builder.addCase(downloadAllAssetsFiles.fulfilled, (state, action: PayloadAction<AllAssetsFilesResponse>) => {
      state.downloadAssetsFilesJobStatus = action.payload;
    });
    builder.addCase(downloadAllAssetsFiles.rejected, (state, action: any) => {
      state.isDownloadAssetFilesLoading = false;
      state.error = action.payload;
    });

    // checkDownloadAssetsFilesStatus
    builder.addCase(checkDownloadAssetsFilesStatus.pending, (state) => {
      state.isDownloadAssetFilesLoading = true;
    });

    builder.addCase(checkDownloadAssetsFilesStatus.fulfilled, (state, action: PayloadAction<Blob | AllAssetsFilesResponse>) => {
      if (action.payload instanceof Blob) {
        const blob: Blob = action.payload;
        const url = window.URL.createObjectURL(blob);
        state.downloadAssetFilesUrl = url;
        state.isDownloadAssetFilesLoading = false;
        state.downloadAssetsFilesJobStatus = null;
      } else {
        action.payload.jobStatus === XRAY_JOB_STATUS.SUCCEEDED || action.payload.jobStatus === XRAY_JOB_STATUS.FAILED
          ? (state.isDownloadAssetFilesLoading = false)
          : (state.isDownloadAssetFilesLoading = true);
        state.downloadAssetsFilesJobStatus = { ...state.downloadAssetsFilesJobStatus, jobStatus: action.payload.jobStatus, downloadUrl: action.payload.downloadUrl };
      }
    });
    builder.addCase(checkDownloadAssetsFilesStatus.rejected, (state, action: any) => {
      state.isDownloadAssetFilesLoading = false;
      state.downloadAssetsFilesJobStatus = null;
      state.error = action.payload;
    });

    // getAssetRecipients
    builder.addCase(getAssetRecipients.pending, (state) => {
      state.loading = true;
      state.recipients = null;
    });
    builder.addCase(getAssetRecipients.fulfilled, (state, action: PayloadAction<RecipientGroup[]>) => {
      state.recipients = action.payload;
      state.loading = false;
    });
    builder.addCase(getAssetRecipients.rejected, (state, action: any) => {
      state.error = action.error.message || 'Something went wrong';
      state.loading = false;
    });
  },
});

export const assetsActions = assetsSlice.actions;

export default assetsSlice.reducer;
