import i18next from 'i18next';
import { DeletedRecipientData } from './types';
import { SUCCESSFUL_DELETE } from './consts';
import { ToastProps } from 'utils/types/types';
import { RESULT_STATUS } from 'utils/enums';

export const generateOnDeleteToastData = (recipientData: DeletedRecipientData): ToastProps => {
  const { name, email, error, message } = recipientData;

  const isSuccess = message === SUCCESSFUL_DELETE;

  const text = isSuccess ? i18next.t('ASSET_PAGE.TOOLTIP.DELETED_SUCCESSFULLY', { NAME: name ?? email }) : message ?? error;
  const type = isSuccess ? RESULT_STATUS.SUCCESS : RESULT_STATUS.ERROR;

  return {
    show: true,
    text,
    type,
  };
};
