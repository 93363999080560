import { useState, useEffect, useMemo, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { deleteRecipient, shareMultipleAssets, getAssetsGroupedRecipients } from 'redux-toolkit/thunks/assetsThunks';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { NEUTRAL_SHADES, SPACING } from '../../utils/theme';
import { TangiButton, TangiToast } from '../TangiLibrary';
import DistributeAssetModalSkeleton from '../DistributeAssetModalSkeleton/DistributeAssetModalSkeleton';
import { DeleteModal } from '../Modals/Modals';
import { validateEmailPattern } from '../../utils/formUtils';
import { ModalContentContainer, StyledModalBody, FormContentContainer, SelectContainer, StyledForm, TableTitle } from './style';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { TableManager } from './Tables/TableManager';
import { useHandleDeleteRecipient } from './useHandleDeleteRecipient/useHandleDeleteRecipient';
import { Header } from './components/Header/Header';
import { NotificationBanner } from './components/NotificationBanner/NotificationBanner';
import { initialToastData } from './consts';
import { useHandleNotifiedAssets } from './useHandleNotifiedAsset/useHandleNotifiedAssets';
import { useHandleRecipientEntitySearch } from './useHandleRecipientEntitySearch/useHandleRecipientEntitySearch';
import { DistributeParentComponent } from './types';
import { initialRecipientsFormData } from './consts';
import {
  isRecipientEntityIncludedInEveryAsset,
  getRecipientEntityAlreadyBeenNotifiedMessage,
  buildShareMultipleAssetsData,
  isGroupIncludedInSelectedOptions,
  getTotalRecipientsCountForMultipleAssets,
} from './utils';
import { RecipientCategoryType } from 'utils/types/assets/assets';
import { useParams } from 'react-router-dom';
import { TangiNotification, NOTIFICATION_VARIANTS, TangiSvgIcon } from '_components/TangiLibrary';
import { CustomSelectValue } from './components/CustomSelectValue/CustomSelectValue';

const DistributeAssetModal = ({ show, onClose, asset, shouldCloseAfterCreation = false, isLoading = false, width = '', height = '', calledFrom = '' }) => {
  const { isLoadingUpdatedAssetRecipients, isLoadingNotificationUpdatedAssets, selectedAssets, isLoadingAssetsGroupedRecipients, assetsGroupedRecipientsMap } = useSelector((state) => state.asset);
  const { Recipientdelete: removeRecipientPermission } = useSelector((state) => state.authentication.permissions);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showGroupNotificationData, setShowGroupNotificationData] = useState({ isShow: false, isClosedByUser: false });

  useEffect(() => {
    if (show) {
      setShowGroupNotificationData({ isShow: false, isClosedByUser: false });
    }
  }, [show]);

  const { clientId } = useParams();

  const { selectOptions, isLoading: isLoadingSelectOptions } = useHandleRecipientEntitySearch();

  const [options, setOptions] = useState(selectOptions);

  const [toastData, setToastData] = useState(initialToastData);

  // of type MultipleAcknowledgementTableData
  const [tableData, setTableData] = useState([]);

  const { modalData, onClickDeleteRecipient, onClickCancel } = useHandleDeleteRecipient(setToastData);

  const assets = useMemo(() => (selectedAssets?.length ? selectedAssets : [...(!isEmpty(asset) ? [asset] : [])]), [selectedAssets, asset]);

  const totalRecipientsNumber = getTotalRecipientsCountForMultipleAssets(tableData);

  useEffect(() => {
    if(assets.length && show) dispatch(getAssetsGroupedRecipients(assets.map(({ id }) => id)));
  }, [assets, show]);

  useEffect(() => {
    if (!show) {
      dispatch(assetsActions.setClearGroupedRecipientsMap());
    }
  }, [show]);

  useEffect(() => {
    (() => {
      if (assets?.length && !isLoadingAssetsGroupedRecipients && assetsGroupedRecipientsMap) {
        const tableData = assets.map(({ id, name: assetName, assetType, contributor: contributors }) => {
          const { individual, businessUnit, department, partner } = assetsGroupedRecipientsMap?.[id] ?? {};
          return {
            id,
            assetName,
            recipients: individual?.[0]?.items ?? [],
            businessUnits: businessUnit ?? [],
            departments: department ?? [],
            partners: partner ?? [],
            contributorsIds: contributors ? contributors.map(({ _id }) => _id) : [],
            assetType: assetType?.name ?? '',
          };
        });
        setTableData(tableData);
      }
    })();
  }, [isLoadingAssetsGroupedRecipients, assetsGroupedRecipientsMap, setTableData]);

  const {
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: initialRecipientsFormData,
  });

  const handleClose = useCallback(() => {
    reset(initialRecipientsFormData);
    clearErrors('recipientEntities');
    setOptions([]);
    onClose();
  }, [reset, clearErrors, setOptions, onClose]);

  useHandleNotifiedAssets({
    onSuccess: () => {
      shouldCloseAfterCreation && handleClose();
    },
    handleToastData: setToastData,
  });

  useEffect(() => {
    if (show) {
      setOptions(selectOptions);
    }
  }, [show]);

  const onSubmit = (data) => {
    const { recipients, partnerships, businessUnits, departments } = buildShareMultipleAssetsData(data.recipientEntities);
    const assetsIds = assets.map(({ id }) => id);
    const isUpdateAsset = calledFrom === DistributeParentComponent.ClientAssets;
    dispatch(shareMultipleAssets({ recipients, partnerships, businessUnits, departments, assetsIds, isUpdateAsset, clientId }));
    dispatch(mixpanelEvents.notifyAssets());
    reset(initialRecipientsFormData);
  };

  const handleChange = (recipientEntities) => {
    clearErrors('recipientEntities');

    if (!recipientEntities.length || !tableData.length) {
      setValue('recipientEntities', recipientEntities);
      return;
    }

    if (!showGroupNotificationData.isClosedByUser && isGroupIncludedInSelectedOptions(recipientEntities)) {
      setShowGroupNotificationData((prev) => ({ ...prev, isShow: true }));
    }

    const lastAddedRecipientEntity = recipientEntities[recipientEntities.length - 1];
    const isLastAddedRecipientEntityAlreadyIncluded = isRecipientEntityIncludedInEveryAsset(lastAddedRecipientEntity.id, lastAddedRecipientEntity.type, tableData);
    isLastAddedRecipientEntityAlreadyIncluded
      ? setError('recipientEntities', { type: 'custom', message: t(getRecipientEntityAlreadyBeenNotifiedMessage(lastAddedRecipientEntity.type)) })
      : setValue('recipientEntities', recipientEntities);
  };

  const handleCreateOption = (recipientEmail) => {
    clearErrors('recipientEntities');
    const isValidEmailPattern = validateEmailPattern(recipientEmail);
    if (isValidEmailPattern) {
      // update select options with the new created option
      const newSelectOption = { type: RecipientCategoryType.INDIVIDUAL, id: recipientEmail, email: recipientEmail, label: recipientEmail, value: recipientEmail };
      setOptions((prev) => [...prev, newSelectOption]);

      // update form values with the new created option
      const currentValues = getValues();
      const { recipientEntities } = currentValues;
      setValue('recipientEntities', [...recipientEntities, newSelectOption]);
    } else {
      setError('recipientEntities', { type: 'custom', message: t('ASSET_PAGE.ERROR_MESSAGE.NOT_VALID_EMAIL') });
    }
  };

  return (
    <>
      <Modal transition="Fade" show={show} onHide={handleClose} data-testid="distribute-asset">
        <ModalContentContainer width={width} height={height}>
          {isLoading ? (
            <DistributeAssetModalSkeleton />
          ) : (
            <>
              <Header handleClose={handleClose} assetsAmount={tableData.length} assetName={tableData[0]?.assetName ?? ''} />

              <StyledModalBody>
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                  {/* container */}
                  <FormContentContainer>
                    <SelectContainer>
                      <Controller
                        name="recipientEntities"
                        control={control}
                        rules={{
                          required: { value: true, message: t('ASSET_PAGE.ERROR_MESSAGE.EMAIL_IS_REQUIRED') },
                        }}
                        render={({ field }) => (
                          <CreatableSelect
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: (provided) => ({ ...provided, zIndex: 2 }),
                              multiValueRemove: (styles, { data }) => ({
                                ...styles,
                                color: data.color,
                                ':hover': {
                                  backgroundColor: NEUTRAL_SHADES[500],
                                  color: data.color,
                                },
                              }),
                              valueContainer: (styles) => ({
                                ...styles,
                                gap: SPACING[1],
                              }),
                            }}
                            isLoading={isLoadingSelectOptions}
                            className="ff-din-DemiBold"
                            isMulti
                            {...field}
                            options={options}
                            onCreateOption={handleCreateOption}
                            onChange={handleChange}
                            classNamePrefix="list"
                            components={{
                              MultiValue: CustomSelectValue,
                            }}
                          />
                        )}
                      />
                    </SelectContainer>
                    <TangiButton
                      type="submit"
                      text={t('GENERAL.BUTTONS_TEXT.NOTIFY')}
                      smallbtn={true}
                      disabled={isLoadingUpdatedAssetRecipients || isLoadingNotificationUpdatedAssets || isLoadingSelectOptions}
                      data-testid="share-asset"
                    />
                  </FormContentContainer>
                  <div className="d-block invalid-feedback">{errors.recipientEntities && errors.recipientEntities.message}</div>
                </StyledForm>

                <NotificationBanner />

                {showGroupNotificationData.isShow && !showGroupNotificationData.isClosedByUser && (
                  <TangiNotification
                    rightAnchor={
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setShowGroupNotificationData({ isShow: false, isClosedByUser: true });
                        }}
                      >
                        <TangiSvgIcon component="remove" color={NEUTRAL_SHADES[1100]} />
                      </div>
                    }
                    variant={NOTIFICATION_VARIANTS.WARNING}
                  >
                    {t('ASSET_PAGE.GROUPS_NOTIFICATION')}
                  </TangiNotification>
                )}

                {tableData.length === 1 && !(isLoadingUpdatedAssetRecipients || isLoadingNotificationUpdatedAssets || isLoadingAssetsGroupedRecipients) && (
                  <TableTitle>{`${t('ASSET_PAGE.TITLE.PEOPLE_WITH_ACCESS')}: ${totalRecipientsNumber}`}</TableTitle>
                )}

                <TableManager
                  tableData={tableData}
                  handleDeleteRecipient={onClickDeleteRecipient}
                  permissions={{ removeRecipient: removeRecipientPermission }}
                  isLoading={isLoadingUpdatedAssetRecipients || isLoadingNotificationUpdatedAssets || isLoadingAssetsGroupedRecipients}
                />
              </StyledModalBody>
            </>
          )}
        </ModalContentContainer>
        <DeleteModal
          title={t('ASSET_PAGE.MODAL.DELETE_RECIPIENT')}
          body={t('ASSET_PAGE.MODAL.RECIPIENTS_WONT_VIEW_ASSET')}
          show={modalData.show}
          loading={isLoadingUpdatedAssetRecipients}
          handleDelete={() => {
            const { assetId, recipientId } = modalData;
            dispatch(deleteRecipient({ assetId, recipientId }));
          }}
          onClose={onClickCancel}
        />
      </Modal>
      <TangiToast
        {...toastData}
        onSuccess={() => {
          setToastData(initialToastData);
        }}
      />
    </>
  );
};

export default DistributeAssetModal;
