import { TableStyles } from 'react-data-table-component';
import styled from 'styled-components';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const TableContainer = styled.div<{ $maxTableBodyHeight?: string }>`
  height: 100%;
  .rdt_TableBody {
    max-height: ${(props) => props?.$maxTableBodyHeight ?? 'auto'};
    overflow-y: scroll;
  }
  .rdt_ExpanderRow .rdt_TableBody {
    max-height: initial;
    overflow-y: initial;
  }

  .rdt_TableRow.hide-expender > :first-child {
    display: none;
  }
`;

export const defaultStyles: TableStyles = {
  responsiveWrapper: {
    style: {
      borderRadius: SPACING[2],
      border: `1px solid ${NEUTRAL_SHADES[300]}`,
    },
  },
  headRow: {
    style: {
      backgroundColor: NEUTRAL_SHADES[200],
    },
  },
};
