import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TangiMultiSelect, TangiButton } from '../TangiLibrary';
import { FiltersRowContainer, FiltersContainer } from './style';
import {  renderAccountStatus, formerStatusOptions } from './utils';
import { assetMetaDataEnums } from '_constants';
import { ASSET_FILTERS } from '_components/AssetFilters/types';
import { usersActions } from 'redux-toolkit/slices/usersSlice';

const FormerEmployeesFilters = ({ filter, isFilterApplied, handleClearSearch }) => {
  const { departments } = useSelector((state) => state.assetMetaData);
  const { t } = useTranslation();
  const [, setIsClearAll] = useState(false);
  const dispatch = useDispatch();

  const handleClearAll = () => {
    setIsClearAll(true);
    dispatch(usersActions.resetFilters());
    handleClearSearch();
    setTimeout(() => setIsClearAll(false), 0);
  };

  const handleFilterChange = (selectedItems, id) => {
    dispatch(usersActions.updateFilter({ id, selectedItems }));
  };


  return (
    <FiltersRowContainer>
      <FiltersContainer className="people-filters" data-testid="people-filters">
        <TangiMultiSelect
          filterTitle={t('PEOPLE.SELECT.STATUS')}
          id="status"
          selectedItems={filter.status}
          itemList={formerStatusOptions}
          onChange={handleFilterChange}
          isAccountStatusNeeded={true}
          customRenderOption={renderAccountStatus}
        />
        <TangiMultiSelect
          filterTitle={t('ASSET_PAGE.DROP_DOWN.DEPARTMENT')}
          id={assetMetaDataEnums.department}
          selectedItems={filter.department}
          itemList={[...departments, { name: t('ASSET_PAGE.DROP_DOWN.NO_DEPARTMENTS'), id: ASSET_FILTERS.NO_DEPARTMENTS }]}
          onChange={handleFilterChange}
          isSearch={true}
        />

        {isFilterApplied && (
          <TangiButton
            smallbtn={true}
            font={14}
            variant="tertiary"
            text={t('GENERAL.BUTTONS_TEXT.CLEAR_ALL')}
            size="small"
            onClick={handleClearAll}
            disabled={!isFilterApplied}
            onChange={handleFilterChange}
            data-testid="clear-all-filters"
          />
        )}
      </FiltersContainer>
    </FiltersRowContainer>
  );
};

export default FormerEmployeesFilters;
