import { Account } from '../account/account';
import { ClientRequestParams } from '../client/client';
import { XRAY_JOB_STATUS, XRAY_SOURCE_TYPE, XRayData } from '../xRay/xRay';

export interface TradeSecretFromPatent {
  subject: string;
  bullets: string[];
  isAssetCreated?: boolean;
  mapping?: (string[] | null)[];
}

export interface PatentMappingScore {
  overallScorePercentage: number;
  totalBulletsFound: number;
  totalBulletsNotFound: number;
}

export interface PatentApplicationData extends XRayData {
  patent_id: string;
  regenerate: number;
  choice_0: null | TradeSecretFromPatent[];
  file_flow: string | null;
  score?: PatentMappingScore;
  accountsOfInventors?: AccountsOfInventors[];
  status_finder: XRAY_JOB_STATUS;
  status_mapper: XRAY_JOB_STATUS;
  inventors: null | string[];
  title: string | null;
  year: number | null;
  url: string | null;
}

export interface GetTradeSecretsFromPatent extends ClientRequestParams {
  patentId: string;
  patentModel: string;
}

export interface GetInventorsAccounts extends ClientRequestParams {
  inventors: string[];
}

export interface GetPatentLastResult extends ClientRequestParams {
  patentResultId: string;
}
export interface UploadPatentApplication extends ClientRequestParams {
  files: File[];
  patentModel: string;
}
export interface PatentStartJobResponse {
  patentResultId: string;
  source: XRAY_SOURCE_TYPE;
}

export interface ContinuationAnalysisRequest {
  patentId?: string;
  files?: File[];
  isAdmin: boolean;
  clientId: string;
}

export interface ContinuationAnalysisResponse {
  continuationResultId: string;
}

export interface ContinuationAnalysisResultsResponse {
  subjects: Subject[];
  status: XRAY_JOB_STATUS;
  details: string;
  sourceType: XRAY_SOURCE_TYPE;
}

export interface ContinuationAnalysisData {
  response: ContinuationAnalysisResultsResponse;
  patentId: string;
  fileName: string;
  excelExportStatus: XRAY_JOB_STATUS;
  wordExportStatus: XRAY_JOB_STATUS;
  showContinuationExportToast: boolean;
}

export interface PatentRequestInfo extends PatentStartJobResponse {
  clientId: string;
}

export enum PATENT_MODEL_TYPE {
  GPT = 'gpt',
  TANGIBLY = 'tangibly-model',
}

export type FileType = 'excel' | 'word';

export type  SuggestedClaimsType = 'independent' | 'dependent';

export interface AccountsOfInventors {
  name: string;
  account: Account | string;
}

export interface Subject {
  title: string;
  tradeSecrets: TradeSecretEntry[];
}

export interface TradeSecretEntry {
  title: string;
  category: ETradeSecretCategory;
  originalText: EOriginalText;
  mappings: Mapping[];
  suggestedClaims: SuggestedClaims[] | null;
}

export interface SuggestedClaims {
  claim_number: number;
  type: SuggestedClaimsType;
  text: string;
  sub_claims: SubClaim[];
}

export type SubClaim = {
  indent_level: number;
  text: string;
  sub_claims: SubClaim[];
};

export enum ETradeSecretCategory {
  SupportedClaims = 'Supported Claims',
  TradeSecretsCandidates = 'Trade Secrets Candidates',
  ContinuationCandidates = 'Continuation Candidates',
  RemovedTooSimilar = 'Removed Too Similar',
}
export interface Mapping {
  text: string;
  score: number | null;
}

export enum EOriginalText {
  CLAIMS = 'claims',
  SPEC = 'spec',
}
