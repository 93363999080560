import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import Tippy from '@tippyjs/react';
import { Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css';

import './People.scss';
import plus from '../../assets/icons/plus.svg';
import { TangiButton, TangiDropdownButton, TangiToast, TangiSearch, TangiTypography, TangiIconButton } from '../../_components/TangiLibrary';
import { Dashboard } from '../Dashboard';
import { ViewPeople } from '../../_components/ViewPeople/ViewPeople';
import TableSkeleton from '../../_components/TableSkeleton/TableSkeleton';
import { getNoDataDisplay } from '../../utils/NoDataTable';
import { usePeople } from './hooks/usePeople';
import { customStyles } from '../../utils/peoplePartnerUtils';
import EmployeesValidationModal from '../../_components/ValidationModal/EmployeesValidationModal';
import PeopleFilters from '../../_components/PeopleFilters/PeopleFilters';
import { InviteAccountForm } from '../../_components/InviteAccountForm/InviteAccountForm';
import { PeopleContainer, FilterSearch, TableContainer, SectionHeader, TableHeaderContainer } from './style';
import { PageHeaderContainer, PageHeader, StyledPageTitle, PageHeaderButtonContainer } from '../../utils/globalStyles';
import { BUTTON_VARIANTS, NEUTRAL_SHADES } from 'utils/theme';
import { ACCOUNT_TYPE } from '../../utils/enums';
import { PeopleMultiselectComponents } from './Components/PeopleMultiselectComponents';

import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { BackToContainer } from 'pages/ContractTool/style';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';

function People() {
  const {
    handleSortContractor,
    setEmpPagination,
    setCntrPagination,
    handleSortEmployee,
    exportExcelEmployees,
    handleOpenBatchValidation,
    handleCloseBatchValidation,
    setShowInviteAccountForm,
    fetchAccounts,
    handleClearSearch,
    deselectUsers,
    selectAll,
    dispatchUserAction,
    getTotalSelected,
    handleResetView,
    view,
    isLearn,
    permissions,
    contractor,
    columns2,
    columns3,
    empPagination,
    cntrPagination,
    employeeData,
    contractorData,
    tablesLoaders,
    showBatchValidation,
    showInviteAccountForm,
    activeAccount,
    activeClient,
    isLawyer,
    peopleToastProps,
    isFilterApplied,
    clientSettingsLoading,
    filter,
    clientLoading,
    metaDataLoading,
    isAllEmployeesSelected,
    isAllContractorsSelected,
    isAllVisibleContractorsSelected,
    isAllVisibleEmployeesSelected,
    isDeactivatedUsersSelected,
    isActiveUsersSelected,
    isPendingUsersSelected,
    conditionalRowStyles,
    clientName,
    tableSettings,
  } = usePeople();

  const {
    tablesData: { employee: allEmployees, contractor: allContractors },
  } = useSelector((state) => state.client);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { clientId } = useParams();
  const searchValue = useSelector((state) => state.users.peoplePage.filters.searchValue);
  const [localSearchInput, setLocalSearchInput] = useState('');

  const handleSearchSubmit = useCallback(
    (value) => {
      dispatch(usersActions.setSearchQuery(value.trim()));
    },
    [localSearchInput],
  );

  const goToFormerEmployees = () => {
    dispatch(usersActions.updateIsFormerEmployeesTable(true));
    history.push(generateRoute(AppRoutes.FORMER_EMPLOYEES, { clientId }));
  };

  useEffect(() => {
    return () => {
      dispatch(usersActions.resetFilters());
      deselectUsers();
    };
  }, []);

  const renderFilters = useCallback(() => {
    return (
      <FilterSearch className="search-filter-section-people">
        <Tippy content={<TangiTypography color={NEUTRAL_SHADES.WHITE}>{t('PEOPLE.FILTERS.SEARCH_BY_USER_NAME_ID_OR_EMAIL')}</TangiTypography>}>
          <div>
            <TangiSearch handleSearch={handleSearchSubmit} setSearchValue={setLocalSearchInput} searchValue={localSearchInput} placeholder={t('PEOPLE.FILTERS.SEARCH')} width="272px" />
          </div>
        </Tippy>
        <PeopleFilters filter={filter} isFilterApplied={isFilterApplied} handleClearSearch={handleClearSearch} />
      </FilterSearch>
    );
  }, [handleSearchSubmit, setLocalSearchInput, localSearchInput, isFilterApplied, handleClearSearch, filter]);

  useEffect(() => {
    setLocalSearchInput(searchValue);
  }, [searchValue]);

  const totalEmployees = useMemo(() => allEmployees?.metadata && allEmployees?.metadata?.length > 0 && allEmployees.metadata[0].total, [allEmployees, filter, tablesLoaders]);
  const totalContractors = useMemo(() => allContractors?.metadata && allContractors?.metadata?.length > 0 && allContractors.metadata[0].total, [allContractors, filter, tablesLoaders]);

  return (
    <>
      <Dashboard title="">
        {/* Actions and Page Title section */}
        <PageHeaderContainer>
          <PageHeader>
            <StyledPageTitle className="ff-din-DemiBold">{t('PEOPLE.TITLE.PEOPLE')}</StyledPageTitle>
            <PageHeaderButtonContainer>
              {(permissions?.Employeecreate || permissions?.Learnemployeecreate) && (
                <TangiDropdownButton
                  text={t('PEOPLE.DROPDOWN.BATCH_UPLOAD')}
                  variant="secondary"
                  endIcon={true}
                  loading={metaDataLoading || clientLoading || clientSettingsLoading}
                  options={[
                    { text: t('PEOPLE.DROPDOWN.DOWNLOAD_EXCEL'), handleItem: exportExcelEmployees },
                    { text: t('PEOPLE.DROPDOWN.UPLOAD_EXCEL'), handleItem: handleOpenBatchValidation },
                  ]}
                />
              )}
              {(permissions?.Employeecreate || permissions?.Learnemployeecreate) && (
                <TangiButton text={t('GENERAL.BUTTONS_TEXT.ADD_USER_CAPITAL')} onClick={() => setShowInviteAccountForm(true)} icon={plus} />
              )}
            </PageHeaderButtonContainer>
          </PageHeader>
        </PageHeaderContainer>
        <PeopleContainer>
          {/* New search & filters section */}
          {renderFilters()}
          {/* Employees section */}
          <SectionHeader>
            <TangiTypography weight="semi-bold" type="heading-md">
              {t('PEOPLE.TITLE.EMPLOYEES')}
            </TangiTypography>
            {/* Employees number */}
            {totalEmployees && !tablesLoaders.employeeLoading ? (
              <TableHeaderContainer>
                <TangiTypography color={NEUTRAL_SHADES[800]} dataTestId="employee-number">
                  {t('PEOPLE.TITLE.EMPLOYEES_TOTAL', { totalEmployees })}
                </TangiTypography>
                {(isAllVisibleEmployeesSelected || isAllEmployeesSelected) && (
                  <div style={{ maxWidth: '200px' }} data-testid={'select-all-employee'}>
                    <TangiButton variant="tertiary" size="small" text={isAllEmployeesSelected ? t('PEOPLE.SELECT.CLEAR') : t('PEOPLE.SELECT.ALL')} onClick={selectAll(ACCOUNT_TYPE.EMPLOYEE)} />
                  </div>
                )}
                <BackToContainer>
                  <span>{t('FORMER_EMPLOYEES.TITLE.FORMER_EMPLOYEES')}</span>
                  <TangiIconButton icon="arrowRight" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={goToFormerEmployees} />
                </BackToContainer>
              </TableHeaderContainer>
            ) : (
              ' '
            )}
            {/* <SortByDropdown onSelect={handleSortSelect} id="sort-allEmployees" /> */}
          </SectionHeader>
          {/* Employees table */}
          <TableContainer className="overflow-fix" data-testid="employee-table">
            {(permissions?.Employeelist || permissions?.Learnemployeeview) && (
              <DataTable
                pagination
                columns={columns2}
                paginationDefaultPage={empPagination.page}
                onChangeRowsPerPage={(pageRows) => setEmpPagination({ page: 1, limit: pageRows })}
                onChangePage={(page) => setEmpPagination({ ...empPagination, page })}
                paginationServer
                paginationTotalRows={
                  typeof allEmployees && allEmployees?.metadata && allEmployees?.metadata !== 'undefined'
                    ? allEmployees && allEmployees?.metadata && allEmployees?.metadata.length > 0 && allEmployees.metadata[0].total
                    : 0
                }
                data={employeeData}
                onSort={handleSortEmployee}
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                sortIcon={<i className="" />}
                progressPending={tablesLoaders.employeeLoading || clientSettingsLoading}
                progressComponent={<TableSkeleton />}
                noDataComponent={getNoDataDisplay(t('PEOPLE.TABLE.NO_EMPLOYEES_FOUND'))}
                onRowClicked={(row) => {
                  row.onClick();
                }}
              />
            )}
          </TableContainer>
          {/* Contractors section */}
          <SectionHeader>
            <TangiTypography weight="semi-bold" type="heading-md">
              {t('PEOPLE.TITLE.CONTRACTORS')}
            </TangiTypography>
            {/* Contractors number */}
            {totalContractors && !tablesLoaders.contractorLoading ? (
              <TableHeaderContainer>
                <TangiTypography color={NEUTRAL_SHADES[800]} dataTestId="contractor-number">
                  {t('PEOPLE.TITLE.CONTRACTORS_TOTAL', { totalContractors })}
                </TangiTypography>
                {isAllVisibleContractorsSelected && (
                  <div style={{ maxWidth: '200px' }} data-testid={'select-all-contractors'}>
                    <TangiButton variant="tertiary" size="small" text={isAllContractorsSelected ? t('PEOPLE.SELECT.CLEAR') : t('PEOPLE.SELECT.ALL')} onClick={selectAll(ACCOUNT_TYPE.CONTRACTOR)} />
                  </div>
                )}
              </TableHeaderContainer>
            ) : (
              ' '
            )}
          </SectionHeader>
          {/* Contractors table */}
          <TableContainer className="overflow-fix" data-testid="contractor-table">
            {(permissions?.Contractorlist || permissions?.Learncontractorcreate) && (
              <DataTable
                style={{ overflow: 'visible' }}
                pagination
                columns={columns3}
                paginationDefaultPage={cntrPagination.page}
                onChangeRowsPerPage={(pageRows) => setCntrPagination({ page: 1, limit: pageRows })}
                onChangePage={(page) => setCntrPagination({ ...cntrPagination, page })}
                paginationServer
                paginationTotalRows={
                  typeof contractor && contractor.metadata && contractor.metadata !== 'undefined'
                    ? contractor && contractor.metadata && contractor.metadata.length > 0 && contractor.metadata[0].total
                    : 0
                }
                data={contractorData}
                onSort={handleSortContractor}
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                sortIcon={<i className="" />}
                progressPending={tablesLoaders.contractorLoading}
                progressComponent={<TableSkeleton />}
                noDataComponent={getNoDataDisplay(t('PEOPLE.TABLE.NO_CONTRACTORS_FOUND'))}
                onRowClicked={(row) => {
                  row.onClick();
                }}
              />
            )}
          </TableContainer>
        </PeopleContainer>
        <Modal transition="Fade" className="custom-modal-style" show={showBatchValidation} onHide={handleCloseBatchValidation} backdrop="static">
          <Modal.Body>
            <EmployeesValidationModal handleClose={handleCloseBatchValidation} clientId={clientId} isLearn={isLearn} />
          </Modal.Body>
        </Modal>
        <Modal transition="Fade" className="invite-account-modal" show={showInviteAccountForm} onHide={() => setShowInviteAccountForm(false)} backdrop="static">
          <Modal.Header closeButton>
            <div id="invite-modal-header">
              <Trans t={t} i18nKey={'PEOPLE.MODAL.TITLE.ADD_USER_TO'} values={{ NAME: isLawyer ? activeClient?.name || '' : activeAccount?.client?.name || '' }} />
            </div>
          </Modal.Header>
          <Modal.Body>
            <InviteAccountForm handleClose={() => setShowInviteAccountForm(false)} />
          </Modal.Body>
        </Modal>
        {view.show && <ViewPeople showModal={view.show} viewData={view.data} type={view.type} mode={view.mode} tableSettings={tableSettings} resetView={handleResetView} />}
      </Dashboard>
      <TangiToast {...peopleToastProps} onSuccess={fetchAccounts} />
      <PeopleMultiselectComponents
        getTotalSelected={getTotalSelected}
        deselectUsers={deselectUsers}
        isDeactivatedUsersSelected={isDeactivatedUsersSelected}
        isActiveUsersSelected={isActiveUsersSelected}
        isPendingUsersSelected={isPendingUsersSelected}
        clientName={clientName}
        fetchAccounts={fetchAccounts}
        dispatchUserAction={dispatchUserAction}
      />
    </>
  );
}

export { People };
