import { Container, QuestionText } from '../QuizQuestion/styles';
import './AnswerQuiz.scss';

const QuizAnswer = ({ questionAndAswer }) => {
  const { questionData, usersAnswer } = questionAndAswer;
  return (
    <Container>
      <QuestionText className="question">{questionData.text}</QuestionText>
      <div className="options">
        {questionData.choices.map((radio, index) => (
          <>
            <input
              style={{ color: 'black', margin: '0px 8px', border: 'none' }}
              key={index}
              type="radio"
              name={radio._id}
              id={questionData.answer === radio.serialNumber ? 'right' : usersAnswer === radio.serialNumber.toString() ? 'wrong' : 'nutral'}
              checked={questionData.answer === radio.serialNumber || usersAnswer === radio.serialNumber.toString()}
            />
            {radio.option}
            <br />
          </>
        ))}
      </div>
    </Container>
  );
};

export default QuizAnswer;
