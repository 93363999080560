import AssetTypeColorBlock from '_components/AssetTypeColorBlock/AssetTypeColorBlock';
import { TangiTypography } from '_components/TangiLibrary/TangiTypography';
import { Container } from './styles';
import { formatLongFileName } from 'utils/fileNameUtils';

interface Props {
  type: string;
  name: string;
  itemsNum: number;
}

export const AssetDisplay = ({ type, name, itemsNum }: Props) => (
  <Container>
    <AssetTypeColorBlock assetTypeName={type} />
    <TangiTypography weight="bold" type="subheading">{formatLongFileName(`${name} (${itemsNum})`,30)}</TangiTypography>
  </Container>
);
