import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '_helpers';
import { predictedPortfolioActions } from 'redux-toolkit/slices/predictedPortfolioSlice';
import { TangiIconButton, TangiTypography } from '_components/TangiLibrary';
import { ExportButton } from 'pages/TradeSecretsAnalysis/styles';
import { RowContainer } from 'pages/TradeSecretsAnalysis/components/Widget/style';
import PortfolioFilters from 'pages/TradeSecretsAnalysis/components/PortfolioFilters/PortfolioFilters';
import { PortfolioDataTable } from 'pages/TradeSecretsAnalysis/components/PortfolioDataTable/PortfolioDataTable';
import { FilterConfigType } from 'pages/TradeSecretsAnalysis/types';
import { CHART_OPTIONS } from 'pages/TradeSecretsAnalysis/components/Card/type';
import { Filters } from 'utils/types/predictedPortfolio/predictedPortfolio';
import { Pagination } from 'utils/types/types';
import { PortfolioModalData, PortfolioModalFiltersConfig } from './types';
import PredictedInfo from './PredictedInfo/PredictedInfo';
import { useModalPredictedPortfolio } from './usePredictedPortfolioModal';
import { BUTTON_VARIANTS, NEUTRAL_SHADES } from 'utils/theme';
import { TYPOGRAPHY_TYPES } from '_components/TangiLibrary/TangiTypography/style';
import { StyledModal } from 'utils/globalStyles';
import { Container, ModalContent, StyledDivider, StyledHeader, TotalResults } from './style';

interface Props {
  isShow: boolean;
  onClose: () => void;
  data: PortfolioModalData | undefined;
  filterConfig?: PortfolioModalFiltersConfig;
  filtersOptions: Filters;
  initialFilter: Record<string, [{ id: string; value: string }]> | undefined;
  currentChart: CHART_OPTIONS;
}

const PredictedPortfolioModal = ({
  isShow,
  data,
  onClose,
  filtersOptions,
  filterConfig = {
    enabledFilters: [FilterConfigType.Subjects, FilterConfigType.Patents, FilterConfigType.Years, FilterConfigType.Companies],
  },
  currentChart,
  initialFilter,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const modalTableData = useSelector((state: RootState) => state.predictedPortfolio.modalTable);
  const isModalTableExportLoading = useSelector((state: RootState) => state.predictedPortfolio.loading.exportModalTable);
  const isModalTableLoading = useSelector((state: RootState) => state.predictedPortfolio.loading.modalTable);
  const pagination = useSelector((state: RootState) => state.predictedPortfolio.modalPagination);
  const error = useSelector((state: RootState) => state.predictedPortfolio.error);

  const { searchValue, filter, isFilterApplied, setFilter, setSearchValue, handleClearSearch, handleSearch, handleExportTable, resetModalState, setFilterByNodeSubject } = useModalPredictedPortfolio({
    isModalOpen: isShow,
    initialFilter: data,
  });

  const hasNoModalTableData = isModalTableLoading || !modalTableData?.data?.length || error;
  const totalItems = modalTableData?.metadata[0]?.total;

  const renderModalHeader = () => {
    return (
      <div>
        <StyledHeader>
          <TangiTypography type="heading-lg">{t('PREDICTED_PORTFOLIO.PORTFOLIO_TABLE.TITLE')}</TangiTypography>
          <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={onHide} icon="close" />
        </StyledHeader>
      </div>
    );
  };

  const renderModalTable = () => {
    if (data) {
      return (
        <ModalContent>
          <PortfolioDataTable
            data={modalTableData}
            pagination={pagination}
            loading={isModalTableLoading}
            setPaginationAction={(newPagination: Pagination) => dispatch(predictedPortfolioActions.setModalPagination(newPagination))}
          />
        </ModalContent>
      );
    }
  };

  const renderModalSubjectInfo = () => {
    return (
      <PredictedInfo
        subject={data?.subject || ''}
        potentialTradeSecretsCount={data?.potentialTradeSecretsCount || 0}
        patentsCount={data?.patentsCount || ''}
        squareColor={data?.squareColor || ''}
        chartType={currentChart}
      />
    );
  };

  const renderModalTotalItems = () => {
    return (
      !isModalTableLoading && (
        <TotalResults>
          <TangiTypography color={NEUTRAL_SHADES[800]} weight="regular" type={TYPOGRAPHY_TYPES.SUBHEADING}>
            {t('PREDICTED_PORTFOLIO.PORTFOLIO_TABLE.TOTAL_ITEMS', {
              NUMBER: totalItems,
            })}
          </TangiTypography>
        </TotalResults>
      )
    );
  };

  const renderModalBody = () => {
    return (
      <Container>
        {renderModalSubjectInfo()}
        {renderModalFilters()}
        {totalItems > 0 && renderModalTotalItems()}
        {renderModalTable()}
      </Container>
    );
  };

  const renderModalFilters = () => {
    return (
      <RowContainer>
        <PortfolioFilters
          filtersOptions={filtersOptions}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filter={filter}
          setFilter={setFilter}
          initialFilter={initialFilter}
          isFilterApplied={isFilterApplied}
          onClearAll={handleClearSearch}
          handleSearch={handleSearch}
          isTableLoading={isModalTableLoading}
          filterConfig={filterConfig}
        />
        <ExportButton
          variant="secondary"
          text={t('PREDICTED_PORTFOLIO.PORTFOLIO_TABLE.EXPORT_DATA')}
          onClick={handleExportTable}
          svgIcon="download"
          disabled={isModalTableExportLoading || hasNoModalTableData}
          loading={isModalTableExportLoading}
          smallbtn
        />
      </RowContainer>
    );
  };

  useEffect(() => {
    if (isShow) {
      setFilterByNodeSubject(initialFilter);
    }
  }, [isShow]);

  const onHide = () => {
    resetModalState();
    onClose();
  };

  return (
    <StyledModal transition="Fade" show={isShow} onHide={onHide} data-testid="predicted-modal">
      {renderModalHeader()}
      <StyledDivider />
      {renderModalBody()}
    </StyledModal>
  );
};

export default PredictedPortfolioModal;
