/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, SerializedError, createSlice } from '@reduxjs/toolkit';
import {
  getCompetitiveAnalysisGraphsResults,
  getCompetitiveAnalysisTradeSecretsExportTable,
  getCompetitiveAnalysisTradeSecretsTable,
  getCompetitiveAnalysisTradeSecretsTableModal,
} from 'redux-toolkit/thunks/competitiveAnalysisThunks';
import { CompetitiveAnalysisGraphsData, CompetitiveAnalysisTableData } from 'utils/types/competitiveAnalysis/competitiveAnalysis';
import { Pagination } from 'utils/types/types';

interface competitiveAnalysisState {
  loading: {
    graph: boolean;
    table: boolean;
    modalTable: boolean;
    exportModalTable: boolean;
    exportTable: boolean;
  };
  error: string;
  graphsData: CompetitiveAnalysisGraphsData;
  tableData: CompetitiveAnalysisTableData;
  modalTable: CompetitiveAnalysisTableData;
  pagination: Pagination;
  modalPagination: Pagination;
}

const initCompetitiveAnalysisGraphsData = () => {
  return {
    count_objects: {
      total_patents: 0,
      total_ts: 0,
      total_subjects: 0,
      total_companies: 0,
    },
    graphs: {
      heatmap: [],
    },
    filters: {
      subjects: [{ id: '', value: '' }],
      patents: [{ id: '', value: '' }],
      years: [],
      companies: [],
    },
    created_date: '',
    main_company: '',
    companies_ts_count: [{ id: '', value: 0, label: '' }],
  };
};

const initCompetitiveAnalysisTableData = () => {
  return {
    data: [{ trade_secret: '', patent: { id: '', value: '' }, subject: { id: '', value: '' }, patent_year: 0, company: '' }],
    metadata: [{ total: 0, page: 0 }],
  };
};

const initialPagination = { page: 1, limit: 10 };

const initialState: competitiveAnalysisState = {
  error: '',
  loading: {
    graph: false,
    table: false,
    exportTable: false,
    modalTable: false,
    exportModalTable: false,
  },
  graphsData: initCompetitiveAnalysisGraphsData(),
  tableData: initCompetitiveAnalysisTableData(),
  modalTable: initCompetitiveAnalysisTableData(),
  pagination: initialPagination,
  modalPagination: initialPagination,
};

const competitiveAnalysisSlice = createSlice({
  name: 'competitiveAnalysisData',
  initialState,
  reducers: {
    setPagination(state, action: PayloadAction<Pagination>) {
      state.pagination = action.payload;
    },
    setInitialPagination(state) {
      state.pagination = initialPagination;
    },
    setModalPagination(state, action: PayloadAction<Pagination>) {
      state.modalPagination = action.payload;
    },
    setInitialModalPagination(state) {
      state.modalPagination = initialPagination;
    },
  },
  extraReducers: (builder) => {
    // getCompetitiveAnalysisTradeSecretsTable
    builder.addCase(getCompetitiveAnalysisTradeSecretsTable.pending, (state) => {
      state.loading.table = true;
    });
    builder.addCase(getCompetitiveAnalysisTradeSecretsTable.fulfilled, (state, action: PayloadAction<CompetitiveAnalysisTableData | null>) => {
      if (action.payload !== null) {
        state.tableData = action.payload;
      }
      state.loading.table = false;
    });
    builder.addCase(getCompetitiveAnalysisTradeSecretsTable.rejected, (state, action: PayloadAction<unknown, string, any, SerializedError>) => {
      state.error = action?.error?.message ?? '';
      state.loading.table = false;
    });

    // getCompetitiveAnalysisGraphsResults
    builder.addCase(getCompetitiveAnalysisGraphsResults.pending, (state) => {
      state.loading.graph = true;
    });
    builder.addCase(getCompetitiveAnalysisGraphsResults.fulfilled, (state, action: PayloadAction<CompetitiveAnalysisGraphsData | null>) => {
      if (action.payload !== null) {
        state.graphsData = action.payload;
      }
      state.loading.graph = false;
    });
    builder.addCase(getCompetitiveAnalysisGraphsResults.rejected, (state, action: PayloadAction<unknown, string, any, SerializedError>) => {
      state.error = action?.error?.message ?? '';
      state.loading.graph = false;
    });

    // getCompetitiveAnalysisTradeSecretsExportTable
    builder.addCase(getCompetitiveAnalysisTradeSecretsExportTable.pending, (state) => {
      state.loading.exportTable = true;
      state.loading.exportModalTable = true;
    });
    builder.addCase(getCompetitiveAnalysisTradeSecretsExportTable.fulfilled, (state) => {
      state.loading.exportTable = false;
      state.loading.exportModalTable = false;
    });
    builder.addCase(getCompetitiveAnalysisTradeSecretsExportTable.rejected, (state) => {
      state.loading.exportTable = false;
      state.loading.exportModalTable = false;
    });

    // getCompetitiveAnalysisTradeSecretsTableModal
    builder.addCase(getCompetitiveAnalysisTradeSecretsTableModal.pending, (state) => {
      state.loading.modalTable = true;
    });
    builder.addCase(getCompetitiveAnalysisTradeSecretsTableModal.fulfilled, (state, action: PayloadAction<CompetitiveAnalysisTableData | null>) => {
      if (action.payload !== null) {
        state.modalTable = action.payload;
      }
      state.loading.modalTable = false;
    });
    builder.addCase(getCompetitiveAnalysisTradeSecretsTableModal.rejected, (state, action: PayloadAction<unknown, string, any, SerializedError>) => {
      state.error = action?.error?.message ?? '';
      state.loading.modalTable = false;
    });
  },
});

export const competitiveAnalysisActions = competitiveAnalysisSlice.actions;

export default competitiveAnalysisSlice.reducer;
