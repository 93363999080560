import React, { ReactNode } from 'react';
import { ToolbarContainer } from './style';

interface TangiActionToolbarProps {
  children: ReactNode;
  isVisible: boolean;
}

export const TangiActionToolbar = ({ children, isVisible }: TangiActionToolbarProps) => {
  return isVisible ? <ToolbarContainer data-testid={'tangi-toolbar'}>{children}</ToolbarContainer> : null;
};
