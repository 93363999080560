import React, { useEffect, useState } from 'react';
import { Form, Row, InputGroup, Button } from 'react-bootstrap';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import _ from 'lodash';
import Select from 'react-select';
import { NavLink } from 'react-router-dom';
import { countries } from 'countries-list';

import './AdminUsersForm.scss';
import { getAssetMetaData } from 'redux-toolkit/thunks/assetMetaDataThunks';
import { EditAsyncSelect, optionTypes } from '../../EditAsyncSelect';
import { scrollToTop } from '../../../utils/windowFuncs';
import { userService } from '../../../_services/userService';
import { adminUserActions } from '../../../AdminRedux/_actions/adminUserActions';
import { Loader } from '../../Loader';
import { IRoles } from '../../../utils/roles';
import { validEmailPattern } from '../../../utils/formUtils';
import { TangiToggle } from '_components/TangiLibrary';

function AdminUsersForm({ type, accountId }) {
  const lawyers = [IRoles.LAWYER, IRoles.LAWYER_ADMIN];
  const cmsAdmin = [IRoles.SUPER_ADMIN];
  const employees = [IRoles.EMPLOYEE, IRoles.EMPLOYEE_ADMIN, IRoles.LEARN_EMPLOYEE, IRoles.LEARN_EMPLOYEE_ADMIN, IRoles.EMPLOYEE_MANAGER, IRoles.EMPLOYEE_HR];
  const partners = [IRoles.PARTNER];
  const [isLoading, setIsLoading] = useState(false);
  const [userStatus, setUserStatus] = useState([]);
  const [existingLogo, setExistingLogo] = useState({});
  const [, setRoles] = useState([]);
  const [defaults, setDefaults] = useState({});
  const [checked, setChecked] = useState(true);
  const [oktaChecked, setOktaChecked] = useState(false);

  const { createUser, errors: userErrors, getUser, updateUser } = useSelector((state) => state.adminUserReducer);
  const { loading: depLoading, departments, newDepartment, businessUnits, newBusinessUnit } = useSelector((state) => state.assetMetaData);
  const dispatch = useDispatch();

  const formTypes = {
    EDIT: 'edit',
    VIEW: 'view',
    ADD: 'Add New User',
  };

  const userStatusMsg = {
    UserSuccess: 'User Created Successfully',
    UpdateSuccess: 'User Updated Successfully',
  };

  // Form Related Hooks
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const role = useWatch({
    control,
    name: 'role',
    defaultValue: '',
  });

  const client = useWatch({
    control,
    name: 'client',
    defaultValue: '',
  });

  useEffect(() => {
    if (client) {
      dispatch(getAssetMetaData(client));
      if (type === formTypes.ADD) {
        setValue('departments', []);
        setValue('businessUnits', []);
      } else if (type === formTypes.EDIT) {
        setValue('departments', []);
        setValue('businessUnits', []);
      }
    }
  }, [client]);

  useEffect(() => {
    if (newDepartment) {
      const departmentsCurrentValues = getValues().departments;
      const departmentsValue = departmentsCurrentValues
        ? [...departmentsCurrentValues, { value: newDepartment.id, label: newDepartment.name }]
        : [{ value: newDepartment.id, label: newDepartment.name }];
      setValue('departments', departmentsValue);
    }
  }, [newDepartment]);

  useEffect(() => {
    if (newBusinessUnit) {
      const currentValues = getValues().businessUnits;
      const filteredValue = currentValues ? [...currentValues, { value: newBusinessUnit.id, label: newBusinessUnit.name }] : [{ value: newBusinessUnit.id, label: newBusinessUnit.name }];
      setValue('businessUnits', filteredValue);
    }
  }, [newBusinessUnit]);

  useEffect(() => {
    if (!_.isEmpty(getUser)) {
      const {
        client: userClient,
        dateOfJoining,
        dateOfLeaving,
        title,
        email,
        fullName,
        phone,
        lawFirm,
        role,
        departments: userDepartments,
        employeeId,
        country,
        location,
        businessUnits: userBusinessUnits,
        linkedin,
        others1,
        others2,
        photo,
        photoName,
        displayName,
        specialAdminPermissions,
      } = getUser;

      setDefaults({
        ROLE: {
          value: role?.name,
          label: role?.name,
        },
      });

      const defaultsTemp = {};
      if (!_.isEmpty(userClient)) defaultsTemp.CLIENT = { value: userClient?._id, label: userClient?.name };
      if (!_.isEmpty(lawFirm)) defaultsTemp.LAWFIRM = { value: lawFirm?._id, label: lawFirm?.name };
      if (!_.isEmpty(photo) && !_.isEmpty(photoName)) setExistingLogo({ name: photoName, link: photo });

      setTimeout(() => {
        setDefaults((state) => ({
          ...state,
          ...defaultsTemp,
        }));
        setIsLoading(false);
      }, 400);

      const joining = dateOfJoining?.split('T')[0];
      const leaving = dateOfLeaving ? dateOfLeaving?.split('T')[0] : '';
      const currentDepartments = getOptions(userDepartments, '_id');
      const currentBusinessUnits = getOptions(userBusinessUnits, '_id');
      const currentCountry = { value: country, label: country };
      const isPatentAccess = specialAdminPermissions?.Patent;
      const isDownloadAllAssetFiles = specialAdminPermissions?.isDownloadAllAssetFiles;
      const ContinuationXRay = specialAdminPermissions?.ContinuationXRay;

      reset({
        fullName,
        photo,
        phone,
        email,
        title,
        dateOfLeaving: leaving,
        dateOfJoining: joining,
        departments: currentDepartments,
        employeeId,
        country: currentCountry,
        location,
        businessUnits: currentBusinessUnits,
        linkedin,
        others1,
        others2,
        displayName,
        xrayFeaturesAccess: {
          Patent: isPatentAccess,
          isDownloadAllAssetFiles,
          ContinuationXRay,
        },
      });
    }
  }, [getUser]);

  const isAddMode = (formType) => formType === formTypes.ADD;
  const isViewMode = (formType) => formType === formTypes.VIEW;
  const isEditMode = (formType) => formType === formTypes.EDIT;
  const isViewOrEdit = (formType) => isViewMode(formType) || isEditMode(formType);

  const getCountriesOptions = () => {
    const formattedCountries = [];
    for (const [, value] of Object.entries(countries)) {
      formattedCountries.push({ value: value.name, label: value.name });
    }
    return formattedCountries;
  };

  const isInRole = (rolesValidation) => rolesValidation.includes(role);

  const getFilteredOptions = (fieldData, name) => {
    let fieldCurrentValues = getValues()[name] || [];
    if (name === 'versions' && !Array.isArray(fieldCurrentValues)) {
      fieldCurrentValues = [fieldCurrentValues];
    }
    if (fieldCurrentValues.length === 0) {
      return getOptions(fieldData);
    }
    return fieldData.reduce((filtered, data) => {
      const fieldOption = { value: data.id, label: data.name };
      if (!fieldCurrentValues.includes(fieldOption)) {
        filtered.push(fieldOption);
      }
      return filtered;
    }, []);
  };

  const getOptions = (results, value = 'id', label = 'name') => {
    const options =
      results &&
      results?.map((result) => ({
        value: result[value],
        label: result[label],
      }));
    return options;
  };

  useEffect(() => {
    if (accountId && (type === formTypes.EDIT || type === formTypes.VIEW)) {
      setIsLoading(true);
      dispatch(adminUserActions.getAdminAccountById(accountId));
    }
  }, [type]);

  useEffect(() => {
    if (!_.isEmpty(createUser) || !_.isEmpty(updateUser)) {
      setIsLoading(false);

      if (isAddMode(type)) setUserStatus(userStatusMsg.UserSuccess);
      else if (isEditMode(type)) setUserStatus(userStatusMsg.UpdateSuccess);

      setTimeout(() => {
        setIsLoading(true);
        scrollToTop();
        window.location.reload();
      }, 1500);
    } else if (!_.isEmpty(userErrors)) {
      setIsLoading(false);
    }
  }, [userErrors, createUser, updateUser]);

  useEffect(() => {
    userService.getRoles({ name: null }).then((response) => {
      const { results } = response;
      setRoles(results);
    });
  }, []);

  const makeCreatableApiData = (data) => {
    return data?.map((item) => (item ? item.value : ''));
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    dispatch(adminUserActions.clearErrors());

    data.email = data.email.toLowerCase().trim();
    data.username = data.email;
    data.fullName = data.fullName || data.displayName;

    if (oktaChecked) {
      data.externalUser = true;
    }

    data.departments = makeCreatableApiData(data.departments ? data.departments : []);
    data.businessUnits = makeCreatableApiData(data.businessUnits ? data.businessUnits : []);
    data.country = data.country && data.country.label;

    isInRole(lawyers) ? delete data.client : delete data.lawFirm;

    if (isEditMode(type)) {
      data.photo = data.photo ? data.photo : '';
      isInRole(lawyers) ? (data.client = '') : (data.lawFirm = '');

      dispatch(adminUserActions.updateAdminUser(data, accountId));
    } else if (isAddMode(type)) {
      dispatch(adminUserActions.createAdminUser(data));
    }
  };

  function handleFiles(files, id) {
    if (isEditMode(type)) {
      setExistingLogo({});
      setValue('photo', '');
    }
    setValue(id, files);
  }

  return (
    <Row className="m-0">
      <Loader isLoading={isLoading} />
      <div className="col-md-12 col-lg-12 mx-auto mt-4">
        <div className="border-0 rounded">
          {!isViewOrEdit(type) && <h3 className="font-weight-normal">{type}</h3>}
          {isViewOrEdit(type) && <h3 className="font-weight-normal text-capitalize">{type} User</h3>}
          {isAddMode(type) && (
            <Form.Group className="mt-4 mb-2" controlId="isOktaUser">
              <Form.Label className="text-dark fw-600 txt-size">Okta User</Form.Label>
              <Form.Check type="checkbox" defaultChecked={oktaChecked} onChange={() => setOktaChecked(!oktaChecked)} className="ff-din-regular" label="Is Okta user?" />
            </Form.Group>
          )}

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="formProfilePicture">
              <Form.Label className="text-dark fw-600 txt-size">Profile Picture </Form.Label>

              {!isAddMode(type) && !_.isEmpty(existingLogo) && (
                <ul className="p-0">
                  <li style={{ listStyle: 'none' }} className="border border-black rounded-3 cursor-pointer">
                    <div className="px-4 py-1">
                      <div className="d-flex justify-content-between align-items-center">
                        <img src={existingLogo.link} className="existing-logo img-responsive" alt={existingLogo.name} />

                        {!isViewMode(type) && (
                          <button
                            type="button"
                            onClick={() => {
                              setExistingLogo({});
                              setValue('photo', '');
                            }}
                            className="btn del-btnt btn-sm btn btn-secondary remove-btn"
                          >
                            <i className="far fa-trash-alt" aria-hidden="true" />
                          </button>
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
              )}

              {!isViewMode(type) && (
                <Dropzone onDrop={(file) => handleFiles(file, 'profile')}>
                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <>
                      <div {...getRootProps()} className="u-focus-outline dropzone-box text-center p-2 text-muted">
                        <input {...getInputProps()} />
                        <svg className="my-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fill="#828282"
                            d="M9.375 7.44595H11.107V15.3749C11.107 15.478 11.1914 15.5624 11.2945 15.5624H12.7008C12.8039 15.5624 12.8883 15.478 12.8883 15.3749V7.44595H14.625C14.782 7.44595 14.8687 7.26548 14.7727 7.14361L12.1477 3.82017C12.1301 3.79776 12.1077 3.77964 12.0821 3.76717C12.0565 3.75471 12.0285 3.74823 12 3.74823C11.9715 3.74823 11.9435 3.75471 11.9179 3.76717C11.8923 3.77964 11.8699 3.79776 11.8523 3.82017L9.22734 7.14127C9.13125 7.26548 9.21797 7.44595 9.375 7.44595ZM20.5781 14.6717H19.1719C19.0688 14.6717 18.9844 14.7561 18.9844 14.8592V18.4686H5.01562V14.8592C5.01562 14.7561 4.93125 14.6717 4.82812 14.6717H3.42188C3.31875 14.6717 3.23438 14.7561 3.23438 14.8592V19.4999C3.23438 19.9147 3.56953 20.2499 3.98438 20.2499H20.0156C20.4305 20.2499 20.7656 19.9147 20.7656 19.4999V14.8592C20.7656 14.7561 20.6812 14.6717 20.5781 14.6717Z"
                          />
                        </svg>
                        <p className=" text-black fs-7">
                          Drag and drop photo, or <span className="card-link text-primary text-decoration-underline color-hover">browse</span>
                        </p>
                      </div>

                      <aside>
                        <small className="text-muted fs-7 txt-size">Upload only accepted formats (.jpeg, .png, .jpg).</small>
                        <ul className="fs-7">{acceptedFiles && acceptedFiles[0] && acceptedFiles[0].name}</ul>
                      </aside>
                    </>
                  )}
                </Dropzone>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmailAddress">
              <Form.Label className="text-dark fw-600 txt-size">
                Email address <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                data-testid="email"
                placeholder="email@example.com"
                disabled={isViewOrEdit(type)}
                {...register('email', {
                  required: { value: true },
                  pattern: {
                    value: validEmailPattern,
                    message: 'Please enter a valid email',
                  },
                })}
              />
              <div className="d-block invalid-feedback">{errors.email && errors.email.message}</div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formFullName">
              <Form.Label className="text-dark fw-600 txt-size">Full Name</Form.Label>
              <Form.Control data-testid="fullname" type="text" placeholder="Enter your full name" {...register('fullName')} disabled={isViewOrEdit(type)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formFullName">
              <Form.Label className="text-dark fw-600 txt-size">
                Display Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control data-testid="displayName" type="text" placeholder="Enter your Display name" {...register('displayName', { required: true })} disabled={isViewMode(type)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formRoles">
              <Form.Label className="text-dark fw-600 txt-size">
                Roles <span className="text-danger">*</span>
              </Form.Label>
              <Controller
                data-testid="role"
                name="role"
                render={({ field: { ref, ...rest } }) => (
                  <EditAsyncSelect isDisabled={isViewMode(type)} defaultValue={defaults.ROLE} inputRef={ref} selectStyles="ff-din-DemiBold" type={optionTypes.ROLES} {...rest} />
                )}
                control={control}
                rules={{ required: true }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTitle">
              <Form.Label className="text-dark fw-600 txt-size">Title </Form.Label>
              <Form.Control data-testid="title" type="text" disabled={isViewMode(type)} placeholder="Enter title" {...register('title')} />
            </Form.Group>
            <Row className="mx-0 mb-3 p-0">
              <Form.Group className="col-sm-6 ps-0" controlId="formHireDate">
                <Form.Label className="text-dark fw-600 txt-size">Hire Date</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control data-testid="dateOfJoining" type="date" {...register('dateOfJoining')} disabled={isViewMode(type)} />
                </InputGroup>
              </Form.Group>
              <Form.Group className="col-sm-6 ps-0" controlId="formExitDate">
                <Form.Label className="text-dark fw-600 txt-size">Exit Date</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control data-testid="dateOfLeaving" type="date" {...register('dateOfLeaving')} disabled={isViewMode(type)} />
                </InputGroup>
              </Form.Group>
            </Row>
            {!isInRole(lawyers) && !isInRole(cmsAdmin) && (
              <div className="mt-3 border-0">
                <Form.Group className="mb-3" controlId="formClient">
                  <Form.Label className="text-dark fw-600 txt-size">
                    Client <span className="text-danger">*</span>
                  </Form.Label>
                  <Controller
                    data-testid="client"
                    name="client"
                    render={({ field: { ref, ...rest } }) => (
                      <EditAsyncSelect
                        data-testid="client-selector"
                        isDisabled={isViewMode(type)}
                        inputRef={ref}
                        selectStyles="ff-din-DemiBold"
                        type={optionTypes.CLIENTS}
                        defaultValue={defaults.CLIENT}
                        clientId={role}
                        {...rest}
                      />
                    )}
                    control={control}
                    rules={{ required: true }}
                  />
                </Form.Group>
              </div>
            )}
            {!isInRole(partners) && (
              <Form.Group className="mb-3" controlId="departments">
                <Form.Label className="text-dark fw-600 txt-size">Departments</Form.Label>
                <Controller
                  name="departments"
                  render={({ field }) => (
                    <Select isDisabled={depLoading || !client} isLoading={depLoading} className="ff-din-DemiBold" isMulti {...field} options={getFilteredOptions(departments, 'departments')} />
                  )}
                  control={control}
                />
                <div className="d-block invalid-feedback">{errors.departments && errors.departments.message}</div>
              </Form.Group>
            )}
            {isInRole(lawyers) && !isInRole(cmsAdmin) && (
              <div className="mt-3 border-0">
                <Form.Group className="mb-3" controlId="formLawfirm">
                  <Form.Label className="text-dark fw-600 txt-size">
                    Lawfirm <span className="text-danger">*</span>
                  </Form.Label>
                  <Controller
                    data-testid="lawFirm"
                    name="lawFirm"
                    render={({ field: { ref, ...rest } }) => (
                      <EditAsyncSelect
                        isDisabled={isViewMode(type)}
                        // addNone
                        defaultValue={defaults.LAWFIRM}
                        inputRef={ref}
                        selectStyles="ff-din-DemiBold"
                        type={optionTypes.LAWFIRMS}
                        {...rest}
                      />
                    )}
                    control={control}
                    rules={{ required: true }}
                  />
                </Form.Group>
              </div>
            )}

            {isInRole(employees) && (
              <>
                <br />
                <Form.Group className="mb-3" controlId="formEmployeeId">
                  <Form.Label className="text-dark fw-600 txt-size">Employee Id</Form.Label>
                  <Form.Control
                    style={errors.employeeId && { borderColor: 'red' }}
                    type="text"
                    data-testid="employeeId"
                    placeholder="Enter Employee Id"
                    {...register('employeeId', {
                      required: { value: false },
                    })}
                  />
                  <div className="d-block invalid-feedback">{errors.employeeId && errors.employeeId.message}</div>
                </Form.Group>
                <Form.Group controlId="country">
                  <Form.Label className="text-dark fw-600 txt-size">Country</Form.Label>
                  <Controller name="country" render={({ field }) => <Select className="ff-din-DemiBold" {...field} options={getCountriesOptions()} />} control={control} />
                </Form.Group>
                <br />
                <Form.Group className="mb-3" controlId="formLocation">
                  <Form.Label className="text-dark fw-600 txt-size">Location</Form.Label>
                  <Form.Control type="text" data-testid="location" placeholder="Enter Location" {...register('location')} />
                </Form.Group>
                <Form.Group controlId="businessUnits">
                  <Form.Label className="text-dark fw-600 txt-size">Business Units</Form.Label>
                  <Controller
                    name="businessUnits"
                    render={({ field }) => (
                      <Select isDisabled={depLoading || !client} isLoading={depLoading} className="ff-din-DemiBold" isMulti {...field} options={getFilteredOptions(businessUnits, 'businessUnits')} />
                    )}
                    control={control}
                  />
                  <div className="d-block invalid-feedback">{errors.businessUnits && errors.businessUnits.message}</div>
                </Form.Group>
                <br />
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label className="text-dark fw-600 txt-size">Linkedin</Form.Label>
                  <Form.Control
                    style={errors.email && { borderColor: 'red' }}
                    type="linkedin"
                    data-testid="linkedin"
                    placeholder="Enter Linkedin Profile"
                    {...register('linkedin', {
                      required: false,
                      pattern: {
                        value: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/[A-z0-9_-]+\/?/,
                        message: 'Please enter a valid linkedin Link',
                      },
                    })}
                  />
                  <div className="d-block invalid-feedback">{errors.linkedin && errors.linkedin.message}</div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formOthers1">
                  <Form.Label className="text-dark fw-600 txt-size">Others 1</Form.Label>
                  <Form.Control type="others1" data-testid="others1" placeholder="Enter Additional Text" {...register('others1')} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formOthers2">
                  <Form.Label className="text-dark fw-600 txt-size">Others 2</Form.Label>
                  <Form.Control type="others2" data-testid="others2" placeholder="Enter Additional Text" {...register('others2')} />
                </Form.Group>
              </>
            )}
            <Form.Group className="mb-3" controlId="formPhoneNumber">
              <Form.Label className="text-dark fw-600 txt-size">Phone number</Form.Label>
              <Form.Control type="text" placeholder="Enter number" {...register('phone')} disabled={isViewMode(type)} />
            </Form.Group>

            {(isInRole(lawyers) || isInRole([IRoles.EMPLOYEE_MANAGER, IRoles.EMPLOYEE_ADMIN])) && (
              <Form.Group className="mb-3" controlId="formXrayFeaturesPatent">
                <TangiToggle
                  label="Allow access to Patent, Contract and Invention Disclosure X-Ray features"
                  checked={watch('xrayFeaturesAccess.Patent')}
                  setChecked={() => setValue('xrayFeaturesAccess.Patent', !watch('xrayFeaturesAccess.Patent'))}
                />
              </Form.Group>
            )}
            {(isInRole(lawyers) || isInRole([IRoles.EMPLOYEE_MANAGER, IRoles.EMPLOYEE_ADMIN])) && (
              <Form.Group className="mb-3" controlId="formXrayFeaturesDownloadAccess">
                <TangiToggle
                  label="Allow access to Download All Asset's Files"
                  checked={watch('xrayFeaturesAccess.isDownloadAllAssetFiles')}
                  setChecked={() => setValue('xrayFeaturesAccess.isDownloadAllAssetFiles', !watch('xrayFeaturesAccess.isDownloadAllAssetFiles'))}
                />
              </Form.Group>
            )}

            {(isInRole(lawyers) || isInRole([IRoles.EMPLOYEE_MANAGER, IRoles.EMPLOYEE_ADMIN])) && (
              <Form.Group className="mb-3" controlId="formXrayFeaturesContinuationXRayAccess">
                <TangiToggle
                  label="Allow access to Continuation Xray feature"
                  checked={watch('xrayFeaturesAccess.ContinuationXRay')}
                  setChecked={() => setValue('xrayFeaturesAccess.ContinuationXRay', !watch('xrayFeaturesAccess.ContinuationXRay'))}
                />
              </Form.Group>
            )}

            {isAddMode(type) && (
              <Form.Group className="mt-4 mb-2" controlId="formEmailNotify">
                <Form.Label className="text-dark fw-600 txt-size">Email Notification</Form.Label>
                <Form.Check
                  type="checkbox"
                  defaultChecked={checked}
                  onChange={() => setChecked(!checked)}
                  className="ff-din-regular"
                  label="Send an email to the user to let them know their account is active"
                  {...register('sendNotification')}
                />
              </Form.Group>
            )}
            <div className="d-flex">
              <NavLink to="/admin/users" className="my-4 btn px-5 font-weight-bold mr-4 fs-6 btn-secondary  nav-links">
                Back
              </NavLink>
              {!isViewMode(type) && (
                <Button className="ms-2 my-4 btn btn-lg px-5 form-btn btn btn-primary text-white" type="submit" disabled={isLoading}>
                  {isEditMode(type) ? 'Update' : 'Add'}
                </Button>
              )}
            </div>

            <div className={`alert alert-primary fade ${userErrors && userErrors.message ? 'show' : ''} text-center w-100`}>{userErrors?.message}</div>
            <div style={{ backgroundColor: '#4ABB79', borderColor: '#4ABB79' }} className={`alert alert-primary fade ${userStatus === userStatusMsg.UserSuccess ? 'show' : ''} text-center w-100`}>
              User Created Successfully
            </div>
            <div style={{ backgroundColor: '#4ABB79', borderColor: '#4ABB79' }} className={`alert alert-primary fade ${userStatus === userStatusMsg.UpdateSuccess ? 'show' : ''} text-center w-100`}>
              User Updated Successfully
            </div>
          </Form>
        </div>
      </div>
    </Row>
  );
}

export { AdminUsersForm };
