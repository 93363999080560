import { ACCOUNT_ACTIONS } from "utils/enums";


export const getToastTextKeys = (action: ACCOUNT_ACTIONS) => {
  let loadingTextKey, successTextKey, emailTextKey, failureTextKey, headerTextKey;

  if (action === ACCOUNT_ACTIONS.ACTIVATE) {
    loadingTextKey = 'PEOPLE.MULTISELECT.ACTION.ACTIVATING';
    successTextKey = 'PEOPLE.MULTISELECT.ACTION.ACTIVATED';
    emailTextKey = 'PEOPLE.MULTISELECT.ACTION.ACTIVATION_EMAIL';
    failureTextKey = 'PEOPLE.MULTISELECT.ACTION.FAILED_ACTIVATION';
    headerTextKey = 'PEOPLE.MULTISELECT.ACTION.USERS_ACTIVATION';
  } else {
    loadingTextKey = 'PEOPLE.MULTISELECT.ACTION.DEACTIVATING';
    successTextKey = 'PEOPLE.MULTISELECT.ACTION.DEACTIVATED';
    emailTextKey = 'PEOPLE.MULTISELECT.ACTION.DEACTIVATION_EMAIL';
    failureTextKey = 'PEOPLE.MULTISELECT.ACTION.FAILED_DEACTIVATION';
    headerTextKey = 'PEOPLE.MULTISELECT.ACTION.USERS_DEACTIVATION';
  }

  return {
    loadingTextKey,
    successTextKey,
    emailTextKey,
    failureTextKey,
    headerTextKey
  };
};