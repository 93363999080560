import { RootState } from '_helpers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastDataHandler } from '../types';
import { generateOnNotifyToastData } from './utils';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { getAllRecipients } from 'redux-toolkit/thunks/assetMetaDataThunks';

interface Props {
  onSuccess?: () => void;
  handleToastData: ToastDataHandler;
}

export const useHandleNotifiedAssets = ({ onSuccess, handleToastData }: Props) => {
  const { notificationUpdatedAssets, isNotificationUpdateComplete } = useSelector((state: RootState) => state.asset);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isNotificationUpdateComplete) {
      const numOfUpdatedAssets = notificationUpdatedAssets?.length ?? 0;
      const isSuccess = notificationUpdatedAssets.length > 0;

      const toastData = generateOnNotifyToastData(isSuccess, numOfUpdatedAssets);

      handleToastData(toastData);
      isSuccess && onSuccess?.();

      dispatch(assetsActions.setClearAssetNotificationSuccess());
      dispatch(assetsActions.setClearIsNotificationUpdateComplete());
      dispatch(getAllRecipients());
    }
  }, [isNotificationUpdateComplete, notificationUpdatedAssets, onSuccess, handleToastData]);
};
