import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Tippy from '@tippyjs/react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import 'tippy.js/dist/tippy.css';

import { getLawyers, getTags } from '../../../utils/fileUtils';
import { getDataTime } from '../../../utils/dateUtils';
import { TableHeader } from './index';
import Paginations from './Pagination/Paginations';
import user from '../../../assets/img/default_profile.png';
import { adminClientService } from '../../../AdminRedux/_services/adminClientService';
import { adminAssetService } from '../../../AdminRedux/_services/adminAssetService';
import { adminCourseService } from '../../../AdminRedux/_services/adminCourseService';
import { adminClientActions, adminAssetActions, adminAgreementActions, adminUserActions, adminCourseActions } from '../../../AdminRedux/_actions';

function Table({ ITEMS_PER_PAGE, onPageChanged, headers, comments, totalResults, type, param, deleteFirmID, handleDeleteFun, currentPage }) {
  const tableType = {
    CLIENT: 'client',
    LAWFIRM: 'lawfirm',
    ASSET: 'asset',
    ASSET_TAG: 'assettag',
    ASSET_TYPE: 'assettype',
    ASSET_STATUS: 'status',
    ASSET_BV: 'bv',
    AGREEMENT: 'agreement',
    AGREEMENT_TYPE: 'agreementtype',
    COURSE: 'course',
    USER: 'user',
  };
  const [showDelete, setShowDelete] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [deleteItem, setDeleteItem] = useState({ id: '', title: '' });
  const dispatch = useDispatch();

  const { control, reset } = useForm({ mode: 'all' });

  const userID = useWatch({ name: 'user', control: control });

  useEffect(() => {
    // eslint-disable-next-line no-unneeded-ternary
    // setBtnDisable(userID ? false : true);
    setTotalItems(totalResults);
  }, [userID, totalResults]);

  const handleClose = () => {
    setShowDelete(false);
    reset({ user: '' });
  };
  const handleShow = (item) => {
    let title = '';
    switch (type) {
      case tableType.CLIENT:
        title = (
          <div className="ff-din-regular">
            Are you sure you want to delete client <span className="fw-bolder">{item.name}</span>?
          </div>
        );
        setDeleteItem({ id: item.id, title });
        break;
      case tableType.COURSE:
        title = (
          <div className="ff-din-regular">
            Are you sure you want to delete course <span className="fw-bolder">{item.name}</span>?
          </div>
        );
        setDeleteItem({ id: item.id });
        break;

      case tableType.ASSET:
        title = (
          <div className="ff-din-regular">
            Are you sure you want to delete asset <span className="fw-bolder">{item.name}</span>?
          </div>
        );
        setDeleteItem({ id: item.id, title });
        break;

      case tableType.USER:
        title = (
          <div>
            <div className="ff-din-regular">
              Are you sure you want to delete account with email <span className="ff-din-DemiBold">{item.email}</span>
            </div>
            <div className="ff-din-regular">
              From{' '}
              {item?.client?._id ? (
                <span>
                  client <span className="ff-din-DemiBold">{item?.client?.name ?? ''}</span>
                </span>
              ) : (
                <span>
                  law firm <span className="ff-din-DemiBold">{item?.lawFirm?.name ?? ''}</span>
                </span>
              )}
              ?
            </div>
          </div>
        );
        setDeleteItem({ id: item.id, title, clientId: item?.client?._id });
        break;
      case tableType.AGREEMENT:
        title = (
          <div className="ff-din-regular">
            Are you sure you want to delete Agreement {item.agreementType.name} by <span className="fw-bolder">{item?.createdBy?.displayName}</span>?
          </div>
        );
        setDeleteItem({ id: item.id, title });
        break;
      default:
        break;
    }
    setShowDelete(true);
  };

  function handleDelete() {
    setIsSpinner(true);
    switch (type) {
      case tableType.CLIENT:
        adminClientService.deleteAdminClient(deleteItem.id).then(() => {
          setIsSpinner(false);
          handleClose();
          window.location.reload();
        });
        break;
      case tableType.ASSET:
        adminAssetService.deleteAdminAsset(deleteItem.id).then(() => {
          setIsSpinner(false);
          handleClose();
          window.location.reload();
        });
        break;
      case tableType.COURSE:
        adminCourseService.deleteAdminCourse(deleteItem.id).then(() => {
          setIsSpinner(false);
          handleClose();
          window.location.reload();
        });
        break;
      case tableType.USER:
        adminAssetService.deleteAdminUser(deleteItem.id, deleteItem.clientId).then(() => {
          setIsSpinner(false);
          handleClose();
          window.location.reload();
        });
        break;
      case tableType.AGREEMENT:
        adminAssetService.deleteAgreement(deleteItem.id).then(() => {
          setIsSpinner(false);
          handleClose();
          window.location.reload();
        });
        break;

      default:
        break;
    }
  }

  const [totalItems, setTotalItems] = useState(0);
  const disable = false;
  const [sorting, setSorting] = useState({ field: '', order: '' });

  const commentsData = useMemo(() => {
    const computedComments = comments;
    setTotalItems(totalResults);

    return computedComments;
  }, [comments]);

  useEffect(() => {
    if (sorting.field && sorting.order) {
      dispatch(adminClientActions.saveAdminClientSort(sorting));
      switch (type) {
        case tableType.CLIENT:
          dispatch(adminClientActions.getAdminClients(currentPage, '', sorting));
          break;
        case tableType.ASSET:
          dispatch(adminAssetActions.getAdminAsset(currentPage, '', sorting));
          break;
        case tableType.ASSET_TAG:
          dispatch(adminAssetActions.getAdminAssetTags(currentPage, '', sorting));
          break;
        case tableType.ASSET_TYPE:
          dispatch(adminAssetActions.getAdminAssetType(currentPage, '', sorting));
          break;
        case tableType.ASSET_STATUS:
          dispatch(adminAssetActions.getAdminAssetStatus(currentPage, '', sorting));
          break;
        case tableType.ASSET_BV:
          dispatch(adminAssetActions.getAdminAssetBusinessValue(currentPage, '', sorting));
          break;
        case tableType.AGREEMENT:
          dispatch(adminAgreementActions.getAdminAgreement(currentPage, '', sorting));
          break;
        case tableType.AGREEMENT_TYPE:
          dispatch(adminAgreementActions.getAdminAgreementType(currentPage, '', '', sorting));
          break;
        case tableType.LAWFIRM:
          dispatch(adminClientActions.getAdminLawFirm(currentPage, '', sorting));
          break;
        case tableType.USER:
          dispatch(adminUserActions.getAdminUser(currentPage, '', sorting));
          break;
        case tableType.COURSE:
          dispatch(adminCourseActions.getAdminCourse(currentPage, '', sorting));
          break;

        default:
          break;
      }
    }
  }, [sorting]);

  let TableData;
  if (type === 'agreement') {
    TableData = (
      <tbody>
        {commentsData.map((comment, index) => (
          <tr key={index}>
            <th className="ff-din-DemiBold" scope="row" key={comment.id}>
              {comment?.agreementType?.name ?? ' - '}
            </th>
            <td className="ff-din-regular">{comment?.account?.displayName ?? ' - '}</td>
            <td className="ff-din-regular">{comment?.createdBy?.displayName ?? ' - '}</td>
            <td className="ff-din-regular">{getDataTime(comment.createdAt)}</td>
            <td className="ff-din-regular">{comment.client.name}</td>
            <td>
              <div className="ff-din-regular fs-85">
                <Link to={`/admin/agreement/edit/${comment?.account._id ?? ''}`}>
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">Edit</div>}>
                    <i className="fas fa-edit text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </Link>
                &nbsp;<span>|</span>
                <span className="p-1" onClick={() => handleShow(comment)} role="presentation">
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">Delete</div>}>
                    <i className="fas fa-trash-alt text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </span>
                &nbsp;<span>|</span>
                <Link to={`/admin/agreement/view/${comment?.account._id ?? ''}`}>
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">View</div>}>
                    <i className="fas fa-eye text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </Link>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  } else if (type === 'client') {
    TableData = (
      <tbody>
        {commentsData.map((comment) => (
          <tr key={comment.id}>
            <th className="ff-din-DemiBold" scope="row">
              {comment.name}
            </th>
            <td className="ff-din-regular">{comment.lawyers && comment.lawyers.length > 0 ? getLawyers(comment.lawyers) : ''}</td>
            <td className="ff-din-regular">{getDataTime(comment.createdAt)}</td>
            <td className="ff-din-regular">{comment.lawyers && comment.lawyers.length > 0 && comment.lawyers[0].lawFirm.length > 0 ? comment.lawyers[0].lawFirm[0].name : ''}</td>
            <td>
              <div className="ff-din-regular fs-85">
                <a className="p-1" data-testid="editClient" href={`/admin/client/edit/${comment.id}`}>
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">Edit</div>}>
                    <i className="fas fa-edit text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </a>
                &nbsp;<span>|</span>
                <span className="p-1" data-testid="deleteClient" onClick={() => handleShow(comment)} role="presentation">
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">Delete</div>}>
                    <i className="fas fa-trash-alt text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </span>
                &nbsp;<span>|</span>
                <a className="p-1" href={`/admin/client/view/${comment.id}`}>
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">View</div>}>
                    <i className="fas fa-eye text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </a>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  } else if (type === 'lawfirm') {
    TableData = (
      <tbody>
        {commentsData.map((comment) => (
          <tr key={comment.id}>
            <th className="ff-din-DemiBold" scope="row">
              {comment.name}
            </th>
            <td className="ff-din-regular">
              <div className="admin-table-textwrap">{comment.description}</div>
            </td>
            <td className="ff-din-regular">{comment.slug}</td>
            <td className="ff-din-regular">{comment.count ? comment.count : '-'}</td>
            <td>
              <div className="ff-din-regular fs-85">
                <a className="p-1" data-testid="editLawfirm" href={`lawfirm/edit/${comment.id}`}>
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">Edit</div>}>
                    <i className="fas fa-edit text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </a>
                &nbsp;<span>|</span>
                <l
                  data-testid="deleteLawfirm"
                  className="p-1"
                  onClick={() => {
                    deleteFirmID(comment);
                  }}
                >
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">Delete</div>}>
                    <i className="fas fa-trash-alt text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </l>
                &nbsp;<span>|</span>
                <a className=" p-1" href={`lawfirm/view/${comment.id}`}>
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">View</div>}>
                    <i className="fas fa-eye text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </a>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  } else if (type === 'asset') {
    TableData = (
      <tbody>
        {commentsData.map((comment) => (
          <tr key={comment.id}>
            <th className="ff-din-DemiBold" scope="row">
              {comment.name}
            </th>
            <td className="ff-din-regular">{comment.lawyer}</td>
            <td className="ff-din-regular">{comment.tags && comment.tags.length > 0 ? getTags(comment.tags) : ''}</td>
            <td className="ff-din-regular">{getDataTime(comment.createdAt)}</td>
            <td>
              <div className="ff-din-regular fs-85">
                <a className="p-1" href={`/admin/asset/edit/${comment.id}`}>
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">Edit</div>}>
                    <i className="fas fa-edit text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </a>
                &nbsp;<span>|</span>
                <span className="p-1" onClick={() => handleShow(comment)} role="presentation">
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">Delete</div>}>
                    <i className="fas fa-trash-alt text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </span>
                &nbsp;<span>|</span>
                <a className="p-1" href={`/admin/asset/view/${comment.id}`}>
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">View</div>}>
                    <i className="fas fa-eye text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </a>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  } else if (type === 'course') {
    TableData = (
      <tbody>
        {commentsData.map((comment, index) => (
          <tr key={index}>
            <td className="ff-din-regular">{comment.title}</td>
            <td className="ff-din-regular">{comment.difficulty}</td>
            <td className="ff-din-regular">{comment.client?.name || 'All Clients'}</td>
            <td className="ff-din-regular">{getDataTime(comment.createdAt)}</td>
            <td>
              <div className="ff-din-regular fs-85">
                <a className="p-1" data-testid="editCourse" href={`/admin/course&type=editcourse/${comment.id}`}>
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">Edit</div>}>
                    <i className="fas fa-edit text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </a>
                &nbsp;<span>|</span>
                <span className="p-1" data-testid="deleteCourse" onClick={() => handleShow(comment)} role="presentation">
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">Delete</div>}>
                    <i className="fas fa-trash-alt text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </span>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  } else if (type === 'user') {
    TableData = (
      <tbody>
        {commentsData.map((comment) => (
          <tr key={comment.id}>
            <th className="ff-din-DemiBold" scope="row">
              <img src={comment.profilePhoto ? comment.profilePhoto : user} className="rounded-circle" width="32" height="32" alt="" />
              {comment.username}
            </th>
            <td className="ff-din-regular">{comment.email}</td>
            <td className="ff-din-regular">{comment.role && comment.role.name ? comment.role.name : ' - '}</td>
            <td style={{ whiteSpace: 'nowrap', maxWidth: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }} className="ff-din-regular">
              {comment?.client?.name ? comment.client.name : comment?.lawFirm?.name ? comment.lawFirm.name : ' - '}
            </td>
            <td>
              {!(comment.status === 'Disabled') ? (
                <div className="ff-din-regular fs-85">
                  <a className="p-1" data-testid="editUser" href={`/admin/user/edit/${comment.id}`}>
                    <Tippy disabled={disable} content={<div className="font-bold text-xs">Edit</div>}>
                      <i className="fas fa-edit text-secondary" role="button" aria-label="Mute volume" />
                    </Tippy>
                  </a>
                  &nbsp;<span>|</span>
                  <span className="p-1" data-testid="deleteUser" onClick={() => handleShow(comment)} role="presentation">
                    <Tippy disabled={disable} content={<div className="font-bold text-xs">Delete</div>}>
                      <i className="fas fa-trash-alt text-secondary" role="button" aria-label="Mute volume" />
                    </Tippy>
                  </span>
                  &nbsp;<span>|</span>
                  <a className="p-1" href={`/admin/user/view/${comment.id}`}>
                    <Tippy disabled={disable} content={<div className="font-bold text-xs">View</div>}>
                      <i className="fas fa-eye text-secondary" role="button" aria-label="Mute volume" />
                    </Tippy>
                  </a>
                  &nbsp;
                </div>
              ) : (
                <div className="ff-din-regular fs-85">User Disabled</div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    );
  } else if (type === 'type' || type === 'assettype' || type === 'assettag' || type === 'status' || type === 'bv' || type === 'agreementtype') {
    TableData = (
      <tbody>
        {commentsData.map((comment) => (
          <tr key={comment.id}>
            <th className="ff-din-DemiBold" scope="row">
              {comment.name}
            </th>
            <td className="ff-din-regular admin-Table-text">
              <div className="admin-table-textwrap">{comment.description}</div>
            </td>
            <td className="ff-din-regular lowerCase">{comment.slug}</td>
            <td className="ff-din-regular">{comment.count ? comment.count : '-'} </td>
            <td>
              <div className="ff-din-regular fs-85">
                <a className="p-1" href={`${param}edit/${comment.id}`}>
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">Edit</div>}>
                    <i className="fas fa-edit text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </a>
                &nbsp;<span>|</span>
                {handleDeleteFun ? (
                  <l
                    className="p-1"
                    onClick={() => {
                      handleDeleteFun(comment);
                    }}
                  >
                    <Tippy disabled={disable} content={<div className="font-bold text-xs">Delete</div>}>
                      <i className="fas fa-trash-alt text-secondary" role="button" aria-label="Mute volume" />
                    </Tippy>
                  </l>
                ) : (
                  <a className="p-1" href={`${param}delete/${comment.id}`}>
                    <Tippy disabled={disable} content={<div className="font-bold text-xs">Delete</div>}>
                      <i className="fas fa-trash-alt text-secondary" role="button" aria-label="Mute volume" />
                    </Tippy>
                  </a>
                )}
                &nbsp;<span>|</span>
                <a className=" p-1" href={`${param}view/${comment.id}`}>
                  <Tippy disabled={disable} content={<div className="font-bold text-xs">View</div>}>
                    <i className="fas fa-eye text-secondary" role="button" aria-label="Mute volume" />
                  </Tippy>
                </a>
                &nbsp;
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <div className="row w-100">
      <div className="col mb-3 col-12 text-center">
        <table className="table table-striped m-2">
          <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
          {TableData}
        </table>
        {commentsData && commentsData.length === 0 && <div className="p-3 text-center w-100">No results found !</div>}
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 d-flex flex-row-reverse">
            <Paginations totalRecords={totalItems} pageLimit={ITEMS_PER_PAGE} pageNeighbours={1} onPageChanged={onPageChanged} />
          </div>
        </div>
      </div>

      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {deleteItem.title}
          {isSpinner && <Spinner animation="border" size="sm" />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {type === 'user' ? (
            <Button variant="primary" disabled={false} onClick={handleDelete}>
              Delete
            </Button>
          ) : (
            <Button variant="primary" onClick={handleDelete}>
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export { Table };
