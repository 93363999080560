import { LeanAccount, LeanClient } from 'utils/types/activityLog/asset/types';
import { TangiTypography } from '_components/TangiLibrary';
import { Avatar } from '_components/Avatar';
import { AVATAR_SIZES } from 'utils/componentUtils';
import { Container, FlexSpan } from '_components/ActivityLog/style';
import { useTranslation } from 'react-i18next';
import { emptyAvatar } from 'assets/icons';

interface AcknowledgeDataItem {
  from?: string[];
}

interface Props {
  data: AcknowledgeDataItem[];
  initiator: {
    account: LeanAccount | null;
    client: LeanClient;
  };
}

export const AssetActivityAcknowledge = ({ initiator, data }: Props) => {
  const { account, client } = initiator;
  const { t } = useTranslation();

  const ackName = data[0].from?.[0] || data[0].from?.[1] || '';

  const renderByAsAccount = () => {
    return (
      <FlexSpan>
        <FlexSpan>
          <Avatar size={AVATAR_SIZES.XS} name={account?.displayName || ackName} email={account?.user.email || ''} />
          <TangiTypography type="subheading" weight="bold">
            {ackName}
          </TangiTypography>
        </FlexSpan>
        <span>{t('ACTIVITY_LOG.ASSET.ACKNOWLEDGED_ASSET')}</span>
      </FlexSpan>
    );
  };

  const renderByAsClient = () => {
    return (
      <FlexSpan>
        <Avatar size={AVATAR_SIZES.XS} name={client.name} image={emptyAvatar} />
        <TangiTypography type="subheading" weight="bold">
          {client.name}
        </TangiTypography>
        <span>{t('ACTIVITY_LOG.ASSET.ACKNOWLEDGED_ASSET')}</span>
      </FlexSpan>
    );
  };

  return <Container>{account ? renderByAsAccount() : renderByAsClient()}</Container>;
};
