import { HeatMapSerie } from '@nivo/heatmap';

import { CountObject } from 'utils/types/predictedPortfolio/predictedPortfolio';
import { TRANSLATION_KEY } from '../types';
import { HeatmapItem } from './types';
import { CompetitiveAnalysisTableData, CountTsForCompany } from 'utils/types/competitiveAnalysis/competitiveAnalysis';
import { ExtendedDatum, ExtraProps } from '../components/Charts/HeatMapChart/types';
import { PIE_CHART_SHUFFLED } from 'utils/theme';

export const getWidgetsArr = (countObject: CountObject) => {
  return [
    { title: TRANSLATION_KEY.WIDGET_COMPANIES, number: countObject?.total_companies || 0 },
    { title: TRANSLATION_KEY.WIDGET_PATENT, number: countObject?.total_patents || 0 },
    { title: TRANSLATION_KEY.WIDGET_POTENTIAL_TREDE_SECRETS, number: countObject?.total_ts || 0 },
    { title: TRANSLATION_KEY.WIDGET_SUBJECTS, number: countObject?.total_subjects || 0 },
  ];
};

export const calculateGraphSize = (countObject: CountObject) => {
  if (!countObject) {
    return { height: 800, width: 800 };
  }
  const subjectTextHeight = 24;
  const companiesTextWidth = 300;
  const height = countObject?.total_subjects * subjectTextHeight || 800;
  const width = Math.max(countObject?.total_companies * companiesTextWidth, 800);

  return { height, width };
};

export const getHeatMapChartData = (heatMapData: HeatmapItem[]): HeatMapSerie<ExtendedDatum, ExtraProps>[] => {
  return heatMapData?.map((item) => ({
    id: item.subject,
    data: item.groups?.map((group) => ({
      x: group.company,
      y: group.ts_percentage,
      ts_count: group.ts_count,
    })),
  }));
};

/**
 * Generates pie chart data with associated colors based on the number of trade secrets per company.
 * If the number of companies exceeds the number of colors provided, the excess companies are grouped under "Others."
 *
 * @param {Array<{ id: string; value: number; label: string }>} tsCountForCompany - An array of objects representing trade secrets per company.
 * Each object should have the following properties:
 *   - `id` {string}: The unique identifier for the company.
 *   - `value` {number}: The count of trade secrets for the company.
 *   - `label` {string}: The display name for the company.
 * @param {string[]} [colors=PIE_CHART_SHUFFLED] - An array of color strings used for pie chart segments. The last color in the array will be used for the "Others" segment if needed. Defaults to `PIE_CHART_SHUFFLED`.
 *
 * @returns {Array<{ id: string; value: number; label: string; color: string }>} - An array of objects representing the pie chart data.
 * Each object includes:
 *   - `id` {string}: The unique identifier for the segment (either a company or "Others").
 *   - `value` {number}: The value representing the size of the segment.
 *   - `label` {string}: The display name for the segment.
 *   - `color` {string}: The color associated with the segment.
 */
export const getPieChartData = (tsCountForCompany: CountTsForCompany[], colors: string[] = PIE_CHART_SHUFFLED) => {
  const actualColorsLength = colors.length - 1;
  let newItem = { id: '', value: 0, label: '', color: '' };

  // Check if the "Others" category needs to be added due to more companies than colors
  if (tsCountForCompany.length > actualColorsLength) {
    const gap = tsCountForCompany.length - actualColorsLength;
    const lastItems = tsCountForCompany.slice(-gap);
    const sum = lastItems.reduce((a, b) => a + b.value, 0);

    tsCountForCompany = tsCountForCompany.slice(0, tsCountForCompany.length - gap);

    newItem = {
      id: 'others',
      value: sum,
      label: 'Others',
      color: colors[colors.length - 1],
    };
  }

  const tsCountForCompanyWithColor = tsCountForCompany?.map((item, index) => ({
    id: item.id,
    value: item.value,
    label: item.label,
    color: colors[index % (colors.length - 1)],
  }));

  if (newItem.id) {
    tsCountForCompanyWithColor.push(newItem);
  }

  return tsCountForCompanyWithColor;
};

/**
 * Transforms the competitive analysis table data into a specific format.
 *
 * @param {CompetitiveAnalysisTableData} competitiveAnalysisTable - The data object containing competitive analysis information.
 * @returns {Array<Object>} An array of objects, each representing a row in the transformed data format.
 *
 * @typedef {Object} CompetitiveAnalysisTableData
 * @property {Array<CompetitiveAnalysisTableRow>} data - An array of competitive analysis table rows.
 *
 * @typedef {Object} CompetitiveAnalysisTableRow
 * @property {string} trade_secret - The potential trade secret information.
 * @property {string} company - The name of the company.
 * @property {Patent} patent - An object containing patent information.
 * @property {string} patent_year - The year of the patent.
 * @property {Subject} subject - An object containing subject information.
 *
 * @typedef {Object} Patent
 * @property {string} value - The value or identifier of the patent.
 *
 * @typedef {Object} Subject
 * @property {string} value - The value or name of the subject.
 */
export const transformData = (competitiveAnalysisTable: CompetitiveAnalysisTableData) => {
  return competitiveAnalysisTable.data.map((item) => ({
    'Potential trade secrets': item.trade_secret,
    Company: item.company,
    Patent: item.patent.value,
    Year: item.patent_year,
    Subject: item.subject.value,
  }));
};

export const csvConfig = {
  useKeysAsHeaders: true,
  filename: 'portfolio_and_competitors_data',
};
