import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { previewAssetWithOtp } from 'redux-toolkit/thunks/assetsThunks';

import { TangiButton, TangiTypography } from '../../_components/TangiLibrary';
import { Loader } from '../../_components/Loader';
import { OtpScreenPreviewForm } from '../../_components/OtpScreenForm/OtpScreenPreviewForm';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { BOOK_A_DEMO_URL } from '_constants/consts';
import { PreviewAssetsTable } from '../../_components/PreviewAssetsTable';
import { ContentViewContainer } from '../../utils/globalStyles';
import { PreviewAssetsContainer, PageHeaderContainer, SideMenu } from './styles';
import { IRoles, isRoleMatch, regularRoles } from '../../utils/roles';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { requestPreviewOTP } from 'redux-toolkit/thunks/assetsThunks';

import { tangiblyLogo } from '../../assets/img';
import '../PreviewAssetPage/PreviewAssetPage.scss';
import { NEUTRAL_SHADES } from '../../utils/theme';

const PreviewAssets = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const url = new URL(window.location.href);
  const encodeToken = url.searchParams.get('token');

  const [OTPUI, setOTPUI] = useState(true);
  const [isSpinner, setSpinner] = useState(false);
  const { previewPostAsset, loading } = useSelector((state) => state.asset);

  const { loggedIn, activeAccount, user } = useSelector((state) => state.authentication);

  const routeType = url.pathname.includes('/assets/preview') ? 'assets' : 'asset';

  useEffect(() => {
    if (previewPostAsset?.assets?.data?.length === 0) {
      setOTPUI(true);
      dispatch(assetsActions.clearPreviewToken());
      dispatch(assetsActions.clearAssetsForAcknowledgement());
      dispatch(requestPreviewOTP(encodeToken));
    }
  }, [dispatch]);

  useEffect(() => {
    if (previewPostAsset?.assets?.data?.length > 0) {
      setOTPUI(false);
      setSpinner(false);
    } else if (previewPostAsset && previewPostAsset?.assets?.data?.length > 0 && previewPostAsset?.assets?.data[0]?.message) {
      setSpinner(false);
    } else {
      setTimeout(() => {
        setSpinner(false);
      }, 7000);
    }
  }, [previewPostAsset]);

  const isRegularAdmin = (activeAccount) => {
    return (
      [IRoles.CONTRACTOR_ADMIN, IRoles.EMPLOYEE_ADMIN, IRoles.EMPLOYEE_MANAGER].includes(activeAccount?.role?.name) ||
      (isRoleMatch(activeAccount?.role?.name, IRoles.CLIENT_ADMIN) && activeAccount?.client)
    );
  };

  const isNormalUser = (activeAccount) => {
    return regularRoles.includes(activeAccount?.role?.name) && activeAccount?.client?._id;
  };

  const userRedirection = () => {
    if (isRegularAdmin(activeAccount) || isNormalUser(activeAccount)) {
      history.push(generateRoute(AppRoutes.HOME_LOBBY, { clientId: activeAccount?.client?._id }));
      window.location.reload();
    } else {
      history.push(AppRoutes.MAIN);
      window.location.reload();
    }
  };

  const handleRowClick = (row) => {
    if (row.isLocked) return;

    const route = generateRoute(
      AppRoutes.ASSET_PREVIEW,
      { clientId: activeAccount?.client?._id },
      {
        token: encodeToken,
        from: 'previewAssets',
      },
    );

    const payload = {
      assets: {
        metadata: [],
        data: [row.value],
      },
    };
    history.push(route, {
      fullAssetsPayload: previewPostAsset,
    });

    dispatch(assetsActions.setPreviewPostAsset(payload));
  };

  return (
    <PreviewAssetsContainer className="preview-asset">
      {OTPUI ? (
        <div className="w-100">
          <OtpScreenPreviewForm encodeToken={encodeToken} routeType={routeType} />
        </div>
      ) : (
        <>
          <Loader isLoading={isSpinner} />
          <div className="container-fluid">
            <PageHeaderContainer>
              <img width="160" alt="TANGIBLY" src={tangiblyLogo} className="mb-3" />
              <TangiTypography type="heading-lg" weight="semibold" className="mt-4 mb-2">
                List of trade secrets shared with you
              </TangiTypography>
              <TangiTypography className="mt-4 mb-3">
                {!loading && (
                  <>
                    {`${previewPostAsset?.assets?.metadata[0]?.total} `}
                    <span style={{ color: NEUTRAL_SHADES[1100] }}>Assets</span>
                  </>
                )}
              </TangiTypography>
            </PageHeaderContainer>
            <AssetsView assets={previewPostAsset.assets} onRowClicked={handleRowClick} loading={loading} encodeToken={encodeToken} />
          </div>
          <SideMenu>
            <div className="d-flex justify-content-center">
              {loggedIn ? (
                <div className="d-block mt-15">
                  <h4 className="ff-din-regular-2 text-muted text-center">Hi, You are logged in as</h4>
                  <div className="fw-bolder fs-5 text-center">{user?.username}</div>
                  <div>
                    <TangiButton text="Go to Dashboard" onClick={() => userRedirection()} />
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column mt-15">
                  <h3 className="ff-din-regular-2 text-center">Are you ready to start managing your trade secrets?</h3>
                  <br />
                  <h4 className="ff-din-regular-2 mx-4 text-muted text-center">Implement best practice and ensure your company’s most valuable assets are safe</h4>
                  <br />
                  <h4 className="ff-din-regular-2 text-muted text-center">Try Tangibly free for 30 days</h4>
                  <a className="btn btn-lg px-5 btn btn-primary text-white text-center align-self-center" href={BOOK_A_DEMO_URL}>
                    Book A Demo
                  </a>
                </div>
              )}
            </div>
          </SideMenu>
        </>
      )}
    </PreviewAssetsContainer>
  );
};

const AssetsView = ({ assets, onRowClicked, loading, encodeToken }) => {
  const dispatch = useDispatch();
  const { pagination, otp } = useSelector((state) => state.asset);

  useMemo(() => {
    dispatch(previewAssetWithOtp({ token: encodeToken, otp }));
  }, [pagination]);
  return (
    <ContentViewContainer className="assets-content-view-container">
      <PreviewAssetsTable assets={assets} loading={loading} onRowClicked={onRowClicked} pagination={pagination} />
    </ContentViewContainer>
  );
};

export { PreviewAssets };
