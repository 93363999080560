import React from 'react';
import { isFunction } from 'lodash';
import Spinner from 'react-bootstrap/Spinner';

import { TangiSvgIcon } from '../TangiSvgIcon/TangiSvgIcon';
import { BUTTON_VARIANTS, BUTTON_SIZES } from '../../../utils/componentUtils'; //TODO: import from the types from utils/theme.ts
import { StyledIconButton } from './style';

export const TangiIconButton = ({
  isActive = false,
  variant = BUTTON_VARIANTS.PRIMARY,
  size = BUTTON_SIZES.SM,
  disabled = false,
  onClick = () => {},
  className = '',
  icon,
  loading = false,
  dataTestId = 'icon-button',
}) => {
  const handleClick = (event) => {
    event.currentTarget.blur();
    if (isFunction(onClick)) {
      event.stopPropagation();
      event.preventDefault();
      onClick();
    }
  };
  return (
    <StyledIconButton variant={variant} size={size} disabled={disabled || loading} onClick={handleClick} $isActive={isActive} data-testid={dataTestId} className={className}>
      {icon && !loading ? <TangiSvgIcon component={icon} /> : loading && <Spinner animation="border" size="sm" variant="secondary" />}
    </StyledIconButton>
  );
};
