import { RecipientCategoryType } from 'utils/types/assets/assets';
import { Partnership } from 'utils/types/partnership/partnership';
import { BuildSelectOptionsInputData, SelectOption, BaseGroupData, RecipientData, PartnerSelectOption, RecipientSelectOption, GroupSelectOption } from './types';
import { FlexRow } from '../style';
import { AvatarEmail } from '_components/Avatar/AvatarEmail';
import { AVATAR_SIZES } from 'utils/theme';
import { TangiTypography } from '_components/TangiLibrary';
import i18next from 'i18next';
import { Container as GroupContainer } from '../components/CustomSelectValue/styles';

export const buildSelectOptions = ({ recipients, departments, businessUnits, partners }: BuildSelectOptionsInputData): SelectOption[] => [
  ...(recipients.length ? parseRecipients(recipients) : []),
  ...(departments.length ? parseGroup(departments, RecipientCategoryType.DEPARTMENT) : []),
  ...(businessUnits.length ? parseGroup(businessUnits, RecipientCategoryType.BUSINESS_UNIT) : []),
  ...(partners.length ? parsePartnership(partners) : []),
];

const parsePartnership = (partnerships: Partnership[]): PartnerSelectOption[] => {
  return partnerships.map(mapPartnership).filter(({ id }) => id);
};

const parseGroup = (groups: BaseGroupData[], type: RecipientCategoryType.DEPARTMENT | RecipientCategoryType.BUSINESS_UNIT): GroupSelectOption[] => {
  const translationStr = `ASSET_PAGE.${type === RecipientCategoryType.DEPARTMENT ? 'DEPARTMENT' : 'BUSINESS_UNIT'}`;
  return groups
    .filter(({ id }) => id)
    .map(({ id, name }) => ({
      id,
      name,
      type,
      value: name,
      label: (
        <GroupContainer>
          <TangiTypography>{`${name} (${i18next.t(translationStr)})`}</TangiTypography>
        </GroupContainer>
      ),
    }));
};

const parseRecipients = (recipients: RecipientData[]): RecipientSelectOption[] => recipients.map(mapRecipient);

const mapPartnership = ({ _id, name, logo }: Partnership): PartnerSelectOption => ({
  id: _id,
  name,
  logo,
  type: RecipientCategoryType.PARTNER,
  value: name,
  label: (
    <GroupContainer>
      <TangiTypography>{`${name} (${i18next.t('ASSET_PAGE.PARTNERSHIP')})`}</TangiTypography>
    </GroupContainer>
  ),
});

const mapRecipient = ({ id, _id, displayName, email }: RecipientData): RecipientSelectOption => ({
  id: id ?? _id,
  name: displayName,
  value: displayName ?? email,
  type: RecipientCategoryType.INDIVIDUAL,
  label: (
    <FlexRow>
      {displayName && <AvatarEmail size={AVATAR_SIZES.XS} isLinkDisabled name={displayName} email={email} styles="me-2" data-testid="select-label-avatar" image="" />}
      <div>
        {displayName && `${displayName} - `}
        {email}
      </div>
    </FlexRow>
  ),
  email,
});
