import './Header.scss';
import { useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Navbar, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';

import { TangiIconButton } from '_components/TangiLibrary';
import { PolicyDropDown } from '_components/PolicyDropDown/PolicyDropDown';
import MobileHeader from './MobileHeader';
import SwitchClientDropdown from '_components/SwitchClientDropdown/SwitchClientDropdown';
import SwitchAccountDropdown from './components/SwitchAccountDropdown/SwitchAccountDropdown';
import { AuthContext } from '_hocs/Cognito/Auth';
import { history } from '../../_helpers';
import { adminsExceptLearn, settingsRoles, lawyers } from '../../utils/roles';
import { BUTTON_SIZES } from 'utils/componentUtils';
import { AppRoutes } from 'routing/consts';
import { generateRoute } from 'routing/generateRoute';
import { tangiblyLogo } from '../../assets/img';
import { BUTTON_VARIANTS } from 'utils/theme';
import { POLICIES } from '../../utils/types/client/client';
import { StyledNavbar, StyledNavSubmenu } from './style';

const Header = () => {
  const activeAccount = useSelector((state) => state.authentication.activeAccount);
  const Role = useSelector((state) => state.authentication.Role);
  const activeClient = useSelector((state) => state.lawfirm.activeClient);

  const { logout } = useContext(AuthContext);
  const { t } = useTranslation();

  const hasPolicyUploadPermissions = useMemo(() => adminsExceptLearn.includes(Role), [Role]);
  const isLawyer = useMemo(() => lawyers.includes(Role), [Role]);

  const shouldRenderPolicyDropdown = () => {
    const policyFiles = isLawyer ? activeClient?.policyFiles : activeAccount?.client?.policyFiles;
    const shouldRenderPolicyForNotAdmins = !!policyFiles?.[0];
    const shouldRenderDropDown = (hasPolicyUploadPermissions || (!hasPolicyUploadPermissions && shouldRenderPolicyForNotAdmins));
    return shouldRenderDropDown;
  };

  const onSettingsClick = () => {
    const clientId = isLawyer ? activeClient?.id : activeAccount?.client?._id;
    history.push(generateRoute(AppRoutes.SETTINGS, { clientId }));
    window.location.reload();
  };

  const renderSwitchClientDropdown = () => {
    return isLawyer && <SwitchClientDropdown />;
  };

  const isPolicyExists = (policyType) => {
    if (policyType !== POLICIES.LLM && policyType !== POLICIES.TRADE_SECRET) {
      return false;
    }
    const filesStr = policyType === POLICIES.LLM ? 'llmFiles' : 'policyFiles';

    const clientFiles = isLawyer ? activeClient?.[filesStr] : activeAccount?.client?.[filesStr];
    const isPolicy = !!(clientFiles?.length && !clientFiles[0].isDeclaredNoFile);

    return isPolicy;
  };

  const renderPolicyDropdown = () => {
    const isTradeSecretFileExist = isPolicyExists(POLICIES.TRADE_SECRET);
    const isLlmFileExist = isPolicyExists(POLICIES.LLM);

    return (
      shouldRenderPolicyDropdown() && (
        <PolicyDropDown
          hasPolicyUploadPermissions={hasPolicyUploadPermissions}
          isTradeSecretFileExist={isTradeSecretFileExist}
          isLlmFileExist={isLlmFileExist}
          isLawyer={isLawyer}
          activeClientId={isLawyer ? activeClient?.id : activeAccount?.client?.id}
        />
      )
    );
  };

  const renderMobileHeader = () => {
    return (
      <>
        {/*eslint-disable-next-line react/no-unknown-property */}
        <div fluid className="py-1 d-sm-none d-md-none d-lg-none d-xl-none" id="MobileHeaderView">
          <MobileHeader handleLogout={logout} />
        </div>
        {/*eslint-disable-next-line react/no-unknown-property */}
        <div fluid className="py-1 d-none d-sm-block d-md-none d-lg-none d-xl-none" id="MobileHeaderView">
          <MobileHeader handleLogout={logout} />
        </div>
      </>
    );
  };

  const renderSettingsButton = () => {
    if (isLawyer && !activeClient) return null;

    return (
      <Tippy content={t('HEADER.SETTINGS')} placement="bottom">
        <span>
          <TangiIconButton dataTestId="settings-button" size={BUTTON_SIZES.MD} icon="settings" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={onSettingsClick} />
        </span>
      </Tippy>
    );
  };

  return (
    <StyledNavbar collapseOnSelect expand="lg" bg="white" variant="light" className="navbar-expand-md navbar-top border-bottom" fixed="top">
      <div className="d-none d-sm-none d-md-block" id="header-nav-container">
        <Navbar id="responsive-navbar-nav">
          <div>
            <img width="160" alt="TANGIBLY" src={tangiblyLogo} />
          </div>
          <Nav className="me-auto" />
          <StyledNavSubmenu>
            {settingsRoles.includes(Role) && renderSettingsButton()}
            {renderPolicyDropdown()}
            {renderSwitchClientDropdown()}
          </StyledNavSubmenu>
          <SwitchAccountDropdown handleLogout={logout} />
        </Navbar>
      </div>
      {renderMobileHeader()}
    </StyledNavbar>
  );
};

export default Header;
