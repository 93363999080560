/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import usersAdapter from 'redux-toolkit/adapters/usersAdapter';
import { agreementActions } from '_actions';
import { IRoles, lawyers } from 'utils/roles';
import { BatchEmployeesParams, CreateMultipleUsersParams } from 'utils/types/users/users';
import { RolesResponse } from 'utils/types/role/role';
import { UpdateFullNameParams } from 'utils/types/auth/auth';
import {
  AcceptInvitationResponse,
  Account,
  BaseBatchEmployeesResponse,
  BaseUpdateManyResponse,
  HasSeenInformation,
  StartUpdateManyResponse,
  UpdateManyAccountsPayload,
  StartBatchEmployeesResponse,
} from 'utils/types/account/account';
import { ENTITY_TYPES, INTERVAL_IN_MILLISECONDS, JOB_STATUS } from 'utils/enums';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { authConstants, userConstants } from '_constants';
import { AppDispatch, RootState } from '_helpers/store';
import { setActiveClientAndResetPartnerships } from './lawFirmThunks';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { isTypeAccountMinimalData } from 'utils/types/account/guards';

export const sendAlias = createAsyncThunk('/account/aliasId', async (accountId: string) => {
  const res: any = await usersAdapter.sendAlias(accountId);
  return res ?? null;
});

export const createMultipleUsers = createAsyncThunk('/account/inviteMultiple', async ({ data, clientId }: CreateMultipleUsersParams) => {
  const res: any = await usersAdapter.createMultipleUsers({ data, clientId });
  return res ?? null;
});

export const updateFullName = createAsyncThunk('/user/fullname', async ({ userId, fullName, token }: UpdateFullNameParams) => {
  const res: any = await usersAdapter.updateFullName({ userId, fullName, token });
  return res ? fullName : null;
});

export const acceptInvitation = createAsyncThunk<AcceptInvitationResponse, string, { state: RootState; dispatch: AppDispatch }>(
  '/account/acceptInvite',
  async (token: string, { getState, dispatch }) => {
    const currentState: RootState = getState();
    const currentAccRole = currentState?.authentication?.Role;

    // Reset active client if the user is a lawyer
    if (lawyers.includes(currentAccRole)) {
      dispatch(setActiveClientAndResetPartnerships(null));
    }

    // Send request to accept invitation route
    const response = await usersAdapter.acceptInvitation(token);
    const newAccount = response.data;
    const responseStatus = response.status;

    // Partial content http status scenario: User is logged in and invited account belongs to a different user - will be logged out automatically
    // OR a user is not logged in - can continue to accept invitation
    if (responseStatus === 206 && newAccount) {
      return { account: newAccount, status: responseStatus };
    }

    // OK http status scenario: User is logged in and invited account belongs to the same user - set the new account as active in the local storage
    else if (responseStatus === 200 && newAccount && !isTypeAccountMinimalData(newAccount)) {
      updateLocalStorageWithInvitedAccount(dispatch, newAccount);

      // Mix Panel Event: accept invite from email
      if (typeof newAccount.user === 'object') {
        mixpanelEvents.login(currentAccRole, newAccount?.user?.email, newAccount, currentState.lawfirm.activeClient);
      }

      return { account: newAccount, status: responseStatus };
    }

    return { account: null, status: responseStatus };
  },
);

export const deactivateAccount = createAsyncThunk('/account/deactivateAccount', async (accountId: string) => {
  const res: any = await usersAdapter.deactivateAccount(accountId);
  return res ? `${res.displayName} deactivated successfully` : null;
});

export const reactivateAccount = createAsyncThunk('/account/reactivateAccount', async (accountId: string) => {
  const res: any = await usersAdapter.reactivateAccount(accountId);
  return res ? `${res.displayName} reactivated successfully` : null;
});

export const deleteAccount = createAsyncThunk('/account/deleteAccount', async (accountId: string) => {
  const res: any = await usersAdapter.deleteAccount(accountId);
  return res ? `${res.message}` : null;
});

export const batchEmployees = createAsyncThunk('/account/batchEmployees', async ({ data, clientId, isLearn }: BatchEmployeesParams, { dispatch }) => {
  const res: any = await usersAdapter.batchEmployees({ data, clientId, isLearn });
  dispatch(checkbatchEmployeesImportStatus({ jobId: res.jobId, jobStatus: res.jobStatus }));
  return res ?? null;
});

export const checkbatchEmployeesImportStatus = createAsyncThunk<BaseBatchEmployeesResponse, StartBatchEmployeesResponse>(
  '/account/checkbatchEmployeesImportStatus',
  async ({ jobId }, { dispatch }) => {
    const res = await usersAdapter.checkbatchEmployeesImportStatus(jobId);
    const { jobStatus } = res ?? {};

    if (jobStatus === JOB_STATUS.STARTED) {
      setTimeout(() => {
        dispatch(checkbatchEmployeesImportStatus({ jobId, jobStatus }));
      }, INTERVAL_IN_MILLISECONDS.FiveSeconds);
    }
    return res ?? null;
  },
);

export const validateEmployees = createAsyncThunk('/account/validateEmployees', async ({ data, clientId, isLearn, numOfEmployees, startingIndex }: BatchEmployeesParams) => {
  const res: any = await usersAdapter.validateEmployees({ data, clientId, isLearn, startingIndex });
  return res ? { employees: res.employees, errors: res.errors, numOfEmployees} : null;
});

export const getRoles = createAsyncThunk('/users/getRoles', async () => {
  const res: RolesResponse = await usersAdapter.getRoles();
  return res ?? null;
});

export const getUniqueCountries = createAsyncThunk('/account/countries', async () => {
  const res: string[] = await usersAdapter.getUniqueCountries();
  return res ?? null;
});

export const createUser = createAsyncThunk('/account/invite', async ({ user, shouldCreateAgreements = true }: any, { dispatch, rejectWithValue }) => {
  try {
    const account: any = await usersAdapter.createUser(user);
    const { agreements } = user;
    const clientId = account.client;
    const accountId = account.id;
    if (clientId && accountId && shouldCreateAgreements && agreements) {
      dispatch(agreementActions.createAgreementWithFiles(agreements, clientId, accountId, null, ENTITY_TYPES.ACCOUNTS, userSuccess, userFailed));
      return 'User Created Successfully';
    }
    if (clientId && accountId) {
      return 'User Created Successfully';
    }
  } catch (error) {
    // We are using rejectWithValue to pass the server error message to the thunk rejected state
    const axiosError = error as AxiosError<{ message?: string }>;
    const errorMessage = axiosError.response?.data?.message || 'An error occurred while adding the user';
    return rejectWithValue({ message: errorMessage });
  }

  return null;
});

export const updateUser = createAsyncThunk('/account/:id', async ({ user, id }: any, { dispatch }) => {
  const agreementList = Object.prototype.hasOwnProperty.call(user, 'agreements') && user.agreements.length > 0;
  let agreementData;
  if (agreementList) {
    agreementData = user.agreements;
  }
  await usersAdapter.updateUser(user, id);
  if (agreementList) {
    if (agreementData && user.role !== IRoles.PARTNER) {
      agreementData = agreementData.map((agreement: any) => ({ ...agreement, account: user.id }));
    }
    const agreeData = {
      agreement: agreementData || [],
    };
    dispatch(agreementActions.updateAgreementWithFiles(agreeData, user.client, ENTITY_TYPES.ACCOUNTS, id));
  }
  return 'User Updated Successfully';
});

export const updateAccount = createAsyncThunk('/account/:id', async ({ account, id }: { account: Partial<Account>; id: string }): Promise<Account> => {
  const res = await usersAdapter.updateAccount(account, id);
  return res;
});

export const updateHasSeenInformationAccount = createAsyncThunk(
  '/account/:id',
  async ({ hasSeenInformation, role, id }: { hasSeenInformation: HasSeenInformation; role: string; id: string }): Promise<Account> => {
    const res = await usersAdapter.updateAccount({ hasSeenInformation, role }, id);
    return res;
  },
);

// TODO: To Delete when agreementActions migrate to new redux toolkit
function userFailed(error: any) {
  return {
    type: userConstants.USER_FAILURE,
    error,
  };
}

function userSuccess() {
  return {
    type: userConstants.USER_SUCCESS,
    status: 'User Created Successfully',
  };
}

const updateLocalStorageWithInvitedAccount = (dispatch: AppDispatch, newAccount: Account) => {
  const user = loadStateFromLocalStorage(authConstants.USER);
  const accountExistsIndex = user?.accounts?.findIndex((acc: Account) => acc._id === newAccount._id);

  if (accountExistsIndex === -1) {
    user.accounts.push(newAccount);
  } else {
    user.accounts[accountExistsIndex] = newAccount;
  }

  saveStateToLocalStorage(authConstants.ACTIVATE_ACCOUNT, newAccount);
  saveStateToLocalStorage(authConstants.USER, user);
};

export const updateSelectedUsers = createAsyncThunk<StartUpdateManyResponse | null, UpdateManyAccountsPayload, { dispatch: AppDispatch }>(
  '/account/activateSelectedUsers',
  async ({ accounts, allEmployees = false, allContractors = false, filter, searchValue, action }, { dispatch }) => {
    const res = await usersAdapter.updateManyAccounts(accounts, allEmployees, allContractors, filter, searchValue, action);
    dispatch(checkUpdateManyStatus({ jobId: res.jobId, jobStatus: res.jobStatus }));
    return res ? res : null;
  },
);

export const checkUpdateManyStatus = createAsyncThunk<BaseUpdateManyResponse, StartUpdateManyResponse>('/account/checkUpdateManyStatus', async ({ jobId }, { dispatch }) => {
  const res = await usersAdapter.checkUpdateManyStatus(jobId);
  const { jobStatus } = res ?? {};

  if (jobStatus === JOB_STATUS.STARTED) {
    setTimeout(() => {
      dispatch(checkUpdateManyStatus({ jobId, jobStatus }));
    }, INTERVAL_IN_MILLISECONDS.FiveSeconds);
  }
  return res ?? null;
});
