import styled from 'styled-components';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${NEUTRAL_SHADES[600]};
  border-radius: 5px;
  margin-left: ${SPACING[2]};
`;

export const NotFoundContainer = styled.div`
  font-size: 22px;
`;
