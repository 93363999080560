import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import { TangiButton } from '_components/TangiLibrary';
import { previewAssetWithOtp } from 'redux-toolkit/thunks/assetsThunks';
import { Loader } from '../Loader';
import './OtpScreenForm.scss';

function OtpScreenPreviewForm({ encodeToken }) {
  const dispatch = useDispatch();
  const { previewOtpGenerationResult, previewPostAsset, acknowledgementLoading } = useSelector((state) => state.asset);
  const [inputs, setInputs] = useState({
    username: '',
  });
  const [usernametxt, setusernametxt] = useState(false);
  const [resetSuccess, setResetSuccess] = useState('');
  const [resetError, setResetError] = useState('');
  const { username } = inputs;

  useEffect(() => {
    if (previewOtpGenerationResult?.result) {
      setResetSuccess('OTP Generated');
      setTimeout(() => {
        setResetSuccess('');
        setResetError('');
      }, 3000);
    }
  }, [previewOtpGenerationResult]);

  useEffect(() => {
    if (previewPostAsset?.message === 'Preview OTP Code Mismatch' || previewPostAsset?.message === 'OTP is Expired') {
      setResetError(previewPostAsset.message === 'OTP is Expired' ? 'OTP is Expired' : 'Invalid OTP');
      setResetSuccess('');
      setTimeout(() => {
        setResetSuccess('');
        setResetError('');
      }, 3000);
    }
  }, [previewPostAsset]);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === 'username' && !value) {
      setusernametxt(true);
    } else {
      setusernametxt(false);
    }
    setInputs(() => ({ ...inputs, [name]: value }));
  }

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username) {
      setusernametxt(true);
    } else if (username.length < 6) {
      setResetError('OTP code is 6 digit');
    } else {
      setusernametxt(false);
      setResetError('');
      dispatch(previewAssetWithOtp({ token: encodeToken, otp: username }));
    }
  };

  const requestAgain = () => {
    window.location.reload();
  };

  return (
    <div className="justify-content-center row">
      <Loader isLoading={acknowledgementLoading} />
      <div className="col-md-7 col-lg-7">
        <div className="bg-white shadow border-0 card card-login rounded">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="d-flex flex-column align-items-center align-self-center text-center mb-3">
                <h3 className="text-primary font-weight-bold">Please enter the OTP to verify your account</h3>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    className="text-center text-success border-top-0 border-left-0 border-right-0 border-bottom rounded-0 css-fr5ril form-control"
                    onChange={handleChange}
                    onKeyUp={handleKeypress}
                    name="username"
                    maxLength="6"
                    type="text"
                    required
                  />
                  <div className={`invalid-feedback ${usernametxt ? 'd-block' : ''}`}>OTP code is required</div>
                </Form.Group>
                <h6 className="text-muted font-weight-normal p-2">A code has been sent to your registered email. Code received will be valid only for next 15 minutes.</h6>
                <div className="mb-3 form-group">
                  <small className="text-center form-text text-muted">
                    Didn&apos;t receive code? &nbsp;
                    <Link className="card-link text-underline" onClick={() => requestAgain()}>
                      Request again
                    </Link>
                  </small>
                </div>
                <div className="justify-content-center flex-center-v flex-align-m container-action-form-auth">
                  <TangiButton text="submit" onKeyUp={handleKeypress} type="submit" />
                </div>
                <div className={resetError ? 'backgroundError w-100' : ''}>{resetError}</div>
                <div className={resetSuccess ? 'backgroundSucess w-100' : ''}>{resetSuccess}</div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export { OtpScreenPreviewForm };
