import React from 'react';
import { NOTIFICATION_VARIANTS, TangiButton, TangiModal, TangiNotification, TangiTypography } from '_components/TangiLibrary';
import { t } from 'i18next';
import * as Styled from './styles';
import { Trans } from 'react-i18next';
import { ACCOUNT_ACTIONS } from 'utils/enums';
import { getActionTextKeys } from './utils';

interface ConfirmationModalProps {
  action: ACCOUNT_ACTIONS;
  isOpen: boolean;
  activateSelectedUsers: () => void;
  deactivateSelectedUsers: () => void;
  setIsConformationModalOpen: (value: boolean) => void;
  getTotalSelected: () => number;
  isDeactivatedUsersSelected: boolean;
  isActiveUsersSelected: boolean;
  isPendingUsersSelected: boolean;
}

export const ConfirmationModal = ({
  action,
  isOpen,
  activateSelectedUsers,
  deactivateSelectedUsers,
  setIsConformationModalOpen,
  getTotalSelected,
  isDeactivatedUsersSelected,
  isActiveUsersSelected,
  isPendingUsersSelected,
}: ConfirmationModalProps) => {
  const totalSelected = getTotalSelected();
  const hasConditionalNote =
    (action === ACCOUNT_ACTIONS.DEACTIVATE && (isDeactivatedUsersSelected || isPendingUsersSelected)) || (action === ACCOUNT_ACTIONS.ACTIVATE && (isActiveUsersSelected || isPendingUsersSelected));

  const handleConfirm = () => {
    if (action === ACCOUNT_ACTIONS.ACTIVATE) {
      activateSelectedUsers();
    } else if (action === ACCOUNT_ACTIONS.DEACTIVATE) {
      deactivateSelectedUsers();
    }
    setIsConformationModalOpen(false);
  };

  const {
    headerTextKey,
    noteTextKey,
    bodyTextKey,
    reversalNoteTextKey,
    reversalTextKey,
    multipleStatusKey,
    actionButtonTextKey
  } = getActionTextKeys(action);

  return (
    <TangiModal
      dataTestId={`conformation-${action}`}
      show={isOpen}
      onHide={() => setIsConformationModalOpen(false)}
      containerStyle={{ maxWidth: '600px' }}
      header={
        <TangiTypography dataTestId={`total-selected-${totalSelected}`} type="heading-lg">
         {t(headerTextKey, { totalSelected })}
        </TangiTypography>
      }
      body={
        <>
          <TangiNotification variant={NOTIFICATION_VARIANTS.WARNING}>{t('PEOPLE.MULTISELECT.WARNING')}</TangiNotification>
          <Styled.Spacing />
          <TangiTypography type="subheading" weight="semibold">
            {t(noteTextKey)}
          </TangiTypography>
          <TangiTypography type="body">{t(bodyTextKey)}</TangiTypography>
          <Styled.Spacing />
          <TangiTypography type="subheading" weight="semibold">
            {t(reversalNoteTextKey)}
          </TangiTypography>
          <TangiTypography type="body">{t(reversalTextKey)}</TangiTypography>
          <Styled.Spacing />
          {hasConditionalNote && (
            <TangiTypography type="body">
              <Trans t={t} i18nKey={t(multipleStatusKey)} />
            </TangiTypography>
          )}
        </>
      }
      footer={
        <Styled.Footer>
          <TangiButton data-testid={`close-modal`} variant="tertiary" onClick={() => setIsConformationModalOpen(false)} text={t('GENERAL.BUTTONS_TEXT.CANCEL')}/>
          <TangiButton data-testid={`${action}--selected`} variant="primary" onClick={handleConfirm} text={t(actionButtonTextKey)}/>
        </Styled.Footer>
      }
    />
  );
};