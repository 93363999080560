import React, { CSSProperties } from 'react';
import { ToastBody, ToastContainer, ToastFooter, ToastHeader } from './styles';
import { TangiIconButton } from '../TangiIconButton';
import { BUTTON_VARIANTS } from 'utils/theme';

interface TangiItemToastProps {
  header: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
  containerStyle?: CSSProperties;
  dataTestId?: string;
  show: boolean;
  closeIcon?: boolean;
  onHide: () => void;
}

export const TangiItemToast = ({ header, body, footer, containerStyle, dataTestId = 'tangi-multiupdate-item-toast', show, onHide, closeIcon = true }: TangiItemToastProps) => {
  if (!show) return null;

  return (
    <ToastContainer style={containerStyle} data-testid={dataTestId}>
      <ToastHeader>
        {header}
        {closeIcon && <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={onHide} icon="close" />}
      </ToastHeader>
      <ToastBody>{body}</ToastBody>
      {footer && <ToastFooter>{footer}</ToastFooter>}
    </ToastContainer>
  );
};
