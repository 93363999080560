import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

import { CURRENT_APP_VERSION, authConstants } from '_constants/authConstants';
import { RootState } from '_helpers';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { AuthContext } from '_hocs/Cognito/Auth';
import useTokenRefresh from '_hocs/Cognito/useTokenRefresh';
import { LogoutType } from '_services/utils/MixPanel/types';
import { LOCAL_STORAGE_KEYS } from 'utils/token/localStorage';

interface Props {
  children: React.ReactNode;
}

const VersionControlHandler = (props: Props) => {
  const loggedIn = useSelector((state: RootState) => state.authentication.loggedIn);
  const { logout, clearStorageKeepFirstLoginFlag } = useContext(AuthContext);

  useTokenRefresh();

  useEffect(() => {
    const checkAppVersion = () => {
      const storedVersion = loadStateFromLocalStorage(authConstants.APP_VERSION);

      if (loggedIn && storedVersion !== CURRENT_APP_VERSION) {
        const oktaUser = loadStateFromLocalStorage(LOCAL_STORAGE_KEYS.OKTA_USER_TOKENS);
        if (oktaUser) {
          clearStorageKeepFirstLoginFlag();
        } else {
          const message = `Version mismatch or not set. Current version: ${CURRENT_APP_VERSION}, Stored version: ${storedVersion}. Logging out...`;
          Sentry.captureMessage(message, 'info');
          logout({ logoutType: LogoutType.Automatic });
        }
      }

      saveStateToLocalStorage(authConstants.APP_VERSION, CURRENT_APP_VERSION);
    };

    checkAppVersion();
  }, []);

  return props.children;
};

export default VersionControlHandler;
