import { useTranslation } from 'react-i18next';

import { StyledModal, Divider } from '../../utils/globalStyles';
import { ModalContentContainer, StyledModalBody, StyledModalHeader, TitleContainer, StyledModalFooter } from './styles';

import { TangiTypography } from '_components/TangiLibrary';

import { TangiIconButton, TangiButton } from '../TangiLibrary';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';

export const DepartmentNotifyModal = ({ showModal, onClose, onConfirm, isEditMode }) => {
  const { t } = useTranslation();

  return (
    <StyledModal transition="Fade" show={showModal} onHide={onClose} data-testid="assets-notification-modal">
      <ModalContentContainer>
        <StyledModalHeader>
          <TitleContainer>
            <div>{t('PEOPLE.INVITE_FORM.DEPARTMENT_MODAL.TITLE')}</div>
            <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={onClose} icon="close" />
          </TitleContainer>
        </StyledModalHeader>
        <StyledModalBody>
          <TangiTypography type="subheading" className="mb-3">
            {t(isEditMode ? 'PEOPLE.INVITE_FORM.DEPARTMENT_MODAL.SUBTITLE_EDIT' : 'PEOPLE.INVITE_FORM.DEPARTMENT_MODAL.SUBTITLE_ADD')}
          </TangiTypography>
        </StyledModalBody>
        <Divider />
        <StyledModalFooter>
          <TangiButton variant="tertiary" text={t('GENERAL.BUTTONS_TEXT.CANCEL')} size="small" onClick={onClose} smallbtn={true} />
          <TangiButton text={t('GENERAL.BUTTONS_TEXT.SAVE_AND_CONFIRM')} size="small" onClick={onConfirm} smallbtn={true} />
        </StyledModalFooter>
      </ModalContentContainer>
    </StyledModal>
  );
};
