import { Paragraph } from "docx";
import { SuggestedClaims, SubClaim } from "utils/types/patent/patent";


// Maximum depth of recursion when formatting claims
const RECURSION_LIMIT = 8;
// Twips (twentieths of a point or one TAB ) used to set the indentation level in a Word document
export const TWINS_PER_LEVEL = 720;

export function formatClaims(
  claim: SuggestedClaims | SubClaim,
  level = 0,
  maxDepth = RECURSION_LIMIT
): Paragraph[] {
  if (level > maxDepth) {
    return [];
  }

  const formattedClaims: Paragraph[] = [];

  if (level === 0) {
    formattedClaims.push(
      new Paragraph({
        text: '',
        style: 'Normal',
      })
    );
  }
  formattedClaims.push(
    new Paragraph({
      text: claim.text,
      style: 'Normal',
      indent: { left: level * TWINS_PER_LEVEL },
    })
  );

  if (claim.sub_claims && claim.sub_claims.length > 0) {
    for (const subClaim of claim.sub_claims) {
      formattedClaims.push(...formatClaims(subClaim, level + 1));
    }
  }

  return formattedClaims;
}
