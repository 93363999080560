import styled from 'styled-components';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';

export const Divider = styled.div`
  width: 1px;
  height: 34px;
  background-color: ${NEUTRAL_SHADES[300]};
  margin: 0 ${SPACING[2]};
`;

export const SelectedContainer = styled(FlexContainer)`
  cursor: pointer;
`;

export default Divider;
