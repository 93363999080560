import { RootState } from '_helpers';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initialModalData } from './consts';
import { generateOnDeleteToastData } from './utils';
import { DeleteModalData } from './types';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { getAllRecipients } from 'redux-toolkit/thunks/assetMetaDataThunks';
import { DeleteRecipientData } from '../Tables/types';
import { ToastDataHandler } from '../types';

export const useHandleDeleteRecipient = (handleToastData: ToastDataHandler) => {
  const deleteRecipientData = useSelector((state: RootState) => state.asset.deleteRecipientData);
  const [modalData, setModalData] = useState<DeleteModalData>(initialModalData);

  const dispatch = useDispatch();

  useEffect(() => {
    (() => {
      const { message, deleted, error } = deleteRecipientData?.[0] ?? {};
      const { fullName: name, email } = deleted ?? {};

      if (message || error) {
        handleToastData(generateOnDeleteToastData({ name, email, error, message }));
        setModalData(initialModalData);
        dispatch(assetsActions.setClearRecipient());
        dispatch(getAllRecipients());
      }
    })();
  }, [deleteRecipientData]);

  const onClickDeleteRecipient = useCallback(
    (data: DeleteRecipientData) => {
      const { recipientId, assetId } = data;
      setModalData({ show: true, recipientId, assetId });
    },
    [setModalData],
  );

  const onClickCancel = useCallback(() => {
    setModalData(initialModalData);
  }, [setModalData]);

  return { modalData, onClickDeleteRecipient,onClickCancel };
};
