import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { NOTIFICATION_VARIANTS, TANGI_BUTTON_SIZES, TangiButton, TangiIconButton, TangiInputV2, TangiNotification, TangiRadioGroup, TangiTypography } from '_components/TangiLibrary';
import InstructionsTooltip from '../InstructionsTooltip/InstructionsTooltip';
import FolderLocations from '../FolderLocations/FolderLocations';
import { RootState } from '_helpers';
import { FormSchema } from '_components/SharePointWizard/types';
import { Option } from '_components/TangiLibrary/TangiRadioGroup/types';
import { IncludeContent, initialSiteValue, radioOptions, wizardFormDefaultValues } from '_components/SharePointWizard/consts';
import { BUTTON_VARIANTS } from 'utils/theme';
import { TopContainer, AddSiteBtnContainer, SitesContainer, SiteSection, RemoveSiteBtnContainer } from './style';

const ConnectSitesAndFoldersStep = () => {
  const { t } = useTranslation();

  const sitesValidation = useSelector((state: RootState) => state.sharePoint.sitesValidation);
  const isSiteError = useSelector((state: RootState) => !!state.sharePoint.stepsAsyncErrors.ADD_USERS);

  const { watch, control, setError } = useFormContext<FormSchema>();

  const { fields, replace, prepend, remove } = useFieldArray({
    control,
    name: 'sites',
  });

  const options: Option[] = useMemo(() => {
    return radioOptions.map((option) => ({
      id: option.id,
      label: t(option.labelKey),
    }));
  }, [t]);

  const siteValues = watch('sites');
  const isFirstSiteLinkValue = Boolean(watch(`sites.0.siteLink`));

  const handlePrependSite = () => {
    prepend(initialSiteValue);
  };

  const handleRemoveSite = (index: number) => {
    remove(index);
  };

  // Populate the field array with default values on mount
  useEffect(() => {
    if (fields.length === 0) {
      replace(wizardFormDefaultValues.sites);
    }
  }, [fields, replace]);

  useEffect(() => {
    if (sitesValidation) {
      sitesValidation.forEach((site: { isSiteExist: boolean }, siteIndex: number) => {
        if (!site.isSiteExist) {
          setError(`sites.${siteIndex}.siteLink`, {
            type: 'manual',
            message: `${t('SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.ERROR.INVALID_SITE_LINK')}`,
          });
        }
        if (sitesValidation?.[siteIndex]?.folders) {
          sitesValidation[siteIndex].folders.forEach((folder: { isExist: boolean }, index: number) => {
            if (!folder.isExist) {
              setError(`sites.${siteIndex}.folders.${index}.path`, {
                type: 'manual',
                message: `${t('SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.ERROR.INVALID_SITE_OR_FOLDER')}`,
              });
            }
          });
        }
      });
    }
  }, [sitesValidation, setError]);

  return (
    <>
      <TopContainer>
        <div>
          <TangiTypography type="subheading">{t('SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.ALLOW_TRACK_SITES_OR_FOLDERS')}</TangiTypography>
          <TangiTypography type="subheading">{t('SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.INSERT_SITE_LINK_AND_FOLDERS')}</TangiTypography>
        </div>

        {isSiteError && (
          <TangiNotification variant={NOTIFICATION_VARIANTS.ERROR}>
            <TangiTypography weight="semibold" type="subheading">
              {t('SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.ERROR.FINDING_MEMBERS')}
            </TangiTypography>
            <TangiTypography type="subheading">{t('SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.ERROR.INVALID_WEBSITE')}</TangiTypography>
          </TangiNotification>
        )}

        <AddSiteBtnContainer>
          <TangiButton
            size={TANGI_BUTTON_SIZES.SMALL}
            smallbtn
            text={t('SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.BUTTON.ADD_SITE')}
            disabled={!isFirstSiteLinkValue}
            variant={BUTTON_VARIANTS.TERTIARY}
            onClick={handlePrependSite}
            svgIcon="add"
            font={14}
            data-testid="add-site"
          />
        </AddSiteBtnContainer>
      </TopContainer>
      <SitesContainer>
        {fields.map((field, index, array) => {
          const isIncludeFolders = siteValues[index].includeContent === IncludeContent.SPECIFIC_FOLDERS;
          const isTheOnlyElement = array?.length === 1;

          return (
            <SiteSection key={field.id}>
              {!isTheOnlyElement && (
                <RemoveSiteBtnContainer>
                  <TangiIconButton dataTestId="remove-site" variant={BUTTON_VARIANTS.TERTIARY_GREY} icon="close" onClick={() => handleRemoveSite(index)} />
                </RemoveSiteBtnContainer>
              )}
              <TangiInputV2<FormSchema>
                required
                label={t('SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.LABEL.SITE_LINK')}
                name={`sites.${index}.siteLink`}
                placeholder={t('SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.PLACEHOLDER.SITE_LINK')}
                labelIcon
                renderLabelTooltipContent={() => <InstructionsTooltip translationKey="SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.TOOLTIP.SITE_LINK" />}
                labelTooltipPlacement="right"
              />
              <TangiRadioGroup<FormSchema> name={`sites.${index}.includeContent`} label={t('SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.LABEL.GIVE_ACCESS_TO')} options={options} />
              <FolderLocations isIncludeFolders={isIncludeFolders} siteIndex={index} />
            </SiteSection>
          );
        })}
      </SitesContainer>
    </>
  );
};

export default ConnectSitesAndFoldersStep;
