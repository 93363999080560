import { TangiTypography } from '_components/TangiLibrary/TangiTypography';
import { RecipientEntity, RecipientEntityRowData } from '../../types';
import { DateDisplay } from '../DateDisplay/DateDisplay';
import { NEUTRAL_SHADES, SUCCESS_SHADES } from 'utils/theme';
import { TangiSvgIcon } from '_components/TangiLibrary';

interface Props {
  row: RecipientEntityRowData;
}

export const AcknowledgmentDisplay = ({ row }: Props) => {
  const { type } = row;

  return type === RecipientEntity.RECIPIENT ? (
    row.acknowledgedOn ? (
      <DateDisplay date={row.acknowledgedOn} showChecked={true} color={SUCCESS_SHADES[500]} />
    ) : null
  ) : row.acknowledgedNum !== row.recipientsNum ? (
    <TangiTypography color={NEUTRAL_SHADES[800]}>{`${row.acknowledgedNum}/${row.recipientsNum} signed`}</TangiTypography>
  ) : (
    <TangiSvgIcon component="check" color="" />
  );
};
