import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { OptionWrapper, Title, RadioInput, RadioLabel, RadioWrapper, ClearAnswer } from './styles';
import { useTranslation } from 'react-i18next';

export interface RadioOption {
  value: string;
  label: string;
  required?: boolean;
}

export interface Props<T extends FieldValues, K extends Path<T>> {
  groupKey: K;
  options: RadioOption[];
  register: UseFormRegister<T>;
  title: string;
  onClear?: () => void;
  isDisableOnClear?: boolean;
}

export const FormRadiosGroup = <T extends FieldValues, K extends Path<T>>({ options, register, title, groupKey, onClear, isDisableOnClear }: Props<T, K>) => {
  const { t } = useTranslation();
  return (
    <OptionWrapper>
      <Title type="subheading">{t(title)}</Title>
      {options.map(({ value, required, label }) => (
        <RadioWrapper key={label}>
          <RadioLabel key={String(groupKey)}>
            <RadioInput data-cy={`radio-${String(groupKey)}`} type="radio" value={value} {...register(groupKey, { required })} />
            {t(label)}
          </RadioLabel>
        </RadioWrapper>
      ))}
      {onClear && (
        <ClearAnswer onClick={onClear} $isDisable={!!isDisableOnClear}>
          {t('IP_AUDIT.IP_AUDIT_STEPS.CLEAR_ANSWER')}
        </ClearAnswer>
      )}
    </OptionWrapper>
  );
};
