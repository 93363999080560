import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const DeleteModal = ({ title, body, show, loading = false, handleDelete, onClose }) => {
  const [deleteCheckBox, setDeleteCheckBox] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!show && deleteCheckBox) {
      setDeleteCheckBox((prevState) => !prevState);
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onClose} backdrop="static" className="delete-confirm" style={{ zIndex: 1100 }}>
      <Modal.Body>
        <div className="d-flex justify-content-center content-center flex-column gap-1">
          <i className="fa fa-trash fa-3x text-danger my-2" />
          <div className="fs-5">{title}</div>
          <div className="ff-din-regular-2 text-center text-muted">{body}</div>
          <label>
            <input
              type="checkbox"
              data-cy={"delete-checkbox"}
              checked={deleteCheckBox}
              onChange={() => {
                setDeleteCheckBox((prev) => !prev);
              }}
            />
            {t('GENERAL.BUTTONS_TEXT.I_AM_SURE')}
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} onClick={onClose} variant="secondary" className="asset-delete-cancel">
          {t('GENERAL.BUTTONS_TEXT.CANCEL')}
        </Button>
        {loading ? (
          <Spinner className="mx-3" animation="border" variant="primary" />
        ) : (
          <Button disabled={!deleteCheckBox} onClick={handleDelete} className="asset-delete-confirm" data-testid="asset-delete">
            {t('GENERAL.BUTTONS_TEXT.DELETE')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export const AlertModal = ({ type, body, onClose, show }) => (
  <Modal show={show} onHide={onClose} backdrop="static" className="delete-confirm">
    <Modal.Body>
      <div role="alert" className={type === 'success' ? 'alert alert-success' : 'alert alert-danger'}>
        {body}
      </div>
    </Modal.Body>
  </Modal>
);
