import { ReactNode } from 'react';
import { RecipientCategory } from 'utils/types/assets/assets';

export enum CategoryTypes {
  DEPARTMENT = 'department',
  BUSINESS_UNIT = 'businessUnit',
}

export type RenderCategoryRecipients = (category: RecipientCategory, headerTranslation: string) => ReactNode;

export type GroupedCategories = {
  [key: string]: RecipientCategory[];
};
