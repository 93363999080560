import styled from 'styled-components';
import { FlexContainer } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

export const AlternativeVideoLinkContainer = styled(FlexContainer)`
  margin-left: ${SPACING[4]};
  margin-bottom: ${SPACING[3]};
`;

export const customLinkStyles = `
   {
    text-decoration: underline;
    cursor: pointer;
  }
`;
