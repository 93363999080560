import styled from 'styled-components';

export const RightButtonContainer = styled.div`
  max-width: 100px;
  margin-left: auto;
`;

export const TextEllipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 460px;
`;
