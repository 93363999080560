import { useCallback, useMemo, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { ComputedCell, HeatMapSerie } from '@nivo/heatmap';
import i18next from 'i18next';

import { Dashboard } from 'pages/Dashboard';
import HeatMapChart from '../components/Charts/HeatMapChart/HeatMapChart';
import TradeSecretsAnalysisHeadline from '../components/Headline/TradeSecretsAnalysisHeadline';
import { FilterConfigType, TRANSLATION_KEY } from '../types';
import TradeSecretsAnalysisCard from '../components/Card/TradeSecretsAnalysisCard';
import { TangiTypography } from '_components/TangiLibrary';
import WidgetsSection from '../components/Widget/WidgetsSection';
import PieAndBarSkeleton from '../components/PieAndBarSkeleton';
import { RootState } from '_helpers';
import { ExtendedDatum, ExtraProps } from '../components/Charts/HeatMapChart/types';
import { dateFormat } from '../PredictedPortfolio/utils';
import { formatDate } from 'utils/dateUtils';
import { calculateGraphSize, getHeatMapChartData, getPieChartData, getWidgetsArr } from './utils';
import { CompetitiveAnalysisGraphsData } from 'utils/types/competitiveAnalysis/competitiveAnalysis';
import { portfolioTableCustomStyle } from '../PredictedPortfolio/styles';
import PortfolioFilters from '../components/PortfolioFilters/PortfolioFilters';
import { useCompetitiveAnalysis } from './useCompetitiveAnalysis';
import { CompetitiveDataTable } from '../components/CompetitiveDataTable/CompetitiveDataTable';
import EmptyState from '../components/EmptyState/EmptyState';
import { hasIPCompetitivePermission } from 'utils/permissions';
import DemoBanner from '../components/DemoBanner/DemoBanner';
import { PieChart } from 'pages/ClientDashboard/components/Charts';
import { WidgetDataObject } from 'pages/ClientDashboard/components/Widgets/Widgets.types';
import TradeSecretsBySubject from '../components/TradeSecretsBySubject/TradeSecretsBySubject';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { FilterItem, PredictedPortfolioTableData } from 'utils/types/predictedPortfolio/predictedPortfolio';
import { Pagination } from 'utils/types/types';
import { scrollToTop } from 'utils/windowFuncs';
import { NodeType } from '../components/Charts/types';
import { CompetitiveModalData, PortfolioModalFiltersConfig } from '../PredictedPortfolio/PredictedProfolioModal/types';
import { competitiveAnalysisActions } from 'redux-toolkit/slices/competitiveAnalysisSlice';
import CompetitiveModal from '../components/CompetitiveModal/CompetitiveModal';
import { CardsRow, PredictedPortfolioContainer, StyledTradeSecretsAnalysisCard, breakWordTypographyStyles } from './styles';
import { RowContainer } from '../components/Widget/style';
import { ExportButton } from '../styles';

const CompetitiveAnalysis = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [nodeData, setNodeData] = useState<NodeType | null>(null);

  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);
  const client = activeClient ?? activeAccount?.client;
  const isDemoMode = useMemo(() => !hasIPCompetitivePermission(client), [client]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const graphsData: CompetitiveAnalysisGraphsData = useSelector((state: RootState) => state.competitiveAnalysis.graphsData);
  const tableData: PredictedPortfolioTableData = useSelector((state: RootState) => state.competitiveAnalysis.tableData);
  const isGraphLoading = useSelector((state: RootState) => state.competitiveAnalysis.loading.graph);
  const isTableLoading = useSelector((state: RootState) => state.competitiveAnalysis.loading.table);
  const isTableExportLoading = useSelector((state: RootState) => state.competitiveAnalysis.loading.exportTable);
  const error = useSelector((state: RootState) => state.predictedPortfolio.error);
  const pagination = useSelector((state: RootState) => state.competitiveAnalysis.pagination);
  const loading = useSelector((state: RootState) => state.competitiveAnalysis.loading);

  const { searchValue, filter, initialFilter, isFilterApplied, setFilter, setSearchValue, handleClearSearch, handleSearch, handleExportTable } = useCompetitiveAnalysis();

  const heatMapData: HeatMapSerie<ExtendedDatum, ExtraProps>[] = useMemo(() => getHeatMapChartData(graphsData?.graphs?.heatmap), [graphsData?.graphs?.heatmap]);
  const pieData: WidgetDataObject[] = useMemo(() => getPieChartData(graphsData?.companies_ts_count ?? []), [graphsData?.companies_ts_count]);
  const heatMapGraphSize = useMemo(() => calculateGraphSize(graphsData?.count_objects), [graphsData]);
  const widgets = useMemo(() => getWidgetsArr(graphsData?.count_objects), [graphsData]);

  const hasNoGraphData = !isGraphLoading && (!heatMapData?.length || error);
  const hasNoTableData = isTableLoading || !tableData?.data?.length || error;

  const lastUpdate = `${t(TRANSLATION_KEY.HEADER_UPDATE)} ${graphsData?.created_date ? formatDate(graphsData.created_date, dateFormat) : ''}`;
  const cardTitle = !!client?.name && !isDemoMode ? `${client?.name} - ${t(TRANSLATION_KEY.COMPETITIVE_HEADER_TITLE)}` : `${t(TRANSLATION_KEY.COMPETITIVE_HEADER_TITLE)}`;

  useEffect(() => {
    scrollToTop();
    dispatch(mixpanelEvents.enterCompetitivePage());
  }, [activeAccount]);

  const handleOpenModal = useCallback((node: NodeType) => {
    setIsModalOpen(true);
    setNodeData(node);
  }, []);

  const setPagination = (newPagination: Pagination) => {
    dispatch(competitiveAnalysisActions.setPagination(newPagination));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectedNode = (nodeData: NodeType | null): CompetitiveModalData | undefined => {
    if (nodeData) {
      const selectedNode = nodeData as ComputedCell<ExtendedDatum>;
      return {
        subject: selectedNode.serieId,
        potentialTradeSecretsCount: selectedNode.data.ts_count,
        companies: selectedNode.data.x,
        squareColor: selectedNode.color,
      };
    }
    return;
  };

  const handleInitialFilter = () => {
    if (nodeData) {
      const { subjects: mapToOptions, companies } = graphsData?.filters;
      const subjectsOptions = mapToOptions.map((subject: FilterItem) => ({ id: subject.id, displayName: subject.value }));
      const companiesOptions = companies?.map((company) => ({ id: company, displayName: company }));

      if (subjectsOptions) {
        const selectedNode = nodeData as ComputedCell<ExtendedDatum>;
        const subject = subjectsOptions.find((option: { displayName: string }) => option.displayName === selectedNode.serieId);
        const company = companiesOptions.find((option: { displayName: string }) => option.displayName === selectedNode.data.x);

        if (subject && company) {
          return {
            subjects: [{ id: subject.id, value: subject.id }] as [{ id: string; value: string }],
            companies: [{ id: company.id, value: company.displayName }] as [{ id: string; value: string }],
          };
        }
      }
      return undefined;
    }
    return undefined;
  };

  const handleFilterConfig = (): PortfolioModalFiltersConfig | undefined => {
    if (nodeData) {
      return {
        enabledFilters: [FilterConfigType.Patents, FilterConfigType.Years, FilterConfigType.Companies],
      };
    }
    return undefined;
  };

  return (
    <Dashboard title="">
      <CompetitiveModal
        isShow={isModalOpen}
        onClose={handleCloseModal}
        data={handleSelectedNode(nodeData)}
        filtersOptions={graphsData?.filters}
        initialFilter={handleInitialFilter()}
        filterConfig={handleFilterConfig()}
      />
      <TradeSecretsAnalysisHeadline title={cardTitle} lastUpdate={lastUpdate} chipText={TRANSLATION_KEY.HEADER_CHIP} chipTooltip={TRANSLATION_KEY.HEADER_CHIP_TOOLTIP} />
      {hasNoGraphData ? (
        <EmptyState />
      ) : (
        <PredictedPortfolioContainer>
          {isDemoMode && <DemoBanner source="CompetitiveAnalysis" />}

          {!hasNoGraphData && <WidgetsSection widgets={widgets} isCompetitiveAnalysis isLoading={isGraphLoading} />}

          {/* Pie Chart */}
          <CardsRow>
            <StyledTradeSecretsAnalysisCard
              dataTestId="pie-chart-ts-analysis-card"
              isToggleButton={false}
              title={t(TRANSLATION_KEY.COMPETITIVE_BY_COMPANIES_CHART_CARD_TITLE)}
              height="336px"
              width="376px"
            >
              {isGraphLoading ? (
                <PieAndBarSkeleton width={300} height={208} />
              ) : hasNoGraphData || !Boolean(pieData?.length) ? (
                <EmptyState height="200px" />
              ) : (
                <PieChart
                  margin={{ top: 40, right: 0, bottom: 60, left: 0 }}
                  data={pieData}
                  total={graphsData?.count_objects.total_ts}
                  innerRadius={0.7}
                  legendTranslateX={0}
                  legendTranslateY={62}
                  maxItemsPerLegend={3}
                  legendItemWidth={100}
                  maxLegendItemtTextLength={16}
                  totalText={
                    <TangiTypography customStyles={breakWordTypographyStyles}>
                      <Trans>{i18next.t(TRANSLATION_KEY.COMPETITIVE_BY_COMPANIES_CHART_CARD_TOTAL_TEXT)} </Trans>
                    </TangiTypography>
                  }
                />
              )}
            </StyledTradeSecretsAnalysisCard>

            {/* Bar Chart in scollable card*/}
            <TradeSecretsBySubject isLoading={isGraphLoading} />
          </CardsRow>

          {/* HeatMap Chart */}
          <TradeSecretsAnalysisCard isToggleButton={false} title={t(TRANSLATION_KEY.COMPETITIVE_PERCENTAGE_CHART_CARD_TITLE)}>
            {isGraphLoading ? (
              <Skeleton height={600} width={800} count={1} />
            ) : (
              <HeatMapChart height={heatMapGraphSize?.height} width={heatMapGraphSize?.width} data={heatMapData} isCompetitiveChart handleClick={handleOpenModal} />
            )}
          </TradeSecretsAnalysisCard>

          <TangiTypography customStyles={portfolioTableCustomStyle} type="heading-md" weight="semibold">
            {t(TRANSLATION_KEY.COMPETITIVE_TABLE_TITLE)}
          </TangiTypography>

          {/* Filters & Table */}
          <RowContainer>
            <PortfolioFilters
              isCompetitive
              filtersOptions={graphsData?.filters}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              filter={filter}
              setFilter={setFilter}
              initialFilter={initialFilter}
              isFilterApplied={isFilterApplied}
              onClearAll={handleClearSearch}
              handleSearch={handleSearch}
              isTableLoading={isTableLoading}
            />
            <ExportButton
              variant="secondary"
              text={t('COMPETITIVE_ANALYSIS.DATA_TABLE.EXPORT_DATA')}
              onClick={handleExportTable}
              svgIcon="download"
              disabled={isTableExportLoading || hasNoTableData}
              loading={isTableExportLoading}
              smallbtn
            />
          </RowContainer>
          <CompetitiveDataTable tradeSecrets={tableData} pagination={pagination} loading={loading} setPagination={setPagination} />
        </PredictedPortfolioContainer>
      )}
    </Dashboard>
  );
};

export default CompetitiveAnalysis;
