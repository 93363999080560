import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

import AssetTypeColorBlock from '../AssetTypeColorBlock/AssetTypeColorBlock';
import ThirdPartyTag from '_components/ThirdPartyTag/ThirdPartyTag';
import { LOCKED_ASSET, assetTypeNameFormatter } from 'utils/assetTypes';
import { formatDate } from 'utils/dateUtils';
import { TableHeaderText, TableBodyText, DuoContainer } from 'utils/globalStyles';
import { HeaderText, ColorAndType, AssetTagsContainer, NameAndCheckBoxContainer } from './style';

export const createTableColumn = () => {
  const { t } = useTranslation();
  return [
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.NAME')}</TableHeaderText>,
      selector: ({ name }) => name,
      fieldName: 'name',
      maxWidth: '240px',
    },
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.ASSET_TYPE')}</TableHeaderText>,
      selector: ({ assetType }) => assetType,
      fieldName: 'assetType',
    },
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.TAGS')}</TableHeaderText>,
      selector: ({ tags }) => tags,
      fieldName: 'tags',
      minWidth: '240px',
    },
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.CREATION_DATE')}</TableHeaderText>,
      selector: ({ createdAt }) => createdAt,
      fieldName: 'createdAt',
      maxWidth: '144px',
    },
  ];
};

export const makeTableRow = ({ value, handleRowClick }) => {
  const isLocked = value?.needsToBeAcknowledgedByMe || (value?.isThirdParty && !value?.hasAccessToThirdPartyAsset);
  const row = { id: value.id, clientId: value.client._id, isLocked, value };
  return {
    id: value.id,
    clientId: value.client._id,
    isLocked,
    value,
    name: (
      <HeaderText data-testid="table-header" isLocked={isLocked} onClick={() => handleRowClick(row)}>
        <DuoContainer>
          <NameAndCheckBoxContainer>{value.name}</NameAndCheckBoxContainer>
          {value?.isThirdParty && <ThirdPartyTag asset={value} />}
        </DuoContainer>
      </HeaderText>
    ),
    assetType: (
      <ColorAndType isLocked={isLocked} onClick={() => handleRowClick(row)}>
        <AssetTypeColorBlock assetTypeName={isLocked ? LOCKED_ASSET : value?.assetType?.name || ''} />
        <span>{isLocked ? LOCKED_ASSET : value?.assetType?.name ? assetTypeNameFormatter(value.assetType.name) : ''}</span>
      </ColorAndType>
    ),
    tags: (
      <AssetTagsContainer isLocked={isLocked} onClick={() => handleRowClick(row)}>
        {!!value?.tags?.length && (
          <>
            <span>{value.tags[0].name}</span>
            {value.tags.length > 1 && (
              <Tippy
                content={value.tags.map((item, index) => (
                  <div key={index}>{item.name}</div>
                ))}
                placement="top-end"
              >
                <span>+ {value.tags.length - 1}</span>
              </Tippy>
            )}
          </>
        )}
      </AssetTagsContainer>
    ),
    createdAt: <TableBodyText onClick={() => handleRowClick(row)}>{value?.createdAt && !value.needsToBeAcknowledgedByMe ? formatDate(value?.createdAt) : ''}</TableBodyText>,
  };
};
