import { MIME_TYPES, FileTypes } from 'utils/enums';

export const acceptedFilesList = [
  MIME_TYPES.PNG,
  MIME_TYPES.JPEG,
  MIME_TYPES.PDF,
  MIME_TYPES.DOC,
  MIME_TYPES.DOCX,
  MIME_TYPES.XLS,
  MIME_TYPES.XLSX,
  MIME_TYPES.PPT,
  MIME_TYPES.PPTX,
  MIME_TYPES.EML,
].join(', ');

export const uploadAcceptedFormats = [FileTypes.PNG, FileTypes.JPEG, FileTypes.PDF, FileTypes.DOC, FileTypes.DOCX, FileTypes.XLS, FileTypes.XLSX, FileTypes.PPT, FileTypes.PPTX, FileTypes.EML].join(
  ', ',
);

export const isValidFileName = (fileName: string) => {
  const format = /[`!@#$%^&*()+=[\]{};':"\\|,<>/?~]/;
  return !format.test(fileName);
};

export const removeSpecialChars = (fileName: string): string => {
  return fileName.replace(/[!@#$%^&*()+=[\]{};':"\\|,<>/?~]/g, '');
};

export const createNewFile = (file: File, newName: string): File => {
  return new File([file], newName, { type: file.type, lastModified: file.lastModified });
};

/**
 * Processes a file's name by removing special characters and creating a new file with the updated name if necessary.
 *
 * This function checks whether the file's name contains special characters, removes them, and constructs a new file
 * with the updated name. If the file name was modified, the `isModified` flag is set to `true`.
 *
 * @param {File} file - The original file whose name needs to be processed for special character removal.
 * @returns {{ file: File; isModified: boolean }} - An object containing the updated file and a boolean
 * indicating whether the file name was modified.
 */
export const processAndCleanFileName = (file: File): { file: File; isModified: boolean } => {
  const cleanFileName = removeSpecialChars(file.name);
  if (cleanFileName === file.name) {
    return { file, isModified: false };
  }
  const parts = cleanFileName.split('.');
  const name = parts.slice(0, -1).join('.') || 'file';
  const extension = parts.length > 1 ? `.${parts[parts.length - 1]}` : '';
  return { file: createNewFile(file, name + extension), isModified: true };
};

/**
 * Parses and cleans file names to remove special characters.
 * If any file name is modified during the process, the `isModified` flag will be set to `true`.
 *
 * @param {File[]} acceptedFiles - An array of files to be processed.
 * @returns {{ files: File[]; isModified: boolean }} - An object containing the processed files
 * with cleaned file names and a boolean indicating whether any file name was modified.
 */
export const parseAndCleanFileNames = (acceptedFiles: File[]) => {
  const files: File[] = [];
  let isModified = false;
  acceptedFiles.forEach((inputFile) => {
    const { file, isModified: _isModified } = processAndCleanFileName(inputFile);
    files.push(file);
    isModified ||= _isModified;
  });
  return { files, isModified };
};

export const isContainEmlFile = (files: File[]): boolean => {
  return files.some((file) => file.type === MIME_TYPES.EML);
};
