import React, { useCallback, useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TangiCreateableSelector, TangiDatePicker, TangiCheckbox, TangiAlert } from '../TangiLibrary';
import AgreementSkeleton from '../AgreementSkeleton/AgreementSkeleton';
import { getSelectorOptions } from '../../utils/getOptions';
import { MAX_FILE_SIZE_AGREEMENTS_AND_POLICY, LARGE_FILE_CODE_ERROR, ONE_MB } from '_constants/fileConstants';
import { formatLongFileName } from 'utils/fileNameUtils';
import { RESULT_STATUS } from 'utils/enums';
import { Container, DuoContainer, FileDropContainer } from './style';

const MAX_REJECTED_FILENAME_LENGTH = 40;

export const Agreement = ({ files, agreementVersions, agreementIndex, agreement, agreementLoading, versionsLoading, register, handleFiles, handleCreateOption, control }) => {
  const [alertProps, setAlertProps] = useState({
    show: false,
    text: '',
    type: RESULT_STATUS.BLANK,
  });

  const isAgreementSigned = useWatch({
    control,
    name: `agreements[${agreementIndex}].signed`,
  });

  const { t } = useTranslation();

  const resetAlertProps = () => {
    setAlertProps({ show: false, text: '', type: RESULT_STATUS.BLANK });
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      resetAlertProps();
      if (fileRejections.length && fileRejections[0]?.errors[0]?.code === LARGE_FILE_CODE_ERROR) {
        const rejectedFileName = formatLongFileName(fileRejections[0].file.name, MAX_REJECTED_FILENAME_LENGTH);
        const maxAcceptedFileSizeinMb = MAX_FILE_SIZE_AGREEMENTS_AND_POLICY / ONE_MB;
        setAlertProps({ show: true, text: t('GENERAL.UPLOAD_FILES_MODAL.FILE_IS_TOO_LARGE', { FILE_NAME: rejectedFileName, FILE_SIZE: maxAcceptedFileSizeinMb }), type: RESULT_STATUS.ERROR });
      }
      handleFiles(acceptedFiles, fileRejections, `agreements[${agreementIndex}].files`);
    },
    [resetAlertProps, t, handleFiles, agreementIndex],
  );

  // useEffect to reset alert props when isAgreementSigned changes
  useEffect(() => {
    resetAlertProps();
  }, [isAgreementSigned]);

  return (
    <Container>
      {agreementLoading ? (
        <AgreementSkeleton />
      ) : (
        <>
          <TangiCheckbox
            label={agreement.name}
            register={register}
            name={`agreements[${agreementIndex}].signed`}
            id={`signed-checkbox-${agreementIndex + 1}`}
            data-testid={`signed-checkbox-${agreementIndex + 1}`}
          />
          {isAgreementSigned && (
            <>
              <TangiCreateableSelector
                options={getSelectorOptions(agreementVersions)}
                label={t('PEOPLE.LABEL.VERSION')}
                name={`agreements[${agreementIndex}].version`}
                isLoading={versionsLoading}
                onCreateOption={(value) => handleCreateOption(value, 'version', agreementIndex)}
                control={control}
                data-testid="version-select"
              />
              <DuoContainer>
                <TangiDatePicker
                  name={`agreements[${agreementIndex}].signed_date`}
                  label={t('PEOPLE.LABEL.EXECUTED_ON')}
                  register={register}
                  required={isAgreementSigned}
                  disabled={!isAgreementSigned}
                />
                <TangiDatePicker name={`agreements[${agreementIndex}].expiry_date`} label={t('PEOPLE.LABEL.EXPIRES_ON')} register={register} disabled={!isAgreementSigned} />
              </DuoContainer>
              <Dropzone tabIndex="0" onDrop={onDrop} maxSize={MAX_FILE_SIZE_AGREEMENTS_AND_POLICY}>
                {({ getRootProps, getInputProps, acceptedFiles }) => (
                  <>
                    <FileDropContainer {...getRootProps()}>
                      <input {...getInputProps()} />
                      <span className="text-muted fs-6">
                        {t('PEOPLE.AGREEMENT_ADD_FILE.DRAG_AND_DROP')}{' '}
                        <span style={{ cursor: 'pointer' }} className="card-link text-primary text-decoration-underline color-hover">
                          {t('PEOPLE.AGREEMENT_ADD_FILE.CHOOSE_FILE')}
                        </span>
                      </span>
                    </FileDropContainer>
                    {acceptedFiles && acceptedFiles[0] ? (
                      <FileDropContainer>
                        <div className="my-3" width="48" height="100px">
                          <i className="ms-2 far fa-file fs-6 text-primary me-1" />
                          <span className="ff-din-regular-2">{acceptedFiles[0]?.name}</span>
                        </div>
                      </FileDropContainer>
                    ) : (
                      files &&
                      files.find((file) => file.isActive) && (
                        <FileDropContainer>
                          <div className="my-3" width="48" height="100px">
                            <i className="ms-2 far fa-file fs-6 text-primary me-1" />
                            <span className="ff-din-regular-2">{files.find((file) => file.isActive)?.name}</span>
                          </div>
                        </FileDropContainer>
                      )
                    )}
                  </>
                )}
              </Dropzone>
              <TangiAlert {...alertProps} className="error-alert" />
            </>
          )}
        </>
      )}
    </Container>
  );
};
