import styled, { css } from 'styled-components';

import { Props as TangiNotificationProps, NOTIFICATION_VARIANTS } from './TangiNotification';
import { WARNING_SHADES, PRIMARY_SHADES, SUCCESS_SHADES, ERROR_SHADES, SECONDARY_SHADES, SPACING } from '../../../utils/theme';

type StyledNotificationProps = Pick<TangiNotificationProps, 'variant' | 'centered'>;

export const StyledNotification = styled.div<StyledNotificationProps>`
  ${({ variant, centered }: StyledNotificationProps) => css`
    padding: ${SPACING[2.5]};
    border-radius: 4px;
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: ${centered && 'center'};
    ${getVariantProps(variant)}
  `}
`;

export const NotificationBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${SPACING[1]};
  font-size: 16px;
  font-family: 'DIN2014-Regular';
  text-align: start;
`;
export const NotificationTitle = styled.div`
  font-family: 'DIN2014-DemiBold';
  font-size: 16px;
`;

export const RightAnchor = styled.div`
  display: flex;
`;

const getVariantProps = (variant: NOTIFICATION_VARIANTS) => {
  switch (variant) {
    case NOTIFICATION_VARIANTS.INFO:
      return css`
        background-color: ${PRIMARY_SHADES[200]};
        path {
          fill: ${PRIMARY_SHADES[700]};
        }
        border: 1px solid ${PRIMARY_SHADES[800]};
      `;
    case NOTIFICATION_VARIANTS.WARNING:
      return css`
        background-color: ${WARNING_SHADES[50]};
        path {
          fill: ${WARNING_SHADES[400]};
        }
        border: 1px solid ${WARNING_SHADES[500]};
      `;
    case NOTIFICATION_VARIANTS.SUCCESS:
      return css`
        background-color: ${SUCCESS_SHADES[50]};
        path {
          fill: ${SUCCESS_SHADES[400]};
        }
        border: 1px solid ${SUCCESS_SHADES[500]};
      `;
    case NOTIFICATION_VARIANTS.ERROR:
      return css`
        background-color: ${ERROR_SHADES[50]};
        path {
          fill: ${ERROR_SHADES[400]};
        }
        border: 1px solid ${ERROR_SHADES[400]};
      `;
    case NOTIFICATION_VARIANTS.MARKETING:
      return css`
        background-color: ${SECONDARY_SHADES[100]};
        path {
          fill: ${SECONDARY_SHADES[800]};
        }
        border: 1px solid ${SECONDARY_SHADES[400]};
      `;
  }
};
