import { MIME_TYPES } from './enums';

/**
 * Finds the key in the MIME_TYPES enum that matches the given MIME type.
 *
 * @param {string | undefined} mimeType - The MIME type to match (e.g., 'application/pdf').
 * @returns {string | undefined} - The matching enum key in lowercase, or `undefined` if no match is found.
 */
export const getMimeTypeKey = (mimeType: string | undefined): string | undefined => {
  if (!mimeType) return undefined;

  const normalizedMimeType = mimeType.trim().toLowerCase();
  const keys = Object.keys(MIME_TYPES) as Array<keyof typeof MIME_TYPES>;

  const matchingKey = keys.find((key) => MIME_TYPES[key].trim().toLowerCase() === normalizedMimeType);

  return matchingKey?.toLowerCase();
};
