import { TangiSvgIcon } from '_components/TangiLibrary';
import { TangiTypography } from '_components/TangiLibrary/TangiTypography';
import { CSSProperties } from 'react';
import { formatDate } from 'utils/dateUtils';
import { NEUTRAL_SHADES } from 'utils/theme';
import { Container } from './styles';

interface Props {
  date?: string;
  color?: CSSProperties['color'];
  showChecked?: boolean;
}

export const DateDisplay = ({ date, color, showChecked }: Props) => {
  return (
    <>
      {date && (
        <Container>
          <TangiTypography color={color ?? NEUTRAL_SHADES[1100]}>{formatDate(date)}</TangiTypography>
          <div data-testid="checked-icon">{showChecked && <TangiSvgIcon component="check" color="" />}</div>
        </Container>
      )}
    </>
  );
};
