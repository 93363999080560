import { useForm } from 'react-hook-form';
import { NOTIFICATION_VARIANTS, TangiNotification, TangiTypography } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';
import { StepTitle } from '../components/StepTitle/StepTitle';
import { IpAuditActionStepSubmission, IpAuditStepKey } from '_components/IpAudit/types';
import { StepContainer, StepInnerContainer } from '../styles';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { NEUTRAL_SHADES } from 'utils/theme';
import { HrProcessFormValues } from './types';
import { parseValuesToFormValues } from '../HrProcess/utils';
import { useEffect, useState } from 'react';
import { RootState } from '_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { useHandleSteps } from '_components/TangiLibrary/TangiStepper/useHandleSteps';
import { FormRadiosGroup } from '../components/FormRadiosGroup/FormRadiosGroup';
import { useIpAuditPopup } from '../../context/IpAuditPopup.provider';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { formBooleanOptions } from '../components/FormRadiosGroup/consts';
import { QuestionsContainer } from '../components/FormRadiosGroup/styles';
import { useIpAuditContext } from '_components/IpAudit/IpAudit.provider';

export const HrProcess = () => {
  const { setHasStepChanges } = useIpAuditPopup();
  const { t } = useTranslation();
  const { handleStepChange } = useHandleSteps();
  const dispatch = useDispatch();
  const hrProcessAction: IpAuditActionStepSubmission = useSelector((state: RootState) => state.client.ipAudit[IpAuditStepKey.HR_PROCESS]);
  const { getStepCurrentData, submitHrProcess, isStepHasWarning } = useIpAuditContext();
  const values = getStepCurrentData(IpAuditStepKey.HR_PROCESS);
  const [isShowNotification, setIsShowNotification] = useState(!!isStepHasWarning(IpAuditStepKey.HR_PROCESS));

  const {
    handleSubmit,
    register,
    formState: { isValid },
    watch,
    setValue,
  } = useForm<HrProcessFormValues>({
    defaultValues: parseValuesToFormValues(values),
    mode: 'onChange',
  });
  const watchProtocol = watch('protocol');
  const watchTraining = watch('training');

  useEffect(() => {
    if (hrProcessAction.hasData) {
      dispatch(clientActions.clearIpAuditAction(IpAuditStepKey.HR_PROCESS));
      handleStepChange(IpAuditStepKey.LLM_POLICY);
    }
  }, [hrProcessAction.hasData]);

  useEffect(() => {
    const employeeTraining = JSON.stringify(values?.employeeTraining);
    const isIncorporateProtocolsConfidential = JSON.stringify(values?.isIncorporateProtocolsConfidential);
    if (watchProtocol !== isIncorporateProtocolsConfidential || watchTraining !== employeeTraining) setHasStepChanges(true);
    setIsShowNotification(watchProtocol === 'false' || watchTraining === 'false');

    return () => setHasStepChanges(false);
  }, [watchProtocol, watchTraining]);

  const onSubmit = (data: HrProcessFormValues) => {
    const { protocol, training } = data;
    const isIncorporateProtocolsConfidential = protocol === 'true';
    const employeeTraining = training === 'true';
    submitHrProcess({ isIncorporateProtocolsConfidential, employeeTraining });
    dispatch(mixpanelEvents.selectHRProcess(data));
    dispatch(mixpanelEvents.completeIPAuditStep(IpAuditStepKey.HR_PROCESS));
  };

  return (
    <StepContainer>
      <StepInnerContainer>
        <StepTitle stepKey={IpAuditStepKey.HR_PROCESS} />
        <TangiTypography type="subheading" color={NEUTRAL_SHADES[1100]}>
          {t('IP_AUDIT.IP_AUDIT_STEPS.HR_PROCESS.DESCRIPTION')}
        </TangiTypography>
        <form>
          <QuestionsContainer>
            <FormRadiosGroup
              groupKey="protocol"
              options={formBooleanOptions}
              title={'IP_AUDIT.IP_AUDIT_STEPS.HR_PROCESS.PROTOCOL'}
              register={register}
              onClear={() => setValue('protocol', '')}
              isDisableOnClear={!watchProtocol}
            />
            <FormRadiosGroup
              groupKey="training"
              options={formBooleanOptions}
              title={'IP_AUDIT.IP_AUDIT_STEPS.HR_PROCESS.TRAINING'}
              register={register}
              onClear={() => setValue('training', '')}
              isDisableOnClear={!watchTraining}
            />
          </QuestionsContainer>
        </form>
        {isShowNotification && <TangiNotification variant={NOTIFICATION_VARIANTS.WARNING}>{t('IP_AUDIT.IP_AUDIT_STEPS.HR_PROCESS.FALSE_COMPLETION')}</TangiNotification>}
      </StepInnerContainer>
      <StepFooter onSubmit={handleSubmit(onSubmit)} isContinueBtnDisabled={!isValid} isContinueBtnLoading={hrProcessAction.isLoading} />
    </StepContainer>
  );
};
