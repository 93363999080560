import { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { TangiAlert, TangiTypography, NOTIFICATION_VARIANTS } from '_components/TangiLibrary';
import AssetItem from '../AssetItem';
import DropZoneNotification from './DropZoneNotification';
import { RESULT_STATUS } from 'utils/enums';
import { formatLongFileName } from 'utils/fileNameUtils';
import { MAX_FILE_SIZE_ASSETS, LARGE_FILE_CODE_ERROR, ONE_MB } from '_constants/fileConstants';
import { acceptedFilesList, uploadAcceptedFormats, parseAndCleanFileNames, isContainEmlFile } from './utils';
import { NEUTRAL_SHADES } from 'utils/theme';
import { StyledFromGroup, StyledList, NotificationContainer } from './style';

const MAX_ALLOWED_UPLOAD_SIZE_MB = MAX_FILE_SIZE_ASSETS / ONE_MB;

const FilesDropZone = ({ files, setFiles, removeCurrentAsset, control, checkPermissions, errors, filesToAttach }) => {
  const [alertProps, setAlertProps] = useState({
    show: false,
    text: '',
    type: RESULT_STATUS.BLANK,
  });
  const [showModifiedFileWarning, setShowModifiedFileWarning] = useState(false);
  const [showEmlFileWarning, setShowEmlFileWarning] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!files.length) {
      setShowModifiedFileWarning(false);
      setShowEmlFileWarning(false);
    }
  }, [files?.length]);

  const resetAlertProps = () => {
    setAlertProps({ show: false, text: '', type: RESULT_STATUS.BLANK });
  };

  // Check the size of a rejected file and display an alert if a file is too large (checks one file at a time)
  const checkSingleFileSize = (fileRejections) => {
    const maxRejectedFilenameLength = 97;
    if (fileRejections[0]?.errors[0]?.code === LARGE_FILE_CODE_ERROR) {
      const rejectedFileName = formatLongFileName(fileRejections[0].file.name, maxRejectedFilenameLength);
      setAlertProps({ show: true, text: t('GENERAL.UPLOAD_FILES_MODAL.FILE_IS_TOO_LARGE', { FILE_NAME: rejectedFileName, FILE_SIZE: MAX_ALLOWED_UPLOAD_SIZE_MB }), type: RESULT_STATUS.ERROR });
    }
  };

  // Check the size of the combined files and display an alert if the total size is too large
  const checkFilesPayloadSize = (combinedFiles) => {
    const totalSize = combinedFiles.reduce((acc, file) => acc + file.size, 0);
    if (totalSize > MAX_FILE_SIZE_ASSETS) {
      setAlertProps({ show: true, text: t('GENERAL.UPLOAD_FILES_MODAL.FILES_UPLOADED_SHOULD_NOT_EXCEED', { UPLOAD_LIMIT_IN_MB: MAX_ALLOWED_UPLOAD_SIZE_MB }), type: RESULT_STATUS.ERROR });
      return false;
    }
    return true;
  };

  const onDrop = (acceptedFiles, fileRejections) => {
    resetAlertProps();
    if (!!acceptedFiles?.length) {
      const isTotalSizeValid = checkFilesPayloadSize([...files, ...acceptedFiles]);
      if (!isTotalSizeValid) return;

      const isShowEmlFileNotification = isContainEmlFile(acceptedFiles);
      isShowEmlFileNotification && setShowEmlFileWarning(true);

      const { files: parsedFiles, isModified } = parseAndCleanFileNames(acceptedFiles);
      isModified && setShowModifiedFileWarning(true);

      const mergeResult = [...files, ...parsedFiles];
      setFiles(mergeResult);
    }

    fileRejections?.length && checkSingleFileSize(fileRejections);
  };

  useEffect(() => {
    if (filesToAttach?.length) {
      const existingFileNames = new Set(files.map((file) => file.name));
      const newFilesArray = filesToAttach.filter((file) => !existingFileNames.has(file.name));
      if (newFilesArray.length) {
        onDrop(newFilesArray);
      }
    }
    //TODO: check file size?
  }, [filesToAttach]);

  return (
    <StyledFromGroup controlId="assetFiles">
      <TangiTypography weight="bold" type="subheading">
        {t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.ADD_FILES')}
      </TangiTypography>
      {files && files.length > 0 && (
        <section>
          <StyledList>
            {files.map((acceptedFile) => (
              <div key={uuidv4}>
                <AssetItem action="create" asset={acceptedFile} assetType="File" removeAssetFunc={removeCurrentAsset} />
              </div>
            ))}
          </StyledList>
          <NotificationContainer>
            {showEmlFileWarning && (
              <DropZoneNotification variant={NOTIFICATION_VARIANTS.INFO} dataTestId="eml-notification" translationKey="ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.EMAIL_CONTENT_WILL_BE_CONVERTED_TO_PDF" />
            )}
            {showModifiedFileWarning && (
              <DropZoneNotification variant={NOTIFICATION_VARIANTS.WARNING} dataTestId="file-modified-notification" translationKey="ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.FILE_RENAME" />
            )}
          </NotificationContainer>
        </section>
      )}
      <Controller
        render={({ field }) => (
          <Dropzone multiple {...field} accept={acceptedFilesList} disabled={!checkPermissions('UPLOAD_FILES')} onDrop={onDrop} maxSize={MAX_FILE_SIZE_ASSETS}>
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <div>
                {!isDragActive && (
                  <div
                    {...getRootProps()}
                    className="container w-100 bg-secondary p-5 text-center rounded text-muted mt-0 dropZone"
                    style={{ cursor: !checkPermissions('UPLOAD_FILES') ? 'no-drop' : 'pointer' }}
                  >
                    <input {...getInputProps()} />
                    <span>
                      <i aria-hidden="true" className="fas fa-upload me-2" />
                      {t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.DRAG_AND_DROP')}
                    </span>
                  </div>
                )}
                {isDragActive && !isDragReject && (
                  <div
                    {...getRootProps()}
                    className="container w-100 bg-secondary p-5 text-center rounded text-muted dropZone"
                    style={{ cursor: !checkPermissions('UPLOAD_FILES') ? 'no-drop' : 'pointer' }}
                  >
                    <input {...getInputProps()} />
                    <span>
                      <i aria-hidden="true" className="fas fa-upload me-2" />
                      {t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.DROP_FILES')}
                    </span>
                  </div>
                )}
                {isDragActive && isDragReject && (
                  <div
                    {...getRootProps()}
                    className="container w-100 bg-danger p-5 text-center rounded text-white dropZone"
                    style={{ cursor: !checkPermissions('UPLOAD_FILES') ? 'no-drop' : 'pointer' }}
                  >
                    <input {...getInputProps()} />
                    <p style={{ margin: 0 }}>{t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.REJECT_PLEASE_UPLOAD_ONLY_EXCEPTED')}</p>
                  </div>
                )}
              </div>
            )}
          </Dropzone>
        )}
        name="files"
        control={control}
      />
      <TangiTypography color={NEUTRAL_SHADES[900]} customStyles="margin-top: -10px">
        {t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.ACCEPTED_FORMATS_AND_SIZE_LIMIT', { UPLOAD_ACCEPTED_FORMATS: uploadAcceptedFormats, UPLOAD_LIMIT_IN_MB: MAX_ALLOWED_UPLOAD_SIZE_MB })}
      </TangiTypography>
      {errors.files && <div className="d-block invalid-feedback">{errors.files && errors.files.message}</div>}
      <TangiAlert {...alertProps} />
    </StyledFromGroup>
  );
};

export default FilesDropZone;
