import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { Dashboard } from '../Dashboard';
import { TangiButton, TangiBackNavigation } from '_components/TangiLibrary';
import { Loader } from '../../_components/Loader';
import { AlertModal } from '../../_components/Modals';
import QuizAnswer from './components/QuizAnswer/QuizAnswer';
import QuizQuestion from './components/QuizQuestion/QuizQuestion';
import { courseActions, courseProgressionsActions } from '../../_actions';
import { CourseProgressionStatus } from 'utils/types/courses/course';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { QuestionsContainer, NotFoundContainer } from './styles';
import { useAppDispatch } from '_helpers';

const Quiz = () => {
  const quiz = useSelector((state) => state.courseReducer.quiz);
  const quizLoading = useSelector((state) => state.courseReducer.quizLoading);
  const progressionsLoading = useSelector((state) => state.courseProgressionsReducer.loading);
  const user = useSelector((state) => state.authentication.user);

  const dispatch = useAppDispatch();
  const { courseId, clientId } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const [answersState, setAnswersState] = useState({});
  const [modal, setModal] = useState({});
  const [userScore, setUserScore] = useState(null);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  // fetch quiz by course ID
  useEffect(() => {
    if (courseId) {
      dispatch(courseActions.getQuiz(courseId));
    }
  }, [courseId]);

  // submit quiz handler
  const onSubmit = () => {
    const totalQuestion = quiz.questions.length;
    let rightAnswers = 0;
    Object.values(answersState).forEach((question) => {
      if (question.wasUserRight) {
        rightAnswers += 1;
      }
    });

    setUserScore(`${rightAnswers}/${totalQuestion}`);
  };

  const onComplete = async () => {
    try {
      await dispatch(
        courseProgressionsActions.editCourseProgression(courseId, user._id, {
          status: CourseProgressionStatus.COMPLETED,
          score: userScore,
        }),
      );
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      history.push(generateRoute(AppRoutes.TRAINING, { clientId }));
    }
  };

  return (
    <Dashboard title="Quiz">
      {quizLoading ? (
        <Loader isLoading={quizLoading} />
      ) : (
        <Row className="px-39 m-3 mx-3">
          <Col className="col-auto col-sm-12 py-3">
            <TangiBackNavigation navigationPath={generateRoute(AppRoutes.TRAINING, { clientId }, { courseid: courseId })} buttonText={t('QUIZ_PAGE.BUTTON.GO_BACK_TO_COURSE')} />
          </Col>
          <Col className="col-auto col-sm-12">
            <h3 className="h1 font-weight-normal text-blk">{quiz?.courseId?.title}</h3>
          </Col>
          {!userScore ? (
            quiz?.questions?.length ? (
              // Quiz
              <>
                <QuestionsContainer data-testid="questions">
                  {quiz.questions?.map((question, index) => (
                    <QuizQuestion key={index} title={`question #${index + 1}`} questionData={question} setAnswersState={setAnswersState} />
                  ))}
                </QuestionsContainer>
                <Col className="col-auto col-sm-12 py-3">
                  <TangiButton
                    text={t('QUIZ_PAGE.BUTTON.SUBMIT_QUIZ')}
                    onClick={onSubmit}
                    disabled={Object.keys(answersState).length !== quiz?.questions?.length}
                    data-testid="submit-quiz"
                    smallbtn={true}
                  />
                </Col>
              </>
            ) : (
              <NotFoundContainer>
                {t('QUIZ_PAGE.EMPTY_STATE.NO_QUIZ')}
                <br />
                {t('QUIZ_PAGE.EMPTY_STATE.STAY_TUNED')}
              </NotFoundContainer>
            )
          ) : (
            // Quiz answers after submit
            <>
              <QuestionsContainer>
                <h3> {t('QUIZ_PAGE.THANK_YOU_FOR_PARTICIPATING', { USER_SCORE: userScore })}</h3>
                {Object.values(answersState)?.map((questionAndAswer, index) => (
                  <QuizAnswer key={index} title={`question #${index + 1}`} questionAndAswer={questionAndAswer} />
                ))}
              </QuestionsContainer>
              <Col className="col-auto col-sm-12">
                <br />
                <input
                  data-testid="review-answers"
                  type="checkbox"
                  checked={checkboxChecked}
                  onChange={() => {
                    setCheckboxChecked((prevState) => !prevState);
                  }}
                />{' '}
                {t('QUIZ_PAGE.I_HAVE_REVIEWED_THE_ANSWERS')}
              </Col>
              <Col className="col-auto col-sm-12 py-3">
                <TangiButton text={t('QUIZ_PAGE.BUTTON.COMPLETE_QUIZ')} disabled={!checkboxChecked} onClick={onComplete} data-testid="complete-quiz" smallbtn={true} loading={progressionsLoading} />
              </Col>
            </>
          )}

          <AlertModal
            type={modal.type}
            body={modal.body}
            onClose={() => {
              setModal((prevState) => {
                return { ...prevState, show: false };
              });
            }}
            show={modal.show}
          />
        </Row>
      )}
    </Dashboard>
  );
};

export default Quiz;
