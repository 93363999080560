import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { createTableColum, makeTableRow } from './utils';
import TableSkeleton from '../../_components/TableSkeleton/TableSkeleton';
import { AssetTableContainer, customTableStyles, conditionalRowStyles } from './style';
import { useMemo } from 'react';

export const ClientAssetsTable = ({ assets, handleDistributeAsset, loading, assetOptions, handleUnlockAsset, isAdmin, pagination, updateSelectedAssets }) => {
  const Role = useSelector((state) => state.authentication.Role);
  const permissions = useSelector((state) => state.authentication.permissions);
  const selectedAssets = useSelector((state) => state.asset.selectedAssets);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleRowClick = (row) => {
    if (row.isLocked) return;
    history.push(`/client/${row.clientId}/asset/${row.id}`);
  };

  const isCheckboxEnabled = (asset) =>
  !asset.sharePoint &&
  !((asset.isThirdParty && !asset.hasAccessToThirdPartyAsset) || asset?.needsToBeAcknowledgedByMe);


  const handleSelectMultAssets = () => {
    let newSelectedAssets = [];

    if (!isAllVisibleAssetsSelected) {
      newSelectedAssets = [
        ...selectedAssets,
        ...assets?.data?.filter(
          asset =>
          isCheckboxEnabled(asset) && 
            !selectedAssets.some(selAsset => selAsset.id === asset.id) 
        )
      ];
    } else {
      newSelectedAssets = selectedAssets.filter(
        selAsset =>
          !assets?.data?.some(
            asset =>
              isCheckboxEnabled(asset) &&
              asset.id === selAsset.id
          )
      );
    }

    dispatch(assetsActions.setSelectedAssets(newSelectedAssets));
  };

  const isAllVisibleAssetsSelected = useMemo(() => {
    const visibleAssets = assets?.data?.filter(
      asset =>
        isCheckboxEnabled(asset)
    ) || [];
  
    if (visibleAssets.length === 0) {
      return false;
    }
  
    return visibleAssets.every(asset =>
      selectedAssets.some(selAsset => selAsset.id === asset.id)
    );
  }, [assets, selectedAssets]);

  const tableData = assets?.data?.map((value) =>
    makeTableRow({
      value,
      handleDistributeAsset,
      handleUnlockAsset,
      role: Role,
      options: assetOptions,
      permissions,
      isAdmin,
      handleRowClick,
      selectedAssets,
      updateSelectedAssets,
      dispatch,
    }),
  );

  const clientTableViewColumn = createTableColum(permissions, isAllVisibleAssetsSelected, handleSelectMultAssets);

  const handleChangePage = (newPage) => {
    if (newPage !== pagination.page) {
      dispatch(assetsActions.setPagination({ ...pagination, page: newPage }));
    }
  };

  return (
    <AssetTableContainer className="overflow-fix">
      <DataTable
        columns={clientTableViewColumn}
        customStyles={customTableStyles}
        sortIcon={<i className="" />}
        data={tableData}
        progressPending={loading}
        progressComponent={<TableSkeleton />}
        highlightOnHover
        onRowClicked={handleRowClick}
        conditionalRowStyles={conditionalRowStyles}
        fixedHeader={true}
        pagination
        paginationServer
        paginationTotalRows={assets?.metadata[0]?.total || 0}
        paginationPerPage={15}
        paginationDefaultPage={assets?.metadata[0]?.page || 1}
        paginationComponentOptions={{ noRowsPerPage: true }}
        onChangePage={handleChangePage}
      />
    </AssetTableContainer>
  );
};
