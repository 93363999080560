import styled from 'styled-components';

import { TangiTypography } from '_components/TangiLibrary';
import { CourseProgressionStatus } from 'utils/types/courses/course';
import { SUCCESS_SHADES, ERROR_SHADES, NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const CourseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  #required-course {
    color: ${ERROR_SHADES[500]};
    padding: 6px;
    font-size: 14px;
  }
`;

export const CourseStatus = styled.div`
  display: flex;
  gap: ${SPACING[2]};
  color: ${(props) => (props.color === CourseProgressionStatus.COMPLETED ? SUCCESS_SHADES[500] : props.color === CourseProgressionStatus.IN_PROGRESS ? NEUTRAL_SHADES[700] : null)};
  font-size: 14px;
  height: ${SPACING[3.5]};
`;

export const CourseTitle = styled(TangiTypography)`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
