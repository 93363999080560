import styled from "styled-components";
import { FlexContainer } from "utils/globalStyles";
import { SPACING } from "utils/theme";

export const Body = styled(FlexContainer)`
  padding: ${SPACING[4]};
  flex-direction: column;
  gap: ${SPACING[3]};
`;

export const Footer = styled(FlexContainer)`
  padding: ${SPACING[3]};
  justify-content: flex-end;
  gap: ${SPACING[2]};
  button {
    min-width: 100px;
  }
`;

export const Spacing = styled.br`
`;
