import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, FormControl } from 'react-bootstrap';

import './AcknowledgeReceipt.scss';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { getAssetsForAcknowledgement, acknowledgeAndGetPreviewToken } from 'redux-toolkit/thunks/assetsThunks';
import { TangiButton, TangiTypography } from '_components/TangiLibrary';
import { Loader } from '../Loader';
import { Star } from '../Star';
import { InvalidError } from '../InvalidError/InvalidError';
import { getFileExtensionCard, icons } from '../../utils/fileUtils';
import { generateRoute } from 'routing/generateRoute';
import { getDataTime } from '../../utils/dateUtils';
import { RightButtonContainer, TextEllipsis } from './styles';
import { AppRoutes } from 'routing/consts';

function AcknowledgeReceipt() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { assetsForAcknowledgement, postAcknowledge, error, acknowledgementLoading, previewToken } = useSelector((state) => state.asset);
  const disablebtn = assetsForAcknowledgement.length === 0 || acknowledgementLoading;

  const [inputs, setInputs] = useState({ username: null, passcode: null });
  const [usernametxt, setusernametxt] = useState(false);
  const [passcodetxt, setpasscodetxt] = useState(false);

  const { username, passcode } = inputs;
  const urlString = window.location.href;
  const url = new URL(urlString);
  const encodeToken = decodeURIComponent(url.searchParams.get('token'));

  useEffect(() => {
    dispatch(getAssetsForAcknowledgement(encodeToken));
    return () => {
      dispatch(assetsActions.clearAssetsForAcknowledgement());
    };
  }, [dispatch]);

  useEffect(() => {
    if (previewToken?.token) {
      if (assetsForAcknowledgement?.length > 1) {
        history.push(generateRoute(AppRoutes.ASSETS_PREVIEW, {}, { token: previewToken.token }));
      } else {
        history.push(generateRoute(AppRoutes.ASSET_PREVIEW, {}, { token: previewToken.token }));
      }
    }
  }, [previewToken, assetsForAcknowledgement, history]);

  function handleChange(e) {
    const { name } = e.target;
    const { value } = e.target;
    if (name === 'username' && !value) {
      setusernametxt(true);
    } else if (name === 'passcode' && !value) {
      setpasscodetxt(true);
    } else {
      setusernametxt(false);
      setpasscodetxt(false);
    }
    setInputs(() => ({ ...inputs, [name]: value }));
  }

  function handleSubmit() {
    if (!username && !passcode) {
      setusernametxt(true);
      setpasscodetxt(true);
    } else if (!username) {
      setusernametxt(true);
      setpasscodetxt(false);
    } else if (!passcode || passcode.length < 6) {
      setusernametxt(false);
      setpasscodetxt(true);
    } else {
      setusernametxt(false);
      setpasscodetxt(false);
      dispatch(acknowledgeAndGetPreviewToken({ code: passcode, token: encodeToken }));
    }
  }

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <div>
      <Loader isLoading={acknowledgementLoading} />
      <div className={`invalid-feedback ${postAcknowledge && postAcknowledge.message ? 'd-block fs-6 text-center' : ''}`}>{postAcknowledge && postAcknowledge.message}</div>
      {error ? (
        <InvalidError type="acknowledge" />
      ) : (
        <div className="justify-content-center row acknowledge">
          <div className="col-md-7 col-lg-6">
            <div className="bg-white shadow border-0 card rounded">
              <div className="h2 lead card-header">Acknowledge Receipt</div>
              <div className="card-body">
                {assetsForAcknowledgement[0]?.sharedOn && <p className="desc text-muted">Shared on: {getDataTime(assetsForAcknowledgement[0].sharedOn, true)}</p>}
                <div>
                  <div className="d-flex flex-column">
                    {!!assetsForAcknowledgement?.length &&
                      assetsForAcknowledgement.map((asset) => (
                        <React.Fragment key={asset._id}>
                          <TangiTypography type="heading-md" weight="semibold" key={asset._id} className="mb-1">
                            Asset: {asset.name}
                          </TangiTypography>
                          {!!asset?.files &&
                            asset.files.map((file, index) => (
                              <li key={index} style={{ listStyle: 'none', marginBottom: '3%' }} className="border border-black rounded-3">
                                <div className="px-4 py-2">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center justify-content-center">
                                      <i aria-hidden="true" className={`${icons[getFileExtensionCard(file.name)]} me-2 icon-big`} />
                                      <div className="m-2">
                                        <TextEllipsis>{file.name}</TextEllipsis>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}

                          {!!asset?.urls &&
                            asset.urls.map((url, index) => (
                              <li key={index} style={{ listStyle: 'none', marginBottom: '3%' }} className="border border-black rounded-3">
                                <div className="px-4 py-2">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center justify-content-center">
                                      <i aria-hidden="true" className={`${icons.link} me-2 icon-big text-primary`} />
                                      <div className="m-2">
                                        <span>{url.label}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
                <div>
                  <br />
                  <p className="card-text">
                    Please type your passcode below to confirm you acknowledge these are trade secrets, and that you will adhere to all of your confidentiality, employment, and/or service obligations.
                  </p>
                </div>
              </div>

              <div className="card-footer">
                <Form>
                  <input type="text" name="" value="" readOnly={true} style={{ display: 'none' }} />
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="text-dark fw-600">Your Name</Form.Label>
                    <Star />
                    <FormControl
                      disabled={disablebtn}
                      className={usernametxt ? 'border border-invalid' : ''}
                      onKeyUp={handleKeypress}
                      onChange={handleChange}
                      type="text"
                      name="username"
                      placeholder="Enter your name"
                      value={inputs.username}
                      autoComplete="off"
                    />
                    <div className={`invalid-feedback ${usernametxt ? 'd-block' : ''}`}>Name is required.</div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="text-dark fw-600">Your Passcode</Form.Label>
                    <Star />
                    <FormControl
                      disabled={disablebtn}
                      className={passcodetxt ? 'border border-invalid' : ''}
                      maxLength="6"
                      onKeyUp={handleKeypress}
                      onChange={handleChange}
                      name="passcode"
                      type="password"
                      placeholder="Enter your 6 digit passcode"
                      value={inputs.passcode}
                      autoComplete="new-password"
                    />
                    <div className={`invalid-feedback ${passcodetxt ? 'd-block' : ''}`}>Passcode must be 6 digit.</div>
                  </Form.Group>
                  <RightButtonContainer>
                    <TangiButton text="Confirm" disabled={disablebtn} onClick={handleSubmit} />
                  </RightButtonContainer>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export { AcknowledgeReceipt };
