import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import GoToQuiz from './GoToQuiz';
import { TangiIconButton } from '_components/TangiLibrary';
import { Course, IProgression } from 'utils/types/courses/course';
import { courseProgressionsActions } from '../../../_actions';
import { RootState } from '_helpers';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { BackToContainer } from 'utils/globalStyles';
import { BUTTON_VARIANTS } from 'utils/componentUtils';

interface Props {
  course: Course;
  courseProgression: IProgression | null;
}

export const LearnSingleCourse = ({ course, courseProgression }: Props) => {
  const user = useSelector((state: RootState) => state.authentication.user);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { clientId } = useParams<{ clientId: string }>();

  // If courseProgressions is not found -> create a new process
  useEffect(() => {
    if (!courseProgression) {
      dispatch(courseProgressionsActions.createCourseProgression(course.id, user._id));
    }
  }, [courseProgression]);

  const handleBackToCourses = () => {
    window.location.href = generateRoute(AppRoutes.TRAINING, { clientId });
  };

  return (
    <Row className="px-39 m-3 mx-3">
      <Col className="col-auto col-sm-12 p-0 py-3">
        <BackToContainer>
          <TangiIconButton data-testid="back-navigation-button" icon="arrowLeft" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={handleBackToCourses} />
          <span>{t('LEARN_PAGE.GO_BACK_TO_COURSES')}</span>
        </BackToContainer>
      </Col>

      <Col className="col-auto col-sm-12">
        <h3 className="h1 font-weight-normal text-blk">{course.title}</h3>
      </Col>

      <Card style={{ padding: '0' }}>
        <ReactPlayer
          data-testid="player"
          width="100%"
          height="500px"
          url={course.featuredVideoUrl}
          controls
          config={{
            youtube: {
              playerVars: { modestbranding: 1 },
            },
          }}
        />

        <Card.Body className="p-4 mt-2">
          <Row>
            <Col sm={8}>
              <Card.Text>{course.description}</Card.Text>
            </Col>
            <Col sm={4}>
              {/* We are currently show only one alternative link */}
              <GoToQuiz courseId={course.id} alternativeLink={course?.alternativeVideoUrls?.[0] || null} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default LearnSingleCourse;
