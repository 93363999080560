import { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ComputedCell, HeatMapSerie } from '@nivo/heatmap';
import Skeleton from 'react-loading-skeleton';
import { ComputedNode } from '@nivo/treemap';

import { Dashboard } from 'pages/Dashboard';
import TradeSecretsAnalysisHeadline from '../components/Headline/TradeSecretsAnalysisHeadline';
import { FilterConfigType, TRANSLATION_KEY } from '../types';
import WidgetsSection from '../components/Widget/WidgetsSection';
import TradeSecretsAnalysisCard from '../components/Card/TradeSecretsAnalysisCard';
import TreeMapChart from '../components/Charts/TreeMapChart/TreeMapChart';
import { getHeatMapChartData } from '../components/Charts/HeatMapChart/utils';
import HeatMapChart from '../components/Charts/HeatMapChart/HeatMapChart';
import { CHART_OPTIONS } from '../components/Card/type';
import { RootState } from '_helpers';
import { TangiTypography } from '_components/TangiLibrary';
import { PortfolioDataTable } from '../components/PortfolioDataTable/PortfolioDataTable';
import PortfolioFilters from '../components/PortfolioFilters/PortfolioFilters';
import { usePredictedPortfolio } from './usePredictedPortfolio';
import { FilterItem, PredictedPortfolioGraphsData, PredictedPortfolioTableData } from 'utils/types/predictedPortfolio/predictedPortfolio';
import { Pagination } from 'utils/types/types';
import { calculateGraphSize, dateFormat, getWidgetsArr } from './utils';
import { ExtendedDatum, ExtraProps } from '../components/Charts/HeatMapChart/types';
import { formatDate } from 'utils/dateUtils';
import { ChartContainer } from '../components/Charts/styles';
import EmptyState from '../components/EmptyState/EmptyState';
import { hasIPPortfolioPermission } from 'utils/permissions';
import DemoBanner from '../components/DemoBanner/DemoBanner';
import { RowContainer } from '../components/Widget/style';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import PredictedPortfolioModal from './PredictedProfolioModal/PredictedProtfolioModal';
import { scrollToTop } from 'utils/windowFuncs';
import { predictedPortfolioActions } from 'redux-toolkit/slices/predictedPortfolioSlice';
import { NodeType } from '../components/Charts/types';
import { TradeSecretsSubjectData } from '../components/Charts/TreeMapChart/types';
import { PortfolioModalData, PortfolioModalFiltersConfig } from './PredictedProfolioModal/types';
import { PredictedPortfolioContainer, portfolioTableCustomStyle } from './styles';
import { ExportButton } from '../styles';

const PredictedPortfolio = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [nodeData, setNodeData] = useState<NodeType | null>(null);
  const [currentChart, setCurrentChart] = useState<CHART_OPTIONS>(CHART_OPTIONS.TREE);

  const pagination = useSelector((state: { predictedPortfolio: { pagination: number } }) => state.predictedPortfolio.pagination);
  const isGraphLoading = useSelector((state: RootState) => state.predictedPortfolio.loading.graph);
  const isTableLoading = useSelector((state: RootState) => state.predictedPortfolio.loading.table);
  const isTableExportLoading = useSelector((state: RootState) => state.predictedPortfolio.loading.exportTable);
  const error = useSelector((state: RootState) => state.predictedPortfolio.error);
  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);
  const graphsData: PredictedPortfolioGraphsData = useSelector((state: RootState) => state.predictedPortfolio.graphsData);
  const tableData: PredictedPortfolioTableData = useSelector((state: RootState) => state.predictedPortfolio.tableData);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const heatMapData: HeatMapSerie<ExtendedDatum, ExtraProps>[] = getHeatMapChartData(graphsData?.graphs?.heatmap);
  const lastUpdate = `${t(TRANSLATION_KEY.HEADER_UPDATE)} ${graphsData?.created_date ? formatDate(graphsData.created_date, dateFormat) : ''}`;

  const hasNoGraphData = !isGraphLoading && (!heatMapData?.length || error);
  const hasNoTableData = isTableLoading || !tableData?.data?.length || error;
  const client = activeClient ?? activeAccount.client;

  const isDemoMode = useMemo(() => !hasIPPortfolioPermission(client), [client]);
  const heatMapGraphSize = useMemo(() => calculateGraphSize(graphsData?.count_objects), [graphsData]);
  const widgets = useMemo(() => getWidgetsArr(graphsData?.count_objects), [graphsData]);

  const cardTitle = !!client?.name && !isDemoMode ? `${client?.name} - ${t(TRANSLATION_KEY.HEADER_TITLE)}` : `${t(TRANSLATION_KEY.HEADER_TITLE)}`;

  const handleOpenModal = useCallback((node: NodeType) => {
    setIsModalOpen(true);
    setNodeData(node);
  }, []);

  const renderChart = useMemo(
    () =>
      currentChart === CHART_OPTIONS.TREE ? (
        <TreeMapChart handleClick={handleOpenModal} />
      ) : (
        <HeatMapChart height={heatMapGraphSize.height} width={heatMapGraphSize.width} data={heatMapData} handleClick={handleOpenModal} />
      ),
    [heatMapGraphSize, heatMapData, currentChart],
  );

  const { searchValue, filter, initialFilter, isFilterApplied, setFilter, setSearchValue, handleClearSearch, handleSearch, handleExportTable } = usePredictedPortfolio();

  const handleInitialFilter = (): Record<string, [{ id: string; value: string }]> | undefined => {
    if (nodeData) {
      const { subjects: mapToOptions, patents } = graphsData?.filters;
      const subjectsOptions = mapToOptions?.map((subject: FilterItem) => ({ id: subject.id, displayName: subject.value }));
      const patentsOptions = patents?.map((patent: FilterItem) => ({ id: patent.id, displayName: patent.value }));

      if (currentChart === CHART_OPTIONS.TREE) {
        const subject = subjectsOptions?.find((option: { displayName: string }) => option.displayName === nodeData.id);

        if (subject) {
          return { subjects: [{ id: subject.id, value: subject.id }] };
        }
      } else if (currentChart === CHART_OPTIONS.HEAT) {
        const heatNodeData = nodeData as ComputedCell<ExtendedDatum>;
        const subject = subjectsOptions?.find((option: { displayName: string }) => option.displayName === heatNodeData.serieId);
        const patent = patentsOptions?.find((option: { displayName: string }) => option.displayName === heatNodeData.data.x);

        if (subject && patent) {
          return {
            subjects: [{ id: subject.id, value: subject.id }],
            patents: [{ id: patent.id, value: patent.id }],
          };
        }
      }
    }
    return undefined;
  };

  useEffect(() => {
    scrollToTop();
    dispatch(mixpanelEvents.enterPredictedPage());
  }, [activeAccount]);

  const handleToggle = (chart: CHART_OPTIONS) => {
    setCurrentChart(chart);
  };

  const renderSkeleton = () => {
    return (
      <ChartContainer>
        <Skeleton height={500} width={800} count={1} />
      </ChartContainer>
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectedNode = (nodeData: NodeType | null): PortfolioModalData | undefined => {
    if (nodeData) {
      const selectedNode = nodeData as ComputedNode<TradeSecretsSubjectData>;
      if (currentChart === CHART_OPTIONS.TREE) {
        return {
          subject: selectedNode.data.subject,
          potentialTradeSecretsCount: selectedNode.data.ts_count,
          patentsCount: selectedNode.data.patents_count,
          squareColor: selectedNode.color,
        };
      } else {
        const selectedNode = nodeData as ComputedCell<ExtendedDatum>;
        return {
          subject: selectedNode.serieId,
          potentialTradeSecretsCount: selectedNode.data.y,
          patentsCount: selectedNode.data.x,
          squareColor: selectedNode.color,
        };
      }
    }
    return;
  };

  const handleFilterConfig = (): PortfolioModalFiltersConfig | undefined => {
    if (nodeData) {
      if (currentChart === CHART_OPTIONS.TREE) {
        return {
          enabledFilters: [FilterConfigType.Companies, FilterConfigType.Patents, FilterConfigType.Years],
        };
      } else {
        return {
          enabledFilters: [FilterConfigType.Companies, FilterConfigType.Years],
        };
      }
    }
    return;
  };

  return (
    <Dashboard title="">
      <PredictedPortfolioModal
        isShow={isModalOpen}
        onClose={handleCloseModal}
        data={handleSelectedNode(nodeData)}
        filtersOptions={graphsData?.filters}
        initialFilter={handleInitialFilter()}
        currentChart={currentChart}
        filterConfig={handleFilterConfig()}
      />
      <TradeSecretsAnalysisHeadline title={cardTitle} lastUpdate={lastUpdate} chipText={TRANSLATION_KEY.HEADER_CHIP} chipTooltip={TRANSLATION_KEY.HEADER_CHIP_TOOLTIP} />
      {hasNoGraphData ? (
        <EmptyState />
      ) : (
        <PredictedPortfolioContainer>
          {isDemoMode && <DemoBanner source="PredictedPorfolio" />}
          <WidgetsSection widgets={widgets} isLoading={isGraphLoading} />
          <TradeSecretsAnalysisCard isToggleButton handleToggle={handleToggle} title={t(TRANSLATION_KEY.CARD_TITLE)}>
            {isGraphLoading ? renderSkeleton() : renderChart}
          </TradeSecretsAnalysisCard>
          <TangiTypography customStyles={portfolioTableCustomStyle} type="heading-md" weight="semibold">
            {t(TRANSLATION_KEY.PORTFOLIO_TABLE_TITLE)}
          </TangiTypography>
          <RowContainer>
            <PortfolioFilters
              filtersOptions={graphsData?.filters}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              filter={filter}
              setFilter={setFilter}
              initialFilter={initialFilter}
              isFilterApplied={isFilterApplied}
              onClearAll={handleClearSearch}
              handleSearch={handleSearch}
              isTableLoading={isTableLoading}
            />
            <ExportButton
              variant="secondary"
              text={t('PREDICTED_PORTFOLIO.PORTFOLIO_TABLE.EXPORT_DATA')}
              onClick={handleExportTable}
              svgIcon="download"
              disabled={isTableExportLoading || hasNoTableData}
              loading={isTableExportLoading}
              smallbtn
            />
          </RowContainer>
          <PortfolioDataTable
            data={tableData}
            pagination={pagination}
            loading={isTableLoading}
            setPaginationAction={(pagination: Pagination) => dispatch(predictedPortfolioActions.setPagination(pagination))}
          />
        </PredictedPortfolioContainer>
      )}
    </Dashboard>
  );
};

export default PredictedPortfolio;
